/*eslint-disable*/

import React from "react";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInputTooltips.js";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import "assets/scss/custom.css";

//redux
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setDropDownHouseForSection2 from "DataControl/actions/setDropDownHouseForSection2.js";
import setLatlon from "DataControl/actions/setLatlon.js";
import setHouseSection1 from "DataControl/actions/setHouseSection1.js";
import setHouseSection2 from "DataControl/actions/setHouseSection2.js";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import MaterialTable from "material-table";

import numeral from "numeral";
import setDataForSalesTable from "DataControl/actions/setDataForSalesTable.js";
import { API_get_price } from "configure/configServer.js";

class RegularTables extends React.Component {
  constructor(props) {
    super(props);
    let self = this;
    this.state = {
      allprice: [],
      exp: 0,
      price_range: [],
      total: {
        unit_all: 0,
        unit_sellaccum: 0,
        unit_sell: 0,
        unit_transfer: 0,
        unit_leftselltransfer: 0,
        unit_leftsell: 0,
      },
      current_subtype: null,
      assetID: [],
      options_allprice: [],
      pricesID: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      }, //keep selected price id
      priceinfo: [],
      optionItems: [],
      optionItems_price: [],
      options: {
        1: [],
      },
      column: [
        {
          title: "ประเภทที่อยู่อาศัย",
          editable: "onAdd",
          field: "srpunit_cat",
          render: (rowData) => (
            <label>
              {self.state.optionItems.filter((item) => {
                if (item.props.value == rowData.srpunit_cat) {
                  return item.props.name;
                }
              })}
            </label>
          ),
          editComponent: (props) => (
            <Select
              value={props.value}
              min={props.min}
              max={props.max}
              onChange={(event) => this.changeSelectHomeType(event, props)}
            >
              {self.state.optionItems}
            </Select>
          ),
        },
        {
          title: "ช่วงราคา",
          field: "srpunit_pricelenid",
          editable: "onAdd",
          editComponent: (props) => (
            <Select
              value={props.value}
              name={props.name}
              //onClick={() => self.callPriceLength(props)}
              //onClick={event => self.removeSelectedPrice(event, props)}
              onChange={(event) => self.handlePriceChange(event, props)}
            >
              {self.state.optionItems_price}
            </Select>
          ),
          render: (rowData) => this.render_stcprice(rowData),
          // render: function(rowData) {
          //   if (!rowData.srpprice_min) {
          //     let price = self.state.options_allprice.filter((item) => {
          //       if (item.props.value === rowData.srpunit_pricelenid) {
          //         return item.props.name
          //       }
          //     });
          //     return <label>{price}</label>;
          //   } else {
          //     return <label style={{ fontSize: "1rem", fontWeight: 400, textAlign: "center" }}>{ numeral(rowData.srpprice_min).format(0, 0) + " - " + numeral(rowData.srpprice_max).format(0, 0)}</label>
          //   }
          // }
        },
        {
          title: "หน่วยทั้งหมด",
          field: "srpunit_all",
          type: "numeric",
          initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.unit_all_state === "error" ? "ข้อมูลผิด" : ""
              }
              success={this.state.unit_all_state === "success"}
              error={this.state.unit_all_state === "error"}
              type="text"
              formControlProps={{
                fullWidth: true,
              }}
              tooltipText={""}
              inputProps={{
                value: props.value,
                onChange: (event) => {
                  let val = event.target.value;
                  props.onChange(val);
                  try {
                    let value = parseFloat(val);
                    if (value < 0) {
                      this.setState({ unit_all_state: false });
                    }
                  } catch (err) {
                    this.setState({ unit_all_state: false });
                  }
                },
              }}
            />
          ),
        },
        {
          title: "ขายได้สะสม",
          field: "srpunit_sellaccum",
          type: "numeric",
          initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.unit_sellaccum_state === "error" ? "ข้อมูลผิด" : ""
              }
              success={this.state.unit_sellaccum_state === "success"}
              error={this.state.unit_sellaccum_state === "error"}
              type="text"
              formControlProps={{
                fullWidth: true,
              }}
              tooltipText={"หน่วยขายได้สะสม <= หน่วยทั้งหมด"}
              inputProps={{
                value: props.value,
                onChange: (event) => {
                  let val = event.target.value;
                  props.onChange(val);
                },
              }}
            />
          ),
        },
        {
          title: "ขายได้ใหม่ในไตรมาสนี้",
          field: "srpunit_sell",
          type: "numeric",
          initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.unit_sell_state === "error" ? "ข้อมูลผิด" : ""
              }
              success={this.state.unit_sell_state === "success"}
              error={this.state.unit_sell_state === "error"}
              type="text"
              formControlProps={{
                fullWidth: true,
              }}
              tooltipText={"ขายได้ใหม่ในไตรมาสนี้ <= หน่วยขายได้สะสม"}
              inputProps={{
                value: props.value,
                onChange: (event) => {
                  let val = event.target.value;
                  props.onChange(val);
                },
              }}
            />
          ),
        },
        {
          title: "หน่วยโอนสะสม",
          field: "srpunit_transfer",
          type: "numeric",
          initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.unit_transfer_state === "error" ? "ข้อมูลผิด" : ""
              }
              success={this.state.unit_transfer_state === "success"}
              error={this.state.unit_transfer_state === "error"}
              type="text"
              formControlProps={{
                fullWidth: true,
              }}
              tooltipText={"หน่วยโอนสะสม <= ขายได้สะสม"}
              inputProps={{
                value: props.value,
                onChange: (event) => {
                  let val = event.target.value;
                  props.onChange(val);
                },
              }}
            />
          ),
        },
        {
          title: "เหลือขายสร้างเสร็จพร้อมโอน",
          field: "srpunit_leftselltransfer",
          type: "numeric",
          initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.unit_leftselltransfer_state === "error"
                  ? "ข้อมูลผิด"
                  : ""
              }
              success={this.state.unit_leftselltransfer_state === "success"}
              error={this.state.unit_leftselltransfer_state === "error"}
              type="text"
              formControlProps={{
                fullWidth: true,
              }}
              tooltipText={
                "เหลือขายสร้างเสร็จพร้อมโอน <= (หน่วยทั้งหมด - หน่วยขายได้สะสม)"
              }
              inputProps={{
                value: props.value,
                onChange: (event) => {
                  let val = event.target.value;
                  props.onChange(val);
                },
              }}
            />
          ),
        },
        {
          title: "หน่วยเหลือขายยังไม่ได้ก่อสร้าง",
          field: "srpunit_leftsell",
          type: "numeric",
          initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.unit_leftsell_state === "error" ? "ข้อมูลผิด" : ""
              }
              success={this.state.unit_leftsell_state === "success"}
              error={this.state.unit_leftsell_state === "error"}
              type="text"
              formControlProps={{
                fullWidth: true,
              }}
              tooltipText={
                "หน่วยเหลือขายยังไม่ได้ก่อสร้าง <= (หน่วยเหลือขาย - หน่วยขายสร้างเสร็จพร้อมโอน)"
              }
              inputProps={{
                value: props.value,
                onChange: (event) => {
                  //console.log(event.target.value)
                  let val = event.target.value;
                  props.onChange(val);
                },
              }}
            />
          ),
        },
        {
          title: "min",
          field: "srpprice_min",
          type: "numeric",
          hidden: true,
        },
        {
          title: "max",
          field: "srpprice_max",
          type: "numeric",
          hidden: true,
        },
      ],
      data: [],
      index: -1,
    };

    this.change = this.change.bind(this);
    this.formatData = this.formatData.bind(this);
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  componentDidMount() {
    this.getAllPrice();
  }

  callPriceLength = (props) => {
    if (this.state.optionItems_price.length === 0) {
      let subcate = props.rowData.srpunit_cat;
      let min = props.rowData.srpprice_min;
      let max = props.rowData.srpprice_max;

      let cate_code = this.props.assetType;
      let cate_id = null;
      if (cate_code.includes("c")) {
        cate_id = 1;
      } else if (cate_code.includes("h")) {
        cate_id = 2;
      } else {
        cate_id = 3;
      }
      this.getPrice(cate_id, subcate, min, max);
    }
  };

  changeSelectHomeType = (event, props) => {
    props.onChange(event.target.value);

    this.setState({ current_subtype: event.target.value });

    let min = event.currentTarget.getAttribute("min");
    let max = event.currentTarget.getAttribute("max");
    let subcate = event.target.value;
    let cate_code = this.props.assetType;
    let cate_id = null;
    if (cate_code.includes("c")) {
      cate_id = 1;
    } else if (cate_code.includes("h")) {
      cate_id = 2;
    } else {
      cate_id = 3;
    }
    let selected_asset = this.state.assetID.indexOf(subcate);
    if (selected_asset !== -1 && this.state.options[subcate].length !== 0) {
      // ถ้าถูกเลือกไปแล้ว ให้ไป call option จาก state
      this.setState({ optionItems_price: this.state.options[subcate] });
    } else {
      this.setState((prevState) => ({
        assetID: [...prevState.assetID, subcate],
      }));
      this.getPrice(cate_id, event.target.value, min, max);
    }
  };

  render_stcprice = (rowData) => {
    let self = this;
    if (!rowData.srpprice_min) {
      let price = self.state.options_allprice.filter((item) => {
        if (item.props.value === rowData.srpunit_pricelenid) {
          return item.props.name;
        }
      });
      if (price && price[0]) {
        if (price && price[0] && parseInt(price[0].props.max) >= 1000000000) {
          return "มากกว่า " + numeral(price[0].props.min).format("0,0.[0]");
        } else if (
          parseInt(price[0].props.min) === 70000000 &&
          parseInt(price[0].props.max) === 1000000000
        ) {
          return "มากกว่า " + numeral(price[0].props.min).format("0,0.[0]");
        }
        return <label>{price[0]}</label>;
      } else {
        return "";
      }
    } else {
      if (parseInt(rowData.srpprice_max) >= 1000000000) {
        return "มากกว่า " + numeral(rowData.srpprice_min).format("0,0.[0]");
      } else if (
        parseInt(rowData.srpprice_min) === 70000000 &&
        parseInt(rowData.srpprice_max) === 1000000000
      ) {
        return "มากกว่า " + numeral(rowData.srpprice_min).format("0,0.[0]");
      } else {
        return (
          <label
            style={{ fontSize: "1rem", fontWeight: 400, textAlign: "center" }}
          >
            {numeral(rowData.srpprice_min).format(0, 0) +
              " - " +
              numeral(rowData.srpprice_max).format(0, 0)}
          </label>
        );
      }
    }
  };

  getAllPrice = () => {
    let self = this;
    let cate_code = this.props.assetType;
    let cate_id = null;
    if (cate_code.includes("c")) {
      cate_id = 1;
    } else if (cate_code.includes("h")) {
      cate_id = 2;
    } else {
      cate_id = 3;
    }

    let param = {
      srpprice_cat: cate_id,
      srpprice_min: 0,
      srpprice_max: 100000000000,
    };

    var request = new Request(API_get_price, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.props.tokenData,
      }),
      body: JSON.stringify(param),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("a", responseJson);
        if (responseJson.code === 200) {
          let info = responseJson.data;
          let optionItems_price = info.map((prices) => (
            <MenuItem
              key={prices.srpprice_id}
              id={prices.srpprice_id}
              value={prices.srpprice_id}
              min={prices.srpprice_min}
              max={prices.srpprice_max}
              name={
                numeral(prices.srpprice_min).format(0, 0) +
                "-" +
                numeral(prices.srpprice_max).format(0, 0)
              }
            >
              {this.chk_if10m(prices)}
              {/* {numeral(prices.srpprice_min).format(0, 0)} {" "} - {" "} {numeral(prices.srpprice_max).format(0, 0)} */}
            </MenuItem>
          ));
          self.setState({ options_allprice: optionItems_price });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  handlePriceChange = (event, props) => {
    props.onChange(event.target.value);
  };
  warningAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="กรุณาตรวจสอบข้อมูล"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          หน่วยเหลือขายยังไม่ก่อสร้าง <b>ต้องน้อยกว่า หรือเท่ากับ </b>{" "}
          (หน่วยในผังโครงการ - หน่วยขายได้สะสม) <br />
          และ <br />
          หน่วยเหลือขายสร้างเสร็จพร้อมโอน <b> ต้องน้อยกว่า หรือเท่ากับ </b>{" "}
          (หน่วยในผังโครงการ - หน่วยขายได้สะสม)"
        </SweetAlert>
      ),
    });
  };

  getPrice = (cate, subcate, min, max) => {
    let self = this;
    let param = {
      srpprice_cat: cate,
      srpprice_min: min,
      srpprice_max: max,
    };

    var request = new Request(API_get_price, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.props.tokenData,
      }),
      body: JSON.stringify(param),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("b", responseJson);
        if (responseJson.code === 200) {
          if (responseJson.data.length === 0) {
            alert(
              "ไม่พบช่วงราคาที่ถูกต้อง กรุณาตรวจสอบช่วงราคาสูงสุด ต่ำสุดในส่วนที่ 1"
            );
            self.setState({ optionItems_price: [] });
          } else {
            let info = responseJson.data;
            let optionItems_price = info.map((prices) => (
              <MenuItem
                key={prices.srpprice_id}
                id={prices.srpprice_id}
                value={prices.srpprice_id}
                min={prices.srpprice_min}
                max={prices.srpprice_max}
                name={
                  numeral(prices.srpprice_min).format(0, 0) +
                  "-" +
                  numeral(prices.srpprice_max).format(0, 0)
                }
              >
                {this.chk_if10m(prices)}
                {/* {numeral(prices.srpprice_min).format(0, 0)} {" "} - {" "} {numeral(prices.srpprice_max).format(0, 0)} */}
              </MenuItem>
            ));

            self.setState({ optionItems_price: optionItems_price });
            var someProperty = { ...this.state.options };
            self.state.options[subcate] = optionItems_price;
            this.setState({ someProperty });
          }
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  removeSelectedPrice(event, props) {
    let priceID = event.target.value;
    var someProperty = { ...this.state.options };
    let subcate = this.state.current_subtype;

    let newOptions = this.state.optionItems_price.map((price) => {
      let isDisable = false;
      let selected_price = this.state.pricesID[subcate].indexOf(price.props.id);

      if (selected_price === -1) {
        // ถ้ายังไม่ถูกเลือก
        isDisable = false;
      } else {
        isDisable = true;
      }
      let newItem = (
        <MenuItem
          key={price.props.id}
          id={price.props.id}
          value={price.props.value}
          disabled={isDisable}
          min={price.props.min}
          max={price.props.max}
          name={
            numeral(price.props.min).format(0, 0) +
            "-" +
            numeral(price.props.max).format(0, 0)
          }
        >
          {numeral(price.props.min).format(0, 0)} -{" "}
          {numeral(price.props.max).format(0, 0)}
        </MenuItem>
      );
      return newItem;
    });

    this.state.options[subcate] = newOptions;
    this.setState({ someProperty });
    this.setState({ optionItems_price: newOptions }, () => { });

    props.onChange(priceID);
  }

  calcSummary = () => {
    //call sum
    let rawData = this.props.salesTable;
    let total_srpunit_all = 0;
    let total_srpunit_sellaccum = 0;
    let total_srpunit_sell = 0;
    let total_srpunit_transfer = 0;
    let total_srpunit_leftselltransfer = 0;
    let total_srpunit_leftsell = 0;

    for (var i = 0; i < rawData.length; i++) {
      total_srpunit_all += parseInt(rawData[i].srpunit_all);
      total_srpunit_sellaccum += parseInt(rawData[i].srpunit_sellaccum);
      total_srpunit_sell += parseInt(rawData[i].srpunit_sell);
      total_srpunit_transfer += parseInt(rawData[i].srpunit_transfer);
      total_srpunit_leftselltransfer += parseInt(
        rawData[i].srpunit_leftselltransfer
      );
      total_srpunit_leftsell += parseInt(rawData[i].srpunit_leftsell);
    }
    let total = {
      unit_all: total_srpunit_all,
      unit_sellaccum: total_srpunit_sellaccum,
      unit_sell: total_srpunit_sell,
      unit_transfer: total_srpunit_transfer,
      unit_leftselltransfer: total_srpunit_leftselltransfer,
      unit_leftsell: total_srpunit_leftsell,
    };
    this.setState({ total: total });
  };

  componentDidUpdate(previousProps) {
    // house type

    if (previousProps.salesTable !== this.props.salesTable) {
      this.setState(
        { data: this.props.salesTable, exp: this.props.salesTable.length - 1 },
        () => {
          this.formatData();
          this.calcSummary();
        }
      );
    }

    if (
      previousProps.dropdown_house_section2 !==
      this.props.dropdown_house_section2
    ) {
      //create menuItem
      let optionItems = this.props.dropdown_house_section2.map((type) => (
        // eslint-disable-next-line react/jsx-key

        <MenuItem
          key={type.value}
          id={type.value}
          value={type.value}
          min={type.min}
          max={type.max}
          name={type.name}
        >
          {type.name}
        </MenuItem>
      ));

      this.setState({ optionItems: optionItems });
      this.setState({ assetID: [] });
    }
  }

  chk_if10m_type2(val) {
    // {numeral(prices.srpprice_min).format(0,0)} {" "} - {" "} {numeral(prices.srpprice_max).format(0,0)}

    if (val.min === "10000001") {
      return "มากกว่า " + numeral(val.min).format("0,0.[0]");
    } else if (val.min === "70000001" && val.max === "1000000000") {
      return "มากกว่า " + numeral(val.min).format("0,0.[0]");
    } else {
      return (
        numeral(val.min).format("0,0.[0]") +
        " - " +
        numeral(val.max).format("0,0.[0]")
      );
    }
  }

  chk_if10m(val) {
    // {numeral(prices.srpprice_min).format(0,0)} {" "} - {" "} {numeral(prices.srpprice_max).format(0,0)}

    if (val.srpprice_min === "10000000") {
      return "มากกว่า " + numeral(val.srpprice_min).format("0,0.[0]");
    } else if (
      val.srpprice_min === "70000001" &&
      val.srpprice_max === "1000000000"
    ) {
      return "มากกว่า " + numeral(val.srpprice_min).format("0,0.[0]");
    } else {
      return (
        numeral(val.srpprice_min).format("0,0.[0]") +
        " - " +
        numeral(val.srpprice_max).format("0,0.[0]")
      );
    }
  }

  change(event, cate, currentRowNumber) {
    const category = { ...this.state[cate] };
    category[currentRowNumber] = parseInt(event.target.value);
    this.setState({ [cate]: category });
  }

  formatData() {
    let srpunit_cat = [];
    let srpunit_pricelenid = [];
    let srpunit_all = [];
    let srpunit_sellaccum = [];
    let srpunit_sell = [];
    let srpunit_transfer = [];
    let srpunit_leftselltransfer = [];
    let srpunit_leftsell = [];

    let total_srpunit_all = 0;
    let total_srpunit_sellaccum = 0;
    let total_srpunit_sell = 0;
    let total_srpunit_transfer = 0;
    let total_srpunit_leftselltransfer = 0;
    let total_srpunit_leftsell = 0;

    let rawData = this.state.data;
    for (var i = 0; i < rawData.length; i++) {
      srpunit_cat.push(parseInt(rawData[i].srpunit_cat));
      srpunit_pricelenid.push(parseInt(rawData[i].srpunit_pricelenid));
      srpunit_all.push(parseInt(rawData[i].srpunit_all));
      srpunit_sellaccum.push(parseInt(rawData[i].srpunit_sellaccum));
      srpunit_sell.push(parseInt(rawData[i].srpunit_sell));
      srpunit_transfer.push(parseInt(rawData[i].srpunit_transfer));
      srpunit_leftselltransfer.push(
        parseInt(rawData[i].srpunit_leftselltransfer)
      );
      srpunit_leftsell.push(parseInt(rawData[i].srpunit_leftsell));

      total_srpunit_all += parseInt(rawData[i].srpunit_all);
      total_srpunit_sellaccum += parseInt(rawData[i].srpunit_sellaccum);
      total_srpunit_sell += parseInt(rawData[i].srpunit_sell);
      total_srpunit_transfer += parseInt(rawData[i].srpunit_transfer);
      total_srpunit_leftselltransfer += parseInt(
        rawData[i].srpunit_leftselltransfer
      );
      total_srpunit_leftsell += parseInt(rawData[i].srpunit_leftsell);
    }
    let total = {
      unit_all: total_srpunit_all,
      unit_sellaccum: total_srpunit_sellaccum,
      unit_sell: total_srpunit_sell,
      unit_transfer: total_srpunit_transfer,
      unit_leftselltransfer: total_srpunit_leftselltransfer,
      unit_leftsell: total_srpunit_leftsell,
    };
    this.setState({ total: total });

    let sum_unit = total_srpunit_all - total_srpunit_sellaccum;

    let homedata_section2 = {
      srpunit_cat: srpunit_cat,
      srpunit_pricelenid: srpunit_pricelenid,
      srpunit_all: srpunit_all,
      srpunit_sellaccum: srpunit_sellaccum,
      srpunit_sell: srpunit_sell,
      srpunit_transfer: srpunit_transfer,
      srpunit_leftselltransfer: srpunit_leftselltransfer,
      srpunit_leftsell: srpunit_leftsell,
      srpunit_sum: sum_unit,
    };
    this.props.setHouseSection2(homedata_section2);
  }

  // trigger function
  // stc_priceRange = event => {
  //   const arraycat_price = this.state.part2_catprice;
  //   const match_val = arraycat_price.filter(function(obj) {
  //     return obj.value === event;
  //   });

  //   var body = {
  //     srpprice_cat: 2,
  //     srpprice_max: match_val[0].price_max,
  //     srpprice_min: match_val[0].price_min
  //   };

  //   var request = new Request(config.API_getprice, {
  //     method: "POST",
  //     headers: new Headers({ "Content-Type": "application/json" }),
  //     body: JSON.stringify(body)
  //   });

  //   fetch(request)
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       const response_array = responseJson.data;
  //       //console.log(responseJson);
  //       switch (responseJson.code) {
  //         case 200:
  //           if (response_array.length === 0) {
  //             //console.log("no price range");
  //           } else {
  //             this.setState({
  //               stc_pricerange: response_array
  //             });
  //           }
  //           break;
  //         default:
  //           break;
  //       }
  //     })
  //     .catch(error => {
  //       //console.log(error);
  //     });
  // };

  async check_case(newData) {
    let all_unit = parseFloat(newData.srpunit_all); //หน่วยทั้งหมด
    let unit_sellaccum = parseFloat(newData.srpunit_sellaccum); //หน่วยขายได้สะสม
    let unit_newSell = parseFloat(newData.srpunit_sell); //ขายได้ใหม่ในไตรมาสนี้

    let unit_transfer = parseFloat(newData.srpunit_transfer); // หน่วยโอนสะสม
    let unit_leftselltransfer = parseFloat(newData.srpunit_leftselltransfer); //หน่วยเหลือขายสร้างเสร็จพร้อมโอน
    let unit_leftsell = parseFloat(newData.srpunit_leftsell); // หน่วยเหลือขายยังไม่ก่อสร้าง

    let all_unit_left = all_unit - unit_sellaccum; //หน่วยเหลือขายทั้งหมด

    let case1 = true;
    let case2 = true;
    let case3 = true;
    let case4 = true;
    let case5 = true;
    let case6 = true;
    let case7 = true;

    this.setState({
      unit_all_state: "",
      unit_sellaccum_state: "",
      unit_sell_state: "",
      unit_transfer_state: "",
      unit_leftselltransfer_state: "",
      unit_leftsell_state: "",
    });

    //case 1
    if (unit_leftsell > all_unit_left) {
      this.setState({ unit_leftsell_state: "error" });
      case1 = false;
    }

    //case 2 : หน่วยเหลือขายสร้างเสร็จพร้อมโอน + หน่วยเหลือขายยังไม่ก่อสร้าง <= หน่วยเหลือขายทั้งหมด
    if (unit_leftselltransfer + unit_leftsell > all_unit_left) {
      this.setState({
        unit_leftsell_state: "error",
        unit_leftselltransfer_state: "error",
      });
      case2 = false;
    }

    //case 3 : หน่วยขายได้สะสม <= หน่วยทั้งหมด
    if (unit_sellaccum > all_unit) {
      this.setState({ unit_sellaccum_state: "error" });
      case3 = false;
    }

    // case 5 : ขายได้ใหม่ในไตรมาสนี้ <= ขายได้สะสม
    if (unit_newSell > unit_sellaccum) {
      this.setState({
        unit_newSell_state: "error",
        unit_sellaccum_state: "error",
      });
      case5 = false;
    }

    //case 6 : หน่วยโอนสะสม <= ขายได้สะสม
    if (unit_transfer > unit_sellaccum) {
      this.setState({
        unit_transfer_state: "error",
        unit_sellaccum_state: "error",
      });
      case6 = false;
    }

    //case7
    //1.ต้องน้อยกว่า หรือ เท่ากับ หน่วยทั้งหมด - ขายได้สะสม
    //กรณี มีหลายสถานะก่อสร้าง ต้องรวมกันไม่เกิน หน่วยทั้งหมด-ขายได้สะสม

    if (
      unit_leftselltransfer > all_unit - unit_sellaccum ||
      unit_leftsell > all_unit - unit_sellaccum
    ) {
      this.setState({
        unit_leftsell_state: "error",
        unit_leftselltransfer_state: "error",
      });
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.allCaseTrue(case1, case2, case3, case5, case6, case7));
      }, 1000);
    });
  }
  allCaseTrue(case1, case2, case3, case5, case6, case7) {
    if (case1 && case2 && case3 && case5 && case6 && case7) {
      return true;
    } else {
      return false;
    }
  }

  // render_cattype(type) {
  //   const arraycat_type = this.state.stc_pricerange;
  //   // //console.log(arraycat_type);

  //   const match_val = arraycat_type.filter(function(obj) {
  //     return obj.srpprice_id === type;
  //   });
  //   // //console.log(match_val);

  //   return (
  //     numeral(match_val[0].srpprice_min).format("0,0.[0]") +
  //     " - " +
  //     numeral(match_val[0].srpprice_max).format("0,0.[0]")
  //   );
  // }

  render() {
    const { classes } = this.props;
    let self = this;
    const StyleHeader = {
      border: "1px solid #dddddd",
      textAlign: "center",
      padding: "8px 40px 8px 40px",
      borderLeft: "none",
      fontWeight: "bold",
    };
    const StyleTHeader = {
      fontSize: "16px",
      width: "100%",
      borderCollapse: "collapse",
      whiteSpace: "nowrap",
      textAlign: "center",
      fontWeight: "bold",
    };
    let salesData = this.props.salesTable ? this.props.salesTable : [];
    let title = [
      { title: "ประเภทที่อยู่อาศัย", field: "srpunit_cat" },
      { title: "ช่วงราคา", field: "srpunit_pricelenid" },
      { title: "หน่วยทั้งหมด", field: "srpunit_all" },
      { title: "ขายได้สะสม", field: "srpunit_sellaccum" },
      { title: "ขายได้ใหม่ในไตรมาสนี้", field: "srpunit_sell" },
      { title: "หน่วยโอนสะสม", field: "srpunit_transfer" },
      {
        title: "หน่วยเหลือขายสร้างเสร็จพร้อมโอน",
        field: "srpunit_leftselltransfer",
      },
      { title: "หน่วยเหลือขายยังไม่ได้ก่อสร้าง", field: "srpunit_leftsell" },
      { title: "การจัดการ" },
    ];
    let summary = [
      this.state.total.unit_all,
      this.state.total.unit_sellaccum,
      this.state.total.unit_sell,
      this.state.total.unit_transfer,
      this.state.total.unit_leftselltransfer,
      this.state.total.unit_leftsell,
    ];
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <MaterialTable
            components={{
              Header: (props) => (
                <thead style={StyleTHeader}>
                  <tr>
                    <td style={StyleHeader}>ประเภทที่อยู่อาศัย</td>
                    <td style={StyleHeader}>ช่วงราคา</td>
                    <td style={StyleHeader}>หน่วยทั้งหมด</td>
                    <td style={StyleHeader}>ขายได้สะสม</td>
                    <td style={StyleHeader}>ขายได้ใหม่ในไตรมาสนี้</td>
                    <td style={StyleHeader}>หน่วยโอนสะสม</td>
                    <td style={StyleHeader}>หน่วยเหลือขายสร้างเสร็จพร้อมโอน</td>
                    <td style={StyleHeader}>หน่วยเหลือขายยังไม่ได้ก่อสร้าง</td>
                    <td style={StyleHeader}>การจัดการ</td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={StyleHeader}>
                      รวม
                    </td>
                    <td style={StyleHeader}>{summary[0]}</td>
                    <td style={StyleHeader}>{summary[1]}</td>
                    <td style={StyleHeader}>{summary[2]}</td>
                    <td style={StyleHeader}>{summary[3]}</td>
                    <td style={StyleHeader}>{summary[4]}</td>
                    <td style={StyleHeader}>{summary[5]}</td>
                    <td style={StyleHeader}></td>
                  </tr>
                </thead>
              ),
            }}
            title="รายงานที่อยู่อาศัยในโครงการ"
            columns={this.state.column}
            data={salesData}
            options={{
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0 ? "#EEE" : "#FFF",
                marginBottom: "50px",
              }),
            }}
            localization={{
              header: {
                actions: "การจัดการ",
              },
              body: {
                addTooltip: "เพิ่มข้อมูล",
                editTooltip: "แก้ไขข้อมูล",
                deleteTooltip: "ลบข้อมูล",
                emptyDataSourceMessage: "ไม่มีข้อมูล",
                editRow: {
                  deleteText: "ยืนยันการลบข้อมูล",
                  saveTooltip: "บันทึก",
                  cancelTooltip: "ยกเลิก",
                },
              },
              toolbar: {
                searchTooltip: "ค้นหา",
                searchPlaceholder: "ค้นหา",
              },
              pagination: {
                labelRowsSelect: "เลือก",
                labelDisplayedRows: "แถว {from}-{to} จาก {count}",
                firstTooltip: "หน้าแรก",
                previousTooltip: "ก่อนหน้า",
                nextTooltip: "ถัดไป",
                lastTooltip: "สุดท้าย",
              },
            }}
            icons={{
              Add: () => (
                <Button color="info">
                  <Add />
                  เพิ่มข้อมูล
                </Button>
              ),
            }}
            editable={{
              onRowAdd: (newData) =>
                // >> หน่วยเหลือขายยังไม่ก่อสร้าง <= (หน่วยในผังโครงการ - หน่วยขายได้สะสม)
                // >> หน่วยเหลือขายสร้างเสร็จพร้อมโอน <= (หน่วยในผังโครงการ - หน่วยขายได้สะสม)

                new Promise((resolve, reject) => {
                  //self.checkCase(newData);

                  let all_unit = parseFloat(newData.srpunit_all); //หน่วยทั้งหมด
                  let unit_sellaccum = parseFloat(newData.srpunit_sellaccum); //หน่วยขายได้สะสม
                  let unit_newSell = parseFloat(newData.srpunit_sell); //ขายได้ใหม่ในไตรมาสนี้

                  let unit_transfer = parseFloat(newData.srpunit_transfer); // หน่วยโอนสะสม
                  let unit_leftselltransfer = parseFloat(
                    newData.srpunit_leftselltransfer
                  ); //หน่วยเหลือขายสร้างเสร็จพร้อมโอน
                  let unit_leftsell = parseFloat(newData.srpunit_leftsell); // หน่วยเหลือขายยังไม่ก่อสร้าง

                  let all_unit_left = all_unit - unit_sellaccum; //หน่วยเหลือขายทั้งหมด

                  let case1 = true;
                  let case2 = true;
                  let case3 = true;
                  let case4 = true;
                  let case5 = true;
                  let case6 = true;
                  let case7 = true;

                  //case 1 : หน่วยเหลือขายยังไม่ก่อสร้าง <= หน่วยเหลือขายทั้งหมด
                  this.setState({
                    unit_all_state: "",
                    unit_sellaccum_state: "",
                    unit_sell_state: "",
                    unit_transfer_state: "",
                    unit_leftselltransfer_state: "",
                    unit_leftsell_state: "",
                  });

                  //case 1
                  if (unit_leftsell > all_unit_left) {
                    this.setState({ unit_leftsell_state: "error" });
                    case1 = false;
                  }

                  //case 2 : หน่วยเหลือขายสร้างเสร็จพร้อมโอน + หน่วยเหลือขายยังไม่ก่อสร้าง <= หน่วยเหลือขายทั้งหมด
                  if (unit_leftselltransfer + unit_leftsell > all_unit_left) {
                    this.setState({
                      unit_leftsell_state: "error",
                      unit_leftselltransfer_state: "error",
                    });
                    case2 = false;
                    //console.log("case 2");
                  }

                  //case 3 : หน่วยขายได้สะสม <= หน่วยทั้งหมด
                  if (unit_sellaccum > all_unit) {
                    this.setState({ unit_sellaccum_state: "error" });
                    case3 = false;
                    //console.log("case 3");
                  }

                  //case 4
                  // if((unit_sellaccum + unit_newSell) > all_unit) {
                  //   this.setState({unit_sell_state: "error", unit_sellaccum_state: "error"});
                  //   case4 = false;
                  // }

                  // case 5 : ขายได้ใหม่ในไตรมาสนี้ <= ขายได้สะสม
                  if (unit_newSell > unit_sellaccum) {
                    this.setState({
                      unit_newSell_state: "error",
                      unit_sellaccum_state: "error",
                    });
                    case5 = false;
                  }

                  //case 6 : หน่วยโอนสะสม <= ขายได้สะสม
                  if (unit_transfer > unit_sellaccum) {
                    this.setState({
                      unit_transfer_state: "error",
                      unit_sellaccum_state: "error",
                    });
                    case6 = false;
                  }

                  //case7
                  //1.ต้องน้อยกว่า หรือ เท่ากับ หน่วยทั้งหมด - ขายได้สะสม
                  //กรณี มีหลายสถานะก่อสร้าง ต้องรวมกันไม่เกิน หน่วยทั้งหมด-ขายได้สะสม

                  if (
                    unit_leftselltransfer > all_unit - unit_sellaccum ||
                    unit_leftsell > all_unit - unit_sellaccum
                  ) {
                    this.setState({
                      unit_leftsell_state: "error",
                      unit_leftselltransfer_state: "error",
                    });
                    case7 = false;
                  }

                  if (case1 && case2 && case3 && case5 && case6 && case7) {
                    //console.log(newData)
                    setTimeout(() => {
                      {
                        const data = this.state.data;
                        //console.log(newData);
                        data.push(newData);
                        this.setState({ data }, () => {
                          resolve();
                          self.formatData();
                        });
                      }
                      let subcate = newData.srpunit_cat;
                      let selected_price = this.state.pricesID[subcate].indexOf(
                        newData.srpunit_pricelenid
                      );
                      if (selected_price === -1) {
                        let newColums = { ...this.state.pricesID };
                        newColums[subcate].push(newData.srpunit_pricelenid);
                        this.setState({ pricesID: newColums });
                      }
                      resolve();
                    }, 500);
                  } else {
                    //console.log("reject")
                    reject();
                  }
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(async (resolve, reject) => {
                  const chk_state = await self.check_case(newData);

                  if (chk_state) {
                    setTimeout(() => {
                      {
                        const data = this.state.data;
                        const index = data.indexOf(oldData);
                        data[index] = newData;
                        self.setState({ data }, () => {
                          resolve();
                        });
                        self.formatData();
                      }
                      resolve();
                    }, 500);
                  } else {
                    self.warningAlert();
                    reject();
                  }
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let data = this.state.data;
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);

                      this.setState({ data }, () => {
                        resolve();
                        self.formatData();
                      });
                    }
                    resolve();
                  }, 500);
                }),
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

RegularTables.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setDropDownHouseForSection2: (payload) =>
    dispatch(setDropDownHouseForSection2(payload)),
  setHouseSection1: (payload) => dispatch(setHouseSection1(payload)),
  setHouseSection2: (payload) => dispatch(setHouseSection2(payload)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  setDataForSalesTable: (payload) => dispatch(setDataForSalesTable(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(RegularTables));
