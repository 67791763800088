/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import * as config from "configure/configServer.js";
import moment from "moment";

// @material-ui/icons
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { PulseLoader } from "react-spinners";

import { connect } from "react-redux";

import setBoundary from "actions/survey/setBoundary.js";
import setProv from "actions/survey/setProvAmphoeTambon.js";

import house_sec1_part3Object from "actions/survey/house/house_sec1_part3Data.js";
import project_datatable from "actions/survey/psurvey_editproj.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-thai-datepickers";
import "moment/locale/th";

class OwnerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasCompany: true,
      hasCompanyState: "",
      renderCompany: [],
      orgLoading: false,
      popperOpen: false,
      province: [],
      amphoe: [],
      tambon: [],

      ownerType: "1",
      ownerTypeState: "success",
      statec_namec_instm: "",
      statec_name: "",
      statec_nameState: "",
      statec_namec_address: "",
      statec_namec_addressState: "",
      statec_namec_moo: "",
      statec_namec_mooState: "",
      statec_namec_floor: "",
      statec_namec_floorState: "",
      statec_namec_namebuilding: "",
      statec_namec_namebuildingState: "",
      statec_namec_soi: "",
      statec_namec_soiState: "",
      statec_namec_road: "",
      statec_namec_roadState: "",
      statec_namec_subdistrict: "",
      statec_namec_subdistrictState: "",
      statec_namec_district: "",
      statec_namec_districtState: "",
      statec_namec_province: "",
      statec_namec_provinceState: "",
      statec_namec_tel: "",
      statec_namec_telState: "",
      statec_namec_fax: "",
      statec_namec_faxState: "",
      statec_namec_saletel: "",
      statec_namec_saletelState: "",
      statec_namec_website: "",
      statec_namec_websiteState: "",
      state_houshold_explorers: "",
      state_houshold_explorersState: "",
      state_houshold_exploredate: null,
      state_houshold_exploredateState: "",
      state_houshold_remark: "",
      state_houshold_remarkState: "",
      statec_namec_subdistrict_label: "",
      statec_namec_district_label: "",
      statec_namec_province_label: "",
      title_org: [
        { label: "บมจ.", value: "1" },
        { label: "บจก.", value: "2" },
        { label: "หหส.", value: "3" },
        { label: "หจก.", value: "4" },
        { label: "อื่นๆ", value: "5" },
      ],
      org_title: "",
      org_titleState: "",
    };
    this.handleOwnerType = this.handleOwnerType.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getProvince();
    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      // create new project
      // console.log("new project");
      this.props.project_datatable(undefined);
    } else {
      // edit existing project
      // console.log("existing project");
      this.get_projectData(this.props.proj_dataTable, false);
    }
  }

  get_projectData = async (value, isOld) => {
    const edit_data = value.data[0];
    // console.log(edit_data);
    if (!isOld) {
      this.setState({
        ownerType: edit_data.c_instm.toString(),
        statec_namec_instm: edit_data.c_instm,
        statec_namec_address: edit_data.c_address,
        statec_namec_moo: edit_data.c_moo,
        statec_namec_district_label: edit_data.c_district,
        statec_namec_fax: edit_data.c_fax,
        statec_namec_floor: edit_data.c_floor,
        statec_namec_soi: edit_data.c_soi,
        statec_namec_namebuilding: edit_data.c_namebuilding,
        statec_namec_province_label: edit_data.c_province,
        statec_namec_road: edit_data.c_road,
        statec_namec_subdistrict_label: edit_data.c_subdistrict,
        statec_namec_tel: edit_data.c_tel,
        statec_namec_saletel: edit_data.household_tel_saleoffice,
        statec_namec_website: edit_data.household_projectwebsite,
        state_houshold_explorers: edit_data.household_explorers,
        state_houshold_exploredate:
          moment(edit_data.household_explorerdate).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.household_explorerdate).format("DD/MM/YYYY") ===
            "01/01/1970" ||
          edit_data.household_explorerdate === null ||
          edit_data.household_explorerdate === undefined
            ? null
            : new Date(edit_data.household_explorerdate),
        statec_namec_subdistrict: edit_data.t_value,
        statec_namec_district: edit_data.d_value,
        statec_namec_province: edit_data.p_value,
        state_houshold_remark: edit_data.household_note,
      });
      if (edit_data.c_name && edit_data.c_name.length > 4) {
        let filterOrgtitle = this.state.title_org.filter(
          (orgTi) => orgTi.label === edit_data.c_name.substring(0, 4)
        );
        if (filterOrgtitle.length > 0) {
          this.setState({
            statec_name: edit_data.c_name.substring(4),
            org_title: filterOrgtitle[0].value,
          });
        } else {
          this.setState({
            org_title: "5",
            statec_name: edit_data.c_name,
          });
        }
      } else {
        this.setState({
          statec_name: edit_data.c_name,
        });
      }
      this.getAmphoe(edit_data.p_value);
      this.getTambon(edit_data.p_value, edit_data.d_value);
      this.setAllSuccess(edit_data, isOld);
    } else {
      this.setState({
        ownerType: edit_data.c_instm.toString(),
        statec_namec_instm: edit_data.c_instm,
        statec_namec_address: edit_data.c_address,
        statec_namec_moo: edit_data.c_moo,
        statec_namec_district_label: edit_data.c_district,
        statec_namec_fax: edit_data.c_fax,
        statec_namec_floor: edit_data.c_floor,
        statec_namec_soi: edit_data.c_soi,
        statec_namec_namebuilding: edit_data.c_namebuilding,
        statec_namec_province_label: edit_data.c_province,
        statec_namec_road: edit_data.c_road,
        statec_namec_subdistrict_label: edit_data.c_subdistrict,
        statec_namec_tel: edit_data.c_tel,
        statec_namec_saletel: edit_data.household_tel_saleoffice,
        statec_namec_website: edit_data.household_projectwebsite,
        // state_houshold_explorers: edit_data.household_explorers,
        // state_houshold_exploredate:
        //   moment(edit_data.household_explorerdate).format("DD/MM/YYYY") ===
        //     "01/01/1900" ||
        //     moment(edit_data.household_explorerdate).format("DD/MM/YYYY") ===
        //     "01/01/1970" ||
        //     edit_data.household_explorerdate === null ||
        //     edit_data.household_explorerdate === undefined
        //     ? null
        //     : new Date(edit_data.household_explorerdate),
        statec_namec_subdistrict: edit_data.t_value,
        statec_namec_district: edit_data.d_value,
        statec_namec_province: edit_data.p_value,
        // state_houshold_remark: edit_data.household_note,
      });
      if (edit_data.c_name && edit_data.c_name.length > 4) {
        let filterOrgtitle = this.state.title_org.filter(
          (orgTi) => orgTi.label === edit_data.c_name.substring(0, 4)
        );
        if (filterOrgtitle.length > 0) {
          this.setState({
            statec_name: edit_data.c_name.substring(4),
            org_title: filterOrgtitle[0].value,
          });
        } else {
          this.setState({
            org_title: "5",
            statec_name: edit_data.c_name,
          });
        }
      } else {
        this.setState({
          statec_name: edit_data.c_name,
        });
      }
      this.getAmphoe(edit_data.p_value);
      this.getTambon(edit_data.p_value, edit_data.d_value);
      this.setAllSuccess(edit_data, isOld);
    }
  };

  componentDidUpdate(previousProps) {
    // console.log(this.state);
    // eslint-disable-next-line react/prop-types
    if (
      JSON.stringify(previousProps.oldproj_data) !==
      JSON.stringify(this.props.oldproj_data)
    ) {
      this.get_projectData(this.props.oldproj_data, true);
    }
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleOwnerType(event) {
    this.setState({
      ownerType: event.target.value,
      statec_namec_instm:
        event.target.value === "3"
          ? event.target.value
          : this.state.statec_namec_instm,
      ownerTypeState: "success",
      statec_name: "",
      statec_namec_address: "",
      statec_namec_moo: "",
      statec_namec_floor: "",
      statec_namec_namebuilding: "",
      statec_namec_soi: "",
      statec_namec_road: "",
      statec_namec_subdistrict: "",
      statec_namec_district: "",
      statec_namec_province: "",
      statec_namec_tel: "",
      statec_namec_fax: "",
      statec_namec_saletel: "",
      statec_namec_website: "",
      renderCompany: [],
      statec_namec_subdistrict_label: "",
      statec_namec_district_label: "",
      statec_namec_province_label: "",
      org_title: "",
      // clear state
      statec_nameState: "",
      statec_namec_addressState: "",
      statec_namec_mooState: "",
      statec_namec_floorState: "",
      statec_namec_namebuildingState: "",
      statec_namec_soiState: "",
      statec_namec_roadState: "",
      statec_namec_subdistrictState: "",
      statec_namec_districtState: "",
      statec_namec_provinceState: "",
      statec_namec_telState: "",
      statec_namec_faxState: "",
      statec_namec_saletelState: "",
      statec_namec_websiteState: "",
    });
  }
  // function that returns true if value is phone, false otherwise
  verifyPhone(value) {
    var phoneRex = /^[#*0-9, /-]+$/;
    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is meet profile details, false otherwise
  verifyName(value) {
    var nameRex = /^[A-Za-zก-๙ ]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyAddress(value) {
    var addressRex = /^[a-zA-Zก-๙0-9.,_ ()-\s/]+$/;
    if (addressRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyUrl(value) {
    var urlRex = /^[a-z:;/A-Zก-๙0-9.,_% -\s/]+$/;
    if (urlRex.test(value) || value === "-") {
      return true;
    }
    return false;
  }

  // validate forms
  change(event, stateName, type) {
    switch (type) {
      case "phone":
        if (this.verifyPhone(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "address":
        if (this.verifyAddress(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "name":
        if (this.verifyName(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  // handle trigger function
  // check add org trigger?
  validateRegister() {
    // console.log("run register");

    const {
      statec_namec_saletel,
      statec_namec_website,
      state_houshold_explorers,
      state_houshold_remark,
      state_houshold_exploredate,
      statec_name,
      statec_namec_address,
      statec_namec_moo,
      statec_namec_floor,
      statec_namec_namebuilding,
      statec_namec_soi,
      statec_namec_road,
      statec_namec_subdistrict,
      statec_namec_district,
      statec_namec_province,
      statec_namec_tel,
      statec_namec_fax,
      statec_namec_instm,
    } = this.state;

    setTimeout(() => {
      var body = {
        household_tel_saleoffice:
          statec_namec_saletel === "" ? "N_A" : statec_namec_saletel,
        household_projectwebsite:
          statec_namec_website === "" ? "N_A" : statec_namec_website,
        household_explorers:
          state_houshold_explorers === "" ? "N_A" : state_houshold_explorers,
        household_note:
          state_houshold_remark === "" ? "N_A" : state_houshold_remark,
        household_explorerdate:
          state_houshold_exploredate === "" ||
          state_houshold_exploredate === null
            ? "01/01/1900"
            : moment(new Date(state_houshold_exploredate)).format("DD/MM/YYYY"),
        // company
        c_name:
          statec_name === "" ? "N_A" : this.state.org_title + " " + statec_name,
        c_address: statec_namec_address === "" ? "N_A" : statec_namec_address,
        c_moo: statec_namec_moo === "" ? "N_A" : statec_namec_moo,
        c_floor: statec_namec_floor === "" ? "N_A" : statec_namec_floor,
        c_namebuilding:
          statec_namec_namebuilding === "" ? "N_A" : statec_namec_namebuilding,
        c_soi: statec_namec_soi === "" ? "N_A" : statec_namec_soi,
        c_road: statec_namec_road === "" ? "N_A" : statec_namec_road,
        c_subdistrict:
          statec_namec_subdistrict === "" ? "N_A" : statec_namec_subdistrict,
        c_district:
          statec_namec_district === "" ? "N_A" : statec_namec_district,
        c_province:
          statec_namec_province === "" ? "N_A" : statec_namec_province,
        c_tel: statec_namec_tel === "" ? "N_A" : statec_namec_tel,
        c_fax: statec_namec_fax === "" ? "N_A" : statec_namec_fax,
        c_instm: statec_namec_instm === "" ? "N_A" : statec_namec_instm,
      };

      if (statec_name !== "") {
        let filterOrgtitle = this.state.title_org.filter(
          (orgTi) =>
            orgTi.value === this.state.org_title && orgTi.value !== "อื่นๆ"
        );
        if (filterOrgtitle.length > 0 && filterOrgtitle[0].label !== "อื่นๆ") {
          body.c_name = filterOrgtitle[0].label + statec_name;
        } else {
          body.c_name = statec_name;
        }
      } else {
        body.c_name = "N_A";
      }

      // console.log(body);
      this.props.house_sec1_part3Object(body);
    }, 500);
  }
  resetAllState() {
    this.setState({
      statec_nameState: "",
      statec_namec_addressState: "",
      statec_namec_mooState: "",
      statec_namec_floorState: "",
      statec_namec_namebuildingState: "",
      statec_namec_soiState: "",
      statec_namec_roadState: "",
      statec_namec_subdistrictState: "",
      statec_namec_districtState: "",
      statec_namec_provinceState: "",
      statec_namec_telState: "",
      statec_namec_faxState: "",
      statec_namec_saletelState: "",
      statec_namec_websiteState: "",
      state_houshold_explorersState: "",
      state_houshold_exploredateState: "",
      state_houshold_remarkState: "",
    });
  }

  validateState(state) {
    const {
      ownerType,
      ownerTypeState,
      org_titleState,
      statec_name,
      statec_nameState,
      statec_namec_addressState,
      statec_namec_mooState,
      statec_namec_floorState,
      statec_namec_namebuildingState,
      statec_namec_soiState,
      statec_namec_roadState,
      statec_namec_subdistrictState,
      statec_namec_districtState,
      statec_namec_provinceState,
      statec_namec_telState,
      statec_namec_faxState,
      statec_namec_saletelState,
      statec_namec_websiteState,
      state_houshold_explorersState,
      state_houshold_exploredateState,
      state_houshold_remarkState,
    } = this.state;

    switch (state) {
      case "save":
        if (
          statec_nameState === "error" ||
          statec_namec_addressState === "error" ||
          statec_namec_mooState === "error" ||
          statec_namec_floorState === "error" ||
          statec_namec_namebuildingState === "error" ||
          statec_namec_soiState === "error" ||
          statec_namec_roadState === "error" ||
          statec_namec_subdistrictState === "error" ||
          statec_namec_districtState === "error" ||
          statec_namec_provinceState === "error" ||
          statec_namec_telState === "error" ||
          statec_namec_faxState === "error" ||
          statec_namec_saletelState === "error" ||
          statec_namec_websiteState === "error" ||
          state_houshold_explorersState === "error" ||
          state_houshold_exploredateState === "error" ||
          state_houshold_remarkState === "error"
        ) {
          return false;
        } else {
          return true;
        }
      case "submit":
        if (
          ownerTypeState === "success" &&
          statec_nameState === "success" &&
          statec_namec_addressState === "success" &&
          statec_namec_mooState === "success" &&
          statec_namec_floorState === "success" &&
          statec_namec_namebuildingState === "success" &&
          statec_namec_soiState === "success" &&
          statec_namec_roadState === "success" &&
          statec_namec_subdistrictState === "success" &&
          statec_namec_districtState === "success" &&
          statec_namec_provinceState === "success" &&
          statec_namec_telState === "success" &&
          statec_namec_faxState === "success" &&
          statec_namec_saletelState === "success" &&
          statec_namec_websiteState === "success" &&
          state_houshold_explorersState === "success" &&
          state_houshold_remarkState === "success" &&
          state_houshold_exploredateState === "success"
        ) {
          return true;
        } else {
          this.setAllEmpty();
          return false;
        }
      default:
        break;
    }
  }

  setAllSuccess(edit_data, isOld) {
    // console.log("attri");
    // console.log("Test", isOld);
    if (!isOld) {
      // console.log("A");
      if (
        edit_data["c_instm"] !== undefined &&
        edit_data["c_instm"] !== "" &&
        edit_data["c_instm"] !== null
      ) {
        this.setState({ ownerTypeState: "success" });
      }
      if (
        edit_data["c_name"] !== undefined &&
        edit_data["c_name"] !== "" &&
        edit_data["c_name"] !== null
      ) {
        this.setState({ statec_nameState: "success" });
      }
      if (
        edit_data["c_province"] !== undefined &&
        edit_data["c_province"] !== "" &&
        edit_data["c_province"] !== null
      ) {
        this.setState({ statec_namec_provinceState: "success" });
      }
      if (
        edit_data["c_subdistrict"] !== undefined &&
        edit_data["c_subdistrict"] !== "" &&
        edit_data["c_subdistrict"] !== null
      ) {
        this.setState({ statec_namec_subdistrictState: "success" });
      }
      if (
        edit_data["c_district"] !== undefined &&
        edit_data["c_district"] !== "" &&
        edit_data["c_district"] !== null
      ) {
        this.setState({ statec_namec_districtState: "success" });
      }
      if (
        edit_data["c_saletel"] !== undefined &&
        edit_data["c_saletel"] !== "" &&
        edit_data["c_saletel"] !== null
      ) {
        this.setState({ statec_namec_saletelState: "success" });
      }
      if (
        edit_data["c_tel"] !== undefined &&
        edit_data["c_tel"] !== "" &&
        edit_data["c_tel"] !== null
      ) {
        this.setState({ statec_namec_telState: "success" });
      }
      if (
        edit_data["c_road"] !== undefined &&
        edit_data["c_road"] !== "" &&
        edit_data["c_road"] !== null
      ) {
        this.setState({ statec_namec_roadState: "success" });
      }
      let attributes = [
        "c_address",
        "c_moo",
        "c_floor",
        "c_namebuilding",
        "c_soi",
        "c_road",
        "c_tel",
        "c_fax",
      ];
      attributes.forEach((name) => {
        if (
          edit_data[name] !== undefined &&
          edit_data[name] !== "" &&
          edit_data[name] !== null
        ) {
          this.setState({ ["statec_name" + name + "State"]: "success" });
        }
      });
      if (
        edit_data["household_note"] !== undefined &&
        edit_data["household_note"] !== "" &&
        edit_data["household_note"] !== null
      ) {
        this.setState({ state_houshold_remarkState: "success" });
      }
      if (
        edit_data["household_projectwebsite"] !== undefined &&
        edit_data["household_projectwebsite"] !== "" &&
        edit_data["household_projectwebsite"] !== null
      ) {
        this.setState({ statec_namec_websiteState: "success" });
      }
      if (
        edit_data["household_tel_saleoffice"] !== undefined &&
        edit_data["household_tel_saleoffice"] !== "" &&
        edit_data["household_tel_saleoffice"] !== null
      ) {
        this.setState({ statec_namec_saletelState: "success" });
      }
      if (
        edit_data["household_explorers"] !== undefined &&
        edit_data["household_explorers"] !== "" &&
        edit_data["household_explorers"] !== null
      ) {
        this.setState({ state_houshold_explorersState: "success" });
      }
      if (
        edit_data["household_explorerdate"] !== undefined &&
        edit_data["household_explorerdate"] !== null &&
        edit_data["household_explorerdate"] !== ""
      ) {
        this.setState({ state_houshold_exploredateState: "success" });
      }
    } else {
      if (
        edit_data["c_instm"] !== undefined &&
        edit_data["c_instm"] !== "" &&
        edit_data["c_instm"] !== null
      ) {
        this.setState({ ownerTypeState: "success" });
      }
      if (
        edit_data["c_name"] !== undefined &&
        edit_data["c_name"] !== "" &&
        edit_data["c_name"] !== null
      ) {
        this.setState({ statec_nameState: "success" });
      }
      if (
        edit_data["c_province"] !== undefined &&
        edit_data["c_province"] !== "" &&
        edit_data["c_province"] !== null
      ) {
        this.setState({ statec_namec_provinceState: "success" });
      }
      if (
        edit_data["c_subdistrict"] !== undefined &&
        edit_data["c_subdistrict"] !== "" &&
        edit_data["c_subdistrict"] !== null
      ) {
        this.setState({ statec_namec_subdistrictState: "success" });
      }
      if (
        edit_data["c_district"] !== undefined &&
        edit_data["c_district"] !== "" &&
        edit_data["c_district"] !== null
      ) {
        this.setState({ statec_namec_districtState: "success" });
      }
      if (
        edit_data["c_saletel"] !== undefined &&
        edit_data["c_saletel"] !== "" &&
        edit_data["c_saletel"] !== null
      ) {
        this.setState({ statec_namec_saletelState: "success" });
      }
      if (
        edit_data["c_tel"] !== undefined &&
        edit_data["c_tel"] !== "" &&
        edit_data["c_tel"] !== null
      ) {
        this.setState({ statec_namec_telState: "success" });
      }
      if (
        edit_data["c_road"] !== undefined &&
        edit_data["c_road"] !== "" &&
        edit_data["c_road"] !== null
      ) {
        this.setState({ statec_namec_roadState: "success" });
      }
      let attributes = [
        "c_address",
        "c_moo",
        "c_floor",
        "c_namebuilding",
        "c_soi",
        "c_road",
        "c_tel",
        "c_fax",
      ];
      attributes.forEach((name) => {
        if (
          edit_data[name] !== undefined &&
          edit_data[name] !== "" &&
          edit_data[name] !== null
        ) {
          this.setState({ ["statec_name" + name + "State"]: "success" });
        }
      });
      // if (
      //   edit_data["household_note"] !== undefined &&
      //   edit_data["household_note"] !== "" &&
      //   edit_data["household_note"] !== null
      // ) {
      //   this.setState({ state_houshold_remarkState: "success" });
      // }
      if (
        edit_data["household_projectwebsite"] !== undefined &&
        edit_data["household_projectwebsite"] !== "" &&
        edit_data["household_projectwebsite"] !== null
      ) {
        this.setState({ statec_namec_websiteState: "success" });
      }
      if (
        edit_data["household_tel_saleoffice"] !== undefined &&
        edit_data["household_tel_saleoffice"] !== "" &&
        edit_data["household_tel_saleoffice"] !== null
      ) {
        this.setState({ statec_namec_saletelState: "success" });
      }
      // if (
      //   edit_data["household_explorers"] !== undefined &&
      //   edit_data["household_explorers"] !== "" &&
      //   edit_data["household_explorers"] !== null
      // ) {
      //   this.setState({ state_houshold_explorersState: "success" });
      // }
      // if (
      //   edit_data["household_explorerdate"] !== undefined &&
      //   edit_data["household_explorerdate"] !== null &&
      //   edit_data["household_explorerdate"] !== ""
      // ) {
      //   this.setState({ state_houshold_exploredateState: "success" });
      // }
    }
  }

  setAllEmpty() {
    if (this.state.ownerType === "") {
      this.setState({ ownerTypeState: "error" });
    }
    if (this.state.statec_name === "") {
      this.setState({ statec_nameState: "error" });
    }
    if (this.state.statec_namec_province === "") {
      this.setState({ statec_namec_provinceState: "error" });
    }
    if (this.state.statec_namec_subdistrict === "") {
      this.setState({ statec_namec_subdistrictState: "error" });
    }
    if (this.state.statec_namec_district === "") {
      this.setState({ statec_namec_districtState: "error" });
    }
    if (this.state.statec_namec_saletel === "") {
      this.setState({ statec_namec_saletelState: "error" });
    }
    if (this.state.statec_namec_website === "") {
      this.setState({ statec_namec_websiteState: "error" });
    }
    if (this.state.state_houshold_explorers === "") {
      this.setState({ state_houshold_explorersState: "error" });
    }

    let attributes = [
      "statec_namec_address",
      "statec_namec_moo",
      "statec_namec_floor",
      "statec_namec_namebuilding",
      "statec_namec_soi",
      "statec_namec_road",
      "statec_namec_tel",
      "statec_namec_fax",
    ];
    attributes.forEach((name) => {
      if (this.state[name] === "") {
        this.setState({ [name + "State"]: "error" });
      }
    });
    // console.log("state_houshold_exploredate")
    // console.log(this.state.state_houshold_exploredate)
    if (this.state.state_houshold_exploredate === null) {
      this.setState({ state_houshold_exploredateState: "error" });
    }
  }

  // auto complete org field
  getOrg(event, type) {
    let value = event.target.value;
    this.setState({ statec_name: value, statec_nameState: "error" });

    var body = {
      org_name: value,
      org_instm: type,
    };
    // console.log(body);
    if (value.length >= 2) {
      this.setState({ orgLoading: true });
      var request = new Request(config.API_get_org, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson.data;
          // console.log(data);
          if (data.length >= 1) {
            // console.log(responseJson.data);
            this.setState({
              popperOpen: true,
              renderCompany: responseJson.data,
              orgLoading: false,
            });
          } else {
            // console.log("else none data");
            this.setState({
              popperOpen: false,
              orgLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            popperOpen: false,
            renderCompany: [],
            statec_nameState: "error",
            orgLoading: false,
          });
        });
    } else {
      this.setState({
        popperOpen: false,
        renderCompany: [],
        statec_nameState: "error",
        orgLoading: false,
      });
    }
  }
  // end of auto complete org field

  loadingOrg(isLoading) {
    if (isLoading) {
      return (
        <PulseLoader size={8} color={"gray"} loading={this.state.loading} />
      );
    } else {
      return null;
    }
  }

  handleSelect(value) {
    if (value.org_address === "") {
      this.setState({
        statec_namec_address: value.org_address,
        statec_namec_addressState: "error",
      });
    } else {
      this.setState({
        statec_namec_address: value.org_address,
        statec_namec_addressState: "success",
      });
    }
    if (value.org_moo === "") {
      this.setState({
        statec_namec_moo: value.org_moo,
        statec_namec_mooState: "error",
      });
    } else {
      this.setState({
        statec_namec_moo: value.org_moo,
        statec_namec_mooState: "success",
      });
    }
    this.setState({
      popperOpen: false,
      statec_name: value.org_name,
      statec_nameState: "success",
      statec_namec_floor: value.org_floor,
      statec_namec_floorState: "success",
      statec_namec_namebuilding: value.org_namebuilding,
      statec_namec_namebuildingState: "success",
      statec_namec_soi: value.org_soi,
      statec_namec_soiState: "success",
      statec_namec_road: value.org_road,
      statec_namec_roadState: "success",
      statec_namec_subdistrict: value.tam_value,
      statec_namec_subdistrictState: "success",
      statec_namec_district: value.d_value,
      statec_namec_districtState: "success",
      statec_namec_province: value.p_value,
      statec_namec_provinceState: "success",
      statec_namec_tel: value.org_tel,
      statec_namec_telState: "success",
      statec_namec_fax: value.org_fax,
      statec_namec_faxState: "success",
      statec_namec_website: value.org_website,
      statec_namec_websiteState: "success",
      statec_namec_saletel: this.datanull_number(value.org_saletel),
      statec_namec_saletelState: this.datanull_state(value.org_saletel),
      statec_namec_subdistrict_label: value.org_subdistrict,
      statec_namec_district_label: value.org_district,
      statec_namec_province_label: value.org_province,
      statec_namec_instm: value.org_instm,
    });
  }
  datanull_text(value) {
    if (value === "") {
      return "--";
    } else {
      return value;
    }
  }
  datanull_number(value) {
    if (value === "") {
      return 0;
    } else {
      return value;
    }
  }
  datanull_state(value) {
    if (value === "") {
      return "error";
    } else {
      return "success";
    }
  }
  handleClose() {
    this.setState({ popperOpen: false });
  }

  setExploreDate = (date) => {
    try {
      this.setState({
        state_houshold_exploredate: moment(date),
        state_houshold_exploredateState: "success",
      });
    } catch (err) {
      //
    }
  };

  // get province
  getProvince() {
    this.setState({ amphoe: [], tambon: [] });
    var body = {
      pro_id: 0,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson.data)
        let prov = responseJson.data.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        this.setState({
          province: prov,
        });
      })
      .catch((error) => {});
  }
  getAmphoe(prov_val) {
    this.setState({ amphoe: [] });
    var body = {
      pro_id: prov_val,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          amphoe: responseJson.data,
        });
      })
      .catch((error) => {
        //
      });
  }
  getTambon(prov_val, amp_val) {
    this.setState({ tambon: [] });
    var body = {
      pro_id: prov_val,
      amp_id: amp_val,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          tambon: responseJson.data,
        });
      })
      .catch((error) => {
        //
      });
  }

  handleSelectedProv = (event) => {
    this.setState({
      statec_namec_subdistrict: "",
      statec_namec_district: "",
      [event.target.name]: event.target.value,
      statec_namec_provinceState: "success",
    });
    this.getAmphoe(event.target.value);
  };
  handleSelectedSub = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      statec_namec_subdistrictState: "success",
    });
    this.getTambon(this.state.statec_namec_province, event.target.value);
  };
  handleSelectedDist = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      statec_namec_districtState: "success",
    });
  };
  handleSelectedOrgTitle = (event) => {
    this.setState({
      org_title: event.target.value,
      org_titleState: "success",
    });
  };

  render() {
    const { classes } = this.props;

    let style_prov =
      this.state.statec_namec_provinceState === "error" ? "red" : "green";
    let style_amp =
      this.state.statec_namec_subdistrictState === "error" ? "red" : "green";
    let style_tam =
      this.state.statec_namec_districtState === "error" ? "red" : "green";

    let optionItems = this.state.renderCompany.map((com) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={com.org_id}
        value={com.org_id}
        style={{ backgroundColor: "#fff" }}
        onClick={() => this.handleSelect(com)}
      >
        {com.org_name}
      </MenuItem>
    ));

    let provinceItems = this.state.province.map((prov) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={prov.label}
        value={prov.value}
        style={{ backgroundColor: "#fff" }}
      >
        {prov.label}
      </MenuItem>
    ));

    let amphoneItems = this.state.amphoe.map((amp) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={amp.label}
        value={amp.value}
        style={{ backgroundColor: "#fff" }}
      >
        {amp.label}
      </MenuItem>
    ));

    let tambonItems = this.state.tambon.map((tam) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={tam.label}
        value={tam.value}
        style={{ backgroundColor: "#fff" }}
      >
        {tam.label}
      </MenuItem>
    ));

    let orgTitle = this.state.title_org.map((org) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={org.label}
        value={org.value}
        style={{ backgroundColor: "#fff" }}
      >
        {org.label}
      </MenuItem>
    ));

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color: this.state.ownerTypeState === "error" ? "red" : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              เลือกประเภทผู้ประกอบการ
            </FormLabel>
            <div className={classes.inlineChecks}>
              <FormControlLabel
                control={
                  <Radio
                    checked={
                      this.state.ownerType === "1" ||
                      this.state.ownerType === "2"
                    }
                    onChange={this.handleOwnerType}
                    value="1"
                    name="radio button demo"
                    aria-label="ownerFromSystem"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ในระบบ"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.ownerType === "3"}
                    onChange={this.handleOwnerType}
                    value="3"
                    name="radio button demo"
                    aria-label="ownerCreateNew"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อื่นๆ (กรณีไม่เจอในระบบ)"
              />
            </div>
          </GridItem>
          {this.state.ownerType === "1" || this.state.ownerType === "2" ? (
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <CustomInput
                success={this.state.statec_nameState === "success"}
                error={this.state.statec_nameState === "error"}
                labelText="ชื่อผู้ประกอบการ"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: this.state.statec_name,
                  onChange: (event) => this.getOrg(event, 1),
                  ref: (node) => {
                    this.anchorEl = node;
                  },
                  endAdornment:
                    this.state.statec_nameState === "error" ? (
                      <InputAdornment position="end">
                        {this.state.orgLoading ? (
                          <div style={{ width: "50px" }}>
                            {this.loadingOrg(this.state.orgLoading)}
                          </div>
                        ) : null}
                        <p
                          style={{
                            width: "105px",
                            margin: "0",
                            textAlign: "end",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          ไม่พบบริษัท
                        </p>
                      </InputAdornment>
                    ) : (
                      undefined
                    ),
                  disabled: !this.state.hasCompany,
                }}
              />
              <Popper
                open={this.state.popperOpen}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                placement="bottom-end"
                className={
                  "MuiPaper-root MuiMenu-paper MuiPaper-elevation8 MuiPopover-paper MuiPaper-rounded"
                }
                style={{
                  position: "absolute",
                  zIndex: 10,
                  maxHeight: "200px",
                  minWidth: "38%",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <ClickAwayListener onClickAway={this.handleClose}>
                  <Paper className={classes.selectMenu}>{optionItems}</Paper>
                </ClickAwayListener>
              </Popper>
            </GridItem>
          ) : null}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <GridContainer>
              {this.state.ownerType === "3" ? (
                <>
                  <GridItem xs={12} sm={12} md={2} lg={2}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                      style={{
                        margin: "0 0 17px 0",
                        position: "relative",
                        paddingTop: "27px",
                        verticalAlign: "unset",
                      }}
                    >
                      <InputLabel
                        className={classes.selectLabel}
                        style={{
                          top: "10px",
                          color:
                            this.state.org_titleState === ""
                              ? "#AAAAAA"
                              : this.state.org_titleState === "success"
                              ? "green"
                              : "red",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "1.42857",
                        }}
                      >
                        คำนำหน้า
                      </InputLabel>
                      <Select
                        style={{
                          marginTop: "0px",
                          textAlign: "start",
                          fontSize: "16px",
                        }}
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.org_title}
                        onChange={this.handleSelectedOrgTitle}
                        inputProps={{
                          name: "org_title",
                          id: "org_title",
                        }}
                      >
                        {orgTitle}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <CustomInput
                      success={this.state.statec_nameState === "success"}
                      error={this.state.statec_nameState === "error"}
                      labelText="ชื่อผู้ประกอบการกรอกใหม่"
                      id="c_name_new"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) =>
                          this.change(event, "statec_name", "address"),
                        type: "text",

                        value: this.state.statec_name,
                      }}
                    />
                  </GridItem>
                </>
              ) : null}

              <GridItem xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  success={this.state.statec_namec_addressState === "success"}
                  error={this.state.statec_namec_addressState === "error"}
                  labelText="ที่อยู่เลขที่"
                  id="c_address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "statec_namec_address", "address"),
                    type: "text",
                    value: this.state.statec_namec_address,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  success={this.state.statec_namec_mooState === "success"}
                  error={this.state.statec_namec_mooState === "error"}
                  labelText="หมู่ที่"
                  id="c_moo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "statec_namec_moo", "address"),
                    type: "text",
                    value: this.state.statec_namec_moo,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  success={this.state.statec_namec_floorState === "success"}
                  error={this.state.statec_namec_floorState === "error"}
                  labelText="ชั้นที่"
                  id="c_floor"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "statec_namec_floor", "address"),
                    type: "text",
                    value: this.state.statec_namec_floor,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  success={
                    this.state.statec_namec_namebuildingState === "success"
                  }
                  error={this.state.statec_namec_namebuildingState === "error"}
                  labelText="ชื่ออาคาร"
                  id="c_building"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(
                        event,
                        "statec_namec_namebuilding",
                        "address"
                      ),
                    type: "text",
                    value: this.state.statec_namec_namebuilding,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  success={this.state.statec_namec_soiState === "success"}
                  error={this.state.statec_namec_soiState === "error"}
                  labelText="ซอย"
                  id="c_soi"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "statec_namec_soi", "address"),
                    type: "text",
                    value: this.state.statec_namec_soi,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  success={this.state.statec_namec_roadState === "success"}
                  error={this.state.statec_namec_roadState === "error"}
                  labelText="ถนน"
                  id="c_road"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "statec_namec_road", "address"),
                    type: "text",
                    value: this.state.statec_namec_road,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>

          {this.state.ownerType === "3" ? (
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{
                      margin: "0 0 17px 0",
                      position: "relative",
                      paddingTop: "27px",
                      verticalAlign: "unset",
                    }}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      style={{
                        top: "10px",
                        color:
                          this.state.statec_namec_provinceState === ""
                            ? "#AAAAAA"
                            : style_prov,
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "1.42857",
                      }}
                    >
                      จังหวัด
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: "0px",
                        textAlign: "start",
                        fontSize: "16px",
                      }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.statec_namec_province}
                      onChange={this.handleSelectedProv}
                      inputProps={{
                        name: "statec_namec_province",
                        id: "statec_namec_province",
                      }}
                    >
                      {provinceItems}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{
                      margin: "0 0 17px 0",
                      position: "relative",
                      paddingTop: "27px",
                      verticalAlign: "unset",
                    }}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      style={{
                        top: "10px",
                        color:
                          this.state.statec_namec_districtState === ""
                            ? "#AAAAAA"
                            : style_amp,
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "1.42857",
                      }}
                    >
                      เขต / อำเภอ
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: "0px",
                        textAlign: "start",
                        fontSize: "16px",
                      }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.statec_namec_district}
                      onChange={this.handleSelectedSub}
                      inputProps={{
                        name: "statec_namec_district",
                        id: "statec_namec_district",
                      }}
                    >
                      {amphoneItems}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{
                      margin: "0 0 17px 0",
                      position: "relative",
                      paddingTop: "27px",
                      verticalAlign: "unset",
                    }}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      style={{
                        top: "10px",
                        color:
                          this.state.statec_namec_subdistrictState === ""
                            ? "#AAAAAA"
                            : style_tam,
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "1.42857",
                      }}
                    >
                      แขวง / ตำบล
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: "0px",
                        textAlign: "start",
                        fontSize: "16px",
                      }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.statec_namec_subdistrict}
                      onChange={this.handleSelectedDist}
                      inputProps={{
                        name: "statec_namec_subdistrict",
                        id: "statec_namec_subdistrict",
                      }}
                    >
                      {tambonItems}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </GridItem>
          ) : (
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    success={
                      this.state.statec_namec_provinceState === "success"
                    }
                    error={this.state.statec_namec_provinceState === "error"}
                    labelText="จังหวัด"
                    id="c_province"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: this.state.statec_namec_province_label,
                      disabled: true,
                      type: "text",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    success={
                      this.state.statec_namec_districtState === "success"
                    }
                    error={this.state.statec_namec_districtState === "error"}
                    labelText="เขต / อำเภอ "
                    id="c_amp"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: this.state.statec_namec_district_label,
                      disabled: true,
                      type: "text",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    success={
                      this.state.statec_namec_subdistrictState === "success"
                    }
                    error={this.state.statec_namec_subdistrictState === "error"}
                    labelText="แขวง / ตำบล"
                    id="c_tumbon"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: this.state.statec_namec_subdistrict_label,
                      disabled: true,
                      type: "text",
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          )}

          <GridItem xs={12} sm={12} md={6} lg={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.statec_namec_telState === "success"}
                  error={this.state.statec_namec_telState === "error"}
                  labelText="โทรศัพท์"
                  id="c_phone"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "statec_namec_tel", "phone"),
                    type: "text",
                    value: this.state.statec_namec_tel,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.statec_namec_faxState === "success"}
                  error={this.state.statec_namec_faxState === "error"}
                  labelText="โทรสาร"
                  id="c_fax"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "statec_namec_fax", "phone"),
                    type: "text",
                    value: this.state.statec_namec_fax,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={this.state.statec_namec_saletelState === "success"}
              error={this.state.statec_namec_saletelState === "error"}
              labelText="โทรศัพท์สำนักงานขาย"
              id="c_saletel"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.statec_namec_saletel,
                onChange: (event) =>
                  this.change(event, "statec_namec_saletel", "phone"),
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={this.state.statec_namec_websiteState === "success"}
              error={this.state.statec_namec_websiteState === "error"}
              labelText="เว็บไซต์บริษัท"
              id="c_website"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.statec_namec_website,
                onChange: (event) =>
                  this.change(event, "statec_namec_website", "url"),
                type: "text",
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={
                    this.state.state_houshold_explorersState === "success"
                  }
                  error={this.state.state_houshold_explorersState === "error"}
                  labelText="นักสำรวจ"
                  id="explorer"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.state_houshold_explorers,
                    onChange: (event) =>
                      this.change(event, "state_houshold_explorers", "name"),
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  style={{
                    margin: "0 0 17px 0",
                    position: "relative",
                    paddingTop: "24px",
                    verticalAlign: "unset",
                  }}
                >
                  <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                    <DatePicker
                      clearable="true"
                      label={
                        <div
                          style={{
                            color:
                              this.state.state_houshold_exploredateState ===
                              "error"
                                ? "red"
                                : this.state.state_houshold_exploredateState ===
                                  "success"
                                ? "green"
                                : "black",
                          }}
                        >
                          วันที่สำรวจ
                        </div>
                      }
                      placeholder="วันที่สำรวจ"
                      format="DD/MM/YYYY"
                      pickerHeaderFormat="ddd D MMM"
                      yearOffset={543}
                      variant="inline"
                      onChange={this.setExploreDate}
                      value={this.state.state_houshold_exploredate}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={this.state.state_houshold_remarkState === "success"}
              error={this.state.state_houshold_remarkState === "error"}
              labelText="หมายเหตุ"
              id="remark"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.state_houshold_remark,
                onChange: (event) =>
                  this.change(event, "state_houshold_remark", "address"),
                type: "text",
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

OwnerInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBoundary: (payload) => dispatch(setBoundary(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  house_sec1_part3Object: (payload) =>
    dispatch(house_sec1_part3Object(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(OwnerInfo));
