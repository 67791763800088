import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
// import Footer from "components/Footer/Footer.js";
//import loadingScreen from "../views/Components/Loading.js";

import routes from "routes.js";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import GHFooter from "components/Footer/GHFooter.js";
import GHAuthNavBar from "components/Navbars/GHAuthNavbar.js";

import GHBankBackground from "assets/img/ghbank-bg.png";

class Pages extends React.Component {
  wrapper = React.createRef();
  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  static propTypes = {
    loadingSet: PropTypes.string.isRequired,
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBgImage = () => {
    return GHBankBackground;
  };

  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        {/*loadingScreen(this.props.loadingSet)*/}
        <GHAuthNavBar brandText={this.getActiveRoute(routes)} {...rest} />
        <div className={classes.wrapper} ref={this.wrapper}>
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + this.getBgImage() + ")" }}
          >
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="/auth" to="/auth/login-page" />
            </Switch>
            <GHFooter lang={this.props.toggleLng} />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withStyles(pagesStyle)(Pages));
