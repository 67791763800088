import * as React from "react";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css"; // sass
import "assets/scss/custom.css";
import { connect } from "react-redux";
import setPointsOnMap from "DataControl/actions/setPointsOnMap.js";
import "react-leaflet-fullscreen/dist/styles.css";
import {
  heremap_API_key,
  heremap_appID,
  heremap_appCode,
} from "configure/configServer.js";
import CryptoJS from "crypto-js";
// import { API_view_all_project, API_gen_secretkey } from "configure/configServer.js";
import * as config from "configure/configServer.js";
import "leaflet-switch-scale-control";
import "leaflet-switch-scale-control/src/L.Control.SwitchScaleControl.css";
import "leaflet.locatecontrol";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: require("assets/img/icon/h_red.png"),
      houseLayer: new L.MarkerClusterGroup(),
      condoLayer: new L.MarkerClusterGroup(),
      villaLayer: new L.MarkerClusterGroup(),
      icon: {
        home: {
          red: require("assets/img/icon/h_red.png"), //1. โครงการปิดการขาย
          green: require("assets/img/icon/h_green.png"), //2. อยู่ระหว่างการขาย
          lightgreen: require("assets/img/icon/h_lightgreen.png"), //3. เปิดขายใหม่ในช่วงสำรวจ
          blue: require("assets/img/icon/h_blue.png"), //4. โครงการในอนาคต
          yellow: require("assets/img/icon/h_yellow.png"), //5. โครงการชะลอการขาย
          pink: require("assets/img/icon/h_pink.png"), //6. เปิดและปิดการขายในช่วงสำรวจ
        },
        condo: {
          red: require("assets/img/icon/c_red.png"), //1. โครงการปิดการขาย
          green: require("assets/img/icon/c_green.png"), //2. อยู่ระหว่างการขาย
          lightgreen: require("assets/img/icon/c_lightgreen.png"), //3. เปิดขายใหม่ในช่วงสำรวจ
          blue: require("assets/img/icon/c_blue.png"), //4. โครงการในอนาคต
          yellow: require("assets/img/icon/c_yellow.png"), //5. โครงการชะลอการขาย
          pink: require("assets/img/icon/c_pink.png"), //6. เปิดและปิดการขายในช่วงสำรวจ
        },
        villa: {
          red: require("assets/img/icon/v_red.png"), //1. โครงการปิดการขาย
          green: require("assets/img/icon/v_green.png"), //2. อยู่ระหว่างการขาย
          lightgreen: require("assets/img/icon/v_lightgreen.png"), //3. เปิดขายใหม่ในช่วงสำรวจ
          blue: require("assets/img/icon/v_blue.png"), //4. โครงการในอนาคต
          yellow: require("assets/img/icon/v_yellow.png"), //5. โครงการชะลอการขาย
          pink: require("assets/img/icon/v_pink.png"), //6. เปิดและปิดการขายในช่วงสำรวจ
        },
      },
    };
  }

  componentDidUpdate() {
    if (this.props.pointsOnMap) {
      this.updateMarkerOnMapHouse();
    }
  }

  loadProject = () => {
    // console.log("load");
    var param = {
      srp_name: 0,
    };

    let self = this;
    var request = new Request(config.API_list_member, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData,
      }),
      body: JSON.stringify(param),
    });
    fetch(request)
      .then(function(response) {
        // console.log(response);
        response.json().then(function(data) {
          switch (data.code) {
            case 200:
              //success
              // console.log("pass");
              let featureCollection = data.data[0].jsonb_build_object;
              // console.log(self.props);
              // console.log(featureCollection);
              self.props.setPointsOnMap(featureCollection);
              self.updateMarkerOnMapHouse();
              // console.log("pass2");
              break;
            default:
              //failed
              // console.log("fail");
              break;
          }
        });
      })
      .catch(function(err) {
        // console.log(err);
      });
    // console.log("return");
    return false;
  };

  updateMarkerOnMapHouse() {
    // console.log("update");
    //houseLayer
    // console.log(this.props.pointsOnMap);
    let self = this;
    let data = this.props.pointsOnMap;
    let type = this.props.type;
    // console.log(this.props.type, "type");

    try {
      let condoData = L.geoJSON(data, {
        pointToLayer: function(feature, latlng) {
          // console.log(feature, type, "type");
          let cate = [feature.properties.srp_cat];
          // let approved = [feature.properties.srp_approved];
          if (cate === 1) {
            let status = feature.properties.cstats_id;
            let url = self.getUrlIcon(status, "condo");
            return L.marker(latlng, {
              icon: self.genMarker(url),
            });
          }
          // if ("all" == "all") {
          //   if (cate == 1) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "condo");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // } else if (type == "sent") {
          //   if (cate == 1 && approved != 4) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "condo");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // } else {
          //   if (cate == 1 && approved == 4) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "condo");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // }
        },
        onEachFeature: function(feature, layer) {
          if (feature.properties && feature.properties) {
            return layer.bindPopup(self.genPopupContent(feature));
          }
        },
      });

      this.state.condoLayer.addLayer(condoData);
    } catch (err) {
      // console.log(err);
    }

    try {
      let houseData = L.geoJSON(data, {
        pointToLayer: function(feature, latlng) {
          let cate = [feature.properties.srp_cat];
          // let approved = [feature.properties.srp_approved];
          if (cate === 2) {
            let status = feature.properties.cstats_id;
            let url = self.getUrlIcon(status, "home");
            return L.marker(latlng, { icon: self.genMarker(url) });
          }
          // if ("all" == "all") {
          //   if (cate == 2) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "home");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // } else if (type == "sent") {
          //   if (cate == 2 && approved != 4) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "home");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // } else {
          //   if (cate == 2 && approved == 4) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "home");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // }
        },
        onEachFeature: function(feature, layer) {
          if (feature.properties && feature.properties) {
            return layer.bindPopup(self.genPopupContent(feature));
          }
        },
      });

      this.state.houseLayer.addLayer(houseData);
    } catch (err) {
      // console.log(err);
    }

    try {
      let villaData = L.geoJSON(data, {
        pointToLayer: function(feature, latlng) {
          let cate = [feature.properties.srp_cat];
          // let approved = [feature.properties.srp_approved];
          if (cate === 3) {
            let status = feature.properties.cstats_id;
            let url = self.getUrlIcon(status, "villa");
            return L.marker(latlng, {
              icon: self.genMarker(url),
            });
          }
          // if (type == "all") {
          //   if (cate == 3) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "villa");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // } else if (type == "sent") {
          //   if (cate == 3 && approved != 4) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "villa");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // } else {
          //   if (cate == 3 && approved == 4) {
          //     let status = feature.properties.cstats_id;
          //     let url = self.getUrlIcon(status, "villa");
          //     return L.marker(latlng, { icon: self.genMarker(url) });
          //   }
          // }
        },
        onEachFeature: function(feature, layer) {
          if (feature.properties && feature.properties) {
            return layer.bindPopup(self.genPopupContent(feature));
          }
        },
      });
      this.state.villaLayer.addLayer(villaData);
    } catch (err) {
      // console.log(err);
    }
  }

  genPopupContent = (feature) => {
    let address =
      "<h4>ต." +
      feature.properties.tambon_t +
      " อ." +
      feature.properties.amphoe_t +
      " จ." +
      feature.properties.province_t +
      "</h4>";
    let status = "<h4>" + feature.properties.cstats_name + "</h4>";
    let popupContent =
      "<h3><b>ชื่อโครงการ: </b>" +
      feature.properties.srp_name +
      "</h3>" +
      address +
      status;

    return popupContent;
  };

  genMarker = (url) => {
    let myIcon = L.icon({
      iconUrl: url,
      iconSize: [40, 40],
      shadowSize: [45, 40],
      iconAnchor: [20, 20],
      popupAnchor: [0, -10],
    });

    return myIcon;
  };

  getUrlIcon = (status, asset_type) => {
    let self = this;
    let url = null;
    switch (status) {
      case 1:
        url = self.state.icon[asset_type].red;
        break;
      case 2:
        url = self.state.icon[asset_type].green;
        break;
      case 3:
        url = self.state.icon[asset_type].lightgreen;
        break;
      case 4:
        url = self.state.icon[asset_type].blue;
        break;
      case 5:
        url = self.state.icon[asset_type].yellow;
        break;
      case 6:
        url = self.state.icon[asset_type].pink;
        break;
      default:
        url = self.state.icon[asset_type].green;
        break;
    }
    return url;
  };

  componentDidMount() {
    let self = this;
    //console.log(self.refs.map)

    let osm_map = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 19,
    });

    // let url_heremap =
    //   "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png?app_id=" +
    //   heremap_appID +
    //   "&app_code=" +
    //   heremap_appCode;

    let url_heremap = `https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?apiKey=${heremap_API_key}&ppi=320`;

    // let url_heremap = "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";

    let sattelite = L.tileLayer(url_heremap, {
      attribution:
        '&copy; <a href="https://www.here.com">HERE Technologies | HERE </a>',
      maxZoom: 19,
    });

    self.map = L.map(self.refs.map, {
      layers: [
        sattelite,
        self.state.houseLayer,
        self.state.condoLayer,
        self.state.villaLayer,
      ],
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: "topleft",
      },
    }).setView([13.82487, 100.637605], 8);

    let baseMaps = {
      ภาพดาวเทียม: sattelite,
      OpenStreetMap: osm_map,
    };

    let overLayMap = {
      บ้าน: self.state.houseLayer,
      อาคารชุด: self.state.condoLayer,
      บ้านพักตากอากาศ: self.state.villaLayer,
    };

    self.map.addControl(
      new L.Control.SwitchScaleControl({
        ratioCustomItemText: "1: ระบุเลข scale",
        updateWhenIdle: true,
        scales: [
          1000,
          2500,
          5000,
          10000,
          20000,
          40000,
          70000,
          140000,
          300000,
          600000,
          1200000,
          2300000,
        ],
      })
    );

    L.control.locate().addTo(self.map);

    L.control.layers(baseMaps, overLayMap).addTo(self.map);
    setTimeout(() => {
      self.map.invalidateSize();
    }, 1000);

    this.loadProject();
    // console.log("continue");
    this.updateMarkerOnMapHouse();
  }

  getToken = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
          });
          this.loadProject();
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  render() {
    return (
      <div
        ref="map"
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setPointsOnMap: (payload) => dispatch(setPointsOnMap(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);
