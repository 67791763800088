import React from "react";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import _ from "lodash";

const DetailQC = (props) => {
  const [data, setData] = React.useState();
  // console.log(props);

  React.useEffect(() => {
    // console.log(props);
    const getData = () => {
      let dataFilter = _.filter(props.data, (o) => o["condo_approved"] === 2);
      // console.log(dataFilter);
      setData(dataFilter);
    };
    getData();
  }, []);

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={() => props.handleClose()}
      >
        ย้อนกลับ
      </Button>
      <MaterialTable
        title="รายละเอียดโครงการไม่ผ่าน"
        columns={[
          { title: "รหัสโครงการ", field: "condo_refcode" },
          { title: "ชื่อโครงการ", field: "condo_nameth" },
          { title: "เหตุผล", field: "rj_detail" },
        ]}
        data={data}
      />
    </div>
  );
};

export default DetailQC;
