import TH from "configure/TH.json";
import EN from "configure/EN.json";

const switchIntl = intl => {
  if (intl === "TH") {
    return TH;
  } else {
    return EN;
  }
};

export default switchIntl;
