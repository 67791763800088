/*eslint-disable*/

import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";
import HouseTable from "views/Project/Home/edit/HouseTable.js";
import moment from "moment";
//External Lib
import CryptoJS from "crypto-js";
//redux
import { connect } from "react-redux";
import setLanguage from "actions/setLanguage";
import tokenSession from "actions/tokenSession.js";
import setHouseSection1 from "actions/setHouseSection1.js";
import setHouseSection2 from "actions/setHouseSection2.js";
import switchIntl from "configure/switchIntl.js";
import { API_gen_secretkey, API_saveSRP } from "configure/configServer.js";
import { API_editSRP } from "configure/configServer.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      design: false,
      code: false,
      develop: false,
      salesDate: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.houseInfoSection1 !== this.props.houseInfoSection1) {
      this.showDate(
        moment(this.props.houseInfoSection1.srp_selldate, "MM/DD/YYYY").format(
          "DD/MM/YYYY"
        )
      );
    }
  }

  showDate(dateStr) {
    //console.log("format data === ");
    //console.log(dateStr);
    const DATE_REGEXP = new RegExp(
      "^(0?[1-9]|[1-2][0-9]|3[0-1])/(0?[1-9]|1[0-2])/([0-9]{4})$",
      "gi"
    );
    dateStr = dateStr.replace(DATE_REGEXP, (str, day, month, year) => {
      return `${day}/${month}/${parseInt(year, 10) + 543}`;
    });
    this.setState({ salesDate: dateStr });
  }

  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  validateField = () => {
    let self = this;
    //console.log(this.props.houseInfoSection2)
    if (!this.props.houseInfoSection2) {
      self.setState({
        alert: (
          <SweetAlert
            info
            style={{ display: "block", marginTop: "-100px" }}
            title={""}
            onConfirm={() => self.hideAlert()}
            onCancel={() => self.hideAlert()}
            confirmBtnText={switchIntl(self.props.toggleLng).basic_word.ok}
          >
            กรุณาตรวจสอบข้อมูลในส่วนที่ 2 ก่อนทำการบันทึก
          </SweetAlert>
        ),
      });
    } else {
      this.getToken();
    }
  };

  getToken = () => {
    let self = this;
    var request = new Request(API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          self.setState({
            tokenData: tokenData,
          });

          self.saveData();
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  saveData() {
    let self = this;
    let data_section1 = this.props.houseInfoSection1;
    let data_section2 = this.props.houseInfoSection2;
    let all_data = Object.assign({}, data_section1, data_section2);

    let URL = API_saveSRP;
    if (this.props.stepProps.approved !== 4) {
      URL = API_editSRP;
    }
    var request = new Request(URL, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.tokenData,
      }),
      body: JSON.stringify(all_data),
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          //console.log(data)
          switch (data.code) {
            case 200:
              //success
              self.setState({
                alert: (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => self.hideAlert()}
                    onCancel={() => self.hideAlert()}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    {switchIntl(self.props.toggleLng).basic_word.save_success}
                  </SweetAlert>
                ),
              });
              break;
            default:
              self.setState({
                alert: (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={""}
                    onConfirm={() => self.hideAlert()}
                    onCancel={() => self.hideAlert()}
                    confirmBtnText={
                      switchIntl(self.props.toggleLng).basic_word.ok
                    }
                  >
                    {" "}
                    {switchIntl(self.props.toggleLng).basic_word.save_failed}
                  </SweetAlert>
                ),
              });
              break;
          }
        });
      })
      .catch(function(err) {
        //console.log(err);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <h3 className={classes.infoText}>รายงานข้อมูลโครงการ{}</h3>
        <GridContainer justify="center">
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>รหัสอ้างอิง</span>}
              id="project_id"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                // eslint-disable-next-line react/prop-types
                value: this.props.houseInfoSection1.srp_projectid,
                disabled: true,
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>ชื่อโครงการ</span>}
              id="project_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.props.houseInfoSection1.srp_name,
                disabled: true,
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>วันที่เปิดขาย</span>}
              id="salesDate"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.salesDate,
                //value: moment(this.props.houseInfoSection1.srp_selldate,'MM/DD/YYYY').format('MM/DD/YYYY'),
                disabled: true,
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              labelText={<span>ที่ตั้งโครงการ</span>}
              id="address"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.props.houseInfoSection1.srp_address,
                disabled: true,
              }}
            ></CustomInput>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <HouseTable
            tokenData={this.state.tokenData}
            assetType={this.props.stepProps.assetType}
          ></HouseTable>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <div style={{ float: "right" }}>
              <Button color="info" onClick={() => this.validateField()}>
                {" "}
                บันทึก
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setHouseSection1: (payload) => dispatch(setHouseSection1(payload)),
  setHouseSection2: (payload) => dispatch(setHouseSection2(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Step2));
