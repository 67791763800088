/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Map from "views/PSurvey/Condo/elements/MapNewProject.js";
import ProvAmphoeTambon from "views/PSurvey/Condo/elements/ProvAmphoeTambon.js";
import FormLabel from "@material-ui/core/FormLabel";

import { connect } from "react-redux";

import setBoundary from "actions/survey/setBoundary.js";
import setProv from "actions/survey/setProvAmphoeTambon.js";
import setLatlon from "actions/survey/setLatlon.js";
import project_datatable from "actions/survey/psurvey_editproj.js";

import house_sec1_part1Location from "actions/survey/condo/condo_sec1_part1Location.js";

import { API_get_prov } from "configure/configServer.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class LocationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      default: "hello",
      survey_address: "",
      survey_addressState: "",
      survey_moo: "",
      survey_mooState: "",
      survey_soi: "",
      survey_soiState: "",
      survey_road: "",
      survey_roadState: "",
      survey_postcode: "",
      survey_postcodeState: "",
      survey_location_lat: 0.0,
      survey_location_latState: "",
      survey_location_lng: 0.0,
      survey_location_lngState: "",
    };
  }

  componentDidMount() {
    //
    this.props.onRef(this);

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      this.props.project_datatable(undefined);
    } else {
      this.get_projectData(this.props.proj_dataTable);
    }

    if (
      this.state.survey_location_lat !== this.props.latlon.lat ||
      this.state.survey_location_lng !== this.props.latlon.lng
    ) {
      this.change(
        { target: { value: this.props.latlon.lat } },
        "survey_location_lat",
        "float"
      );
      this.change(
        { target: { value: this.props.latlon.lng } },
        "survey_location_lng",
        "float"
      );
    }
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  chk_empty(val) {
    if (val === "" || val === null || val === undefined) {
      return "";
    } else {
      return "success";
    }
  }
  get_projectData = (value) => {
    const edit_data = value.data[0];
    this.setState({
      survey_address: edit_data.condo_address,
      survey_moo: edit_data.condo_moo,
      survey_soi: edit_data.condo_soi,
      survey_road: edit_data.condo_road,
      survey_postcode: edit_data.condo_postalcode,
      survey_addressState: this.chk_empty(edit_data.condo_address),
      survey_mooState: this.chk_empty(edit_data.condo_moo),
      survey_soiState: this.chk_empty(edit_data.condo_soi),
      survey_roadState: this.chk_empty(edit_data.condo_road),
      survey_postcodeState: this.chk_empty(edit_data.condo_postalcode),
    });
  };

  updateLatlng(event, stateName) {
    // console.log('updateLatlng')
    if (
      this.state.survey_location_lat &&
      this.state.survey_location_lng &&
      this.state.survey_location_lat !== "" &&
      this.state.survey_location_lng !== ""
    ) {
      this.setState({
        [stateName]: event.target.value,
        [stateName + "State"]: "success",
      });
      var location = {
        lat: this.state.survey_location_lat,
        lng: this.state.survey_location_lng,
      };
      this.props.setLatlon(location);
    }
  }

  componentDidUpdate(previousProps) {
    // eslint-disable-next-line react/prop-types
    if (
      previousProps.latlon.lat !== this.props.latlon.lat ||
      previousProps.latlon.lng !== this.props.latlon.lng
    ) {
      this.change(
        { target: { value: this.props.latlon.lat } },
        "survey_location_lat",
        "float"
      );
      this.change(
        { target: { value: this.props.latlon.lng } },
        "survey_location_lng",
        "float"
      );
    }
    // get postcode
    if (previousProps.prov_amphoe_tambon !== this.props.prov_amphoe_tambon) {
      this.getPostcode(this.props.prov_amphoe_tambon);
    }

    // check old projectid data
    if (
      JSON.stringify(previousProps.oldproj_data) !==
      JSON.stringify(this.props.oldproj_data)
    ) {
      this.get_projectData(this.props.oldproj_data);
      // console.log(this.props.oldproj_data);
    }
  }

  getPostcode(add_obj) {
    let data = {
      pro_id: add_obj.prov_id,
      amp_id: add_obj.amp_id,
      tam_id: add_obj.tam_id,
    };
    var request = new Request(API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        switch (data.code) {
          case 200:
            if (data.data && data.data[0] && "postcode" in data.data[0]) {
              this.setState({
                survey_postcode: data.data[0].postcode,
                survey_postcodeState: "success",
              });
            }
            break;
          default:
            // console.log("error");
            break;
        }
      });
  }
  // function that returns true if value is meet profile details, false otherwise
  verifyName(value) {
    var nameRex = /^[A-Za-zก-๙0-9]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyGeoloc(number) {
    return /^[0-9]+(\.)?[0-9]*$/.test(number);
  }

  verifyAddress(value) {
    var addressRex = /^[a-zA-Zก-๙0-9._-\s/]+$/;
    if (addressRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyMoo(value) {
    var addressRex = /^[a-zA-Zก-๙0-9._-\s/]+$/;
    if (addressRex.test(value) && value.length <= 3) {
      return true;
    }
    return false;
  }
  verifyPostcode(value) {
    var postcodeRex = new RegExp("^(?=.*[0-9])(?=.{4,4})");
    if (postcodeRex.test(value)) {
      return true;
    }
    return false;
  }

  // validate forms
  change(event, stateName, type) {
    switch (type) {
      case "name":
        if (this.verifyName(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "address":
        if (this.verifyAddress(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "postcode":
        if (this.verifyPostcode(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "moo":
        if (this.verifyMoo(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "float":
        if (this.verifyGeoloc(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
          this.setState({ [stateName]: event.target.value });
        } else {
          // console.log(" come ");
          if (
            (stateName === "survey_location_lat" ||
              stateName === "survey_location_lng") &&
            event.target.value === ""
          ) {
            // console.log(" set empty ");
            // console.log(stateName);
            this.setState({ [stateName]: "" });
          } else {
          }
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  validateRegister() {
    const {
      survey_address,
      survey_moo,
      survey_soi,
      survey_road,
      survey_postcode,
    } = this.state;

    const {
      selected_tambon,
      selected_amphoe,
      selected_prov,
    } = this.sec1Part1prov.state;

    var body = {
      lat: parseFloat(this.props.latlon.lat),
      lon: parseFloat(this.props.latlon.lng),
      condo_subdistrict: selected_tambon === "" ? "N_A" : selected_tambon,
      condo_district: selected_amphoe === "" ? "N_A" : selected_amphoe,
      condo_province: selected_prov === "" ? "N_A" : selected_prov,
      condo_address: survey_address === "" ? "N_A" : survey_address,
      condo_moo: survey_moo === "" ? "N_A" : survey_moo,
      condo_soi: survey_soi === "" ? "N_A" : survey_soi,
      condo_road: survey_road === "" ? "N_A" : survey_road,
      condo_postalcode: survey_postcode === "" ? "N_A" : survey_postcode,
    };

    this.props.house_sec1_part1Location(body);
  }

  validateState(state) {
    const {
      survey_roadState,
      survey_postcodeState,
    } = this.state;

    var survey_mapState = this.mapComponent.mapState();
    // console.log(survey_mapState)
    // console.log(
    //   " ----> " +
    //     survey_addressState +
    //     " " +
    //     survey_mooState +
    //     " " +
    //     survey_soiState +
    //     " " +
    //     survey_roadState +
    //     " " +
    //     survey_postcodeState
    // );

    switch (state) {
      case "save":
        if (
          (survey_mapState === true && survey_roadState === "error") ||
          survey_postcodeState === "error"
        ) {
          this.setAllEmpty();
          return false;
        } else {
          return true;
        }
      case "submit":
        if (
          survey_mapState === true &&
          survey_roadState === "success" &&
          survey_postcodeState === "success"
        ) {
          return true;
        } else {
          this.setAllEmpty();
          return false;
        }
      default:
        break;
    }
  }

  setAllEmpty() {
    if (
      this.state.survey_location_lat === 0 ||
      this.state.survey_location_lat === ""
    ) {
      this.setState({ survey_location_latState: "error" });
    }
    if (
      this.state.survey_location_lng === 0 ||
      this.state.survey_location_lng === ""
    ) {
      this.setState({ survey_location_lngState: "error" });
    }
    if (this.state.survey_address === "") {
      this.setState({ survey_addressState: "error" });
    }
    if (this.state.survey_moo === "") {
      this.setState({ survey_mooState: "error" });
    }
    if (this.state.survey_soi === "") {
      this.setState({ survey_soiState: "error" });
    }
    if (this.state.survey_road === "") {
      this.setState({ survey_roadState: "error" });
    }
    if (this.state.survey_postcode === "") {
      this.setState({ survey_postcodeState: "error" });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Map onRef={(ref) => (this.mapComponent = ref)}></Map>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{
                float: "none",
                padding: "0px",
              }}
            >
              ที่ตั้งโครงการ: พิกัดภูมิศาสตร์ (Latitude / Longitude) ณ
              ซุ้มประตูโครงการ
            </FormLabel>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.survey_location_latState === "success"}
                  error={this.state.survey_location_latState === "error"}
                  labelText="Latitude*"
                  id="survey_lat"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "survey_location_lat", "float"),
                    onBlur: (event) =>
                      this.updateLatlng(event, "survey_location_lat"),
                    value: this.state.survey_location_lat,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.survey_location_lngState === "success"}
                  error={this.state.survey_location_lngState === "error"}
                  labelText="Longitude*"
                  id="survey_lon"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "survey_location_lng", "float"),
                    onBlur: (event) =>
                      this.updateLatlng(event, "survey_location_lng"),
                    value: this.state.survey_location_lng,
                  }}
                />
              </GridItem>
            </GridContainer>
            <ProvAmphoeTambon
              onRef={(ref) => (this.sec1Part1prov = ref)}
            ></ProvAmphoeTambon>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.survey_addressState === "success"}
                  error={this.state.survey_addressState === "error"}
                  labelText="เลขที่"
                  id="survey_address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_address,
                    onChange: (event) =>
                      this.change(event, "survey_address", "address"),
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.survey_mooState === "success"}
                  error={this.state.survey_mooState === "error"}
                  labelText="หมู่ที่"
                  id="survey_moo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_moo,
                    onChange: (event) =>
                      this.change(event, "survey_moo", "moo"),
                    type: "text",
                    autoComplete: "off",
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.survey_soiState === "success"}
                  error={this.state.survey_soiState === "error"}
                  labelText="ซอย"
                  id="survey_soi"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_soi,
                    onChange: (event) =>
                      this.change(event, "survey_soi", "address"),
                    type: "text",
                    autoComplete: "off",
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.survey_roadState === "success"}
                  error={this.state.survey_roadState === "error"}
                  labelText="ถนน"
                  id="survey_road"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_road,
                    onChange: (event) =>
                      this.change(event, "survey_road", "address"),
                    type: "text",
                    autoComplete: "off",
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  success={this.state.survey_postcodeState === "success"}
                  error={this.state.survey_postcodeState === "error"}
                  labelText="รหัสไปรษณีย์"
                  id="survey_postcode"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_postcode,
                    onChange: (event) =>
                      this.change(event, "survey_postcode", "postcode"),
                    type: "number",
                    autoComplete: "off",
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LocationInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  setBoundary: (payload) => dispatch(setBoundary(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  house_sec1_part1Location: (payload) =>
    dispatch(house_sec1_part1Location(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(LocationInfo));
