/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";

import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { ClipLoader, PulseLoader } from "react-spinners";
import CryptoJS from "crypto-js";
import * as config from "configure/configServer.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import BasicInfo from "views/PSurvey/Villa/sec1_part1/basicInformation.js";
import LocationInfo from "views/PSurvey/Villa/sec1_part1/locationInformation.js";
import PublishInfo from "views/PSurvey/Villa/sec1_part2/publishInformation.js";
import OwnerInfo from "views/PSurvey/Villa/sec1_part3/ownerInformation.js";
import ImageInfo from "views/PSurvey/Villa/sec1_part4/imageInformation.js";

// redux
import { connect } from "react-redux";
import surveyInfo from "actions/survey/surveyInfo.js";

import pressSave from "actions/survey/villa/toggleSave.js";
import villa_sec1_part1Object from "actions/survey/villa/villa_sec1_part1Data.js";
import villaSec1_Object from "actions/survey/villa/villa_sec1Obj.js";
import surveyModal from "actions/survey/surveyModal.js";
import setProjectid from "actions/survey/setProjectId.js";
import villa_initial from "actions/survey/villa/villa_initial.js";
import previous_projectid from "actions/survey/previous_porjid.js";
import setLatlon from "actions/survey/setLatlon.js";
import oldproject_data from "actions/survey/oldproject_data.js";
import project_datatable from "actions/survey/psurvey_editproj.js";
import googlemapSave from "actions/survey/googleImage.js";
import imageuploading from "actions/uploading_image.js";
// import show_label from "actions/show_label.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";

class VillaStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,
      popperOpen: false,
      popperLoading: false,
      renderOldProject: [],
      projectid: "",
      btLoading: false,
      user_id: 0,
      token: "",
      current_proj: "",
      old_projectid: "",
      old_projectidState: "",
      villa_oldprojid: "",
      exist_projectid: "",
      projectId_state: "",

      ref_code: "",
      ref_code_state: "",
      refcode_subid: "",

      projectStatus: "",
      open: false,
      projectid_ex: "",
      view_psurvey: false,
    };
    this.handleSurveyType = this.handleSurveyType.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  static propTypes = {
    surveyCondition: PropTypes.object.isRequired,
    surveyInfo: PropTypes.object.isRequired,
    villa_sec1_part1Object: PropTypes.object.isRequired,
    surveyModal: PropTypes.object.isRequired,
    project_id: PropTypes.string.isRequired,
    villasec1part1object: PropTypes.object.isRequired,
    villasec1part1locobject: PropTypes.object.isRequired,
    villasec1part2object: PropTypes.object.isRequired,
    villasec1part3object: PropTypes.object.isRequired,
    villasec1part4object: PropTypes.object.isRequired,
    villaSec1_Object: PropTypes.object.isRequired,
    villaSec1Object: PropTypes.object.isRequired,
    googlemap_64: PropTypes.string.isRequired,
  };

  componentDidMount() {
    // console.log("componentDidMountsec1")
    this.getSecretKey();

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      // console.log("condition found here");
      this.props.project_datatable(undefined);
    } else {
      this.props.imageuploading(true);
      this.get_projectData(this.props.proj_dataTable);
    }
  }

  componentDidUpdate(previousProps) {
    if (
      JSON.stringify(previousProps.project_id) !==
      JSON.stringify(this.props.project_id)
    ) {
      this.setState({
        // eslint-disable-next-line react/prop-types
        projectid: "",
        projectId_state: "",
      });
      if (
        this.state.ref_code_state !== "success" &&
        this.state.ref_code !== "N_A" &&
        this.props.project_id
      ) {
        this.setState({
          ref_code:
            this.props.project_id.substring(0, 11) + this.state.refcode_subid,
        });
        // inject ref_code
        // console.log("inject refcode");
        this.props.villa_sec1_part1Object({
          ...this.props.villasec1part1object,
          refcode:
            this.props.project_id.substring(0, 11) + this.state.refcode_subid,
        });

        // if (this.state.refcode_subid !== "") {
        //   this.verifyRefCode(
        //     this.props.project_id.substring(0, 11) + this.state.refcode_subid
        //   );
        // }
      }
    }
  }
  // show_label = (value) => {
  //   this.props.show_label({
  //     refcode: value.villa_refcode,
  //     project_nameth: value.villa_nameth,
  //     project_nameen: value.villa_nameeng,
  //     project_status: value.villa_projectstatus,
  //   });
  // console.log(this.props.show_label)
  // };
  checkFullBodyNoImage() {
    const part1_state = this.sec1Part1.validateState("submit"); //done
    const part1loc_state = this.sec1Part1Loc.validateState("submit"); //done
    const part2_state = this.sec1Part2.validateState("submit");
    const part3_state = this.sec1Part3.validateState("submit");
    const part4_state = this.sec1Part4.validateState("submit");
    // console.log("checkFullBodyNoImage");
    // console.log({
    //   part1_state,
    //   part1loc_state,
    //   part2_state,
    //   part3_state,
    //   part4_state,
    // });

    // console.log("checkFullBodyNoImage")
    // console.log({
    //   part1_state,part1loc_state,part2_state,part3_state
    // })
    // console.log(this.check_projecyIdExist(this.props.project_id))
    // console.log(this.state.projectId_state)
    // console.log(this.state.ref_code_state)
    if (
      this.check_projectIdExist(this.props.project_id) &&
      this.state.projectId_state !== "error" &&
      (this.state.ref_code_state === "success" ||
        this.state.ref_code === "N_A") &&
      part1_state &&
      part1loc_state &&
      part2_state &&
      part3_state &&
      part4_state
    ) {
      return true;
    } else {
      return false;
    }
  }

  get_projectData = (value) => {
    // console.log(value);
    // if(!value.data[0].lat&&!value.data[0].lon){
    //   return;
    // }
    const status = value.status;
    const edit_data = value.data[0];
    let lat = 0;
    let lng = 0;
    if (!value.data[0].lat || !value.data[0].lon) {
      lat = 0;
      lng = 0;
      // console.log("catch lat lon");
      alert("แบบสอบถามนี้ ค่าพิกัดเสียหาย");
    } else {
      try {
        lat = value.data[0].lat.toFixed(6);
        lng = value.data[0].lon.toFixed(6);
      } catch {
        // console.log("catch lat lon");
        alert("แบบสอบถามนี้ ค่าพิกัดเสียหาย");
      }
    }
    let popLocation = {
      lat: lat,
      lng: lng,
    };
    this.props.setLatlon(popLocation);
    this.props.googlemapSave({
      villa_googlemapimg: edit_data.villa_googlemapimg,
    });
    if (
      "villa_oldprojid" in edit_data &&
      edit_data.villa_oldprojid &&
      edit_data.villa_oldprojid !== "" &&
      edit_data.villa_oldprojid !== "N_A"
    ) {
      this.props.surveyInfo("old");
      this.setState({
        old_projectid: edit_data.villa_oldprojid,
        old_projectidState: "success",
      });
    }
    if ("rj_detail" in edit_data) {
      this.setState({ rj_detail: edit_data.rj_detail });
    }
    // console.log("set status ", status);
    this.setState({
      projectStatus: status,
      view_psurvey: value.view_psurvey,
      ref_code:
        edit_data.villa_refcode && edit_data.villa_refcode !== "undefined"
          ? edit_data.villa_refcode
          : "N_A",
    });
    if (
      "villa_refcode" in edit_data &&
      edit_data.villa_refcode !== null &&
      edit_data.villa_refcode !== "undefined"
    ) {
      this.setState({
        ref_code_state: "success",
      });
    }

    this.setState({
      projectStatus: status,
      projectid_ex: edit_data.villa_projectid,
    });

    this.call_merge_sec1();

    setTimeout(() => {
      this.props.imageuploading(false);
    }, 4000);
  };

  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            user_id: jsonDec.member_id,
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  sendState() {
    return this.state;
  }

  handleSurveyType(event) {
    this.props.surveyInfo(event.target.value);
    // reset all state here
    this.sec1Part1.resetAllState();
    this.sec1Part1Loc.resetAllState();
    this.sec1Part2.resetAllState();
    this.sec1Part3.resetAllState();
    this.sec1Part4.resetAllState();
    this.setState({
      projectId_state: "",
      old_projectidState: "",
    });
  }

  verifyText(value) {
    var textRex = /^[A-Za-zก-๙ 0-9]*$/;
    if (textRex.test(value)) {
      if (value.trim().length !== 0) return true;
      else return false;
    }
    return false;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyDigit(value) {
    var textRex = /^[0-9 \- C V H Q]*$/;
    if (textRex.test(value)) {
      if (value.trim().length !== 0) return true;
      else return false;
    }
    return false;
  }

  isFloat(n) {
    // this.props.show_label({ ...this.props.show_label, refcode: value });

    return Number(n) === n && n % 1 !== 0;
  }
  async verifyRefCode(value) {
    if (value.length >= 10 && value.indexOf(" ") === -1) {
      this.setState({ ref_code_des: " กำลังตรวจสอบ" });
      var body = {
        form: "villa",
        refcode: value,
      };
      // console.log(body)
      this.setState({ popperLoading: true });
      var request = new Request(config.API_checkrefcode, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: this.state.token,
        }),
        body: JSON.stringify(body),
      });

      return await fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson)
          if (responseJson.code === 200) {
            // console.log("verified success");
            this.setState({ ref_code_state: "success" });
            this.setState({ ref_code_des: " นี้สามารถใช้ได้" });
          } else {
            this.setState({ ref_code_des: " นี้มีในระบบแล้ว" });
            this.setState({ ref_code_state: "error" });
          }
        })
        .catch((error) => {
          this.setState({ ref_code_des: " ตรวจสอบล้มเหลว" });
          this.setState({ ref_code_state: "error" });
        });
    } else {
      this.setState({ ref_code_des: " ตรวจสอบล้มเหลว" });
      this.setState({ ref_code_state: "error" });
    }
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "text":
        if (this.verifyText(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "digit":
        if (this.verifyDigit(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "ref_code":
        if (
          this.verifyDigit(event.target.value) &&
          event.target.value.length >= stateNameEqualTo
        ) {
          this.verifyRefCode(event.target.value);
        } else {
          this.setState({ [stateName + "_state"]: "error" });
          this.setState({ ref_code_des: null });
        }
        break;
      case "float":
        if (this.isFloat(event.target.value)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "_state"]: "success" });
        } else {
          this.setState({ [stateName + "_state"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  checkProperties(obj) {
    const hasValue = Object.values(obj).includes("");
    return hasValue;
  }

  async checkValue() {
    await this.captureData();

    if (this.props.villaSec1Object === undefined) {
      return true;
    } else {
      const hasValue = Object.values(this.props.villaSec1Object).includes("");
      return hasValue;
    }
  }

  async isValidated() {
    this.props.imageuploading(true);
    await this.sec1Part1Loc.mapComponent.getProjectId();

    const body = await this.call_merge_sec1();
    // console.log(body);
    // fixed here
    const state = await this.check_errorState("submit");
    const isEdit_table = this.props.proj_dataTable;

    if (isEdit_table === undefined) {
      switch (state) {
        case true:
          if (
            // body.villa_address === "N_A" ||
            body.villa_allocate === "N_A" ||
            // body.villa_googlemapimg[0] === "" ||
            body.villa_currentconstructimg[0] === "" ||
            body.villa_brochuremapimg[0] === "" ||
            body.villa_projectplanimg[0] === "" ||
            body.villa_frontprojectplaceimg[0] === "" ||
            body.villa_saleofficeimg[0] === "" ||
            body.villa_exhouseimg[0] === "" ||
            body.villa_dateconstruct === "01/01/1900" ||
            body.villa_datefinish === "01/01/1900" ||
            body.villa_datefirstsell === "01/01/1900" ||
            // body.villa_district === "1001" ||
            body.villa_explorerdate === "01/01/1900" ||
            body.villa_explorers === "N_A" ||
            body.villa_forelder === "N_A" ||
            // body.villa_maxprice === 0 ||
            // body.villa_minprice === 0 ||
            body.villa_nameeng === "N_A" ||
            body.villa_nameth === "N_A" ||
            body.villa_postalcode === "N_A" ||
            body.villa_projectid === undefined
          ) {
            this.surveyModal("error");
            this.check_projecyIdExist(
              body.villa_projectid,
              body.villa_oldprojid
            );
            this.props.imageuploading(false);
            return false;
          } else {
            // console.log(body)
            this.props.villaSec1_Object(body);
            this.props.imageuploading(false);
            return true;
          }
        default:
          this.surveyModal("error");
          this.check_projecyIdExist(body.villa_projectid, body.villa_oldprojid);
          this.props.imageuploading(false);
          return false;
      }
    } else {
      const stateEdit = await this.check_errorState("save");

      switch (isEdit_table.status) {
        case 1:
        case 3:
          this.props.imageuploading(false);
          return true;
        default:
          // console.log(
          //   body.villa_frontprojectplaceimg[0] === "" &&
          //     isEdit_table.status !== "5" &&
          //     isEdit_table.status !== 5
          // );
          if (this.state.view_psurvey === false) {
            if (
              body.villa_allocate === "N_A" ||
              body.villa_dateconstruct === "01/01/1900" ||
              body.villa_datefinish === "01/01/1900" ||
              body.villa_datefirstsell === "01/01/1900" ||
              body.villa_forelder === "N_A" ||
              body.villa_frontprojectplaceimg[0] === "" ||
              body.villa_nameeng === "N_A" ||
              body.villa_nameth === "N_A" ||
              body.villa_postalcode === "N_A" ||
              body.villa_projectid === undefined ||
              stateEdit === false
            ) {
              this.surveyModal("error");
              this.check_projecyIdExist(
                body.villa_projectid,
                body.villa_oldprojid
              );
              this.props.imageuploading(false);
              return false;
            } else {
              // console.log(body)
              this.props.villaSec1_Object(body);
              this.props.imageuploading(false);
              return true;
            }
          } else {
            return true;
          }
      }
    }
  }
  check_projectIdExist(projectID) {
    if (this.state.ref_code.length < 14) {
      this.setState({
        ref_code_state: "error",
      });
    }
    if (projectID === undefined) {
      this.setState({
        projectId_state: "error",
      });
      return false;
    } else {
      this.setState({
        projectId_state: "success",
      });
      return true;
    }
  }
  check_projecyIdExist(projectID, prokectIDold) {
    // console.log("ss");
    if (this.state.ref_code.length < 14) {
      this.setState({
        ref_code_state: "error",
      });
    }
    switch (this.props.surveyCondition) {
      case "new":
        if (projectID === undefined) {
          this.setState({
            projectId_state: "error",
          });
          return false;
        } else {
          this.setState({
            projectId_state: "",
          });
          return true;
        }
      case "old":
        if (projectID === undefined || prokectIDold === "") {
          this.setState({
            projectId_state: "error",
            old_projectidState: "error",
          });
          return false;
        } else {
          this.setState({
            projectId_state: "",
            old_projectidState: "",
          });
          return true;
        }
      default:
        break;
    }
  }

  async check_errorState(state) {
    const part1_state = this.sec1Part1.validateState(state); //done
    const part1loc_state = this.sec1Part1Loc.validateState(state); //done
    const part2_state = this.sec1Part2.validateState(state);
    const part3_state = this.sec1Part3.validateState(state);
    const part4_state = this.sec1Part4.validateState(state);
    // console.log({ part1_state, part1loc_state, part2_state, part3_state });

    // this.sec1Part2.validateRegister();
    // this.sec1Part1Loc.validateRegister();
    // this.sec1Part3.validateRegister();
    // this.sec1Part4.validateRegister();

    // console.log()
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          this.merge_state(
            part1_state,
            part1loc_state,
            part2_state,
            part3_state,
            part4_state
          )
        );
      }, 1000);
    });
  }

  merge_state(state1, state2, state3, state5) {
    // console.log("ส่วนที่ 1 Location: " + state1);
    // console.log("ส่วนที่ 2 Basicinfo: " + state2);
    // console.log("ส่วนที่ 3 Publishinfo: " + state3);
    // console.log("ส่วนที่ 4 Ownerinfo: " + state4);
    // console.log("ส่วนที่ 5 ImageInfo: " + state5);
    if (
      state1 === true &&
      state2 === true &&
      state3 === true &&
      state5 === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  setDate = (date) => {
    // console.log(this.props.getregtime)
    try {
      const valueOfInput = date.format("DD/MM/YYYY");
      this.setState({
        salesDate: valueOfInput,
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  surveyModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success"
            ? "บันทึกสำเร็จ"
            : validity === "refcode"
            ? "รหัส refcode มีปัญหา"
            : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "save"
          ? "กรุณาตรวจสอบเลขที่แบบสอบถาม และ กรอก รายละเอียดทั่วไปของโครงการ ให้ครบถ้วนก่อนทำการบันทึก"
          : "กรุณาตรวจสอบเลขที่แบบสอบถาม และ กรอกข้อมูล ส่วนที่ 1 ทั้งหมดให้ครบถ้วนก่อนไปส่วนถัดไป"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 3000);
  }

  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success"
            ? "บันทึกสำเร็จ"
            : validity === "refcode"
            ? "รหัส refcode มีปัญหา"
            : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 3000);
  }

  alertConnection() {
    this.props.surveyModal(
      <SweetAlert
        type="warning"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title="การเชื่อมต่อมีปัญหา ไม่สามารถเชื่อมต่อข้อมูลได้"
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 3000);
  }

  async savebutton() {
    // console.log("savebutton");
    this.setState({
      btLoading: true,
    });

    await this.sec1Part1Loc.mapComponent.getProjectId();

    const body = await this.call_merge_sec1();
    const state = await this.check_errorState("save");

    if (
      body.villa_projectid === undefined ||
      body.villa_userid === 0 ||
      body.lat === 0 ||
      body.lon === 0 ||
      body.villa_province === "" ||
      body.villa_district === "" ||
      body.villa_subdistrict === "" ||
      body.villa_frontprojectplaceimg[0] === "" ||
      body.villa_nameeng === "N_A" ||
      body.villa_nameth === "N_A" ||
      body.villa_road === "N_A" ||
      body.villa_postalcode === "N_A"
    ) {
      this.surveyModal("save");
      // console.log("not inte");
      this.check_projecyIdExist(body.villa_projectid, body.villa_oldprojid);
      this.setState({
        btLoading: false,
      });
    } else {
      // send to server
      switch (state) {
        case true:
          this.submit_save(body);
          break;
        default:
          this.surveyModal("save");
          this.check_projecyIdExist(body.villa_projectid, body.villa_oldprojid);
          this.setState({
            btLoading: false,
          });
          break;
      }
    }
  }

  async call_merge_sec1() {
    let usrID = { villa_userid: this.state.user_id };
    let oldID = { villa_oldprojid: this.state.old_projectid };
    let exID = { ex_id: this.state.old_projectid };
    // console.log();
    this.sec1Part1.validateRegister();
    this.sec1Part2.validateRegister();
    this.sec1Part1Loc.validateRegister();
    this.sec1Part3.validateRegister();
    this.sec1Part4.validateRegister();

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          this.merge_data(
            this.props.villasec1part1object,
            this.props.villasec1part2object,
            this.props.villasec1part1locobject,
            this.props.googlemap_64,
            {
              villa_projectid: this.props.project_id,
              villa_refcode: this.state.ref_code,
            },
            usrID,
            oldID,
            exID,
            this.props.villasec1part3object,
            this.props.villasec1part4object
          )
        );
      }, 1000);
    });
  }

  captureData() {
    this.sec1Part1.validateRegister();
    this.sec1Part2.validateRegister();
    this.sec1Part1Loc.validateRegister();
    this.sec1Part3.validateRegister();
    this.sec1Part4.validateRegister();

    let body = this.props.project_id;

    if (body !== undefined) {
      if (this.props.pre_proj === undefined) {
        // generated project id
      } else {
        body = {
          current_id: this.props.pre_proj,
          form: "villa",
          pro_id: this.props.project_id.pro_id,
          zone_id: this.props.project_id.zone_id,
        };
      }
      var request = new Request(config.API_genprojid, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: this.state.token,
        }),
        body: JSON.stringify(body),
      });
      // console.log(body)
      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson)
          if (responseJson.code === 200) {
            this.setState({
              // eslint-disable-next-line react/prop-types
              projectid: responseJson.data,
              regdate: "",
            });
            this.props.setProjectid(responseJson.data);
            this.sec1Part1Loc.mapComponent.getImagePath(responseJson.data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          // console.log(error);
        });
    } else {
      // this.surveyModal("save");
      // this.setState({
      //   btLoading: false
      // });
    }
  }

  merge_data(obj1, obj2, obj3, obj4, obj5, obj6, obj7, obj8, obj9, obj10) {
    const body = {
      ...obj1,
      ...obj2,
      ...obj3,
      ...obj4,
      ...obj5,
      ...obj6,
      ...obj7,
      ...obj8,
      ...obj9,
      ...obj10,
    };
    return body;
  }

  handleClose() {
    this.setState({ popperOpen: false });
  }

  submit_save = (body) => {
    this.setState({
      btLoading: false,
    });
    const token = this.state.token;
    const target = this.props.villa_initial_data;
    const source = {
      ...body,
    };
    const log_villa_sec_1 = { event: "Insert or update villa section 1" };
    const mergebody = Object.assign(
      target,
      source,
      this.props.villaSec2Object &&
        this.props.villaSec2Object instanceof Object &&
        Object.values(this.props.villaSec2Object).length > 0 &&
        Object.values(this.props.villaSec2Object)[0].length > 0
        ? this.props.villaSec2Object
        : {},
      this.props.villaSec3Object &&
        this.props.villaSec3Object instanceof Object &&
        Object.values(this.props.villaSec3Object).length > 0 &&
        Object.values(this.props.villaSec3Object)[0].length > 0
        ? this.props.villaSec3Object
        : {},
      log_villa_sec_1
    );

    // console.log(mergebody);
    var request = new Request(config.API_save_villa, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
      body: JSON.stringify(mergebody),
    });
    // console.log(mergebody);
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          btLoading: false,
        });
        // console.log(responseJson);
        switch (responseJson.code) {
          case 200:
            // this.show_label(body)
            // console.log(this.props.show_Label)
            this.alertModal("success");
            break;
          case 409:
            // this.show_label(body)
            // console.log(this.props.show_Label)
            this.alertModal("refcode");
            break;
          default:
            this.alertModal("warning");
            break;
        }
      })
      .catch((error) => {
        this.alertConnection();
        this.setState({
          btLoading: false,
        });
      });
  };

  // auto complete org field
  get_oldproj(event) {
    let value = event.target.value;
    this.setState({ old_projectid: value, old_projectidState: "error" });

    var body = {
      form: "villa",
      nameth: value,
    };

    if (value.length >= 3) {
      // console.log(this.state.token)
      // console.log(body)
      this.setState({ popperLoading: true });
      var request = new Request(config.API_search_oldproject, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: this.state.token,
        }),
        body: JSON.stringify(body),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          let data = responseJson.data.filter(
            (proj) => proj.villa_projectid && proj.villa_projectid.length > 4
          );
          if (data.length >= 1) {
            this.setState({
              popperOpen: true,
              renderOldProject: data,
              popperLoading: false,
            });
          } else {
            this.setState({
              popperOpen: false,
              popperLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            popperOpen: false,
            renderOldProject: [],
            old_projectidState: "error",
            popperLoading: false,
          });
        });
    } else {
      this.setState({
        popperOpen: false,
        renderOldProject: [],
        old_projectidState: "error",
        popperLoading: false,
      });
    }
  }
  // end of auto complete org field

  loadingPro(isLoading) {
    if (isLoading) {
      return <PulseLoader size={8} color={"gray"} loading={true} />;
    } else {
      return null;
    }
  }
  isEditable = () => {
    return !(
      this.state.view_psurvey === true ||
      this.state.projectStatus === 1 ||
      this.state.projectStatus === 3 ||
      this.state.memberStatus === 2
    );
  };
  handleSelect(value) {
    this.setState({
      popperOpen: false,
      old_projectidState: "success",
      old_projectid: value.villa_projectid,
      refcode_subid: value.villa_refcode.split("-")[3],
    });
    // console.log(this.props.project_id);
    let body = {
      villa_projectid: value.villa_projectid,
      villa_approved: 3,
    };
    // console.log(body);
    var request = new Request(config.API_showdetail_projectid_villa, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        const data = responseJson.data;
        // console.log('data')
        switch (responseJson.code) {
          case 200:
            if (data.length === 0) {
              // alert("No data in this project id");
              this.setState({
                popperOpen: false,
                old_projectidState: "error",
                old_projectid: "",
                open: true,
              });
            } else {
              if (data[0].lat === 0 || data[0].lon === 0) {
                this.setState({
                  popperOpen: false,
                  old_projectidState: "error",
                  old_projectid: "",
                  open: true,
                });
              } else {
                let period;
                let year;
                if (value.villa_projectid.substring(2, 4) === "H1") {
                  period = "H2";
                  year = value.villa_projectid.substring(0, 2);
                } else if (value.villa_projectid.substring(2, 4) === "H2") {
                  period = "H1";
                  year = (
                    parseInt(value.villa_projectid.substring(0, 2)) + 1
                  ).toString();
                } else if (value.villa_projectid.substring(2, 4) === "Q1") {
                  period = "Q2";
                  year = value.villa_projectid.substring(0, 2);
                } else if (value.villa_projectid.substring(2, 4) === "Q2") {
                  period = "Q3";
                  year = value.villa_projectid.substring(0, 2);
                } else if (value.villa_projectid.substring(2, 4) === "Q3") {
                  period = "Q4";
                  year = value.villa_projectid.substring(0, 2);
                } else if (value.villa_projectid.substring(2, 4) === "Q4") {
                  period = "Q1";
                  year = (
                    parseInt(value.villa_projectid.substring(0, 2)) + 1
                  ).toString();
                }

                this.setState({
                  villa_oldprojid: value.villa_projectid,
                  ref_code:
                    year +
                    period +
                    value.villa_projectid.substring(4, 11) +
                    value.villa_refcode.split("-")[3],
                });
                this.verifyRefCode(this.state.ref_code);
                this.props.oldproject_data({
                  data: [data[0]],
                  unity: responseJson.unity ? responseJson.unity : [],
                });
                // console.log(this.props.oldproject_data);
                if (data[0].lat && data[0].lon) {
                  let popLocation = {
                    lat: data[0].lat.toFixed(6),
                    lng: data[0].lon.toFixed(6),
                  };
                  this.props.setLatlon(popLocation);
                }
              }
            }
            break;
          case 400:
            this.setState({
              popperOpen: false,
              old_projectidState: "error",
              old_projectid: "",
              open: true,
            });
            break;
          default:
        }
      })
      .catch((error) => {
        // error
      });
  }
  successAlert(title, message, status) {
    this.props.surveyModal(
      <SweetAlert
        {...status}
        className="SweetAlert"
        title={<div>{title}</div>}
        customClass="modalContent"
        onConfirm={() => this.props.surveyModal(null)}
        confirmBtnCssClass={"modalBt"}
      >
        {message.length > 200 ? (
          <div
            style={{
              whiteSpace: "pre-line",
              overflowY: "scroll",
              height: "100px",
            }}
          >
            <br />
            {message}
          </div>
        ) : (
          <div style={{ whiteSpace: "pre-line" }}>{message}</div>
        )}
      </SweetAlert>
    );
  }
  isResubmiting = () => {
    // console.log(this.state.projectStatus)
    return this.state.projectStatus === 2 || this.state.projectStatus === "2";
  };

  blockEdit(isEdit) {
    if (isEdit) {
      // console.log('disable all')
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            zIndex: "50",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            cursor: "not-allowed",
          }}
        ></div>
      );
    } else {
      //
    }
  }
  render() {
    const { classes } = this.props;

    let optionItems = this.state.renderOldProject.map((com, index) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={index}
        value={com.villa_projectid}
        style={{ backgroundColor: "#fff" }}
        onClick={() => this.handleSelect(com)}
      >
        {com.villa_refcode + " " + com.villa_nameth}
      </MenuItem>
    ));

    return (
      <GridContainer style={{ padding: "15px" }}>
        {this.blockEdit(!this.isEditable())}

        <Snackbar
          place="tc"
          color="warning"
          message="ข้อมูลโครงการเก่าไม่ครบถ้วน ทำให้ไม่สามารถดึงข้อมูลโครงการเก่าได้"
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        {/* Section_1 Part 1 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>รายละเอียดทั่วไปของโครงการ</b>
          </h4>
          <div className="top_bar" style={{ display: "flex" }}>
            <div
              className="form_radio"
              style={{ width: "100%", display: "flex" }}
            >
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  disabled={
                    this.props.project_id !== undefined &&
                    this.props.project_id !== ""
                  }
                  control={
                    <Radio
                      checked={this.props.surveyCondition === "new"}
                      onChange={this.handleSurveyType}
                      value="new"
                      name="radio button enabled"
                      aria-label="new"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="แบบสอบถามใหม่"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  disabled={
                    this.props.project_id !== undefined &&
                    this.props.project_id !== ""
                  }
                  control={
                    <Radio
                      checked={this.props.surveyCondition === "old"}
                      onChange={this.handleSurveyType}
                      value="old"
                      name="radio button enabled"
                      aria-label="old"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="แบบสอบถามเก่า"
                />
              </div>
            </div>
            <div className="ngum" style={{ width: "100%" }}>
              <FormControlLabel
                style={{ float: "right" }}
                control={
                  <CustomInput
                    success={this.state.ref_code_state === "success"}
                    error={this.state.ref_code_state === "error"}
                    labelText={"ref code." + (this.state.ref_code_des || "")}
                    id="ref_code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "เติมอย่างน้อย 11 หลัก",
                      value: this.state.ref_code,
                      onChange: (event) =>
                        this.change(event, "ref_code", "ref_code", 14),
                    }}
                  />
                }
              />
            </div>
          </div>
          <GridContainer style={{ marginBottom: "15px", marginTop: "10px" }}>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FormLabel
                className={classes.labelHorizontal}
                style={{
                  float: "none",
                  padding: "0px",
                  textAlign: "center",
                  fontSize: "18px",
                  color: "gray",
                }}
              >
                *คำชี้แจง
              </FormLabel>
              {this.props.proj_dataTable === undefined ? (
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    float: "none",
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  กรุณาปักหมุดบนแผนที่เพื่อทำการสร้าง เลขที่แบบสอบถาม
                </FormLabel>
              ) : (
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    float: "none",
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  ไม่สามารถแก้ไขเลขที่แบบสอบถามได้กรณีหากทำการบันทึกแล้ว
                </FormLabel>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  {this.state.rj_detail ? (
                    <Button
                      color="danger"
                      onClick={() =>
                        this.successAlert(
                          "เหตุผลที่ไม่ผ่าน",
                          this.state.rj_detail
                        )
                      }
                    >
                      {"เหตุผลที่ไม่ผ่าน"}
                    </Button>
                  ) : null}
                  <CustomInput
                    success={this.state.projectId_state === "success"}
                    error={this.state.projectId_state === "error"}
                    labelText="เลขที่แบบสอบถาม"
                    id="survey_id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      disabled: true,
                      value:
                        this.props.project_id === undefined
                          ? ""
                          : this.props.project_id,
                    }}
                  />
                </GridItem>
                {this.props.surveyCondition === "new" ? null : (
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <CustomInput
                      success={this.state.old_projectidState === "success"}
                      error={this.state.old_projectidState === "error"}
                      labelText="ค้นหาชื่อแบบสอบถามเก่า"
                      id="old_projid"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "กรอกอย่างน้อย 4 ตัวอักษร",
                        value: this.state.old_projectid,
                        onChange: (event) => this.get_oldproj(event),
                        ref: (node) => {
                          this.anchorEl = node;
                        },
                        endAdornment:
                          this.state.old_projectidState === "error" ? (
                            <InputAdornment position="end">
                              {this.state.popperLoading ? (
                                <div style={{ width: "50px" }}>
                                  {this.loadingPro(this.state.popperLoading)}
                                </div>
                              ) : null}
                              <p
                                style={{
                                  width: "105px",
                                  margin: "0",
                                  textAlign: "end",
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                ไม่พบแบบสอบถาม
                              </p>
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                    />
                    <Popper
                      open={this.state.popperOpen}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                      placement="bottom-end"
                      className={
                        "MuiPaper-root MuiMenu-paper MuiPaper-elevation8 MuiPopover-paper MuiPaper-rounded"
                      }
                      style={{
                        position: "absolute",
                        zIndex: 99999,
                        maxHeight: "200px",
                        minWidth: "38%",
                        overflow: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <Paper className={classes.selectMenu}>
                          {optionItems}
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <LocationInfo onRef={(ref) => (this.sec1Part1Loc = ref)} />
          {/* Break */}
          <FormLabel
            className={classes.labelHorizontal}
            style={{
              float: "none",
              padding: "0px",
              paddingTop: "15px",
              textAlign: "center",
            }}
          >
            ข้อมูลทั่วไป: ระบุข้อมูลต่างๆของโครงการ
          </FormLabel>
          <BasicInfo onRef={(ref) => (this.sec1Part1 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>

        {/* Section_1 Part 2 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>รายละเอียดการขายของโครงการ</b>
          </h4>
          <PublishInfo onRef={(ref) => (this.sec1Part2 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>
        {/* Section_1 Part 3 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>รายละเอียดผู้ประกอบการ</b>
          </h4>
          <OwnerInfo onRef={(ref) => (this.sec1Part3 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>
        {/* Section_1 Part 4 */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <ImageInfo onRef={(ref) => (this.sec1Part4 = ref)} />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        ></GridItem>
      </GridContainer>
    );
  }
}

VillaStep1.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  surveyInfo: (payload) => dispatch(surveyInfo(payload)),
  villa_sec1_part1Object: (payload) =>
    dispatch(villa_sec1_part1Object(payload)),
  pressSave: (payload) => dispatch(pressSave(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
  setProjectid: (payload) => dispatch(setProjectid(payload)),
  villaSec1_Object: (payload) => dispatch(villaSec1_Object(payload)),
  villa_initial: (payload) => dispatch(villa_initial(payload)),
  previous_projectid: (payload) => dispatch(previous_projectid(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  oldproject_data: (payload) => dispatch(oldproject_data(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  googlemapSave: (payload) => dispatch(googlemapSave(payload)),
  imageuploading: (bool) => dispatch(imageuploading(bool)),
  // show_label: (payload) => dispatch(show_label(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(VillaStep1));
