import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import NavPills from "components/NavPills/NavPills.js";
import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  NavLink,
  Row,
} from "reactstrap";
import switchIntl from "../../configure/switchIntl.js";
import "./ManageUser.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const template = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
};
class GHDashboard extends React.Component {
  state = {
    curSrpYear: new Date().getFullYear() + "",
    curPsurveyYear: new Date().getFullYear() + "", // option
    psurveyPath: config.requestUrl,
    srpPath: config.requestUrl,
    srpCurTab: 0,
    psurveyCurTab: 0,
    countsrp: [{}],
    guild_name: "",
    member_email: "",
    member_id: "",
    member_lname: "",
    member_name: "",
    member_profimg: "",
    member_status: "",
    member_tel: "",
    member_type: "",
    member_verify: "",
    org_address: "",
    org_district: "",
    org_fax: "",
    org_floor: "",
    org_name: "",
    org_namebuilding: "",
    org_province: "",
    org_road: "",
    org_saletel: "",
    org_soi: "",
    org_subdistrict: "",
    org_tel: "",
    org_website: "",
    p_value: "",
    tam_value: "",
    show: false,
    hasCompany: true,
    hasCompanyState: "",
    renderCompany: [],
    registerFname: "",
    registerFnameState: "",
    registerLname: "",
    registerLnameState: "",
    registerPhone: "",
    registerPhoneState: "",
    registerEmail: "",
    registerEmailState: "",
    registerUsername: "",
    registerUsernameState: "",
    registerPassword: "12345678",
    registerPasswordState: "",
    registerConfirmPassword: "12345678",
    registerConfirmPasswordState: "",
    registerOrganization: "",
    registerOrganizationState: "",
    registerCheckbox: false,
    registerCheckboxState: "",
    registerCaptcha: "",
    registerCaptchaState: "",
    addOrgName: "",
    addOrgNameState: "",
    addOrgNo: "",
    addOrgNoState: "",
    addOrgBuilding: "",
    addOrgBuildingState: "",
    addOrgFloor: "",
    addOrgFloorState: "",
    addOrgSoi: "",
    addOrgSoiState: "",
    addOrgStreet: "",
    addOrgStreetState: "",
    addOrgProv: "",
    addOrgProvState: "",
    addOrgSubdist: "",
    addOrgSubdistState: "",
    addOrgDist: "",
    addOrgDistState: "",
    addOrgPhone: "",
    addOrgPhoneState: "",
    addOrgFax: "",
    addOrgFaxState: "",
    addOrgSalePhone: "",
    addOrgSalePhoneState: "",
    addOrgUrl: "",
    addOrgUrlState: "",
    newCompanyAsso: "",
    province: [],
    amphoe: [],
    tambon: [],
    passwordResetable: false,
    btLoading: false,
    orgLoading: false,
    disButton: false,
    popperOpen: false,
    orgnameValue: "",
    easypass: false,

    countsrpmonth: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    },
    countpsurveymonth: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    },
  };

  componentDidMount() {
    this.getSecretKey();
  }

  resetPassword = (adminPassword) => {
    this.setState({
      alert: false,
      passwordResetable: true,
      registerPassword: "",
      registerConfirmPassword: "",
    });
  };

  resetAllState = () => {
    return this.setState({
      guild_name: "",
      member_email: "",
      member_id: "",
      member_lname: "",
      member_name: "",
      member_profimg: "",
      member_status: "",
      member_tel: "",
      member_type: "",
      member_verify: "",
      org_address: "",
      org_district: "",
      org_fax: "",
      org_floor: "",
      org_name: "",
      org_namebuilding: "",
      org_province: "",
      org_road: "",
      org_saletel: "",
      org_soi: "",
      org_subdistrict: "",
      org_tel: "",
      org_website: "",
      p_value: "",
      tam_value: "",
      show: false,
      hasCompany: true,
      hasCompanyState: "",
      renderCompany: [],
      registerFname: "",
      registerFnameState: "",
      registerLname: "",
      registerLnameState: "",
      registerPhone: "",
      registerPhoneState: "",
      registerEmail: "",
      registerEmailState: "",
      registerUsername: "",
      registerUsernameState: "",
      registerPassword: "12345678",
      registerPasswordState: "",
      registerConfirmPassword: "12345678",
      registerConfirmPasswordState: "",
      registerOrganization: "",
      registerOrganizationState: "",
      registerCheckbox: false,
      registerCheckboxState: "",
      registerCaptcha: "",
      registerCaptchaState: "",
      addOrgName: "",
      addOrgNameState: "",
      addOrgNo: "",
      addOrgNoState: "",
      addOrgBuilding: "",
      addOrgBuildingState: "",
      addOrgFloor: "",
      addOrgFloorState: "",
      addOrgSoi: "",
      addOrgSoiState: "",
      addOrgStreet: "",
      addOrgStreetState: "",
      addOrgProv: "",
      addOrgProvState: "",
      addOrgSubdist: "",
      addOrgSubdistState: "",
      addOrgDist: "",
      addOrgDistState: "",
      addOrgPhone: "",
      addOrgPhoneState: "",
      addOrgFax: "",
      addOrgFaxState: "",
      addOrgSalePhone: "",
      addOrgSalePhoneState: "",
      addOrgUrl: "",
      addOrgUrlState: "",
      newCompanyAsso: "",
      province: [],
      amphoe: [],
      tambon: [],
      passwordResetable: false,
    });
  };

  comfirmResetPassword = async () => {
    let { member_id, registerPassword } = this.state;
    var { toggleLng } = this.props;
    var that = this;

    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = {
      member_id,
      member_passwd: registerPassword,
    };
    var request = new Request(config.API_adminchangepasswd, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    await fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          that.successAlert(
            toggleLng === "TH"
              ? "เปลี่ยนรหัสผ่านสำเร็จ"
              : "Change Password Success",
            toggleLng === "TH"
              ? "เปลี่ยนรหัสผ่านสำเร็จ"
              : "Change Password Success",
            { success: true }
          );
        } else {
          that.successAlert(
            toggleLng === "TH"
              ? "เปลี่ยนรหัสผ่านล้มเหลว"
              : "Change Password Failed",
            toggleLng === "TH"
              ? "เปลี่ยนรหัสผ่านล้มเหลว"
              : "Change Password Failed",
            { warning: true }
          );
        }
      })
      .catch((error) => {
        that.successAlert(
          toggleLng === "TH"
            ? "เปลี่ยนรหัสผ่านล้มเหลว"
            : "Change Password Failed",
          toggleLng === "TH"
            ? "เปลี่ยนรหัสผ่านล้มเหลว"
            : "Change Password Failed",
          { warning: true }
        );
      });

    return await this.setState({
      passwordResetable: false,
      registerPasswordState: "",
      registerConfirmPasswordState: "",
    });
  };

  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });
          self.getSrpAccountCount();
          self.getPsurveyAccountCount();
          self.getAdminAccountLog();
          self.getSrpAccountLog();
          self.getPsurveyAccountLog();
          self.getAdminLog();
          self.getSrpLog();
          self.getPsurveyLog();
          self.getAllAccount();
          self.getSrpMonth();
          self.getPsurveyMonth();
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  handleAllReadOnly = (member_type) => {
    let { toggleLng } = this.props;
    var that = this;
    this.successAlert(
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeApprovalAllReadOnlyHead
        : "Project Approval Failed",
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeApprovalAllReadOnlyHead
        : "Project Approval Failed",
      {
        info: true,
        onConfirm: () => {
          const { key } = this.state;
          if (!key) {
            return;
          }
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          const tokenData = jsonDec.token;
          let body = {
            member_type,
          };
          var request = new Request(`${config.API_update_readonly}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });

          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.successAlert(
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllReadOnlyHeadSuccess
                    : "Project Approval Success",
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllReadOnlyHeadSuccess
                    : "Project Approval Success",
                  { success: true }
                );
                that.getAllAccount();
                that.getSrpAccountCount();
                that.getPsurveyAccountCount();
              } else {
                that.successAlert(
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllReadOnlyHeadFail
                    : "Change Approval Failed",
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllReadOnlyHeadFail
                    : "Change Approval Failed",
                  { warning: true }
                );
              }
            })
            .catch((error) => {
              that.successAlert(
                toggleLng === "TH"
                  ? switchIntl(toggleLng).manageuser
                      .changeApprovalAllReadOnlyHeadFail
                  : "Change Approval Failed",
                toggleLng === "TH"
                  ? switchIntl(toggleLng).manageuser
                      .changeApprovalAllReadOnlyHeadFail
                  : "Change Approval Failed",
                { warning: true }
              );
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };

  handleAllActive = (member_type) => {
    let { toggleLng } = this.props;
    var that = this;
    this.successAlert(
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeApprovalAllActiveHead
        : "Project Approval Failed",
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeApprovalAllActiveHead
        : "Project Approval Failed",
      {
        info: true,
        onConfirm: () => {
          const { key } = this.state;
          if (!key) {
            return;
          }
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          const tokenData = jsonDec.token;
          let body = {
            member_type: member_type,
          };
          var request = new Request(`${config.API_update_active}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });

          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.successAlert(
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllActiveHeadSuccess
                    : "Change Approval Success",
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllActiveHeadSuccess
                    : "Change Approval Success",
                  { success: true }
                );
                that.getAllAccount();
                that.getSrpAccountCount();
                that.getPsurveyAccountCount();
              } else {
                that.successAlert(
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllActiveHeadFail
                    : "Change Approval Failed",
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser
                        .changeApprovalAllActiveHeadFail
                    : "Change Approval Failed",
                  { warning: true }
                );
              }
            })
            .catch((error) => {
              that.successAlert(
                toggleLng === "TH"
                  ? switchIntl(toggleLng).manageuser
                      .changeApprovalAllActiveHeadFail
                  : "Change Approval Failed",
                toggleLng === "TH"
                  ? switchIntl(toggleLng).manageuser
                      .changeApprovalAllActiveHeadFail
                  : "Change Approval Failed",
                { warning: true }
              );
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };
  async componentDidUpdate(previousProps, previousState) {
    var self = this;
    if (previousState.org_province !== this.state.org_province) {
      if (this.state.province.length > 0) {
        await this.state.province.forEach(function(eachpro) {
          if (eachpro.label === self.state.org_province) {
            self.setState({ pro_id: eachpro.value });
            self.getAmphoe(eachpro.value);
          }
        });
      }
    }
  }
  getAdminAccountLog = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_admin_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member_type: 1 }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200 && 0 in responseJson.data) {
        }
        this.setState({ countAdminAccountLog: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getPsurveyAccountLog = () => {
    var self = this;

    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_admin_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member_type: 2 }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          (responseJson.code === 200 || responseJson.code === "200") &&
          0 in responseJson.data
        ) {
          self.setState({ countPsurveyAccountLog: responseJson.data });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getSrpAccountLog = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_admin_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member_type: 3 }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200 && 0 in responseJson.data) {
        }
        this.setState({ countSrpAccountLog: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getAdminLog = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_log}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member_type: 1 }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200 && 0 in responseJson.data) {
        }
        this.setState({ countAdminLog: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getPsurveyLog = () => {
    var self = this;

    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_log}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member_type: 2 }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          (responseJson.code === 200 || responseJson.code === "200") &&
          0 in responseJson.data
        ) {
          self.setState({ countPsurveyLog: responseJson.data });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getSrpLog = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_log}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member_type: 3 }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200 && 0 in responseJson.data) {
        }
        this.setState({ countSrpLog: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  dateParse = (date) => {
    try {
      let day = date.substring(8, 10);
      let mounth = date.substring(5, 7);
      let year = date.substring(0, 4);
      let time = date.substring(11, 19);
      return day + "-" + mounth + "-" + (parseInt(year) + 543) + " : " + time;
    } catch {
      return "";
    }
  };
  editUserInfo = () => {
    let { member_id, member_name, member_lname, member_tel } = this.state;
    var { toggleLng } = this.props;
    var that = this;

    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = {
      member_id,
      member_name,
      member_lname,
      member_tel,
      member_profimg: this.props.getBase64,
    };
    var request = new Request(config.API_updateprofile, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          that.successAlert(
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeInfoHeadSuccess
              : "Edit Profile Success",
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeInfoHeadSuccess
              : "Edit Profile Success",
            { success: true }
          );
          that.getAllAccount();
          that.getSrpAccountCount();
          that.getPsurveyAccountCount();
        } else {
          that.successAlert(
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeInfoHeadFail
              : "Edit Profile Failed",
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeInfoHeadFail
              : "Edit Profile Failed",
            { warning: true }
          );
        }
      })
      .catch((error) => {
        that.successAlert(
          toggleLng === "TH"
            ? switchIntl(toggleLng).manageuser.changeInfoHeadFail
            : "Edit Profile Failed",
          toggleLng === "TH"
            ? switchIntl(toggleLng).manageuser.changeInfoHeadFail
            : "Edit Profile Failed",
          { warning: true }
        );
      });
  };
  handleSelectAccountApproval = (username, appr, rig) => {
    let { toggleLng } = this.props;
    var that = this;

    this.successAlert(
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeApprovalHead
        : "Project Approval Failed",
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeApprovalHead
        : "Project Approval Failed",
      {
        info: true,
        onConfirm: () => {
          let approval = 0;
          let right = 0;
          if (appr === "อนุมัติ") {
            approval = 1;
          } else if (appr === "ไม่อนุมัติ") {
            approval = 2;
          } else {
            approval = 0;
          }

          if (rig === "Active") {
            right = 1;
          } else if (rig === "Inactive") {
            right = 0;
          } else if (rig === "Read-only") {
            right = 2;
          }
          const { key } = this.state;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          const tokenData = jsonDec.token;

          let body = {
            username: username,
            verify: approval,
            status: right,
          };
          var request = new Request(`${config.API_update_admin}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });

          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.successAlert(
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser.changeApprovalHeadSuccess
                    : "Change Approval Success",
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser.changeApprovalHeadSuccess
                    : "Change Approval Success",
                  { success: true }
                );
                that.getAllAccount();
                that.getSrpAccountCount();
                that.getPsurveyAccountCount();
              } else {
                that.successAlert(
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
                    : "Change Approval Failed",
                  toggleLng === "TH"
                    ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
                    : "Change Approval Failed",
                  { warning: true }
                );
              }
            })
            .catch((error) => {
              that.successAlert(
                toggleLng === "TH"
                  ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
                  : "Change Approval Failed",
                toggleLng === "TH"
                  ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
                  : "Change Approval Failed",
                { warning: true }
              );
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };

  accountApproval = (e) => {
    var { toggleLng } = this.props;
    var that = this;
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = { member_id: this.state.member_id + "", member_status: e + "" };
    var request = new Request(`${config.API_approveuser}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          that.successAlert(
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeApprovalHeadSuccess
              : "Change Approval Success",
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeApprovalHeadSuccess
              : "Change Approval Success",
            { success: true }
          );
          that.getAllAccount();
        } else {
          that.successAlert(
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
              : "Change Approval Failed",
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
              : "Change Approval Failed",
            { warning: true }
          );
        }
      })
      .catch((error) => {
        that.successAlert(
          toggleLng === "TH"
            ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
            : "Change Approval Failed",
          toggleLng === "TH"
            ? switchIntl(toggleLng).manageuser.changeApprovalHeadFail
            : "Change Approval Failed",
          { warning: true }
        );
      });
  };
  getProvince() {
    this.setState({ amphoe: [], tambon: [] });
    var body = {
      pro_id: 0,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          province: responseJson.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  getAmphoe(prov_val) {
    var self = this;
    this.setState({ amphoe: [], addOrgSubdist: "" });
    var body = {
      pro_id: prov_val,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState(
          {
            amphoe: responseJson.data,
          },
          () => {
            this.state.amphoe.forEach(function(eachdis) {
              if (eachdis.label === self.state.org_district) {
                self.getTambon(self.state.pro_id, eachdis.value);
              }
            });
          }
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  getTambon(prov_val, amp_val) {
    this.setState({ tambon: [], addOrgDist: "" });
    var body = {
      pro_id: prov_val,
      amp_id: amp_val,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          tambon: responseJson.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  handleSelectedProv = (event) => {
    this.setState({
      addOrgSubdist: "",
      addOrgDist: "",
      [event.target.name]: event.target.value,
      addOrgProvState: "success",
    });
    this.getAmphoe(event.target.value);
  };

  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  handleSelectedSub = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      addOrgSubdistState: "success",
    });
    this.getTambon(this.state.addOrgProv, event.target.value);
  };
  handleSelectedDist = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      addOrgDistState: "success",
    });
  };
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    let value = event.target.value;
    switch (type) {
      case "name":
        if (this.verifyName(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (
          this.verifyPhone(event.target.value) &&
          value.length >= stateNameEqualTo &&
          value.length <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "username":
        if (this.verifyUser(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyPassword(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is meet profile details, false otherwise
  verifyName(value) {
    var nameRex = /^[A-Za-zก-๙]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is phone, false otherwise
  verifyPhone(value) {
    var phoneRex = /^0[0-9/-]+$/;
    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is username, false otherwise
  verifyUser(value) {
    var userRex = /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/;
    if (userRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is password, false otherwise
  verifyPassword(value) {
    var passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,32}$/;
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }
  handleSelectAccountRight = (e) => {
    let { toggleLng } = this.props;
    this.successAlert(
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeRightHead
        : "Project Approval Failed",
      toggleLng === "TH"
        ? switchIntl(toggleLng).manageuser.changeRightHead
        : "Project Approval Failed",
      {
        info: true,
        onConfirm: () => {
          this.setState({ alert: false });

          this.successAlert(
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeRightHeadSuccess
              : "Project Approval Failed",
            toggleLng === "TH"
              ? switchIntl(toggleLng).manageuser.changeRightHeadSuccess
              : "Project Approval Failed",
            { success: true }
          );
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };
  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          {...("onConfirm" in status
            ? ""
            : { onConfirm: () => this.setState({ alert: null }) })}
          onCancel={() => this.setState({ alert: null })}
        >
          {message}
        </SweetAlert>
      ),
    });
  }
  getSrpMonth = () => {
    var that = this;
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_srp_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({
        year: this.state.curSrpYear ? this.state.curSrpYear : 0,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        template.series = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
        if (responseJson.code === 200 && "data" in responseJson) {
          let tempData = responseJson.data;

          tempData.forEach(function(i) {
            template.labels.forEach(function(j, k) {
              if (i.mon === j) {
                template.series[0][k] = i.count_user;
              }
            });
          });
        }
        that.setState({ countsrpmonth: template });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getPsurveyMonth = () => {
    var that = this;
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    const template = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    };
    var request = new Request(`${config.API_psurvey_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({
        year: this.state.curPsurveyYear ? this.state.curPsurveyYear : 0,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        template.series = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];

        if (responseJson.code === 200 && "data" in responseJson) {
          let tempData = responseJson.data;
          tempData.forEach(function(i) {
            template.labels.forEach(function(j, k) {
              if (i.mon === j) {
                template.series[0][k] = i.count_user;
              }
            });
          });
        }
        that.setState({ countpsurveymonth: template });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  showAccountData = (b) => {
    var that = this;
    var { toggleLng } = this.props;

    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = { member_id: b };
    var request = new Request(`${config.API_profile}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          that.setState({ ...responseJson.data });
          that.setState({ srpAccountModal: b });
        } else {
          that.successAlert(
            toggleLng === "TH"
              ? "แสดงข้อมูลผู้ใช้ล้มเหลว"
              : "Show User Detail Failed",
            toggleLng === "TH"
              ? "แสดงข้อมูลผู้ใช้ล้มเหลว"
              : "Show User Detail Failed",
            { warning: true }
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  showSrpAccountData = (b) => {
    this.setState({ psurveyAccountModal: b });
  };
  showPsurveyAccountData = (b) => {
    this.setState({ psurveyAccountModal: b });
  };

  removeAccountData = (b) => {
    var that = this;

    var { toggleLng } = this.props;
    this.successAlert(
      switchIntl(toggleLng).manageuser.deleteHead,
      switchIntl(toggleLng).manageuser.deleteHead,
      {
        info: true,
        onConfirm: () => {
          const { key } = this.state;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          const tokenData = jsonDec.token;
          let body = { member_id: b };
          var request = new Request(`${config.API_deluser}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });

          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.getAllAccount();
                that.getSrpAccountCount();
                that.getPsurveyAccountCount();
                that.successAlert(
                  toggleLng === "TH"
                    ? "ลบผู้ใช้สำเร็จ"
                    : "Delete User Detail Success",
                  toggleLng === "TH"
                    ? "ลบผู้ใช้สำเร็จ"
                    : "Delete User Detail Success",
                  { success: true }
                );
              } else {
                that.successAlert(
                  toggleLng === "TH"
                    ? "ลบผู้ใช้ล้มเหลว"
                    : "Delete User Detail Failed",
                  toggleLng === "TH"
                    ? "ลบผู้ใช้ล้มเหลว"
                    : "Delete User Detail Failed",
                  { warning: true }
                );
              }
            })
            .catch((error) => {
              that.successAlert(
                toggleLng === "TH"
                  ? "ลบผู้ใช้ล้มเหลว"
                  : "Delete User Detail Failed",
                toggleLng === "TH"
                  ? "ลบผู้ใช้ล้มเหลว"
                  : "Delete User Detail Failed",
                { warning: true }
              );
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };

  getSrpAccountCount = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_countsrpaccount}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ countSrpAccount: responseJson });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getPsurveyAccountCount = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_countpsurveyaccount}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ countPsurveyAccount: responseJson });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getAllAccount = async () => {
    var that = this;
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_list_member}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member: 1 }),
    });

    await fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        that.setState({ adminAccountList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });

    var request2 = new Request(`${config.API_list_member}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member: 2 }),
    });

    await fetch(request2)
      .then((response) => response.json())
      .then((responseJson) => {
        that.setState({ psurveyAccountList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });

    var request3 = new Request(`${config.API_list_member}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ member: 3 }),
    });

    await fetch(request3)
      .then((response) => response.json())
      .then((responseJson) => {
        that.setState({ srpAccountList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  render() {
    var that = this;
    const { classes, toggleLng } = this.props;
    const accLogColumns = [
      {
        Header: switchIntl(toggleLng).manageuser.acclogtable_col2,
        headerStyle: { textAlign: "left" },
        accessor: "acc_date",
        filterable: false,
        width: 300,
        Cell: (val) => <div>{this.dateParse(val.original["acc_date"])}</div>,
      },
      {
        Header: switchIntl(toggleLng).manageuser.acclogtable_col1,
        headerStyle: { textAlign: "left" },
        accessor: "username",
        width: 300,
      },
    ];

    const logColumns = [
      {
        Header: switchIntl(toggleLng).manageuser.logtable_col1,
        headerStyle: { textAlign: "left" },
        accessor: "timestamp",
        filterable: false,
        // width: 300,
        Cell: (val) => <div>{this.dateParse(val.original["timestamp"])}</div>,
      },
      {
        Header: switchIntl(toggleLng).manageuser.logtable_col2,
        headerStyle: { textAlign: "left" },
        accessor: "username",
        // width: 300,
      },
      {
        Header: switchIntl(toggleLng).manageuser.logtable_col3,
        headerStyle: { textAlign: "left" },
        accessor: "event",
        // width: 300,
      },
      {
        Header: switchIntl(toggleLng).manageuser.logtable_col4,
        headerStyle: { textAlign: "left" },
        accessor: "refcode",
        filterMethod: (filter, row) => {
          return row.refcode.indexOf(filter.value) >= 0;
        },
        // width: 300,
      },
      {
        Header: switchIntl(toggleLng).manageuser.logtable_col5,
        headerStyle: { textAlign: "left" },
        accessor: "company_name",
        // width: 300,
      },
    ];

    const tableHeadDataTable = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "index", // String-based value accessors!
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head1,
        accessor: "ชื่อผู้ใช้งาน",
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head2,
        accessor: "บริษัท",
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head3,
        accessor: "วันที่ลงทะเบียน",
        filterable: false,
        Cell: (val) => (
          <div>{this.dateParse(val.original["วันที่ลงทะเบียน"])}</div>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head5,
        accessor: "จังหวัด",
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head6,
        accessor: "สถานะ",
        filterable: false,
        Cell: (val) => (
          <Select
            value={val.original["สถานะ"]}
            onChange={(e) =>
              that.handleSelectAccountApproval(
                val.original["ชื่อผู้ใช้งาน"],
                val.original["สิทธิ์ในการใช้งาน"],
                e.target.value
              )
            }
          >
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Inactive"
            >
              {switchIntl(toggleLng).manageuser.tabNotAllow}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Active"
            >
              {switchIntl(toggleLng).manageuser.tabAllow}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Read-only"
            >
              {"Read-only"}
            </MenuItem>
          </Select>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head8,
        headerStyle: { textAlign: "left" },
        accessor: "member_id",
        filterable: false,
        sortable: false,
        Cell: (val) => (
          <div>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => that.showAccountData(val.original["member_id"])}
            >
              <Edit />
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => that.removeAccountData(val.original["member_id"])}
            >
              <Delete />
            </Button>
          </div>
        ),
      },
    ];

    const tableWaitHeadDataTable = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "index", // String-based value accessors!
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head1,
        accessor: "ชื่อผู้ใช้งาน",
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head2,
        accessor: "บริษัท",
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head3,
        accessor: "วันที่ลงทะเบียน",
        filterable: false,
        Cell: (val) => (
          <div>{this.dateParse(val.original["วันที่ลงทะเบียน"])}</div>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head5,
        accessor: "จังหวัด",
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head6,
        accessor: "สถานะ",
        filterable: false,
        Cell: (val) => (
          <Select
            value={val.original["สถานะ"]}
            onChange={(e) =>
              that.handleSelectAccountApproval(
                val.original["ชื่อผู้ใช้งาน"],
                val.original["สิทธิ์ในการใช้งาน"],
                e.target.value
              )
            }
          >
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Inactive"
            >
              {switchIntl(toggleLng).manageuser.tabNotAllow}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Active"
            >
              {switchIntl(toggleLng).manageuser.tabAllow}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Read-only"
            >
              {"Read-only"}
            </MenuItem>
          </Select>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head7,
        accessor: "สิทธิ์ในการใช้งาน",
        filterable: false,
        Cell: (val) => (
          <Select
            value={val.original["สิทธิ์ในการใช้งาน"]}
            onChange={(e) =>
              that.handleSelectAccountApproval(
                val.original["ชื่อผู้ใช้งาน"],
                e.target.value,
                val.original["สถานะ"]
              )
            }
          >
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="อนุมัติ"
            >
              {switchIntl(toggleLng).manageuser.tabApprove}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="ไม่อนุมัติ"
            >
              {switchIntl(toggleLng).manageuser.tabNotApprove}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="รอการอนุมัติ"
            >
              {switchIntl(toggleLng).manageuser.tabWaitApprove}
            </MenuItem>
          </Select>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head8,
        headerStyle: { textAlign: "left" },
        accessor: "member_id",
        filterable: false,
        sortable: false,
        Cell: (val) => (
          <div>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => that.showAccountData(val.original["member_id"])}
            >
              <Edit />
            </Button>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => that.removeAccountData(val.original["member_id"])}
            >
              <Delete />
            </Button>
          </div>
        ),
      },
    ];

    const tableTopAdminHeadDataTable = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "index", // String-based value accessors!
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableTopAdmin_head1,
        accessor: "ชื่อผู้ใช้งาน",
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableTopAdmin_head2,
        accessor: "วันที่ลงทะเบียน",
        filterable: false,
        Cell: (val) => (
          <div>{this.dateParse(val.original["วันที่ลงทะเบียน"])}</div>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableTopAdmin_head4,
        accessor: "สถานะ",
        filterable: false,
        Cell: (val) => (
          <Select
            value={val.original["สถานะ"]}
            onChange={(e) =>
              that.handleSelectAccountApproval(
                val.original["ชื่อผู้ใช้งาน"],
                val.original["สิทธิ์ในการใช้งาน"],
                e.target.value
              )
            }
          >
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Inactive"
            >
              {switchIntl(toggleLng).manageuser.tabNotAllow}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Active"
            >
              {switchIntl(toggleLng).manageuser.tabAllow}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Read-only"
            >
              {"Read-only"}
            </MenuItem>
          </Select>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableTopAdmin_head5,
        accessor: "สิทธิ์ในการใช้งาน",
        filterable: false,
        Cell: (val) => (
          <Select
            value={val.original["สิทธิ์ในการใช้งาน"]}
            onChange={(e) =>
              that.handleSelectAccountApproval(
                val.original["ชื่อผู้ใช้งาน"],
                e.target.value,
                val.original["สถานะ"]
              )
            }
          >
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="อนุมัติ"
            >
              {switchIntl(toggleLng).manageuser.tabApprove}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="ไม่อนุมัติ"
            >
              {switchIntl(toggleLng).manageuser.tabNotApprove}
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="รอการอนุมัติ"
            >
              {switchIntl(toggleLng).manageuser.tabWaitApprove}
            </MenuItem>
          </Select>
        ),
      },
      {
        Header: switchIntl(toggleLng).manageuser.tableTopAdmin_head6,
        accessor: "ระบบ",
        filterable: false,
      },

      {
        Header: switchIntl(toggleLng).manageuser.tableSrp_head8,
        headerStyle: { textAlign: "left" },
        accessor: "member_id",
        filterable: false,
        sortable: false,
        Cell: (val) => (
          <div>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => that.showAccountData(val.original["member_id"])}
            >
              <Edit />
            </Button>
            <Button
              justIcon
              round
              color="info"
              simple
              onClick={() => that.removeAccountData(val.original["member_id"])}
            >
              <Delete />
            </Button>
          </div>
        ),
      },
    ];
    let style_prov = this.state.addOrgProvState === "error" ? "red" : "green";
    let style_amp = this.state.addOrgSubdistState === "error" ? "red" : "green";
    let style_tam = this.state.addOrgDistState === "error" ? "red" : "green";

    let provinceItems = (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={this.state.org_province}
        value={this.state.org_province}
        style={{ backgroundColor: "#fff" }}
      >
        {this.state.org_province}
      </MenuItem>
    );

    let amphoneItems = (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={this.state.org_district}
        value={this.state.org_district}
        style={{ backgroundColor: "#fff" }}
      >
        {this.state.org_district}
      </MenuItem>
    );

    let tambonItems = (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={this.state.org_subdistrict}
        value={this.state.org_subdistrict}
        style={{ backgroundColor: "#fff" }}
      >
        {this.state.org_subdistrict}
      </MenuItem>
    );

    const editUser_content = (
      <Row justify="center">
        <Col xs={12} sm={12} md={12}>
          <form>
            <Card style={{ marginTop: "-10%" }}>
              <CardBody style={{ justify: "center" }}>
                <h3 className={classes.infoText}>
                  {
                    switchIntl(this.props.toggleLng).manageuser
                      .modalUserInfoHeader
                  }
                </h3>
                <Row justify="center">
                  <Col xs={12} sm={3} md={3}>
                    <PictureUpload
                      existImage={
                        this.state.member_profimg
                          ? (this.state.member_type === 2
                              ? config.psurveyPath
                              : this.state.member_type === 3
                              ? config.srpPath
                              : config.requestUrl) + this.state.member_profimg
                          : false
                      }
                    />

                    {switchIntl(this.props.toggleLng).addaccount.userrole}
                    {`  `}

                    <Select
                      disabled
                      value={
                        this.state.member_type ? this.state.member_type : "0"
                      }
                      onChange={(e) =>
                        this.setState({ member_type: e.target.value })
                      }
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="0"
                      >
                        {"เลือกสิทธิ์ผู้ใช้งาน"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="1"
                      >
                        {"Admin"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="2"
                      >
                        {"PSurvey"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="3"
                      >
                        {"SRP"}
                      </MenuItem>
                    </Select>
                  </Col>
                  <Col xs={12} sm={9} md={9}>
                    <CustomInput
                      success={this.state.registerFnameState === "success"}
                      error={this.state.registerFnameState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.firstname
                      }
                      id="registerFname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) =>
                          this.change(event, "member_name", "name"),
                        type: "text",
                        value: this.state.member_name
                          ? this.state.member_name
                          : "",
                        endAdornment:
                          this.state.registerFnameState === "error" ? (
                            <InputAdornment position="end">
                              <Close
                                className={classes.danger}
                                style={{ color: "red" }}
                              />
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                    />
                    <CustomInput
                      success={this.state.registerLnameState === "success"}
                      error={this.state.registerLnameState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.lastname
                      }
                      id="registerLname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) =>
                          this.change(event, "member_lname", "name"),
                        type: "text",
                        value: this.state.member_lname
                          ? this.state.member_lname
                          : "",

                        endAdornment:
                          this.state.registerLnameState === "error" ? (
                            <InputAdornment position="end">
                              <Close
                                className={classes.danger}
                                style={{ color: "red" }}
                              />
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                    />
                    <CustomInput
                      success={this.state.registerPhoneState === "success"}
                      error={this.state.registerPhoneState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.phone
                      }
                      id="registerPhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) =>
                          this.change(event, "member_tel", "phone", 9, 10),
                        type: "text",
                        value: this.state.member_tel
                          ? this.state.member_tel
                          : "",

                        endAdornment:
                          this.state.registerPhoneState === "error" ? (
                            <InputAdornment position="end">
                              <Close
                                className={classes.danger}
                                style={{ color: "red" }}
                              />
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                    />
                  </Col>
                </Row>
                <CustomInput
                  success={this.state.registerEmailState === "success"}
                  error={this.state.registerEmailState === "error"}
                  labelText={switchIntl(this.props.toggleLng).register.email}
                  id="registerEmail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "member_email", "email"),
                    type: "email",
                    value: this.state.member_email
                      ? this.state.member_email
                      : "",
                    disabled: true,

                    endAdornment:
                      this.state.registerEmailState === "error" ? (
                        <InputAdornment position="end">
                          <Close
                            className={classes.danger}
                            style={{ color: "red" }}
                          />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
                <CustomInput
                  success={this.state.registerUsernameState === "success"}
                  error={this.state.registerUsernameState === "error"}
                  labelText={switchIntl(this.props.toggleLng).register.username}
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  dis
                  inputProps={{
                    onChange: (event) => {
                      return this.change(event, "member_username", "username");
                    },
                    type: "text",
                    value: this.state.member_username
                      ? this.state.member_username
                      : "",
                    disabled: true ? this.state.member_username : "text08",
                    endAdornment:
                      this.state.registerUsernameState === "error" ? (
                        <InputAdornment position="end">
                          <Close
                            className={classes.danger}
                            style={{ color: "red" }}
                          />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />

                <CustomInput
                  success={this.state.registerPasswordState === "success"}
                  error={this.state.registerPasswordState === "error"}
                  labelText={switchIntl(this.props.toggleLng).register.password}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.registerPassword,
                    onChange: (event) =>
                      this.change(event, "registerPassword", "password"),
                    type: "password",
                    autoComplete: "off",
                    endAdornment:
                      this.state.registerPasswordState === "error" ? (
                        <InputAdornment position="end">
                          {this.state.easypass ? (
                            <p>Password easy</p>
                          ) : (
                            <Close
                              className={classes.danger}
                              style={{ color: "red" }}
                            />
                          )}
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                    disabled: !this.state.passwordResetable,
                  }}
                />
                {this.state.registerPasswordState === "error" ? (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "red",
                      textAlign: "start",
                    }}
                  >
                    <p style={{ margin: 0 }}>
                      - รหัสผ่านจำเป็นต้องมีทั้งตัวเลขและตัวหนังสือ
                    </p>
                    <p style={{ margin: 0 }}>
                      -
                      รหัสผ่านจำเป็นต้องมีทั้งตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่และมีตัวเลขอย่างน้อย
                      1 ตัว
                    </p>
                    <p style={{ margin: 0 }}>
                      - รหัสผ่านจำเป็นต้องมีความยาวอย่างน้อย 8 ตัวแต่ไม่เกิน 32
                      ตัว
                    </p>
                  </div>
                ) : null}
                <CustomInput
                  success={
                    this.state.registerConfirmPasswordState === "success"
                  }
                  error={this.state.registerConfirmPasswordState === "error"}
                  labelText={
                    switchIntl(this.props.toggleLng).register.conpassword
                  }
                  id="confirmpassword"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(
                        event,
                        "registerConfirmPassword",
                        "equalTo",
                        "registerPassword"
                      ),
                    value: this.state.registerConfirmPassword,
                    type: "password",
                    autoComplete: "off",
                    endAdornment:
                      this.state.registerConfirmPasswordState === "error" ? (
                        <InputAdornment position="end">
                          <p
                            style={{
                              width: "150px",
                              margin: "0",
                              textAlign: "end",
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            {
                              switchIntl(this.props.toggleLng).register
                                .noconpass
                            }
                          </p>
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                    disabled: !this.state.passwordResetable,
                  }}
                />

                {this.state.passwordResetable ? (
                  <Button onClick={() => this.comfirmResetPassword()}>
                    {" "}
                    ยืนยันเปลี่ยนรหัสผ่าน{" "}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      return this.setState({
                        alert: (
                          <SweetAlert
                            style={{
                              display: "block",
                              marginTop: "-100px",
                            }}
                            title={"ยืนยันการเปลี่ยนรหัสผ่าน"}
                            onConfirm={(inputValue) =>
                              this.resetPassword(inputValue)
                            }
                            onCancel={() => this.setState({ alert: null })}
                            confirmBtnText={"ยืนยัน"}
                            cancelBtnText={"ยกเลิก"}
                            confirmBtnCssClass={
                              this.props.classes.button +
                              " " +
                              this.props.classes.danger
                            }
                            cancelBtnCssClass={
                              this.props.classes.button +
                              " " +
                              this.props.classes.default
                            }
                            showCancel
                          >
                            {" "}
                            {"คุณกำลังแก้รหัสผ่านของผู้ใช้คนอื่น"}
                          </SweetAlert>
                        ),
                      });
                    }}
                  >
                    เปลี่ยนรหัสผ่าน
                  </Button>
                )}

                {false ? null : (
                  <div>
                    <CustomInput
                      success={this.state.addOrgNameState === "success"}
                      error={this.state.addOrgNameState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.orgname
                      }
                      id="orgname"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: this.state.org_name ? this.state.org_name : "",
                        onChange: (event) =>
                          this.change(event, "org_name", "name"),
                        disabled: true,
                      }}
                    />
                    <Row justify="center">
                      <Col xs={12} sm={4} md={4}>
                        <CustomInput
                          success={this.state.addOrgNoState === "success"}
                          error={this.state.addOrgNoState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgaddress
                          }
                          id="orgno"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.org_address
                              ? this.state.org_address
                              : "",
                            disabled: true,
                            onChange: (event) =>
                              this.change(event, "org_address", "name"),
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={4} md={4}>
                        <CustomInput
                          success={this.state.addOrgBuildingState === "success"}
                          error={this.state.addOrgBuildingState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register
                              .orgbuilding
                          }
                          id="orgbuilding"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.org_namebuilding
                              ? this.state.org_namebuilding
                              : "",
                            disabled: true,
                            onChange: (event) =>
                              this.change(event, "org_namebuilding", "name"),
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={4} md={4}>
                        <CustomInput
                          success={this.state.addOrgFloorState === "success"}
                          error={this.state.addOrgFloorState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgfloor
                          }
                          id="orgfloor"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.org_floor
                              ? this.state.org_floor
                              : "",
                            disabled: true,
                            onChange: (event) =>
                              this.change(event, "org_floor", "name"),
                          }}
                        />
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col xs={12} sm={6} md={6}>
                        <CustomInput
                          success={this.state.addOrgSoiState === "success"}
                          error={this.state.addOrgSoiState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgsoi
                          }
                          id="orgsoi"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.org_soi ? this.state.org_soi : "",
                            disabled: true,
                            onChange: (event) =>
                              this.change(event, "org_soi", "name"),
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={6} md={6}>
                        <CustomInput
                          success={this.state.addOrgStreetState === "success"}
                          error={this.state.addOrgStreetState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgroad
                          }
                          id="orgstreet"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.org_road
                              ? this.state.org_road
                              : "",
                            disabled: true,
                            onChange: (event) =>
                              this.change(event, "org_road", "name"),
                          }}
                        />
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                          style={{
                            margin: "0 0 17px 0",
                            position: "relative",
                            paddingTop: "27px",
                            verticalAlign: "unset",
                          }}
                        >
                          <InputLabel
                            className={classes.selectLabel}
                            style={{
                              top: "10px",
                              color:
                                this.state.addOrgProvState === ""
                                  ? "#AAAAAA"
                                  : style_prov,
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "1.42857",
                            }}
                          >
                            {switchIntl(this.props.toggleLng).register.orgprov}
                          </InputLabel>
                          <Select
                            disabled
                            style={{
                              marginTop: "0px",
                              textAlign: "start",
                              fontSize: "16px",
                            }}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.org_province}
                            onChange={this.handleSelectedProv}
                            inputProps={{
                              name: "org_province",
                              id: "addOrgProv",
                            }}
                          >
                            {provinceItems}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                          style={{
                            margin: "0 0 17px 0",
                            position: "relative",
                            paddingTop: "27px",
                            verticalAlign: "unset",
                          }}
                        >
                          <InputLabel
                            className={classes.selectLabel}
                            style={{
                              top: "10px",
                              color:
                                this.state.addOrgSubdistState === ""
                                  ? "#AAAAAA"
                                  : style_amp,
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "1.42857",
                            }}
                          >
                            {
                              switchIntl(this.props.toggleLng).register
                                .orgsubdis
                            }
                          </InputLabel>
                          <Select
                            disabled
                            style={{
                              marginTop: "0px",
                              textAlign: "start",
                              fontSize: "16px",
                            }}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.org_district}
                            onChange={this.handleSelectedSub}
                            inputProps={{
                              name: "org_district",
                              id: "addOrgSubdist",
                            }}
                          >
                            {amphoneItems}
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              key={"เกาะสมุย"}
                              value={"เกาะสมุย"}
                              style={{ backgroundColor: "#fff" }}
                            >
                              {"เกาะสมุย"}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                          style={{
                            margin: "0 0 17px 0",
                            position: "relative",
                            paddingTop: "27px",
                            verticalAlign: "unset",
                          }}
                        >
                          <InputLabel
                            className={classes.selectLabel}
                            style={{
                              top: "10px",
                              color:
                                this.state.addOrgDistState === ""
                                  ? "#AAAAAA"
                                  : style_tam,
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "1.42857",
                            }}
                          >
                            {switchIntl(this.props.toggleLng).register.orgdis}
                          </InputLabel>
                          <Select
                            disabled
                            style={{
                              marginTop: "0px",
                              textAlign: "start",
                              fontSize: "16px",
                            }}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.org_subdistrict}
                            onChange={this.handleSelectedDist}
                            inputProps={{
                              name: "org_subdistrict",
                              id: "addOrgDist",
                            }}
                          >
                            {tambonItems}
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                    <CustomInput
                      success={this.state.addOrgPhoneState === "success"}
                      error={this.state.addOrgPhoneState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.orgtel
                      }
                      id="orgphone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: this.state.org_tel ? this.state.org_tel : "",
                        disabled: true,
                        onChange: (event) =>
                          this.change(event, "org_tel", "phone", 9, 10),
                      }}
                    />
                    <CustomInput
                      success={this.state.addOrgFaxState === "success"}
                      error={this.state.addOrgFaxState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.orgfax
                      }
                      id="orgfax"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: this.state.org_fax ? this.state.org_fax : "",
                        disabled: true,
                        onChange: (event) =>
                          this.change(event, "state", "phone", 9, 10),
                      }}
                    />
                    <CustomInput
                      success={this.state.addOrgSalePhoneState === "success"}
                      error={this.state.addOrgSalePhoneState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.orgset
                      }
                      id="orgsalephone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: this.state.org_saletel
                          ? this.state.org_saletel
                          : "",
                        disabled: true,
                        onChange: (event) =>
                          this.change(event, "org_saletel", "phone", 9, 10),
                      }}
                    />
                    <CustomInput
                      success={this.state.addOrgUrlState === "success"}
                      error={this.state.addOrgUrlState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.orgweb
                      }
                      id="orgurl"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: this.state.org_website
                          ? this.state.org_website
                          : "",
                        disabled: true,
                        onChange: (event) =>
                          this.change(event, "org_website", "name"),
                      }}
                    />
                  </div>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <NavLink
                  to={"#"}
                  style={{ width: "100%", paddingRight: "5px" }}
                  onClick={this.state.disButton ? null : this.validateRegister}
                ></NavLink>
                <NavLink
                  to={"/auth/"}
                  style={{ width: "100%", paddingLeft: "5px" }}
                ></NavLink>
              </CardFooter>
            </Card>
          </form>
        </Col>
      </Row>
    );

    const srp_content = (
      <Row style={{ padding: "0px", margin: "0px" }}>
        {(() => {
          if (
            this.state.srpAccountModal === undefined ||
            this.state.srpAccountModal === false
              ? false
              : true
          ) {
            return (
              <Dialog
                open={
                  this.state.srpAccountModal === undefined ||
                  this.state.srpAccountModal === false
                    ? false
                    : true
                }
                fullWidth={"lg"}
                maxWidth={"lg"}
                classes={{
                  root: classes.center,
                  paper: classes.modal,
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.setState({ srpAccountModal: false })}
                aria-labelledby="alert-dialog-slide-titleM"
                ar="1"
              >
                <DialogContent
                  id="modal-slide-description"
                  className={classes.modalBody}
                >
                  <Button
                    onClick={() =>
                      this.setState(
                        {
                          srpAccountModal: false,
                        },
                        () => this.resetAllState()
                      )
                    }
                    justIcon
                    round
                    color="white"
                    style={{
                      position: "absolute",
                      right: "50px",
                      zIndex: "100",
                    }}
                  >
                    <Close className={classes.icons} />
                  </Button>
                  {editUser_content}
                  <Button onClick={() => this.editUserInfo()}>
                    {switchIntl(toggleLng).manageuser.editButton}
                  </Button>
                </DialogContent>
              </Dialog>
            );
          }
        })()}
        <Col xs={12}>
          <Card style={{ padding: "15px" }}>
            <CardBody>
              <AntTabs
                value={this.state.srpCurTab ? this.state.srpCurTab : 0}
                onChange={(e, v) => this.setState({ srpCurTab: v })}
                aria-label="ant example"
              >
                <AntTab label={switchIntl(toggleLng).manageuser.table_nav1} />
                <AntTab label={switchIntl(toggleLng).manageuser.table_nav2} />
              </AntTabs>

              <TabPanel value={this.state.srpCurTab} index={0}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label style={{ fontSize: "12px" }} for="RuleFilter">
                            {switchIntl(toggleLng).manageuser.tableSrp_head6}
                          </Label>
                          <Input
                            type="select"
                            name="ruleFilter"
                            id="RuleFilter"
                            value={
                              this.state.curSrpApprovalFilter
                                ? this.state.curSrpApprovalFilter
                                : "All"
                            }
                            onChange={(e) =>
                              that.setState({
                                curSrpApprovalFilter: e.target.value,
                              })
                            }
                          >
                            <option value={"All"}>
                              {switchIntl(toggleLng).manageuser.tabAll}
                            </option>
                            <option value={"Inactive"}>
                              {switchIntl(toggleLng).manageuser.tabNotAllow}
                            </option>
                            <option value={"Active"}>
                              {switchIntl(toggleLng).manageuser.tabAllow}
                            </option>
                            <option value={"Read-only"}>{"Read-only"}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <div className="pull-rightM" style={{ paddingTop: "20px" }}>
                      <Button
                        color="success"
                        onClick={() => this.handleAllActive("3")}
                      >
                        All Active
                      </Button>
                      <Button
                        color="warning"
                        onClick={() => this.handleAllReadOnly("3")}
                      >
                        All Read-only
                      </Button>
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  data={
                    this.state.srpAccountList
                      ? !this.state.curSrpApprovalFilter ||
                        this.state.curSrpApprovalFilter === "All"
                        ? this.state.srpAccountList.filter(
                            (val) => val["สิทธิ์ในการใช้งาน"] === "อนุมัติ"
                          )
                        : this.state.srpAccountList
                            .filter(
                              (val) => val["สิทธิ์ในการใช้งาน"] === "อนุมัติ"
                            )
                            .filter(
                              (val) =>
                                val["สถานะ"] === this.state.curSrpApprovalFilter
                            )
                      : []
                  }
                  filterable
                  columns={tableHeadDataTable}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "index",
                      desc: false,
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel value={this.state.srpCurTab} index={1}>
                <ReactTable
                  data={
                    this.state.srpAccountList
                      ? this.state.srpAccountList.filter(
                          (val) => val["สิทธิ์ในการใช้งาน"] !== "อนุมัติ"
                        )
                      : []
                  }
                  filterable
                  columns={tableWaitHeadDataTable}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "index",
                      desc: false,
                    },
                  ]}
                />
              </TabPanel>

              <h3>{switchIntl(toggleLng).manageuser.dateLogHead}</h3>
              <Button color="info">
                <CSVLink
                  data={
                    this.state.countSrpAccountLog
                      ? this.state.countSrpAccountLog
                      : []
                  }
                  style={{ color: "white" }}
                >
                  <DownloadIcon />

                  {switchIntl(toggleLng).manageuser.exportCSVfile}
                </CSVLink>
              </Button>
              <div style={{ width: "100%" }}>
                <ReactTable
                  data={
                    this.state.countSrpAccountLog
                      ? this.state.countSrpAccountLog
                      : []
                  }
                  filterable
                  columns={accLogColumns}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "acc_date",
                      desc: true,
                    },
                  ]}
                />
                <ReactTable
                  data={this.state.countSrpLog ? this.state.countSrpLog : []}
                  filterable
                  columns={logColumns}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "timestamp",
                      desc: true,
                    },
                  ]}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );

    const psurvey_content = (
      <Row style={{ padding: "0px", margin: "0px" }}>
        <Dialog
          open={
            this.state.psurveyAccountModal === undefined ||
            this.state.psurveyAccountModal === false
              ? false
              : true
          }
          transition={Transition}
          style={{ width: "100%" }}
          keepMounted
          onClose={() => this.setState({ psurveyDataModal: false })}
          aria-labelledby="modal-slide-titleM"
          aria-describedby="modal-slide-description"
        >
          <DialogContent
            style={{ backgroundColor: "#eeeeee" }}
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <Button
              onClick={() => this.setState({ psurveyAccountModal: false })}
              justIcon
              round
              color="white"
              style={{
                position: "absolute",
                right: "50px",
                zIndex: "100",
              }}
            >
              <Close className={classes.icons} />
            </Button>

            {editUser_content}

            <Button>{switchIntl(toggleLng).manageuser.approveButton}</Button>
            <Button>{switchIntl(toggleLng).manageuser.unApproveButton}</Button>
          </DialogContent>
        </Dialog>
        <Col xs={12}>
          <Card style={{ padding: "15px" }}>
            <CardBody>
              <AntTabs
                value={this.state.psurveyCurTab ? this.state.psurveyCurTab : 0}
                onChange={(e, v) => this.setState({ psurveyCurTab: v })}
                aria-label="ant example"
              >
                <AntTab label={switchIntl(toggleLng).manageuser.table_nav1} />
                <AntTab label={switchIntl(toggleLng).manageuser.table_nav2} />
              </AntTabs>

              <TabPanel value={this.state.psurveyCurTab} index={0}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label style={{ fontSize: "12px" }} for="RuleFilter">
                            {switchIntl(toggleLng).manageuser.tableSrp_head6}
                          </Label>
                          <Input
                            type="select"
                            name="ruleFilter"
                            id="RuleFilter"
                            value={
                              this.state.curPsurveyApprovalFilter
                                ? this.state.curPsurveyApprovalFilter
                                : "All"
                            }
                            onChange={(e) =>
                              that.setState({
                                curPsurveyApprovalFilter: e.target.value,
                              })
                            }
                          >
                            <option value={"All"}>
                              {switchIntl(toggleLng).manageuser.tabAll}
                            </option>
                            <option value={"Inactive"}>
                              {switchIntl(toggleLng).manageuser.tabNotAllow}
                            </option>
                            <option value={"Active"}>
                              {switchIntl(toggleLng).manageuser.tabAllow}
                            </option>
                            <option value={"Read-only"}>{"Read-only"}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <div className="pull-rightM" style={{ paddingTop: "20px" }}>
                      <Button
                        color="success"
                        onClick={() => this.handleAllActive("2")}
                      >
                        All Active
                      </Button>
                      <Button
                        color="warning"
                        onClick={() => this.handleAllReadOnly("2")}
                      >
                        All Read-only
                      </Button>
                    </div>
                  </Col>
                </Row>
                <ReactTable
                  data={
                    this.state.psurveyAccountList
                      ? !this.state.curPsurveyApprovalFilter ||
                        this.state.curPsurveyApprovalFilter === "All"
                        ? this.state.psurveyAccountList.filter(
                            (val) => val["สิทธิ์ในการใช้งาน"] === "อนุมัติ"
                          )
                        : this.state.psurveyAccountList
                            .filter(
                              (val) => val["สิทธิ์ในการใช้งาน"] === "อนุมัติ"
                            )
                            .filter(
                              (val) =>
                                val["สถานะ"] ===
                                this.state.curPsurveyApprovalFilter
                            )
                      : []
                  }
                  filterable
                  columns={tableHeadDataTable}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "index",
                      desc: false,
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel value={this.state.psurveyCurTab} index={1}>
                <ReactTable
                  data={
                    this.state.psurveyAccountList
                      ? this.state.psurveyAccountList.filter(
                          (val) => val["สิทธิ์ในการใช้งาน"] !== "อนุมัติ"
                        )
                      : []
                  }
                  filterable
                  columns={tableWaitHeadDataTable}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "index",
                      desc: false,
                    },
                  ]}
                />
              </TabPanel>

              <h3>{switchIntl(toggleLng).manageuser.dateLogHead}</h3>
              <Button color="info">
                <CSVLink
                  data={
                    this.state.countPsurveyAccountLog
                      ? this.state.countPsurveyAccountLog
                      : []
                  }
                  style={{ color: "white" }}
                >
                  <DownloadIcon />
                  {switchIntl(toggleLng).manageuser.exportCSVfile}
                </CSVLink>
              </Button>
              <div style={{ width: "100%" }}>
                <ReactTable
                  data={
                    this.state.countPsurveyAccountLog
                      ? this.state.countPsurveyAccountLog
                      : []
                  }
                  filterable
                  columns={accLogColumns}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "acc_date",
                      desc: true,
                    },
                  ]}
                />
                <ReactTable
                  data={
                    this.state.countPsurveyLog ? this.state.countPsurveyLog : []
                  }
                  filterable
                  columns={logColumns}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "timestamp",
                      desc: true,
                    },
                  ]}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );

    return (
      <div>
        {this.state.alert}

        <NavPills
          color="warning"
          tabs={[
            {
              tabButton: "PSurvey Application",
              tabContent: psurvey_content,
            },
            {
              tabButton: "SRP Application",
              tabContent: srp_content,
            },
            {
              tabButton: "Admin",
              tabContent: (
                <Row style={{ padding: "0px", margin: "0px" }}>
                  <Col xs={12}>
                    <Card style={{ padding: "15px" }}>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={4}>
                                <FormGroup>
                                  <Label
                                    style={{ fontSize: "12px" }}
                                    for="RuleFilter"
                                  >
                                    {
                                      switchIntl(toggleLng).manageuser
                                        .tableTopAdmin_head4
                                    }
                                  </Label>
                                  <Input
                                    type="select"
                                    name="ruleFilter"
                                    id="RuleFilter"
                                    value={
                                      this.state.curAdminRightFilter
                                        ? this.state.curAdminRightFilter
                                        : "All"
                                    }
                                    onChange={(e) =>
                                      that.setState({
                                        curAdminRightFilter: e.target.value,
                                      })
                                    }
                                  >
                                    <option value={"All"}>
                                      {switchIntl(toggleLng).manageuser.tabAll}
                                    </option>
                                    <option value={"Inactive"}>
                                      {
                                        switchIntl(toggleLng).manageuser
                                          .tabNotAllow
                                      }
                                    </option>
                                    <option value={"Active"}>
                                      {
                                        switchIntl(toggleLng).manageuser
                                          .tabAllow
                                      }
                                    </option>
                                    <option value={"Read-only"}>
                                      {"Read-only"}
                                    </option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label
                                    style={{ fontSize: "12px" }}
                                    for="RuleFilter"
                                  >
                                    {
                                      switchIntl(toggleLng).manageuser
                                        .tableTopAdmin_head5
                                    }
                                  </Label>
                                  <Input
                                    type="select"
                                    name="ruleFilter"
                                    id="RuleFilter"
                                    value={
                                      this.state.curAdminApprovalFilter
                                        ? this.state.curAdminApprovalFilter
                                        : "All"
                                    }
                                    onChange={(e) =>
                                      that.setState({
                                        curAdminApprovalFilter: e.target.value,
                                      })
                                    }
                                  >
                                    <option value={"All"}>
                                      {switchIntl(toggleLng).manageuser.tabAll}
                                    </option>
                                    <option value="อนุมัติ">
                                      {
                                        switchIntl(toggleLng).manageuser
                                          .tabApprove
                                      }
                                    </option>
                                    <option value="ไม่อนุมัติ">
                                      {
                                        switchIntl(toggleLng).manageuser
                                          .tabNotApprove
                                      }
                                    </option>
                                    <option value="รอการอนุมัติ">
                                      {
                                        switchIntl(toggleLng).manageuser
                                          .tabWaitApprove
                                      }
                                    </option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <Button
                                  onClick={() => {
                                    window.location.href = "/addaccount";
                                  }}
                                  color="info"
                                >
                                  {
                                    switchIntl(toggleLng).manageuser
                                      .addNewAccountButton
                                  }
                                </Button>
                              </Col>
                              <Col md={4}></Col>
                            </Row>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                        <ReactTable
                          data={
                            this.state.adminAccountList
                              ? this.state.adminAccountList
                                  .filter((val) =>
                                    !this.state.curAdminRightFilter ||
                                    this.state.curAdminRightFilter === "All"
                                      ? true
                                      : val["สถานะ"] ===
                                        this.state.curAdminRightFilter
                                  )
                                  .filter((val) =>
                                    !this.state.curAdminApprovalFilter ||
                                    this.state.curAdminApprovalFilter === "All"
                                      ? true
                                      : val["สิทธิ์ในการใช้งาน"] ===
                                        this.state.curAdminApprovalFilter
                                  )
                                  .filter((val) =>
                                    !this.state.curAdminRoleFilter ||
                                    this.state.curAdminRoleFilter === "All"
                                      ? true
                                      : val["ระบบ"] ===
                                        this.state.curAdminRoleFilter
                                  )
                              : []
                          }
                          filterable
                          columns={tableTopAdminHeadDataTable}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "index",
                              desc: false,
                            },
                          ]}
                        />
                        <h3>{switchIntl(toggleLng).manageuser.dateLogHead}</h3>
                        <Button color="info">
                          <CSVLink
                            data={
                              this.state.countAdminAccountLog
                                ? this.state.countAdminAccountLog
                                : []
                            }
                            style={{ color: "white" }}
                          >
                            <DownloadIcon />
                            {switchIntl(toggleLng).manageuser.exportCSVfile}
                          </CSVLink>
                        </Button>
                        <ReactTable
                          style={{ width: "100%" }}
                          data={
                            this.state.countAdminAccountLog
                              ? this.state.countAdminAccountLog
                              : []
                          }
                          filterable
                          columns={accLogColumns}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "acc_date",
                              desc: true,
                            },
                          ]}
                        />
                        <ReactTable
                          style={{ width: "100%" }}
                          data={
                            this.state.countAdminLog
                              ? this.state.countAdminLog
                              : []
                          }
                          filterable
                          columns={logColumns}
                          defaultPageSize={5}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "timestamp",
                              desc: true,
                            },
                          ]}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

GHDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withStyles(modalStyle)(GHDashboard));
