import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getBase64 from "../../actions/getProfile.js";
import SweetAlert from "react-bootstrap-sweetalert";
// switch language function
import switchIntl from "configure/switchIntl.js";
//import Button from "components/CustomButtons/Button.js";
import "./PictureUpload.css";
import GHBankLoading from "assets/img/reicLoading.gif";
import defaultImage from "assets/img/default-avatar.png";
//import defaultImage2 from "assets/img/image_placeholder.png";
class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: defaultImage,
      reject: true,
      rejectReason: false,
      loadingScreen: false,
      alert: false,
      text: 0,
    };
  }

  componentDidUpdate(previousProps, previousState) {
    var self = this;
    if (this.props.existImage !== previousProps.existImage) {
      self.imgExists(this.props.existImage);
    }
  }
  componentDidMount() {
    if (this.props.existImage) {
      this.imgExists(this.props.existImage);
    }
  }
  imgExists = async (url) => {
    var img = new Image();
    var self = this;
    img.onerror = async function () {
      //console.log('onerror of '+self.props.existImage)
      return await false;
    };
    img.onload = async function () {
      //console.log('onload of '+self.props.existImage)
      self.props.getBase64(self.props.existImage);
      await self.setState({
        file: null,
        //imagePreviewUrl:defaultImage2,
        imagePreviewUrl: self.props.existImage,
        reject: true,
      });
      return await true;
    };
    img.src = url;
    //return await isExist;
  };

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
  };

  handleImageChange = (e) => {
    e.preventDefault();
    var that = this;

    let file = e.target.files[0];

    var imageType = /image.*/;

    //console.log('modall');
    //console.log(this.state.loadingScreen);
    //this.setState({loadingScreen:true});

    if (!file) {
      that.setState({
        reject: true,
        file: "error",
        rejectReason:
          that.props.toggleLng === "TH" ? "ไม่พบไฟล์" : "File not found",
        loadingScreen: false,
      });
      //that.successAlert(that.props.toggleLng==='TH' ?"ไม่พบไฟล์":"File not found",that.props.toggleLng==='TH' ?"ไม่พบไฟล์":"File not found",{warning:true});

      return;
    }

    this.setState({ loadingScreen: true });
    if (file.type.match(imageType)) {
      // it's an image, process it
      //console.log('file size')
      //console.log(file)
      if (file.size > 2000000) {
        //console.log('too big');
        //console.log(that.props.toggleLng==='TH' ?"ไฟล์ขนาดใหญ่เกินไปกรุณาอัพโหลดไฟล์ที่เล็กกว่า 2 MB":"File is too large");

        that.setState({
          reject: true,
          file: "error",
          rejectReason:
            that.props.toggleLng === "TH"
              ? "ไฟล์ขนาดใหญ่เกินไปกรุณาอัพโหลดไฟล์ที่เล็กกว่า 2 MB"
              : "File is too large",
          loadingScreen: false,
        });
        //that.successAlert(that.props.toggleLng==='TH' ?"ไฟล์ขนาดใหญ่เกินไปกรุณาอัพโหลดไฟล์ที่เล็กกว่า 2 MB":"File is too large",that.props.toggleLng==='TH' ?"ไฟล์ขนาดใหญ่เกินไปกรุณาอัพโหลดไฟล์ที่เล็กกว่า 2 MB":"File is too large",{warning:true});

        return;
      } else {
        //console.log('upload start');
        let reader = new FileReader();
        reader.onloadend = () => {
          that.setState({
            file: file,
            imagePreviewUrl: reader.result,
            reject: false,
            loadingScreen: false,
          });
          // eslint-disable-next-line react/prop-types
          //console.log(reader.result)
          let curImage = reader.result.substring(
            reader.result.indexOf("data:image/jpeg;base64,") + 23
          );
          that.props.getBase64(curImage);
          that.setState({});
          //console.log('uploaded');
        };
        reader.readAsDataURL(file);
      }

      //that.successAlert(that.props.toggleLng==='TH' ?"ไฟล์ขนาดใหญ่เกินไปกรุณาอัพโหลดไฟล์ที่เล็กกว่า 2 MB":"File is too large",that.props.toggleLng==='TH' ?"ไฟล์ขนาดใหญ่เกินไปกรุณาอัพโหลดไฟล์ที่เล็กกว่า 2 MB":"File is too large",{warning:true});
    } else {
      // it's not an image, reject it

      that.setState({ reject: true, loadingScreen: false });
    }
  };

  successAlert(title, message, status) {
    //console.log('ll')
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          onConfirm={() => this.setState({ alert: null })}
        //onCancel={() => this.hideA}
        //confirmBtnCssClass={}
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  };

  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
      //
    }
  }

  render() {
    return (
      <div className="picture-container">
        {this.loadingScreen(this.state.loadingScreen)}
        <div className="picture">
          {this.state.reject}
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          {this.state.imagePreviewUrl}
          <input
            type="file"
            onChange={(e) => this.handleImageChange(e)}
            accept="image/x-png,image/jpeg"
          />
        </div>
        {this.state.file === null ? (
          <div className="module-button">
            <h6 className="description">
              {switchIntl(this.props.toggleLng).addaccount.uploadimg}
            </h6>
          </div>
        ) : (
          <h6
            className="description"
            style={{ color: this.state.reject ? "red" : "green" }}
          >
            {this.state.reject
              ? switchIntl(this.props.toggleLng).register.profileerror
              : switchIntl(this.props.toggleLng).register.profileok}
            <br />
            {this.state.reject ? this.state.rejectReason : ""}
          </h6>
        )}
        {/* {this.state.rejectReason
            ? this.state.rejectReason
            : ""}  */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getBase64: (base64) => dispatch(getBase64(base64)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PictureUpload);
