import React from "react";
//import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { HorizontalBar } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setGraphData from "DataControl/actions/setGraphData.js";
import setGraphData_absorption from "DataControl/actions/setGraphData_absorption.js";
// import Minimize from "@material-ui/icons/ExpandLess";
// import Maximize from "@material-ui/icons/ExpandMore";
let totalProject = {
  labels: ["บ้านจัดสรร", "อาคารชุด", "บ้านพักตากอากาศ"],
  datasets: [
    {
      data: [0, 0, 0],
      backgroundColor: ["#FFCE56", "#36A2EB", "#FF6384"],
      hoverBackgroundColor: ["#FFCE56", "#36A2EB", "#FF6384"],
    },
  ],
};

let data_count_house = {
  labels: [
    "บ้านเดี่ยว",
    "บ้านแฝด",
    "อาคารพาณิชย์",
    "ทาวน์เฮ้าส์ หรือทาวน์โฮม",
    "ที่ดินเปล่า",
  ],
  datasets: [
    {
      label: "บ้านจัดสรร",
      backgroundColor: "#FFCE56",
      borderColor: "#FFCE56",
      borderWidth: 1,
      hoverBackgroundColor: "#FFCE56",
      hoverBorderColor: "#FFCE56",
      data: [0, 0, 0, 0, 0],
    },
  ],
};

let data_count_villa = {
  labels: ["บ้านเดี่ยว", "บ้านแฝด", "ทาวน์เฮ้าส์ หรือทาวน์โฮม", "ที่ดินเปล่า"],
  datasets: [
    {
      label: "บ้านพักตากอากาศ",
      data: [],
      backgroundColor: "#FF6384",
      borderColor: "#FF6384",
      borderWidth: 1,
      hoverBackgroundColor: "#FF6384",
      hoverBorderColor: "#FF6384",
    },
  ],
};

let data_count_condo = {
  labels: ["สตูดิโอ", "1 ห้องนอน", "2 ห้องนอน", "3 ห้องนอนขึ้นไป"],
  datasets: [
    {
      label: "อาคารชุด",
      data: [],
      backgroundColor: "#36A2EB",
      borderColor: "#36A2EB",
      borderWidth: 1,
      hoverBackgroundColor: "#36A2EB",
      hoverBorderColor: "#36A2EB",
    },
  ],
};

let data_unit = {
  labels: [
    "หน่วยในผังโครงการทั้งหมด",
    "หน่วยขายได้สะสม",
    "หน่วยขายได้ใหม่",
    "หน่วยโอนสะสม",
    "หน่วยเหลือขายสร้างเสร็จพร้อมโอน",
    "หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง",
  ],
  datasets: [
    {
      label: "บ้านจัดสรร",
      backgroundColor: "#FFCE56",
      borderColor: "#FFCE56",
      borderWidth: 1,
      hoverBackgroundColor: "#FFCE56",
      hoverBorderColor: "#FFCE56",
      data: [0, 0, 0, 0, 0, 0],
    },
    {
      label: "อาคารชุด",
      backgroundColor: "#36A2EB",
      borderColor: "#36A2EB",
      borderWidth: 1,
      hoverBackgroundColor: "#36A2EB",
      hoverBorderColor: "#36A2EB",
      data: [0, 0, 0, 0, 0, 0],
    },
    {
      label: "วิลล่า",
      backgroundColor: "#FF6384",
      borderColor: "#FF6384",
      borderWidth: 1,
      hoverBackgroundColor: "#FF6384",
      hoverBorderColor: "#FF6384",
      data: [0, 0, 0, 0, 0, 0],
    },
  ],
};

let default_data_absorp_house = {
  labels: [],
  datasets: [
    {
      label: "บ้านจัดสรร",
      backgroundColor: "#FFCE56",
      borderColor: "#FFCE56",
      borderWidth: 1,
      hoverBackgroundColor: "#FFCE56",
      hoverBorderColor: "#FFCE56",
      data: [],
    },
  ],
};

let default_data_absorp_condo = {
  labels: [],
  datasets: [
    {
      label: "อาคารชุด",
      backgroundColor: "#36A2EB",
      borderColor: "#36A2EB",
      borderWidth: 1,
      hoverBackgroundColor: "#36A2EB",
      hoverBorderColor: "#36A2EB",
      data: [],
    },
  ],
};

let default_data_absorp_villa = {
  labels: [],
  datasets: [
    {
      label: "บ้านพักตากอากาศ",
      backgroundColor: "#FF6384",
      borderColor: "#FF6384",
      borderWidth: 1,
      hoverBackgroundColor: "#FF6384",
      hoverBorderColor: "#FF6384",
      data: [],
    },
  ],
};

let options = {
  tooltips: {
    enabled: true,
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map((data) => {
          sum += data;
          return data;
        });
        let percentage = ((value * 100) / sum).toFixed(2) + "%";
        // console.log(percentage);
        return percentage;
      },
      color: "#fff",
    },
  },
};
class Dashboard extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  state = {
    showGraph0: true,
    showGraph1: true,
    showGraph2: true,
    showGraph3: true,
    showGraph4: true,
    showGraph5: true,
    showGraph6: true,
    showGraph7: true,
    showGraph8: true,
    showGraph9: true,
    showGraph10: true,
    showGraph11: true,
    showGraph12: true,
    data_absorp_villa: {
      labels: [],
      datasets: [
        {
          label: "บ้านพักตากอากาศ",
          backgroundColor: "#FF6384",
          borderColor: "#FF6384",
          borderWidth: 1,
          hoverBackgroundColor: "#FF6384",
          hoverBorderColor: "#FF6384",
          data: [],
        },
      ],
    },
    data_absorp_condo: {
      labels: [],
      datasets: [
        {
          label: "อาคารชุด",
          backgroundColor: "#36A2EB",
          borderColor: "#36A2EB",
          borderWidth: 1,
          hoverBackgroundColor: "#36A2EB",
          hoverBorderColor: "#36A2EB",
          data: [],
        },
      ],
    },
    data_absorp_house: {
      labels: [],
      datasets: [
        {
          label: "บ้านจัดสรร",
          backgroundColor: "#FFCE56",
          borderColor: "#FFCE56",
          borderWidth: 1,
          hoverBackgroundColor: "#FFCE56",
          hoverBorderColor: "#FFCE56",
          data: [],
        },
      ],
    },
    ishide: true,
    data_count_house: null,
    data_count_villa: null,
    data_count_condo: null,
    data_absorp: null,
    data_unit: null,
    data_1: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_2: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_3: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_4: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_5: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_6: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_7: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_8: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_9: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_10: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
    data_11: {
      house: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      condo: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
      villa: {
        labels: [],
        datasets: [
          {
            label: "บริษัทท่าน",
            backgroundColor: "#FFCE56",
            borderColor: "#FFCE56",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCE56",
            hoverBorderColor: "#FFCE56",
            data: [],
          },
          {
            label: "สมาชิกทั้งหมด",
            backgroundColor: "#36A2EB",
            borderColor: "#36A2EB",
            borderWidth: 1,
            hoverBackgroundColor: "#36A2EB",
            hoverBorderColor: "#36A2EB",
            data: [],
          },
        ],
      },
    },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.graphData !== this.props.graphData) {
      try {
        let raw = this.props.graphData;

        let condoInfo = raw.filter((props) => {
          return props.cat === 1;
        });

        let houseInfo = raw.filter((props) => {
          return props.cat === 2;
        });

        let villaInfo = raw.filter((props) => {
          return props.cat === 3;
        });

        // console.log(houseInfo, villaInfo, condoInfo);

        // let singlehouse = houseInfo.filter((props) => {
        //   return props.name_cat.includes("บ้านเดี่ยว");
        // });
        // let twinhouse = houseInfo.filter((props) => {
        //   return props.name_cat.includes("บ้านแฝด");
        // });
        // let saleshouse = houseInfo.filter((props) => {
        //   return props.name_cat.includes("อาคารพาณิชย์");
        // });
        // let townhouse = houseInfo.filter((props) => {
        //   return props.name_cat.includes("ทาวน์เฮาส์ หรือ ทาวน์โฮม");
        // });
        // let land = houseInfo.filter((props) => {
        //   return props.name_cat.includes("ที่ดินเปล่า");
        // });

        // let single_villa = villaInfo.filter((props) => {
        //   return props.name_cat.includes("บ้านเดี่ยว");
        // });
        // let twin_villa = villaInfo.filter((props) => {
        //   return props.name_cat.includes("บ้านแฝด");
        // });
        // let townhouse_villa = villaInfo.filter((props) => {
        //   return props.name_cat.includes("ทาวน์เฮาส์ หรือ ทาวน์โฮม");
        // });
        // let land_villa = villaInfo.filter((props) => {
        //   return props.name_cat.includes("ที่ดินเปล่า");
        // });

        // let studio = condoInfo.filter((props) => {
        //   return props.name_cat.includes("สตูดิโอ");
        // });
        // let onebed = condoInfo.filter((props) => {
        //   return props.name_cat.includes("1 ห้องนอน");
        // });
        // let twobed = condoInfo.filter((props) => {
        //   return props.name_cat.includes("2 ห้องนอน");
        // });
        // let threebed = condoInfo.filter((props) => {
        //   return props.name_cat.includes("3 ห้องนอนขึ้นไป");
        // });

        // let house_subcate = [
        //   singlehouse.length,
        //   twinhouse.length,
        //   saleshouse.length,
        //   townhouse.length,
        //   land.length,
        // ];
        // let villa_subcate = [
        //   single_villa.length,
        //   twin_villa.length,
        //   townhouse_villa.length,
        //   land_villa.length,
        // ];
        // let condo_subcate = [
        //   studio.length,
        //   onebed.length,
        //   twobed.length,
        //   threebed.length,
        // ];
        // console.log("update data");
        // this.updateTotalProject(
        //   houseInfo.length,
        //   condoInfo.length,
        //   villaInfo.length
        // );
        // this.updateSubproject(house_subcate, condo_subcate, villa_subcate);
        this.updateUnitData(houseInfo, condoInfo, villaInfo);

        // this.total_subhouse.chartInstance.update();
        // this.total_subcondo.chartInstance.update();
        // this.total_subvilla.chartInstance.update();
        // this.total_project.chartInstance.update();
        // this.house_unit.chartInstance.update();
      } catch (err) {
        // console.log(err);
      }
    }

    if (prevProps.absorption !== this.props.absorption) {
      this.updateAbsorption(this.props.absorption);
    }

    // all unit
    if (prevProps.graphData_allunit !== this.props.graphData_allunit) {
      let raw = this.props.graphData_allunit;
      let house_allunit = [];
      let house_member_allunit = [];
      let labels_house = [];

      let condo_allunit = [];
      let condo_member_allunit = [];
      let labels_condo = [];

      let villa_allunit = [];
      let villa_member_allunit = [];
      let labels_villa = [];
      let raw_condo = [];
      let raw_house = [];
      let raw_villa = [];

      raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_allunit.push(raw_house[i].unit);
        house_member_allunit.push(raw_house[i].unit_all);

        labels_house.push(raw_house[i].pricelength);
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_allunit.push(raw_condo[i].unit);
        condo_member_allunit.push(raw_condo[i].unit_all);

        labels_condo.push(raw_condo[i].pricelength);
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_allunit.push(raw_villa[i].unit);
        villa_member_allunit.push(raw_villa[i].unit_all);

        labels_villa.push(raw_villa[i].pricelength);
      }

      var allunit_graph = { ...this.state.data_3 };

      allunit_graph["house"].labels = labels_house;
      allunit_graph["house"].datasets[0].data = house_allunit;
      allunit_graph["house"].datasets[1].data = house_member_allunit;

      allunit_graph["condo"].labels = labels_condo;
      allunit_graph["condo"].datasets[0].data = condo_allunit;
      allunit_graph["condo"].datasets[1].data = condo_member_allunit;

      allunit_graph["villa"].labels = labels_villa;
      allunit_graph["villa"].datasets[0].data = villa_allunit;
      allunit_graph["villa"].datasets[1].data = villa_member_allunit;

      this.setState({ data_3: allunit_graph });
    }

    // left sell + leftsell transfer
    if (prevProps.graphData_graphView !== this.props.graphData_graphView) {
      let raw = this.props.graphData_graphView;
      let house_leftsell = [];
      let house_member_leftsell = [];
      let house_leftselltransfer = [];
      let house_member_leftselltransfer = [];
      let labels_house = [];

      let condo_leftsell = [];
      let condo_member_leftsell = [];
      let condo_leftselltransfer = [];
      let condo_member_leftselltransfer = [];
      let labels_condo = [];

      let villa_leftsell = [];
      let villa_member_leftsell = [];
      let villa_leftselltransfer = [];
      let villa_member_leftselltransfer = [];
      let labels_villa = [];

      let raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      let raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      let raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_leftsell.push(raw_house[i].leftsell);
        house_member_leftsell.push(raw_house[i].leftsell1);

        house_leftselltransfer.push(raw_house[i].leftselltransfer);
        house_member_leftselltransfer.push(raw_house[i].leftselltransfer1);

        labels_house.push(raw_house[i].pricelength);
      }
      for (let i = 0; i < raw_condo.length; i++) {
        condo_leftsell.push(raw_condo[i].leftsell);
        condo_member_leftsell.push(raw_condo[i].leftsell1);

        condo_leftselltransfer.push(raw_condo[i].leftselltransfer);
        condo_member_leftselltransfer.push(raw_condo[i].leftselltransfer1);

        labels_condo.push(raw_house[i].pricelength);
      }
      for (let i = 0; i < raw_villa.length; i++) {
        villa_leftsell.push(raw_villa[i].leftsell);
        villa_member_leftsell.push(raw_villa[i].leftsell1);

        villa_leftselltransfer.push(raw_villa[i].leftselltransfer);
        villa_member_leftselltransfer.push(raw_villa[i].leftselltransfer1);

        labels_villa.push(raw_house[i].pricelength);
      }

      var leftsell_graph = { ...this.state.data_1 };
      var leftselltransfer_graph = { ...this.state.data_2 };

      //leftsell
      leftsell_graph["house"].labels = labels_house;
      leftsell_graph["house"].datasets[0].data = house_leftsell;
      leftsell_graph["house"].datasets[1].data = house_member_leftsell;

      leftsell_graph["condo"].labels = labels_condo;
      leftsell_graph["condo"].datasets[0].data = condo_leftsell;
      leftsell_graph["condo"].datasets[1].data = condo_member_leftsell;

      leftsell_graph["villa"].labels = labels_villa;
      leftsell_graph["villa"].datasets[0].data = villa_leftsell;
      leftsell_graph["villa"].datasets[1].data = villa_member_leftsell;

      //leftsell transfer
      leftselltransfer_graph["house"].labels = labels_house;
      leftselltransfer_graph["house"].datasets[0].data = house_leftselltransfer;
      leftselltransfer_graph[
        "house"
      ].datasets[1].data = house_member_leftselltransfer;

      leftselltransfer_graph["condo"].labels = labels_condo;
      leftselltransfer_graph["condo"].datasets[0].data = condo_leftselltransfer;
      leftselltransfer_graph[
        "condo"
      ].datasets[1].data = condo_member_leftselltransfer;

      leftselltransfer_graph["villa"].labels = labels_villa;
      leftselltransfer_graph["villa"].datasets[0].data = villa_leftselltransfer;
      leftselltransfer_graph[
        "villa"
      ].datasets[1].data = villa_member_leftselltransfer;

      this.setState({ data_1: leftsell_graph });
      this.setState({ data_2: leftselltransfer_graph });
    }

    if (prevProps.graphData_projectall !== this.props.graphData_projectall) {
      let raw = this.props.graphData_projectall;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateTotalProject(
        houseInfo.length,
        condoInfo.length,
        villaInfo.length
      );
    }

    if (prevProps.graphData_allunit !== this.props.graphData_allunit) {
      let raw = this.props.graphData_allunit;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    // new sell
    if (prevProps.graphData_newsell !== this.props.graphData_newsell) {
      let raw = this.props.graphData_newsell;
      let house_newsell = [];
      let house_member_newsell = [];
      let labels_house = [];

      let condo_newsell = [];
      let condo_member_newsell = [];
      let labels_condo = [];

      let villa_newsell = [];
      let villa_member_newsell = [];
      let labels_villa = [];

      let raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      let raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      let raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_newsell.push(raw_house[i].sellnew);
        house_member_newsell.push(raw_house[i].sellnew_all);

        labels_house.push(raw_house[i].pricelength);
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_newsell.push(raw_condo[i].sellnew);
        condo_member_newsell.push(raw_condo[i].sellnew_all);

        labels_condo.push(raw_condo[i].pricelength);
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_newsell.push(raw_villa[i].sellnew);
        villa_member_newsell.push(raw_villa[i].sellnew_all);

        labels_villa.push(raw_villa[i].pricelength);
      }

      var newsell_graph = { ...this.state.data_4 };

      //new sell
      newsell_graph["house"].labels = labels_house;
      newsell_graph["house"].datasets[0].data = house_newsell;
      newsell_graph["house"].datasets[1].data = house_member_newsell;

      newsell_graph["condo"].labels = labels_condo;
      newsell_graph["condo"].datasets[0].data = condo_newsell;
      newsell_graph["condo"].datasets[1].data = condo_member_newsell;

      newsell_graph["villa"].labels = labels_villa;
      newsell_graph["villa"].datasets[0].data = villa_newsell;
      newsell_graph["villa"].datasets[1].data = villa_member_newsell;

      this.setState({ data_4: newsell_graph });
    }

    if (prevProps.graphData_newsell !== this.props.graphData_newsell) {
      let raw = this.props.graphData_newsell;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    if (prevProps.graphData_graphView !== this.props.graphData_graphView) {
      let raw = this.props.graphData_graphView;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    if (
      prevProps.graphData_transfer_acc !== this.props.graphData_transfer_acc
    ) {
      let raw = this.props.graphData_transfer_acc;
      let house_transfer_acc = [];
      let house_member_transfer_acc = [];
      let labels_house = [];

      let condo_transfer_acc = [];
      let condo_member_transfer_acc = [];
      let labels_condo = [];

      let villa_transfer_acc = [];
      let villa_member_transfer_acc = [];
      let labels_villa = [];

      let raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      let raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      let raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_transfer_acc.push(raw_house[i].transfer);
        house_member_transfer_acc.push(raw_house[i].transferall);

        labels_house.push(raw_house[i].pricelength);
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_transfer_acc.push(raw_condo[i].transfer);
        condo_member_transfer_acc.push(raw_condo[i].transferall);

        labels_condo.push(raw_condo[i].pricelength);
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_transfer_acc.push(raw_villa[i].transfer);
        villa_member_transfer_acc.push(raw_villa[i].transferall);

        labels_villa.push(raw_villa[i].pricelength);
      }

      var transfer_acc_graph = { ...this.state.data_5 };

      //transfer_acc
      transfer_acc_graph["house"].labels = labels_house;
      transfer_acc_graph["house"].datasets[0].data = house_transfer_acc;
      transfer_acc_graph["house"].datasets[1].data = house_member_transfer_acc;

      transfer_acc_graph["condo"].labels = labels_condo;
      transfer_acc_graph["condo"].datasets[0].data = condo_transfer_acc;
      transfer_acc_graph["condo"].datasets[1].data = condo_member_transfer_acc;

      transfer_acc_graph["villa"].labels = labels_villa;
      transfer_acc_graph["villa"].datasets[0].data = villa_transfer_acc;
      transfer_acc_graph["villa"].datasets[1].data = villa_member_transfer_acc;
    }

    if (
      prevProps.graphData_transfer_acc !== this.props.graphData_transfer_acc
    ) {
      let raw = this.props.graphData_transfer_acc;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    if (prevProps.graphData_leftsell !== this.props.graphData_leftsell) {
      let raw = this.props.graphData_leftsell;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    // open new sell
    if (
      prevProps.graphData_open_newsell !== this.props.graphData_open_newsell
    ) {
      let raw = this.props.graphData_open_newsell;
      let house_open_newsell = [];
      let house_member_open_newsell = [];
      let labels_house = [];

      let condo_open_newsell = [];
      let condo_member_open_newsell = [];
      let labels_condo = [];

      let villa_open_newsell = [];
      let villa_member_open_newsell = [];
      let labels_villa = [];

      let raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      let raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      let raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_open_newsell.push(raw_house[i].sum_open_new);
        house_member_open_newsell.push(raw_house[i].sum_open_newall);
        labels_house.push(raw_house[i].pricelength);
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_open_newsell.push(raw_condo[i].sum_open_new);
        condo_member_open_newsell.push(raw_condo[i].sum_open_newall);
        labels_condo.push(raw_condo[i].pricelength);
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_open_newsell.push(raw_villa[i].sum_open_new);
        villa_member_open_newsell.push(raw_villa[i].sum_open_newall);
        labels_villa.push(raw_villa[i].pricelength);
      }

      var open_newsell_graph = { ...this.state.data_9 };

      open_newsell_graph["house"].labels = labels_house;
      open_newsell_graph["house"].datasets[0].data = house_open_newsell;
      open_newsell_graph["house"].datasets[1].data = house_member_open_newsell;

      open_newsell_graph["condo"].labels = labels_condo;
      open_newsell_graph["condo"].datasets[0].data = condo_open_newsell;
      open_newsell_graph["condo"].datasets[1].data = condo_member_open_newsell;

      open_newsell_graph["villa"].labels = labels_villa;
      open_newsell_graph["villa"].datasets[0].data = villa_open_newsell;
      open_newsell_graph["villa"].datasets[1].data = villa_member_open_newsell;

      this.setState({ data_9: open_newsell_graph });
    }

    if (
      prevProps.graphData_open_newsell !== this.props.graphData_open_newsell
    ) {
      let raw = this.props.graphData_open_newsell;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    if (prevProps.graphData_sell_acc !== this.props.graphData_sell_acc) {
      let raw = this.props.graphData_sell_acc;
      let house_sell_acc = [];
      let house_member_sell_acc = [];
      let labels_house = [];

      let condo_sell_acc = [];
      let condo_member_sell_acc = [];
      let labels_condo = [];

      let villa_sell_acc = [];
      let villa_member_sell_acc = [];
      let labels_villa = [];

      let raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      let raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      let raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_sell_acc.push(raw_house[i].sellaccum);
        house_member_sell_acc.push(raw_house[i].sellaccum_all);
        labels_house.push(raw_house[i].pricelength);
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_sell_acc.push(raw_condo[i].sellaccum);
        condo_member_sell_acc.push(raw_condo[i].sellaccum_all);
        labels_condo.push(raw_condo[i].pricelength);
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_sell_acc.push(raw_villa[i].sellaccum);
        villa_member_sell_acc.push(raw_villa[i].sellaccum_all);
        labels_villa.push(raw_villa[i].pricelength);
      }

      var sell_acc_graph = { ...this.state.data_7 };

      sell_acc_graph["house"].labels = labels_house;
      sell_acc_graph["house"].datasets[0].data = house_sell_acc;
      sell_acc_graph["house"].datasets[1].data = house_member_sell_acc;

      sell_acc_graph["condo"].labels = labels_condo;
      sell_acc_graph["condo"].datasets[0].data = condo_sell_acc;
      sell_acc_graph["condo"].datasets[1].data = condo_member_sell_acc;

      sell_acc_graph["villa"].labels = labels_villa;
      sell_acc_graph["villa"].datasets[0].data = villa_sell_acc;
      sell_acc_graph["villa"].datasets[1].data = villa_member_sell_acc;

      this.setState({ data_7: sell_acc_graph });
    }

    if (prevProps.graphData_sell_acc !== this.props.graphData_sell_acc) {
      let raw = this.props.graphData_sell_acc;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    if (
      prevProps.graphData_sell_acc_transfer !==
      this.props.graphData_sell_acc_transfer
    ) {
      let raw = this.props.graphData_sell_acc_transfer;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    // project new sell
    // console.log("test", prevProps, this.props);
    if (prevProps.graphData_newproject !== this.props.graphData_newproject) {
      let raw = this.props.graphData_newproject;
      // console.log(raw);
      let house_numofproj = [];
      let house_member_numofproj = [];
      let labels_house = [];

      let condo_numofproj = [];
      let condo_member_numofproj = [];
      let labels_condo = [];

      let villa_numofproj = [];
      let villa_member_numofproj = [];
      let labels_villa = [];
      let raw_condo = [];
      let raw_house = [];
      let raw_villa = [];

      raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_numofproj.push(raw_house[i].numofproj);
        house_member_numofproj.push(raw_house[i].numofprojall);

        labels_house.push(
          raw_house[i].province_t + " / " + raw_house[i].amphoe_t
        );
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_numofproj.push(raw_condo[i].numofproj);
        condo_member_numofproj.push(raw_condo[i].numofprojall);

        labels_condo.push(
          raw_house[i].province_t + " / " + raw_house[i].amphoe_t
        );
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_numofproj.push(raw_villa[i].numofproj);
        villa_member_numofproj.push(raw_villa[i].numofprojall);

        labels_villa.push(
          raw_house[i].province_t + " / " + raw_house[i].amphoe_t
        );
      }

      var numofproj_graph = { ...this.state.data_6 };

      numofproj_graph["house"].labels = labels_house;
      numofproj_graph["house"].datasets[0].data = house_numofproj;
      numofproj_graph["house"].datasets[1].data = house_member_numofproj;

      numofproj_graph["condo"].labels = labels_condo;
      numofproj_graph["condo"].datasets[0].data = condo_numofproj;
      numofproj_graph["condo"].datasets[1].data = condo_member_numofproj;

      numofproj_graph["villa"].labels = labels_villa;
      numofproj_graph["villa"].datasets[0].data = villa_numofproj;
      numofproj_graph["villa"].datasets[1].data = villa_member_numofproj;

      this.setState({ data_6: numofproj_graph });
    }

    if (prevProps.graphData_newproject !== this.props.graphData_newproject) {
      let raw = this.props.graphData_newproject;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    // sell acc transfer
    if (
      prevProps.graphData_sell_acc_transfer !==
      this.props.graphData_sell_acc_transfer
    ) {
      let raw = this.props.graphData_sell_acc_transfer;
      // console.log(raw);
      let house_sell_acc_transfer = [];
      let house_member_sell_acc_transfer = [];
      let labels_house = [];

      let condo_sell_acc_transfer = [];
      let condo_member_sell_acc_transfer = [];
      let labels_condo = [];

      let villa_sell_acc_transfer = [];
      let villa_member_sell_acc_transfer = [];
      let labels_villa = [];
      let raw_condo = [];
      let raw_house = [];
      let raw_villa = [];

      raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_sell_acc_transfer.push(raw_house[i].transferbacklog);
        house_member_sell_acc_transfer.push(raw_house[i].transferbacklog_all);

        labels_house.push(raw_house[i].pricelength);
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_sell_acc_transfer.push(raw_condo[i].transferbacklog);
        condo_member_sell_acc_transfer.push(raw_condo[i].transferbacklog_all);

        labels_condo.push(raw_condo[i].pricelength);
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_sell_acc_transfer.push(raw_villa[i].transferbacklog);
        villa_member_sell_acc_transfer.push(raw_villa[i].transferbacklog_all);

        labels_villa.push(raw_villa[i].pricelength);
      }

      var sell_acc_transfer_graph = { ...this.state.data_8 };

      sell_acc_transfer_graph["house"].labels = labels_house;
      sell_acc_transfer_graph[
        "house"
      ].datasets[0].data = house_sell_acc_transfer;
      sell_acc_transfer_graph[
        "house"
      ].datasets[1].data = house_member_sell_acc_transfer;

      sell_acc_transfer_graph["condo"].labels = labels_condo;
      sell_acc_transfer_graph[
        "condo"
      ].datasets[0].data = condo_sell_acc_transfer;
      sell_acc_transfer_graph[
        "condo"
      ].datasets[1].data = condo_member_sell_acc_transfer;

      sell_acc_transfer_graph["villa"].labels = labels_villa;
      sell_acc_transfer_graph[
        "villa"
      ].datasets[0].data = villa_sell_acc_transfer;
      sell_acc_transfer_graph[
        "villa"
      ].datasets[1].data = villa_member_sell_acc_transfer;

      this.setState({ data_8: sell_acc_transfer_graph });
    }

    if (
      prevProps.graphData_sell_acc_transfer !==
      this.props.graphData_sell_acc_transfer
    ) {
      let raw = this.props.graphData_sell_acc_transfer;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }

    // leftsell
    if (prevProps.graphData_leftsell !== this.props.graphData_leftsell) {
      let raw = this.props.graphData_leftsell;
      // console.log(raw);
      let house_leftsell = [];
      let house_member_leftsell = [];
      let labels_house = [];

      let condo_leftsell = [];
      let condo_member_leftsell = [];
      let labels_condo = [];

      let villa_leftsell = [];
      let villa_member_leftsell = [];
      let labels_villa = [];
      let raw_condo = [];
      let raw_house = [];
      let raw_villa = [];

      raw_condo = raw.filter((props) => {
        return props.cat === 1;
      });

      raw_house = raw.filter((props) => {
        return props.cat === 2;
      });

      raw_villa = raw.filter((props) => {
        return props.cat === 3;
      });

      for (let i = 0; i < raw_house.length; i++) {
        house_leftsell.push(raw_house[i].leftsell);
        house_member_leftsell.push(raw_house[i].leftsellall);

        labels_house.push(raw_house[i].pricelength);
      }

      for (let i = 0; i < raw_condo.length; i++) {
        condo_leftsell.push(raw_condo[i].leftsell);
        condo_member_leftsell.push(raw_condo[i].leftsellall);

        labels_condo.push(raw_condo[i].pricelength);
      }

      for (let i = 0; i < raw_villa.length; i++) {
        villa_leftsell.push(raw_villa[i].leftsell);
        villa_member_leftsell.push(raw_villa[i].leftsellall);

        labels_villa.push(raw_villa[i].pricelength);
      }

      // var leftsell_graph = { ...this.state.data_10 };

      leftsell_graph["house"].labels = labels_house;
      leftsell_graph["house"].datasets[0].data = house_leftsell;
      leftsell_graph["house"].datasets[1].data = house_member_leftsell;

      leftsell_graph["condo"].labels = labels_condo;
      leftsell_graph["condo"].datasets[0].data = condo_leftsell;
      leftsell_graph["condo"].datasets[1].data = condo_member_leftsell;

      leftsell_graph["villa"].labels = labels_villa;
      leftsell_graph["villa"].datasets[0].data = villa_leftsell;
      leftsell_graph["villa"].datasets[1].data = villa_member_leftsell;

      this.setState({ data_10: leftsell_graph });
    }

    if (prevProps.graphData_leftsell !== this.props.graphData_leftsell) {
      let raw = this.props.graphData_leftsell;
      let condoInfo = raw.filter((props) => {
        return props.cat === 1;
      });

      let houseInfo = raw.filter((props) => {
        return props.cat === 2;
      });

      let villaInfo = raw.filter((props) => {
        return props.cat === 3;
      });
      this.updateUnitData(houseInfo, condoInfo, villaInfo);
    }
  }

  updateAbsorption = (absorption) => {
    let house = absorption.filter((props) => {
      return props.cat_id === 2;
    });

    let condo = absorption.filter((props) => {
      return props.cat_id === 1;
    });

    let villa = absorption.filter((props) => {
      return props.cat_id === 3;
    });

    let house_absorp = house.map((props) => {
      return parseFloat(props.absorption);
    });

    let house_absorp_label = house.map((props) => {
      return props.province_t;
    });

    let condo_absorp = condo.map((props) => {
      return parseFloat(props.absorption);
    });

    let condo_absorp_label = condo.map((props) => {
      return props.province_t;
    });

    let villa_absorp = villa.map((props) => {
      return props.absorption;
    });

    let villa_absorp_label = villa.map((props) => {
      return props.province_t;
    });

    //new data;

    default_data_absorp_house.datasets[0].data = house_absorp;
    default_data_absorp_house.labels = house_absorp_label;

    this.setState({ data_absorp_house: default_data_absorp_house });

    default_data_absorp_condo.datasets[0].data = condo_absorp;
    default_data_absorp_condo.labels = condo_absorp_label;
    this.setState({ data_absorp_condo: default_data_absorp_condo });

    default_data_absorp_villa.datasets[0].data = villa_absorp;
    default_data_absorp_villa.labels = villa_absorp_label;
    this.setState({ data_absorp_villa: default_data_absorp_villa });

    try {
      this.absorp_house.chartInstance.update();
      this.absorp_condo.chartInstance.update();
      this.absorp_villa.chartInstance.update();
    } catch (err) {}
  };

  updateSubproject = (house, condo, villa) => {
    data_count_house.datasets[0].data = house;
    data_count_villa.datasets[0].data = condo;
    data_count_condo.datasets[0].data = villa;

    this.total_subhouse.chartInstance.update();
    this.total_subcondo.chartInstance.update();
    this.total_subvilla.chartInstance.update();
  };

  updateTotalProject = (house, condo, villa) => {
    let data = [house, condo, villa];
    totalProject.datasets[0].data = data;
    this.total_project.chartInstance.update();
  };

  updateUnitData = (houseInfo, condoInfo, villaInfo) => {
    let unit_all_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.unit_all || 0);
    }, 0);

    let sellaccum_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellaccum || 0);
    }, 0);

    let sell_house = houseInfo.reduce((sum, props) => {
      // console.log(props);
      return sum + parseInt(props.sellnew || 0);
    }, 0);

    // console.log(sell_house);

    let transfer_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.transfer || 0);
    }, 0);

    let leftselltransfer_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftselltransfer || 0);
    }, 0);

    let leftsell_house = houseInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftsell || 0);
    }, 0);

    let unit_all_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.unit_all || 0);
    }, 0);

    let sellaccum_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellaccum || 0);
    }, 0);

    let sell_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellnew || 0);
    }, 0);

    let transfer_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.transfer || 0);
    }, 0);

    let leftselltransfer_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftselltransfer || 0);
    }, 0);

    let leftsell_condo = condoInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftsell || 0);
    }, 0);

    let unit_all_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.unit_all);
    }, 0);

    let sellaccum_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellaccum);
    }, 0);

    let sell_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.sellnew);
    }, 0);

    let transfer_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.transfer);
    }, 0);

    let leftselltransfer_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftselltransfer);
    }, 0);

    let leftsell_villa = villaInfo.reduce((sum, props) => {
      return sum + parseInt(props.leftsell);
    }, 0);

    let house_unit = [
      unit_all_house,
      sellaccum_house,
      sell_house,
      transfer_house,
      leftselltransfer_house,
      leftsell_house,
    ];

    let condo_unit = [
      unit_all_condo,
      sellaccum_condo,
      sell_condo,
      transfer_condo,
      leftselltransfer_condo,
      leftsell_condo,
    ];

    let villa_unit = [
      unit_all_villa,
      sellaccum_villa,
      sell_villa,
      transfer_villa,
      leftselltransfer_villa,
      leftsell_villa,
    ];

    // [3930, 0, 0, 0, 0, 0][(0, 0, 0, 7, 0, 0)];
    data_unit.datasets[0].data = data_unit.datasets[0].data.map((item, index) =>
      item === 0 ? item + house_unit[index] : item
    );
    data_unit.datasets[1].data = data_unit.datasets[1].data.map((item, index) =>
      item === 0 ? item + condo_unit[index] : item
    );
    data_unit.datasets[2].data = data_unit.datasets[2].data.map((item, index) =>
      item === 0 ? item + villa_unit[index] : item
    );

    this.setState({ data_unit });
    // console.log(data_unit);

    // this.house_unit.chartInstance.update();
  };

  render() {
    const { classes } = this.props;
    // console.log("props", this.props);
    const names = [
      { name: "หน่วยเหลือขายทั้งหมด", id: 1, ref: "unit_all" },
      { name: "หน่วยเหลือขายสร้างเสร็จพร้อมโอน", id: 2, ref: "unit_transfer" },
      { name: "หน่วยในผังโครงการทั้งหมด", id: 3, ref: "all_unit" },
      { name: "หน่วยขายได้ใหม่", id: 4, ref: "unit_new_sell" },
      { name: "หน่วยโอนสะสม", id: 5, ref: "unit_transferacc" },
      { name: "โครงการเปิดขายใหม่", id: 6, ref: "proj_sell" },
      { name: "หน่วยขายได้สะสม", id: 7, ref: "unit_sellacc" },
      { name: "หน่วยขายได้รอโอนสะสม (backlog)", id: 8, ref: "unit_backlog" },
      { name: "หน่วยเปิดขายใหม่", id: 9, ref: "unit_new_open" },
      {
        name: "หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง",
        id: 10,
        ref: "unit_notbuild",
      },
    ];

    const graph = names.map((props) => {
      // console.log(props);
      if (
        this.props.showgraph &&
        this.props.showgraph.indexOf(props.id) !== -1
      ) {
        let statename = "showGraph" + props.id;
        return (
          <Card>
            <CardBody>
              {/* <a href="#">
                {" "}
                <h4
                  onClick={() =>
                    this.state[statename]
                      ? this.setState({ [statename]: false })
                      : this.setState({ [statename]: true })
                  }
                >
                  {props.name}
                  {this.state[statename] ? (
                    <Minimize style={{ float: "right" }} />
                  ) : (
                    <Maximize style={{ float: "right" }} />
                  )}
                </h4>
              </a> */}
              {this.state[statename] && (
                <>
                  <h4>{props.name}</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <Card>
                        <CardHeader color="danger" icon>
                          <h4 className={classes.cardIconTitle}>บ้านจัดสรร</h4>
                        </CardHeader>
                        <CardBody>
                          <div>
                            <HorizontalBar
                              ref={this.props.ref}
                              data={this.state["data_" + props.id].house}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Card>
                        <CardHeader color="danger" icon>
                          <h4 className={classes.cardIconTitle}>อาคารชุด</h4>
                        </CardHeader>
                        <CardBody>
                          <div>
                            <HorizontalBar
                              ref={(price_condo) =>
                                (this.price_condo = price_condo)
                              }
                              data={this.state["data_" + props.id].condo}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Card>
                        <CardHeader color="danger" icon>
                          <h4 className={classes.cardIconTitle}>
                            บ้านพักตากอากาศ
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <div>
                            <HorizontalBar
                              ref={(price_villa) =>
                                (this.price_villa = price_villa)
                              }
                              data={this.state["data_" + props.id].villa}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </>
              )}
            </CardBody>
          </Card>
        );
      }
      return null;
    });

    return (
      <GridContainer>
        <GridContainer>
          {this.props.showgraph && this.props.showgraph.indexOf(0) !== -1 ? (
            <GridItem xs={6} sm={6} md={6}>
              <CardBody
                style={{ display: this.state.ishide ? "block" : "none" }}
              >
                <h5>จำนวนโครงการทั้งหมด</h5>
                <div>
                  <Pie
                    ref={(total_project) =>
                      (this.total_project = total_project)
                    }
                    data={totalProject}
                    options={options}
                  />
                </div>
              </CardBody>
            </GridItem>
          ) : null}
          {this.props.showgraph && this.props.showgraph.indexOf(3) !== -1 ? (
            <GridItem xs={6} sm={6} md={6}>
              <CardBody>
                <div>
                  <h5>หน่วยในผังโครงการแยกตามประเภทที่อยู่อาศัย</h5>
                  <HorizontalBar
                    ref={(house_unit) => (this.house_unit = house_unit)}
                    data={this.state.data_unit}
                  />
                </div>
              </CardBody>
            </GridItem>
          ) : null}
        </GridContainer>
        {graph}
        {this.props.showgraph && this.props.showgraph.indexOf(11) !== -1 ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader color="danger" icon>
                  <h4 className={classes.cardIconTitle}>
                    อัตราดูดซับต่อเดือน บ้านจัดสรร
                  </h4>
                </CardHeader>
                <CardBody>
                  <div>
                    <Bar
                      ref={(absorp_house) => (this.absorp_house = absorp_house)}
                      data={this.state.data_absorp_house}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader color="danger" icon>
                  <h4 className={classes.cardIconTitle}>
                    อัตราดูดซับต่อเดือน อาคารชุด
                  </h4>
                </CardHeader>
                <CardBody>
                  <div>
                    <Bar
                      ref={(absorp_condo) => (this.absorp_condo = absorp_condo)}
                      data={this.state.data_absorp_condo}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader color="danger" icon>
                  <h4 className={classes.cardIconTitle}>
                    อัตราดูดซับต่อเดือน บ้านพักตากอากาศ
                  </h4>
                </CardHeader>
                <CardBody>
                  <div>
                    <Bar
                      ref={(absorp_villa) => (this.absorp_villa = absorp_villa)}
                      data={this.state.data_absorp_villa}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
        <GridItem xs={12} sm={12} md={7}>
          <div></div>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setGraphData: (payload) => dispatch(setGraphData(payload)),
  setGraphData_absorption: (payload) =>
    dispatch(setGraphData_absorption(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
