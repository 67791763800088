/* eslint-disable no-useless-escape */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { ClipLoader } from "react-spinners";

import * as config from "configure/configServer.js";
import moment from "moment";
// redux
import { connect } from "react-redux";
import surveyInfo from "actions/survey/surveyInfo.js";
import condo_sec2Object from "actions/survey/condo/condo_sec2Data.js";
import condoSec2_Object from "actions/survey/condo/condo_sec2Obj.js";
import setBuildingAndUnit_sec2 from "actions/survey/condo/BuildingAndUnit_sec2.js";

import condo_sec3Object from "actions/survey/condo/condo_sec3Data.js";
import condoSec3_Object from "actions/survey/condo/condo_sec3Obj.js";

import condo_sec4Object from "actions/survey/condo/condo_sec4Data.js";

import CustomInput from "components/CustomInput/CustomInputTooltips.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CryptoJS from "crypto-js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import surveyModal from "actions/survey/surveyModal.js";
import imageuploading from "actions/uploading_image.js";

import numeral from "numeral";

// Date
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-thai-datepickers";
import "moment/locale/th";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "./CondoTable.css";
import SweetAlert from "react-bootstrap-sweetalert";

class CondoStep2 extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,

      build_status: ["ยังไม่ก่อสร้าง", "สร้างเสร็จ", "อยู่ระหว่างสร้าง"],
      first_sale: null,
      buildinfo_construct: null,
      buildinfo_finish: null,

      date_warning_msg: "กรุณาระบุ",
      total_unit: 0, //จำนวนหน่วยทั้งหมด
      //จำนวนตึก
      no_of_building: 0,
      // noti core
      noti: false,
      open: false,
      color: "info",
      message: "Hello",
      place: "tr",

      token: "",
      btLoading: false,
      value: 0,
      createModal: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      markerPosition: { lat: null, lng: null },
      welfareCheck: [],
      openNotification: false,
      language: "th",
      cat_detail: [],
      // cat detail state change
      bs_name_State: "",
      bs_totalfloor_State: "",
      bs_totalunit_State: "",
      bs_startbuild_State: "",
      bs_finishedbuild_State: "",
      bs_notbuild_State: "",
      // tooltip state value
      tooltip_floor: "ระบุจำนวนชั้น",
      column: [
        {
          title: "ชื่ออาคาร",
          field: "bs_name",
          initialEditValue: "",
          emptyValue: "",
          type: "string",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.bs_name_State === "error" ? "**" : undefined
              }
              success={this.state.bs_name_State === "success"}
              error={this.state.bs_name_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "bs_name"),
                value: props.value,
              }}
              tooltipText={"กรุณาระบุชื่ออาคาร"}
            />
          ),
        },
        {
          title: "จำนวนชั้น",
          field: "bs_totalfloor",
          type: "numeric",
          initialEditValue: "",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.bs_totalfloor_State === "error" ? "**" : undefined
              }
              success={this.state.bs_totalfloor_State === "success"}
              error={this.state.bs_totalfloor_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "bs_totalfloor"),
                value: props.value,
              }}
              tooltipText={this.state.tooltip_floor}
            />
          ),
        },
        {
          title: "หน่วยทั้งหมด",
          field: "bs_totalunit",
          type: "numeric",
          initialEditValue: "",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.bs_totalunit_State === "error" ? "**" : undefined
              }
              success={this.state.bs_totalunit_State === "success"}
              error={this.state.bs_totalunit_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "bs_totalunit"),
                value: props.value,
              }}
              tooltipText={this.state.bs_totalunit}
            />
          ),
        },
        {
          title: "วันที่เริ่มก่อสร้าง",
          field: "bs_startbuild",
          type: "string",
          initialEditValue: "",
          emptyValue: "",
          render: (rowData) => (
            <label>
              {rowData.bs_startbuild &&
              rowData.bs_startbuild.length &&
              rowData.bs_startbuild.length > 7
                ? rowData.bs_startbuild.substring(0, 6) +
                  (parseInt(rowData.bs_startbuild.substring(6)) + 543)
                : null}
            </label>
          ),
          editComponent: (props) => (
            <div>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  success={this.state.bs_startbuild_State === "success"}
                  error={this.state.bs_startbuild_State === "error"}
                  clearable="true"
                  label={
                    props.rowData.bs_startbuild ? null : (
                      <label style={{ color: "red" }}>
                        {this.state.date_warning_msg}
                      </label>
                    )
                  }
                  format="DD/MM/YYYY"
                  pickerHeaderFormat="ddd D MMM"
                  yearOffset={543}
                  variant="inline"
                  onChange={(event) => this.setStartBuild(event, props)}
                  value={
                    moment(
                      props.rowData.bs_startbuild,
                      "DD/MM/YYYY"
                    ).toISOString() || this.state.buildinfo_construct
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          ),
        },
        {
          title: "วันที่คาดว่าจะสร้างเสร็จ",
          field: "bs_finishedbuild",
          type: "string",
          initialEditValue: "",
          emptyValue: "",
          render: (rowData) => (
            <label>
              {rowData.bs_finishedbuild &&
              rowData.bs_finishedbuild.length &&
              rowData.bs_finishedbuild.length > 7
                ? rowData.bs_finishedbuild.substring(0, 6) +
                  (parseInt(rowData.bs_finishedbuild.substring(6)) + 543)
                : null}
            </label>
          ),
          editComponent: (props) => (
            <div>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  success={this.state.bs_finishedbuild_State === "success"}
                  error={this.state.bs_finishedbuild_State === "error"}
                  clearable="true"
                  label={
                    props.rowData.bs_finishedbuild ? null : (
                      <label style={{ color: "red" }}>
                        {this.state.date_warning_msg}
                      </label>
                    )
                  }
                  format="DD/MM/YYYY"
                  pickerHeaderFormat="ddd D MMM"
                  yearOffset={543}
                  variant="inline"
                  onChange={(event) => this.setFinishedBuild(event, props)}
                  value={
                    moment(
                      props.rowData.bs_finishedbuild,
                      "DD/MM/YYYY"
                    ).toISOString() || this.state.buildinfo_finish
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          ),
        },
        {
          title: "สถานะการก่อสร้าง",
          field: "bs_notbuild",
          initialEditValue: "",
          emptyValue: "",
          type: "string",
          render: (rowData) =>
            this.state.build_status[parseInt(rowData.bs_notbuild)],
          editComponent: (props) => (
            <FormControl
              fullWidth
              className={this.props.classes.selectFormControl}
              success={this.state.bs_notbuild_State === "success"}
              error={this.state.bs_notbuild_State === "error"}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.bs_notbuild_State === ""
                      ? "#AAAAAA"
                      : this.state.bs_notbuild_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                โปรดเลือก
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "16px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                value={props.value ? props.value : ""}
                onChange={(event) =>
                  this.setBuildType(event.target.value, props)
                }
              >
                <MenuItem value="0">ยังไม่ก่อสร้าง</MenuItem>
                <MenuItem value="1">สร้างเสร็จ</MenuItem>
                <MenuItem value="2">อยู่ระหว่างสร้าง</MenuItem>
              </Select>
            </FormControl>
          ),
        },
      ],
      data: [],
      index: -1,
    };
  }

  isResubmiting = () => {
    return this.state.projectStatus === 2 || this.state.projectStatus === "2";
  };
  isEditable = () => {
    return !(
      this.state.view_psurvey === true ||
      this.state.projectStatus === 1 ||
      this.state.projectStatus === 3 ||
      this.state.memberStatus === 2
    );
  };

  setStartBuild = (event, props) => {
    try {
      let projectid = this.props.project_id;
      let val = moment(event);
      let finishedbuild = props.rowData.bs_finishedbuild
        ? moment(props.rowData.bs_finishedbuild, "DD/MM/YYYY")
        : null;
      let bs_notbuild = props.rowData.bs_notbuild;
      const year = parseInt(projectid.substring(0, 2)) + 2500 - 543;
      if (finishedbuild && bs_notbuild) {
        if (val > finishedbuild) {
          this.setState({ bs_startbuild_State: "error" });
          this.setState({ bs_finishedbuild_State: "error" });
          this.setState({ bs_notbuild_State: "error" });
        } else {
          if (projectid.substring(2, 3) === "Q") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 31/3
              // console.log("Q1");
              if (val > moment(`31/03/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (
                finishedbuild > moment(`31/03/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (
                finishedbuild < moment(`01/04/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/4 - 30/6
              // console.log("Q2");
              if (val > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "3") {
              // 1/7 - 30/9
              // console.log("Q3");
              if (val > moment(`30/09/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (
                finishedbuild > moment(`30/09/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (
                finishedbuild < moment(`01/10/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "4") {
              // 1/10 - 31/12
              // console.log("Q4");
              if (val > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            }
          } else if (projectid.substring(2, 3) === "H") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 30/6
              // console.log("H1");
              if (val > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/7 - 31/12
              // console.log("H2");
              if (val > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            }
          }
          this.setState({ bs_startbuild_State: "success" });
          this.setState({ bs_finishedbuild_State: "success" });
          this.setState({ bs_notbuild_State: "success" });
        }
      } else {
        this.setState({ bs_startbuild_State: "error" });
        this.setState({ bs_finishedbuild_State: "error" });
        this.setState({ bs_notbuild_State: "error" });
      }
      // console.log("test", bs_notbuild);
      props.onRowDataChange({
        ...props.rowData,
        bs_startbuild: moment(new Date(event)).format("DD/MM/YYYY"),
        bs_notbuild: bs_notbuild,
      });
    } catch (err) {
      // console.log(err);
    }
  };

  setFinishedBuild = (event, props) => {
    try {
      let projectid = this.props.project_id;
      let val = moment(event);
      let startbuild = props.rowData.bs_startbuild
        ? moment(props.rowData.bs_startbuild, "DD/MM/YYYY")
        : null;
      let bs_notbuild = props.rowData.bs_notbuild;
      const year = parseInt(projectid.substring(0, 2)) + 2500 - 543;
      if (startbuild && bs_notbuild) {
        if (startbuild > val) {
          this.setState({ bs_startbuild_State: "error" });
          this.setState({ bs_finishedbuild_State: "error" });
          this.setState({ bs_notbuild_State: "error" });
        } else {
          if (projectid.substring(2, 3) === "Q") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 31/3
              // console.log("Q1");
              if (startbuild > moment(`31/03/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (val > moment(`31/03/${year}`, "DD/MM/YYYY")) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (val < moment(`01/04/${year}`, "DD/MM/YYYY")) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/4 - 30/6
              // console.log("Q2");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (val > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (val < moment(`01/07/${year}`, "DD/MM/YYYY")) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "3") {
              // 1/7 - 30/9
              // console.log("Q3");
              if (startbuild > moment(`30/09/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (val > moment(`30/09/${year}`, "DD/MM/YYYY")) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (val < moment(`01/10/${year}`, "DD/MM/YYYY")) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "4") {
              // 1/10 - 31/12
              // console.log("Q4");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (val > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (val < moment(`01/01/${year + 1}`, "DD/MM/YYYY")) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            }
          } else if (projectid.substring(2, 3) === "H") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 30/6
              // console.log("H1");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (val > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (val < moment(`01/07/${year}`, "DD/MM/YYYY")) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/7 - 31/12
              // console.log("H2");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                bs_notbuild = "0";
              } else if (val > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("อยู่ระหว่างสร้าง");
                bs_notbuild = "2";
              } else if (val < moment(`01/01/${year + 1}`, "DD/MM/YYYY")) {
                // console.log("สร้างเสร็จ");
                bs_notbuild = "1";
              }
            }
          }
          this.setState({ bs_startbuild_State: "success" });
          this.setState({ bs_finishedbuild_State: "success" });
          this.setState({ bs_notbuild_State: "success" });
        }
      } else {
        this.setState({ bs_startbuild_State: "error" });
        this.setState({ bs_finishedbuild_State: "error" });
        this.setState({ bs_notbuild_State: "error" });
      }
      // console.log("test", bs_notbuild);
      props.onRowDataChange({
        ...props.rowData,
        bs_finishedbuild: moment(new Date(event)).format("DD/MM/YYYY"),
        bs_notbuild: bs_notbuild,
      });
      // props.bs_startbuild;
    } catch (err) {
      // console.log(err);
    }
  };

  setBuildType = (event, props) => {
    try {
      let projectid = this.props.project_id;
      let startbuild = props.rowData.bs_startbuild
        ? moment(props.rowData.bs_startbuild, "DD/MM/YYYY")
        : null;
      let finishedbuild = props.rowData.bs_finishedbuild
        ? moment(props.rowData.bs_finishedbuild, "DD/MM/YYYY")
        : null;
      // let val = event;
      const year = parseInt(projectid.substring(0, 2)) + 2500 - 543;
      if (startbuild && finishedbuild) {
        if (startbuild > finishedbuild) {
          this.setState({ bs_startbuild_State: "error" });
          this.setState({ bs_finishedbuild_State: "error" });
          this.setState({ bs_notbuild_State: "error" });
        } else {
          if (projectid.substring(2, 3) === "Q") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 31/3
              // console.log("Q1");
              if (startbuild > moment(`31/03/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (event === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`31/03/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (event === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/04/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (event === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/4 - 30/6
              // console.log("Q2");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (event === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (event === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (event === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "3") {
              // 1/7 - 30/9
              // console.log("Q3");
              if (startbuild > moment(`30/09/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (event === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`30/09/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (event === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/10/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (event === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "4") {
              // 1/10 - 31/12
              // console.log("Q4");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (event === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (event === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (event === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            }
          } else if (projectid.substring(2, 3) === "H") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 30/6
              // console.log("H1");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (event === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (event === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (event === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/7 - 31/12
              // console.log("H2");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (event === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (event === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (event === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            }
          }
        }
      } else {
        this.setState({ bs_startbuild_State: "error" });
        this.setState({ bs_finishedbuild_State: "error" });
        this.setState({ bs_notbuild_State: "error" });
      }
      // console.log("test", val);
      props.onRowDataChange({
        ...props.rowData,
        bs_notbuild: event,
      });
      // props.bs_startbuild;
    } catch (err) {
      // console.log(err);
    }
  };

  getInitialState = () => {
    const initialState = {
      /* state props */
    };
    return initialState;
  };
  resetState = () => {
    this.setState(this.getInitialState());
  };

  static propTypes = {
    condo_sec2Object: PropTypes.object.isRequired,
  };
  sendState() {
    return this.state;
  }

  componentDidMount() {
    this.getSecretKey();

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
    } else if (
      !(
        this.props.proj_dataTable.bs.length === 1 &&
        "bs_name" in this.props.proj_dataTable.bs[0] &&
        (this.props.proj_dataTable.bs[0].bs_name === "" ||
          this.props.proj_dataTable.bs[0].bs_name === "N_A")
      )
    ) {
      // console.log("merge start");
      this.get_projectData(this.props.proj_dataTable);
      // this.show_label(this.props.proj_dataTable);
    } else {
      // this.show_label(this.props.proj_dataTable);
    }
  }

  getProjectStatus() {
    if (this.state.project_status === 1) {
      return "โครงการปิดการขาย";
    } else if (this.state.project_status === 2) {
      return "อยู่ระหว่างขาย";
    } else if (this.state.project_status === 3) {
      return "เปิดขายใหม่ในช่วงสำรวจ";
    } else if (this.state.project_status === 4) {
      return "โครงการอนาคต";
    } else if (this.state.project_status === 5) {
      return "โครงการชะลอการขาย";
    } else if (this.state.project_status === 6) {
      return "โครงการเปิดและปิดการขายในช่วงสำรวจ";
    }
  }

  deleteSection() {
    let projectid =
      this.props.condo_initial_data.condo_projectid === "N_A"
        ? this.props.proj_dataTable
          ? this.props.proj_dataTable.data[0].condo_projectid
          : this.props.condoSec1Object.condo_projectid
        : this.props.condo_initial_data.condo_projectid;
    let refcode =
      this.props.condo_initial_data.condo_refcode === undefined
        ? this.props.proj_dataTable
          ? this.props.proj_dataTable.data[0].condo_refcode
          : this.props.condoSec1Object.condo_refcode
        : this.props.condo_initial_data.condo_refcode;
    var param = {
      projectid: projectid,
      refcode: refcode,
      event: "Delete condo section 2, 3 and 4",
    };
    var request = new Request(config.API_sendevent, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(param),
    });
    fetch(request)
      .then((response) => {
        response.json().then((data) => {
          if (data.code === 200) {
            // console.log('Delete Success')
            // this.setState({
            //   deleteAlert: (
            //     <SweetAlert
            //       success="true"
            //       style={{ display: "block", marginTop: "-100px" }}
            //       title="ส่วนที่ 3 และ 4 ถูกลบเรียบร้อย"
            //       showConfirm={false}
            //     ></SweetAlert>
            //   ),
            // });
          } else {
            this.setState({
              deleteAlert: (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="ทำรายการล้มเหลว กรุณาทำรายการใหม่"
                  showConfirm={false}
                ></SweetAlert>
              ),
            });
          }
          setTimeout(() => {
            this.setState({ deleteAlert: null });
          }, 1500);
        });
      })
      .catch(function(err) {
        // console.log(err);
      });
  }

  // show_label = (value) => {
  //   const edit_data = value.data[0];
  //   this.setState({
  //     refcode: edit_data.condo_refcode,
  //     project_nameth: edit_data.condo_nameth,
  //     project_nameen: edit_data.condo_nameeng,
  //     project_status: edit_data.condo_projectstatus,
  //   });

  //   this.call_merge_sec2();
  // };

  get_projectData = (value) => {
    // console.log("value", value);
    const status = value.status;
    const edit_data = value.data[0];
    this.setState({
      projectStatus: status,
      view_psurvey: value.view_psurvey,
    });
    let tmp = value.bs;

    this.setState({
      no_of_building: tmp.length,
      refcode: edit_data.household_refcode,
      project_nameth: edit_data.household_nameth,
      project_nameen: edit_data.household_nameeng,
      project_status: edit_data.household_projectstatus,
    });

    // for (let i = 0; i < tmp.length; i++) {
    //   tmp[i]["bs_startbuild"] = tmp[i].bs_startbuild;
    //   tmp[i]["bs_finishedbuild"] = tmp[i].bs_finishedbuild;
    //   switch (tmp[i].bs_notbuild) {
    //     case "0":
    //       tmp[i]["buildDate"] = "01/01/1900";
    //       tmp[i]["bs_startbuild"] = "01/01/1900";
    //       tmp[i]["bs_finishedbuild"] = "01/01/1900";
    //       break;
    //     case "1":
    //       tmp[i]["buildDate"] = tmp[i].bs_finishedbuild;
    //       tmp[i]["bs_startbuild"] = "01/01/1900";
    //       // tmp[i]["bs_finishedbuild"] = tmp[i].bs_finishedbuild;
    //       // tmp[i]["bs_finishedbuild"] = tmp[i].bs_finishedbuild;
    //       break;
    //     case "2":
    //       // tmp[i]["buildDate"] = moment(tmp[i].bs_startbuild).format(
    //       //   "DD/MM/YYYY"
    //       // );
    //       tmp[i]["buildDate"] = tmp[i].bs_startbuild;
    //       // tmp[i]["bs_startbuild"] = tmp[i].bs_startbuild;
    //       // tmp[i]["bs_startbuild"] = tmp[i].bs_startbuild;
    //       tmp[i]["bs_finishedbuild"] = "01/01/1900";
    //       break;
    //     default:
    //       break;
    //   }
    // }
    // console.log(tmp)
    this.setState(
      {
        cat_detail: value.bs,
      },
      () => {
        this.merge_data();
      }
    );
    this.sumallUnit(value.bs);
    this.props.setBuildingAndUnit_sec2(value.bs);
  };

  sumallUnit(unit) {
    const arr = unit;
    let sum_unit = 0;
    for (let i = 0; i < arr.length; i++) {
      sum_unit = parseInt(sum_unit) + parseInt(arr[i].bs_totalunit);
    }

    this.setState({
      total_unit: sum_unit,
    });
  }

  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  merge_data() {
    // var self = this;
    let cat_detail_obj = {};
    // console.log("cat_detail", this.state.cat_detail);
    if (this.state.cat_detail.length > 0) {
      this.state.cat_detail.map(function(value) {
        return Object.keys(value).forEach(function(i) {
          if (i !== "tableData") {
            if (!cat_detail_obj[i]) {
              cat_detail_obj[i] = [];
            }
            if (i === "bs_startbuild" || i === "bs_finishedbuild") {
              cat_detail_obj[i].push(value[i]);
            } else {
              cat_detail_obj[i].push(value[i]);
            }
          }
        });
      });
    }
    // console.log("cat_detail_obj", cat_detail_obj);
    let condodata_section2 = {
      // สถานะการก่อสร้าง
      condo_presaleamoutbuilding:
        cat_detail_obj && "bs_name" in cat_detail_obj
          ? cat_detail_obj["bs_name"].length
          : 0,
      bs_notbuild:
        cat_detail_obj && "bs_notbuild" in cat_detail_obj
          ? cat_detail_obj["bs_notbuild"]
          : [],
      // จำนวนชั้น
      bs_totalfloor:
        cat_detail_obj && "bs_totalfloor" in cat_detail_obj
          ? cat_detail_obj["bs_totalfloor"]
          : [],
      // จำนวนหน่วย
      bs_totalunit:
        cat_detail_obj && "bs_totalunit" in cat_detail_obj
          ? cat_detail_obj["bs_totalunit"]
          : [],
      // ชื่อตึก
      bs_name:
        cat_detail_obj && "bs_name" in cat_detail_obj
          ? cat_detail_obj["bs_name"]
          : [],
      // วันที่เริ่มสร้าง --> "01/01/1900" == ไม่มีข้อมูล, วันที่อื่นๆ == get ค่าตามปกติ
      bs_startbuild:
        cat_detail_obj && "bs_startbuild" in cat_detail_obj
          ? cat_detail_obj["bs_startbuild"]
          : [],
      // วันที่สร้างเสร็จ --> "01/01/1900" == ไม่มีข้อมูล, วันที่อื่นๆ == get ค่าตามปกติ
      bs_finishedbuild:
        cat_detail_obj && "bs_finishedbuild" in cat_detail_obj
          ? cat_detail_obj["bs_finishedbuild"]
          : [],
    };
    // console.log("condodata_section2", condodata_section2);
    // console.log("merge done");
    this.props.condoSec2_Object(condodata_section2);

    return condodata_section2;
  }

  async call_merge_sec2() {
    // console.log("call merge");
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.merge_data());
      }, 1000);
    });
  }

  async isValidated() {
    this.props.imageuploading(true);
    const body = await this.call_merge_sec2();
    if (
      body.bs_name.length === 0 ||
      body.bs_notbuild.length === 0 ||
      body.bs_totalfloor.length === 0 ||
      body.bs_totalunit.length === 0
    ) {
      this.surveyModal("notallow");
      this.props.imageuploading(false);
      return false;
    } else {
      this.props.imageuploading(false);
      return true;
    }
  }

  surveyModal(validity) {
    // console.log("validity", validity);
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "save" ? "ไม่สามารถบันทึกเอกสารได้" : "ไม่สามารถไปต่อได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "save"
          ? "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
          : "กรุณากรอกข้อมูล ส่วนที่ 1 และ 2 ทั้งหมดให้ครบถ้วนก่อนไปส่วนถัดไป"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  async submit_save() {
    this.setState({
      btLoading: true,
    });
    const token = this.state.token;
    const part1 = this.props.condoSec1Object;
    const body = await this.call_merge_sec2();
    // console.log("body", body);
    if (
      body.bs_name.length === 0 ||
      body.bs_startbuild.length === 0 ||
      body.bs_finishedbuild.length === 0 ||
      body.bs_totalfloor.length === 0 ||
      body.bs_totalunit.length === 0 ||
      body.bs_notbuild.length === 0
    ) {
      this.surveyModal("save");
      this.setState({
        btLoading: false,
      });
    } else {
      for (let index = 0; index < body.bs_name.length; index++) {
        let bs_name = body.bs_name[index];
        let bs_totalfloor = body.bs_totalfloor[index];
        let bs_totalunit = body.bs_totalunit[index];
        let bs_startbuild = body.bs_startbuild[index];
        let bs_finishedbuild = body.bs_finishedbuild[index];
        let bs_notbuild = body.bs_notbuild[index]
          ? String(body.bs_notbuild[index])
          : null;
        let checkData = this.checkValidDataInRowBeforeSubmit(
          index,
          bs_name,
          bs_totalfloor,
          bs_totalunit,
          bs_startbuild,
          bs_finishedbuild,
          bs_notbuild
        );
        if (checkData.some((value) => value === false)) {
          this.surveyModal("save");
          this.setState({
            btLoading: false,
          });
          return null;
        }
      }
      console.log("body.bs_name.length", body.bs_name.length);
      const target = Object.assign({}, this.props.condo_initial_data);

      // console.log(this.props.condo_initial_data);
      const source = body;
      // console.log("part1", part1);
      // console.log("source", source);
      // console.log(source);
      // console.log(part3);
      const log_condo_sec_2 = { event: "Insert or update condo section 2" };
      const mergebody = Object.assign(
        target,
        source,
        part1,
        this.props.condosec3object &&
          this.props.condosec3object instanceof Object &&
          Object.values(this.props.condosec3object).length > 0 &&
          Object.values(this.props.condosec3object)[0].length > 0
          ? this.props.condosec3object
          : {},
        this.props.condosec4object &&
          this.props.condosec4object instanceof Object &&
          Object.values(this.props.condosec4object).length > 0 &&
          Object.values(this.props.condosec4object)[0].length > 0
          ? this.props.condosec4object
          : {},
        log_condo_sec_2
      );
      // console.log(mergebody);
      var request = new Request(config.API_save_condo, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: token,
        }),
        body: JSON.stringify(mergebody),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson)
          this.setState({
            btLoading: false,
          });
          if (responseJson.code === 200) {
            this.alertModal("success");
          } else {
            this.errorModal("400");
          }
        })
        .catch((err) => {
          this.errorModal("400");
          this.setState({
            btLoading: false,
          });
        });
    }
  }

  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success" ? "บันทึกสำเร็จ" : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  errorModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "400" ? "warning" : "danger"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={validity === "400" ? "บันทึกไม่สำเร็จ" : "การเชื่อมต่อมีปัญหา"}
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  successAlert(title, message, status) {
    let trashStyle = {
      paddingTop: "10px",
      color: "#e74c3c",
    };
    let messageStyle = {
      color: "#e74c3c",
      fontWeight: "bold",
      fontSize: "16px",
    };
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          custom
          className="SweetAlert"
          title={<div>{title}</div>}
          showCancel
          showConfirm
          confirmBtnCssClass="modalBtDelete"
          cancelBtnCssClass="modalBt modalBtExit"
          customIcon={
            <div style={trashStyle}>
              <i className="fa fa-trash fa-4x" />
            </div>
          }
          confirmBtnText={"ยืนยัน"}
          cancelBtnText={"ยกเลิก"}
        >
          {<span style={messageStyle}>{message}</span>}
        </SweetAlert>
      ),
    });
  }

  // my new function start here
  // -----------------------------------------------
  change(event, props, stateName) {
    switch (stateName) {
      case "bs_name":
        if (this.buildingName(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "bs_totalunit":
        if (this.cat_unitAll(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "bs_totalfloor":
        if (this.bs_totalfloorHome(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      default:
        break;
    }
  }

  bs_totalfloorHome = (event, props) => {
    // console.log("event", event);
    var phoneRex = /^[0-9.]+$/;
    if (phoneRex.test(event)) {
      props.onChange(event);
      if (event.startsWith(0)) {
        this.showNotification("warning", "จำนวนชั้นต้องมีค่าไม่เท่ากับ 0");
        this.setState({ bs_totalfloor_State: "error" });
        return false;
      } else if (event % 1 !== 0.5 && event % 1 !== 0) {
        this.showNotification(
          "warning",
          "จำนวนชั้นต้องเป็นจำนวนเต็ม หรือ ทศนิยม .5 เท่านั้น"
        );
        this.setState({ bs_totalfloor_State: "error" });
        return false;
      }
      this.setState({ bs_totalfloor_State: "success" });
      return true;
    }
    this.setState({ bs_totalfloor_State: "error" });
    props.onChange("");
    return false;
  };

  buildingName = (event, props) => {
    const val = event;
    const regex = /^[A-Za-zก-๙0-9-@_ ]*$/;
    if (regex.test(val)) {
      props.onChange(event);
      this.setState({ bs_name_State: "success" });
      return true;
    }
    props.onChange("");
    this.setState({ bs_name_State: "error" });
    return false;
  };

  cat_unitAll = (event, props) => {
    const val = event;
    var phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(event);
      this.setState({ bs_totalunit_State: "success" });
      return true;
    }
    props.onChange("");
    this.setState({ bs_totalunit_State: "error" });
    return false;
  };

  between(x, min, max) {
    return x >= min && x <= max;
  }

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  resetAll_State() {
    this.setState({
      bs_name_State: "",
      bs_totalfloor_State: "",
      bs_totalunit_State: "",
      bs_startbuild_State: "",
      bs_finishedbuild_State: "",
      bs_notbuild_State: "",
    });
  }

  checkValidDataInRowBeforeAdd = (data) => {
    if (!data.bs_name || data.bs_name === "") {
      this.setState({ bs_name_State: "error" });
    } else {
      this.setState({ bs_name_State: "success" });
    }
    if (
      !data.bs_totalfloor ||
      data.bs_totalfloor === "0" ||
      data.bs_totalfloor === ""
    ) {
      this.setState({ bs_totalfloor_State: "error" });
    } else {
      this.setState({
        bs_totalfloor_State: "success",
      });
    }
    if (
      !data.bs_totalunit ||
      data.bs_totalunit === "0" ||
      data.bs_totalunit === ""
    ) {
      this.setState({ bs_totalunit_State: "error" });
    } else {
      this.setState({
        bs_totalunit_State: "success",
      });
    }
    if (
      !data.bs_startbuild ||
      data.bs_startbuild === "" ||
      !data.bs_finishedbuild ||
      data.bs_finishedbuild === "" ||
      !data.bs_notbuild ||
      data.bs_notbuild === ""
    ) {
      this.setState({ bs_startbuild_State: "error" });
      this.setState({ bs_finishedbuild_State: "error" });
      this.setState({ bs_notbuild_State: "error" });
    } else {
      let projectid = this.props.project_id;
      let startbuild = data.bs_startbuild
        ? moment(data.bs_startbuild, "DD/MM/YYYY")
        : null;
      let finishedbuild = data.bs_finishedbuild
        ? moment(data.bs_finishedbuild, "DD/MM/YYYY")
        : null;
      let bs_notbuild = String(data.bs_notbuild);
      // console.log("Data", projectid, startbuild, finishedbuild, bs_notbuild);
      const year = parseInt(projectid.substring(0, 2)) + 2500 - 543;
      if (startbuild && finishedbuild) {
        if (startbuild > finishedbuild) {
          this.setState({ bs_startbuild_State: "error" });
          this.setState({ bs_finishedbuild_State: "error" });
          this.setState({ bs_notbuild_State: "error" });
        } else {
          if (projectid.substring(2, 3) === "Q") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 31/3
              // console.log("Q1");
              if (startbuild > moment(`31/03/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`31/03/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/04/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/4 - 30/6
              // console.log("Q2");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "3") {
              // 1/7 - 30/9
              // console.log("Q3");
              if (startbuild > moment(`30/09/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`30/09/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/10/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "4") {
              // 1/10 - 31/12
              // console.log("Q4");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            }
          } else if (projectid.substring(2, 3) === "H") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 30/6
              // console.log("H1");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/7 - 31/12
              // console.log("H2");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  this.setState({ bs_startbuild_State: "success" });
                  this.setState({ bs_finishedbuild_State: "success" });
                  this.setState({ bs_notbuild_State: "success" });
                } else {
                  this.setState({ bs_startbuild_State: "error" });
                  this.setState({ bs_finishedbuild_State: "error" });
                  this.setState({ bs_notbuild_State: "error" });
                }
              }
            }
          }
        }
      } else {
        this.setState({ bs_startbuild_State: "error" });
        this.setState({ bs_finishedbuild_State: "error" });
        this.setState({ bs_notbuild_State: "error" });
      }
    }
  };

  checkValidDataInRowBeforeSubmit = (
    index,
    bs_name,
    bs_totalfloor,
    bs_totalunit,
    bs_startbuild,
    bs_finishedbuild,
    bs_notbuild
  ) => {
    let check = [];
    // console.log(
    //   "Check",
    //   index,
    //   bs_name,
    //   bs_totalfloor,
    //   bs_totalunit,
    //   bs_startbuild,
    //   bs_finishedbuild,
    //   bs_notbuild
    // );
    if (!bs_name || bs_name === "") {
      check.push(false);
    } else {
      check.push(true);
    }
    if (!bs_totalfloor || bs_totalfloor === "0" || bs_totalfloor === "") {
      check.push(false);
    } else {
      check.push(true);
    }
    if (!bs_totalunit || bs_totalunit === "0" || bs_totalunit === "") {
      check.push(false);
    } else {
      check.push(true);
    }
    if (
      !bs_startbuild ||
      bs_startbuild === "" ||
      !bs_finishedbuild ||
      bs_finishedbuild === "" ||
      !bs_notbuild ||
      bs_notbuild === ""
    ) {
      check.push(false, false, false);
    } else {
      let projectid = this.props.project_id;
      let startbuild = bs_startbuild
        ? moment(bs_startbuild, "DD/MM/YYYY")
        : null;
      let finishedbuild = bs_finishedbuild
        ? moment(bs_finishedbuild, "DD/MM/YYYY")
        : null;
      // console.log("Data", projectid, startbuild, finishedbuild, bs_notbuild);
      const year = parseInt(projectid.substring(0, 2)) + 2500 - 543;
      if (startbuild && finishedbuild) {
        if (startbuild > finishedbuild) {
          check.push(false, false, false);
        } else {
          if (projectid.substring(2, 3) === "Q") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 31/3
              // console.log("Q1");
              if (startbuild > moment(`31/03/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild > moment(`31/03/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild < moment(`01/04/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/4 - 30/6
              // console.log("Q2");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              }
            } else if (projectid.substring(3, 4) === "3") {
              // 1/7 - 30/9
              // console.log("Q3");
              if (startbuild > moment(`30/09/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild > moment(`30/09/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild < moment(`01/10/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              }
            } else if (projectid.substring(3, 4) === "4") {
              // 1/10 - 31/12
              // console.log("Q4");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              }
            }
          } else if (projectid.substring(2, 3) === "H") {
            if (projectid.substring(3, 4) === "1") {
              // 1/1 - 30/6
              // console.log("H1");
              if (startbuild > moment(`30/06/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild > moment(`30/06/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild < moment(`01/07/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              }
            } else if (projectid.substring(3, 4) === "2") {
              // 1/7 - 31/12
              // console.log("H2");
              if (startbuild > moment(`31/12/${year}`, "DD/MM/YYYY")) {
                // console.log("ยังไม่สร้าง");
                if (bs_notbuild === "0") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild > moment(`31/12/${year}`, "DD/MM/YYYY")
              ) {
                // console.log("อยู่ระหว่างสร้าง");
                if (bs_notbuild === "2") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              } else if (
                finishedbuild < moment(`01/01/${year + 1}`, "DD/MM/YYYY")
              ) {
                // console.log("สร้างเสร็จ");
                if (bs_notbuild === "1") {
                  check.push(true, true, true);
                } else {
                  check.push(false, false, false);
                }
              }
            }
          }
        }
      } else {
        check.push(false, false, false);
      }
    }
    return check;
  };

  checkDataInRowBeforeAdd = (data) => {
    let {
      bs_name_State,
      bs_totalfloor_State,
      bs_totalunit_State,
      bs_startbuild_State,
      bs_finishedbuild_State,
    } = this.state;
    if (
      bs_name_State === "error" ||
      bs_totalfloor_State === "error" ||
      bs_totalunit_State === "error" ||
      bs_startbuild_State === "error" ||
      bs_finishedbuild_State === "error"
    ) {
      this.showNotification("warning", "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก");
      return false;
    } else {
      return true;
    }
  };

  processDataInRowChange = () => {};

  blockEdit(isEdit) {
    if (isEdit) {
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            zIndex: "10000",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            cursor: "not-allowed",
          }}
        ></div>
      );
    } else {
      //
    }
  }
  render() {
    var self = this;
    const bt_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "120px",
      height: "40px",
      fontSize: "16px",
      borderRadius: "0.2rem",
      backgroundColor: "#384D97",
      color: "white",
    };
    const StyleHeader = {
      border: "1px solid #dddddd",
      textAlign: "center",
      borderLeft: "none",
    };
    const StyleTHeader = {
      fontSize: "14px",
      width: "100%",
      borderCollapse: "collapse",
      whiteSpace: "nowrap",
      textAlign: "center",
    };
    return (
      <GridContainer style={{ padding: "15px" }}>
        <GridItem>
          <h4>จำนวนอาคารที่เปิดขาย และสถานะการก่อสร้าง</h4>
          <h4>
            มีทั้งหมด{" "}
            <label> {numeral(this.state.no_of_building).format("0,0")} </label>{" "}
            อาคาร ดังนี้
          </h4>
        </GridItem>
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {this.state.alert}
          {this.state.deleteAlert}
          <MaterialTable
            style={{
              width: "100%",
              backgroundColor: "#fff",
              textAlign: "center",
            }}
            icons={{
              Add: () => (
                <div style={bt_style}>
                  <Add style={{ marginRight: "10px" }} />
                  เพิ่มข้อมูล
                </div>
              ),
            }}
            components={{
              Header: (props) => (
                <thead style={StyleTHeader}>
                  <tr>
                    <td rowSpan="2" style={StyleHeader}>
                      ชื่ออาคาร
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      จำนวนชั้น
                    </td>
                    <td style={StyleHeader}>หน่วยทั้งหมด</td>
                    <td rowSpan="2" style={StyleHeader}>
                      วันที่เริ่มก่อสร้าง
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      วันที่คาดว่าจะสร้างเสร็จ
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      สถานะการก่อสร้าง ณ ปัจจุบัน
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      การจัดการข้อมูล
                    </td>
                  </tr>
                  <tr>
                    <td style={StyleHeader}>
                      รวม : {numeral(this.state.total_unit).format("0,0")} หน่วย
                    </td>
                  </tr>
                </thead>
              ),
            }}
            localization={{
              header: {
                actions: "การจัดการ",
              },
              body: {
                addTooltip: "เพิ่มข้อมูล",
                editTooltip: "แก้ไขข้อมูล",
                deleteTooltip: "ลบข้อมูล",
                emptyDataSourceMessage: "ไม่มีข้อมูล",
                editRow: {
                  deleteText: "ยืนยันการลบข้อมูล",
                  saveTooltip: "บันทึก",
                  cancelTooltip: "ยกเลิก",
                },
              },
              toolbar: {
                searchTooltip: "ค้นหา",
                searchPlaceholder: "ค้นหา",
              },
              pagination: {
                labelRowsSelect: "แถว",
                labelDisplayedRows: " {from}-{to} จาก {count}",
                firstTooltip: "หน้าแรก",
                previousTooltip: "ก่อนหน้า",
                nextTooltip: "ถัดไป",
                lastTooltip: "สุดท้าย",
              },
            }}
            title=""
            columns={this.state.column}
            data={this.state.cat_detail}
            options={{
              pageSize: this.state.cat_detail
                ? this.state.cat_detail.length
                : 10,
              pageSizeOptions: [
                10,
                20,
                30,
                this.state.cat_detail ? this.state.cat_detail.length : 50,
              ],
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0
                    ? "rgba(0, 0, 0, 0.03)"
                    : "#FFF",
              }),
              headerStyle: {
                fontSize: "0.9rem",
                fontWeight: "600",
              },
            }}
            editable={
              this.isEditable()
                ? {
                    onRowAdd: (newData) => {
                      self.setState({
                        buildinfo_construct: null,
                        buildinfo_finish: null,
                      });
                      this.checkValidDataInRowBeforeAdd(newData);
                      // console.log(newData)
                      return new Promise((resolve, reject) => {
                        this.successAlert(
                          "ยืนยันการเพิ่มข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 3 และ 4 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({
                                alert: null,
                              });
                              if (
                                this.state.cat_detail.filter(
                                  (row) =>
                                    row.bs_name + "" === newData.bs_name + ""
                                ).length > 0
                              ) {
                                this.setState({ bs_name_State: "error" });
                                this.showNotification(
                                  "warning",
                                  "ชื่ออาคารนี้มีในตารางแล้ว"
                                );
                                return reject();
                              }
                              // console.log("newData", newData);
                              if (this.checkDataInRowBeforeAdd(newData)) {
                                // console.log("In Add");
                                setTimeout(() => {
                                  {
                                    const cat_detail = this.state.cat_detail;
                                    newData.id = cat_detail.length;
                                    cat_detail.push(newData);
                                    this.setState({ cat_detail }, () => {
                                      // console.log(cat_detail)
                                      this.props.setBuildingAndUnit_sec2(
                                        cat_detail
                                      );
                                      this.merge_data();
                                      resolve();
                                    });
                                    // calculate total unit
                                    let totalunit = 0;
                                    for (
                                      var i = 0;
                                      i < cat_detail.length;
                                      i++
                                    ) {
                                      totalunit += parseInt(
                                        cat_detail[i].bs_totalunit
                                      );
                                    }
                                    // set number of building
                                    let no_of_building = cat_detail.length;
                                    this.setState({
                                      no_of_building: no_of_building,
                                      total_unit: parseInt(totalunit),
                                    });
                                    this.resetAll_State();
                                  }
                                  resolve();
                                  this.props.condo_sec3Object([]);
                                  this.props.condoSec3_Object([]);
                                  this.props.condo_sec4Object([]);
                                }, 1000);
                              } else {
                                // console.log("Add", "Atrium");
                                this.showNotification(
                                  "warning",
                                  "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                                );
                                reject();
                                // console.log("reject");
                                // this.checkDataInRowBeforeAdd(newData);
                              }
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      });
                    },
                    onRowUpdate: (newData, oldData) => {
                      // console.log("newData 2", newData);
                      // console.log("oldData 2", oldData);
                      this.checkValidDataInRowBeforeAdd(newData);
                      return new Promise((resolve, reject) => {
                        this.successAlert(
                          "ยืนยันการแก้ไขข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 3 และ 4 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              if (
                                this.state.cat_detail.filter(
                                  (row, index) =>
                                    row.bs_name + "" === newData.bs_name + "" &&
                                    index !==
                                      this.state.cat_detail.indexOf(oldData)
                                ).length > 0
                              ) {
                                this.setState({ bs_name_State: "error" });
                                this.showNotification(
                                  "warning",
                                  "ชื่ออาคารนี้มีในตารางแล้ว"
                                );
                                return reject();
                              }
                              // recheck date build
                              if (this.checkDataInRowBeforeAdd(newData)) {
                                // console.log(newData);
                                setTimeout(() => {
                                  {
                                    const cat_detail = this.state.cat_detail;
                                    const index = cat_detail.indexOf(oldData);
                                    cat_detail[index] = newData;
                                    this.setState({ cat_detail }, () => {
                                      this.props.setBuildingAndUnit_sec2(
                                        cat_detail
                                      );
                                      this.merge_data();
                                      resolve();
                                    });
                                    //calculate total unit
                                    let totalunit = 0;
                                    for (
                                      var i = 0;
                                      i < cat_detail.length;
                                      i++
                                    ) {
                                      totalunit += parseInt(
                                        cat_detail[i].bs_totalunit
                                      );
                                    }
                                    //set number of building
                                    let no_of_building = cat_detail.length;
                                    this.setState({
                                      no_of_building: no_of_building,
                                      total_unit: parseInt(totalunit),
                                    });
                                    this.resetAll_State();
                                  }
                                  resolve();
                                  this.props.condo_sec3Object([]);
                                  this.props.condoSec3_Object([]);
                                  this.props.condo_sec4Object([]);
                                  this.deleteSection();
                                }, 1000);
                              } else {
                                // console.log("Update", "Utopia");
                                this.showNotification(
                                  "warning",
                                  "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                                );
                                reject();
                                this.checkDataInRowBeforeAdd(newData);
                              }
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      });
                    },
                    onRowDelete: (oldData) => {
                      self.setState({
                        buildinfo_construct: null,
                        buildinfo_finish: null,
                      });
                      return new Promise((resolve, reject) => {
                        this.successAlert(
                          "ยืนยันการแก้ไขข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 3 และ 4 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              setTimeout(() => {
                                {
                                  let cat_detail = this.state.cat_detail;
                                  const index = cat_detail.indexOf(oldData);
                                  cat_detail.splice(index, 1);
                                  this.setState({ cat_detail }, () => {
                                    this.props.setBuildingAndUnit_sec2(
                                      cat_detail
                                    );
                                    this.merge_data();

                                    resolve();
                                  });
                                  //calculate total unit
                                  let totalunit = 0;
                                  for (var i = 0; i < cat_detail.length; i++) {
                                    totalunit += parseInt(
                                      cat_detail[i].bs_totalunit
                                    );
                                  }
                                  //set number of building
                                  let no_of_building = cat_detail.length;
                                  this.setState({
                                    no_of_building: no_of_building,
                                    total_unit: parseInt(totalunit),
                                  });

                                  this.resetAll_State();
                                }
                                resolve();
                                this.props.condo_sec3Object([]);
                                this.props.condoSec3_Object([]);
                                this.props.condo_sec4Object([]);
                              }, 1000);
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      });
                    },
                  }
                : {}
            }
          />
        </GridItem>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "0 15px",
            marginTop: "10px",
          }}
        >
          {(this.state.projectStatus !== 3 ||
            this.state.view_psurvey === false) &&
          this.isEditable() &&
          !this.isResubmiting() ? (
            <Button
              color="info"
              size="sm"
              style={{ fontSize: "16px" }}
              onClick={() => this.submit_save()}
              disabled={this.state.btLoading}
            >
              {this.loadingButton(this.state.btLoading)}
              บันทึก
            </Button>
          ) : null}
        </div>
      </GridContainer>
    );
  }
}

CondoStep2.propTypes = {
  classes: PropTypes.object,
  condoSec2_Object: PropTypes.any,
  BuildingAndUnit_sec2: PropTypes.any,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  surveyInfo: (payload) => dispatch(surveyInfo(payload)),
  condo_sec2Object: (payload) => dispatch(condo_sec2Object(payload)),
  setBuildingAndUnit_sec2: (payload) =>
    dispatch(setBuildingAndUnit_sec2(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
  condoSec2_Object: (payload) => dispatch(condoSec2_Object(payload)),
  imageuploading: (bool) => dispatch(imageuploading(bool)),
  condo_sec3Object: (payload) => dispatch(condo_sec3Object(payload)),
  condo_sec4Object: (payload) => dispatch(condo_sec4Object(payload)),
  condoSec3_Object: (payload) => dispatch(condoSec3_Object(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(CondoStep2));
