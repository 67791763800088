/* eslint-disable no-useless-escape */
import React from "react";
import { ClipLoader } from "react-spinners";

import CustomInput from "components/CustomInput/CustomInputTooltips.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import * as config from "configure/configServer.js";
import surveyModal from "actions/survey/surveyModal.js";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "./VillaTable.css";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import CryptoJS from "crypto-js";
import MaterialTable from "material-table";
import Add from "@material-ui/icons/Add";

import numeral from "numeral";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { connect } from "react-redux";
import villa_sec3Object from "actions/survey/villa/villa_sec3Data.js";
import villaSec3_Object from "actions/survey/villa/villa_sec3Obj.js";
import PropTypes from "prop-types";
import surveyInfo from "actions/survey/surveyInfo.js";
const section3keys = [
  "stc_type",
  "stc_pricelenid",
  "stc_unit",
  "stc_sellsigned",
  "stc_remaining",
  "stc_newsigned",
  "stc_transfered",
  "stc_newtransfer",
  "stc_notconstruct",
  "stc_underconstruct",
  "stc_finished",
  "stc_newconstruct",
  "stc_newfinished",
  "stc_sellnotconstruct",
  "stc_sellunderconstruct",
  "stc_sellfinished",
  "stc_rmnotconstruct",
  "stc_rmunderconstruct",
  "stc_rmfinished",
];

class VillaStep3 extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,
      // priceupperlower
      price_limit: [],
      price_selected: [],
      price_allRange: [],
      // new state start here
      part2_cattype: [],
      part2_catfloor: [],
      part2_catprice: [],
      part2_allunit: [],
      part2_maxUnit: 0,
      // noti core
      noti: false,
      open: false,
      color: "info",
      message: "",
      place: "tc",

      token: "",
      btLoading: false,
      value: 0,
      language: "th",

      stc_detail: [],
      stc_pricerange: [],
      disabled_stcUnit: true,
      istype_land: false,
      stc_maxUnit: [],
      part1_projectStatus: "",
      // stc detail state change
      stc_type_State: "error",
      stc_floor_Data: "",
      stc_floor_State: "",
      stc_pricelenid_State: "",
      stc_unit_State: "",
      stc_sellsigned_State: "",
      stc_remaining_State: "",
      stc_newsigned_State: "",
      stc_transfered_State: "",
      stc_newtransfer_State: "",
      stc_notconstruct_State: "",
      stc_underconstruct_State: "",
      stc_finished_State: "",
      stc_newconstruct_State: "",
      stc_newfinished_State: "",
      stc_sellnotconstruct_State: "",
      stc_sellunderconstruct_State: "",
      stc_sellfinished_State: "",
      stc_rmnotconstruct_State: "",
      stc_rmunderconstruct_State: "",
      stc_rmfinished_State: "",
      // tooltip state value
      tooltip_stc_unit: "",
      tooltip_stc_sellsigned: "",
      tooltip_stc_remaining: "",
      tooltip_stc_newsigned: "",
      tooltip_stc_transfered: "",
      tooltip_stc_newtransfer: "",
      tooltip_stc_notconstruct: "",
      tooltip_stc_underconstruct: "",
      tooltip_stc_finished: "",
      tooltip_stc_newconstruct: "",
      tooltip_stc_newfinished: "",
      tooltip_stc_sellnotconstruct: "",
      tooltip_stc_sellunderconstruct: "",
      tooltip_stc_sellfinished: "",
      tooltip_stc_rmnotconstruct: "",
      tooltip_stc_rmunderconstruct: "",
      tooltip_stc_rmfinished: "",
      // start of column render
      stc_unit_placeholder: "เลือกประเภทก่อน",
      stc_sellsigned_placeholder: "",
      stc_remaining_placeholder: "",
      stc_newsigned_placeholder: "",
      stc_transfered_placeholder: "",
      stc_newtransfer_placeholder: "",
      stc_notconstruct_placeholder: "",
      stc_underconstruct_placeholder: "",
      stc_finished_placeholder: "",
      stc_newconstruct_placeholder: "",
      stc_newfinished_placeholder: "",
      stc_sellnotconstruct_placeholder: "",
      stc_sellunderconstruct_placeholder: "",
      stc_sellfinished_placeholder: "",
      stc_rmnotconstruct_placeholder: "",
      stc_rmunderconstruct_placeholder: "",
      stc_rmfinished_placeholder: "",
      // sum all
      Sum_stc_finished: 0,
      Sum_stc_newconstruct: 0,
      Sum_stc_newfinished: 0,
      Sum_stc_newsigned: 0,
      Sum_stc_newtransfer: 0,
      Sum_stc_notconstruct: 0,
      Sum_stc_remaining: 0,
      Sum_stc_rmfinished: 0,
      Sum_stc_rmnotconstruct: 0,
      Sum_stc_rmunderconstruct: 0,
      Sum_stc_sellsigned: 0,
      Sum_stc_sellfinished: 0,
      Sum_stc_sellnotconstruct: 0,
      Sum_stc_sellunderconstruct: 0,
      Sum_stc_transfered: 0,
      Sum_stc_underconstruct: 0,
      Sum_stc_unit: 0,
      // column
      column: [
        {
          title: "ประเภทบ้าน",
          field: "stc_type",
          width: 20,
          render: (rowData) => this.render_stctype(rowData.stc_type),
          editable: "onAdd",
          editComponent: (props) => (
            <FormControl
              className={this.props.classes.selectFormControl}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.stc_type_State === ""
                      ? "#AAAAAA"
                      : this.state.stc_type_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                {this.state.stc_type_State === ""
                  ? ""
                  : this.state.stc_type_State === "success"
                  ? "เลือกแล้ว"
                  : "โปรดเลือก"}
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "12px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                value={props.value ? this.chk_stctype(props.value) : ""}
                onChange={(event) => this.change(event, props, "stc_type")}
              >
                {this.state.part2_cattype.map((cat) => (
                  <MenuItem
                    classes={{ root: this.props.classes.selectMenuItem }}
                    key={cat.value}
                    value={cat.value}
                    style={{ backgroundColor: "#fff" }}
                  >
                    {cat.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          title: "ราคาขาย (บาท)",
          field: "stc_pricelenid",
          render: (rowData) => this.render_stcprice(rowData),
          editable: "onAdd",

          editComponent: (props) => (
            <FormControl
              className={this.props.classes.selectFormControl}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.stc_pricelenid_State === ""
                      ? "#AAAAAA"
                      : this.state.stc_pricelenid_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                {this.state.stc_pricelenid_State === ""
                  ? "เลือกประเภทก่อน"
                  : this.state.stc_pricelenid_State === "success"
                  ? "เลือกแล้ว"
                  : "โปรดเลือก"}
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "12px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                disabled={
                  this.state.stc_pricerange.length === 0
                    ? true
                    : this.state.stc_pricelenid_State === ""
                    ? true
                    : this.state.stc_pricelenid_State === "success"
                    ? false
                    : false
                }
                value={props.value ? props.value : ""}
                onChange={(event) =>
                  this.change(event, props, "stc_pricelenid")
                }
              >
                {this.state.stc_pricerange.length === 0
                  ? null
                  : this.checkDisabledRow(
                      this.state.stc_pricerange,
                      props.rowData
                    )}
              </Select>
            </FormControl>
          ),
        },
        {
          title: "หน่วยในผังโครงการ",
          field: "stc_unit",

          // initialEditValue: 0,
          emptyValue: 0,
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_unit_State === "error" ? "**" : undefined
              }
              success={this.state.stc_unit_State === "success"}
              error={this.state.stc_unit_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "stc_unit"),

                value: props.value ? props.value : "",
                disabled: this.state.disabled_stcUnit,
                placeholder: this.state.stc_unit_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit ? "" : this.state.tooltip_stc_unit
              }
            />
          ),
        },
        {
          title: "ทำสัญญาจะซื้อจะขายแล้ว",
          field: "stc_sellsigned",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_sellsigned_State === "error" ? "**" : undefined
              }
              success={this.state.stc_sellsigned_State === "success"}
              error={this.state.stc_sellsigned_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_sellsigned"),
                value: props.value ? props.value : "",
                disabled: this.state.disabled_stcUnit,
                placeholder: this.state.stc_sellsigned_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_sellsigned
              }
            />
          ),
        },
        {
          title: "เหลือขาย",
          field: "stc_remaining",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_remaining_State === "error" ? "**" : undefined
              }
              success={this.state.stc_remaining_State === "success"}
              error={this.state.stc_remaining_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "stc_remaining"),

                value: props.value ? props.value : "",
                disabled: this.state.disabled_stcUnit,
                placeholder: this.state.stc_remaining_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_remaining
              }
            />
          ),
        },
        {
          title: "ทำสัญญาจะซื้อจะขายใหม่",
          field: "stc_newsigned",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_newsigned_State === "error" ? "**" : undefined
              }
              success={this.state.stc_newsigned_State === "success"}
              error={this.state.stc_newsigned_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "stc_newsigned"),

                value: props.value ? props.value : "",
                disabled: this.state.disabled_stcUnit,
                placeholder: this.state.stc_newsigned_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_newsigned
              }
            />
          ),
        },
        {
          title: "โอนกรรมสิทธิ์แล้ว",
          field: "stc_transfered",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_transfered_State === "error" ? "**" : undefined
              }
              success={this.state.stc_transfered_State === "success"}
              error={this.state.stc_transfered_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_transfered"),

                value: props.value ? props.value : "",
                disabled: this.state.disabled_stcUnit,
                placeholder: this.state.stc_transfered_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_transfered
              }
            />
          ),
        },
        {
          title: "โอนกรรมสิทธิ์ใหม่",
          field: "stc_newtransfer",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_newtransfer_State === "error" ? "**" : undefined
              }
              success={this.state.stc_newtransfer_State === "success"}
              error={this.state.stc_newtransfer_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_newtransfer"),

                value: props.value ? props.value : "",
                disabled: this.state.disabled_stcUnit,
                placeholder: this.state.stc_newtransfer_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_newtransfer
              }
            />
          ),
        },
        {
          title: "ยังไม่ก่อสร้าง",
          field: "stc_notconstruct",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_notconstruct_State === "error" ? "**" : undefined
              }
              success={this.state.stc_notconstruct_State === "success"}
              error={this.state.stc_notconstruct_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_notconstruct"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_notconstruct_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_notconstruct
              }
            />
          ),
        },
        {
          title: "อยู่ระหว่างก่อสร้าง",
          field: "stc_underconstruct",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_underconstruct_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.stc_underconstruct_State === "success"}
              error={this.state.stc_underconstruct_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_underconstruct"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_underconstruct_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_underconstruct
              }
            />
          ),
        },
        {
          title: "สร้างเสร็จ",
          field: "stc_finished",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_finished_State === "error" ? "**" : undefined
              }
              success={this.state.stc_finished_State === "success"}
              error={this.state.stc_finished_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "stc_finished"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_finished_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_finished
              }
            />
          ),
        },
        {
          title: "เริ่มก่อสร้างใหม่",
          field: "stc_newconstruct",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_newconstruct_State === "error" ? "**" : undefined
              }
              success={this.state.stc_newconstruct_State === "success"}
              error={this.state.stc_newconstruct_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_newconstruct"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_newconstruct_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_newconstruct
              }
            />
          ),
        },
        {
          title: "ก่อสร้างเสร็จใหม่",
          field: "stc_newfinished",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_newfinished_State === "error" ? "**" : undefined
              }
              success={this.state.stc_newfinished_State === "success"}
              error={this.state.stc_newfinished_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_newfinished"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_newfinished_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_newfinished
              }
            />
          ),
        },
        {
          title: "ยังไม่ก่อสร้าง",
          field: "stc_sellnotconstruct",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_sellnotconstruct_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.stc_sellnotconstruct_State === "success"}
              error={this.state.stc_sellnotconstruct_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_sellnotconstruct"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_sellnotconstruct_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_sellnotconstruct
              }
            />
          ),
        },
        {
          title: "อยู่ระหว่างก่อสร้าง",
          field: "stc_sellunderconstruct",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_sellunderconstruct_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.stc_sellunderconstruct_State === "success"}
              error={this.state.stc_sellunderconstruct_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_sellunderconstruct"),
                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_sellunderconstruct_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_sellunderconstruct
              }
            />
          ),
        },
        {
          title: "สร้างเสร็จ",
          field: "stc_sellfinished",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_sellfinished_State === "error" ? "**" : undefined
              }
              success={this.state.stc_sellfinished_State === "success"}
              error={this.state.stc_sellfinished_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_sellfinished"),
                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_sellfinished_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_sellfinished
              }
            />
          ),
        },
        {
          title: "ยังไม่ก่อสร้าง",
          field: "stc_rmnotconstruct",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_rmnotconstruct_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.stc_rmnotconstruct_State === "success"}
              error={this.state.stc_rmnotconstruct_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_rmnotconstruct"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_rmnotconstruct_placeholder,
              }}
              tooltipText={
                this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_rmnotconstruct
              }
            />
          ),
        },
        {
          title: "อยู่ระหว่างก่อสร้าง",
          field: "stc_rmunderconstruct",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_rmunderconstruct_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.stc_rmunderconstruct_State === "success"}
              error={this.state.stc_rmunderconstruct_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_rmunderconstruct"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_rmunderconstruct_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_rmunderconstruct
              }
            />
          ),
        },
        {
          title: "สร้างเสร็จ",
          field: "stc_rmfinished",

          // initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.stc_rmfinished_State === "error" ? "**" : undefined
              }
              success={this.state.stc_rmfinished_State === "success"}
              error={this.state.stc_rmfinished_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "stc_rmfinished"),

                value: props.value ? props.value : "",
                disabled:
                  this.state.disabled_stcUnit ||
                  props.rowData.stc_type === "ที่ดินเปล่า"
                    ? true
                    : this.state.istype_land,
                placeholder: this.state.stc_rmfinished_placeholder,
              }}
              tooltipText={
                this.state.istype_land
                  ? ""
                  : this.state.disabled_stcUnit
                  ? ""
                  : this.state.tooltip_stc_rmfinished
              }
            />
          ),
        },
      ],
      data: [],
      index: -1,
    };
  }

  static propTypes = {
    villa_sec3Object: PropTypes.object.isRequired,
  };
  componentDidMount() {
    this.getSecretKey();
    this.stc_setWarning("");

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
    } else {
      this.get_projectData(this.props.proj_dataTable);
    }
  }

  getProjectStatus() {
    if (this.state.part1_projectStatus === 1) {
      return "โครงการปิดการขาย";
    } else if (this.state.part1_projectStatus === 2) {
      return "อยู่ระหว่างขาย";
    } else if (this.state.part1_projectStatus === 3) {
      return "เปิดขายใหม่ในช่วงสำรวจ";
    } else if (this.state.part1_projectStatus === 4) {
      return "โครงการอนาคต";
    } else if (this.state.part1_projectStatus === 5) {
      return "โครงการชะลอการขาย";
    } else if (this.state.part1_projectStatus === 6) {
      return "โครงการเปิดและปิดการขายในช่วงสำรวจ";
    }
  }

  get_projectData = (value) => {
    this.setState({
      projectStatus: value.status,
      view_psurvey: value.view_psurvey,
    });
    this.setState({
      stc_detail: value.stc,
      disabled_stcUnit: false,
      stc_type_State: "error",
    });
    this.sumallUnit(value.stc);
    this.call_merge_sec3();
  };

  setOnEdit = () => {
    let that = this;
    let arrayprice_selected = this.state.price_selected;
    let inputdata = this.state.stc_detail;
    let new_arr = arrayprice_selected;

    setTimeout(() => {
      inputdata.forEach(function(id) {
        new_arr.forEach(function(na) {
          if (id.stc_type === na.label) {
            na.price_id.push(id.stc_pricelenid);
          }
        });
      });
    }, 1000);
    that.setState({
      price_selected: new_arr,
    });
  };

  removeSelectedPrice = (olddata) => {
    let arrayprice_selected = this.state.price_selected;

    let new_arr = arrayprice_selected;

    for (let i = 0; i < arrayprice_selected.length; i++) {
      let all_priceid = arrayprice_selected[i].price_id;

      let removed_index = all_priceid.indexOf(olddata.stc_pricelenid);

      if (removed_index !== -1) {
        all_priceid.splice(removed_index, 1);
      }
    }
    this.setState({
      price_selected: new_arr,
    });
  };

  editSelectedPrice = (newdata, olddata) => {
    let arrayprice_selected = this.state.price_selected;

    let new_arr = arrayprice_selected;

    for (let i = 0; i < arrayprice_selected.length; i++) {
      let all_priceid = arrayprice_selected[i].price_id;

      let removed_index = all_priceid.indexOf(olddata.stc_pricelenid);
      let exist_index = all_priceid.indexOf(newdata.stc_pricelenid);

      if (olddata.stc_pricelenid !== newdata.stc_pricelenid) {
        if (removed_index !== -1) {
          all_priceid.splice(removed_index, 1);
        }

        if (exist_index === -1) {
          all_priceid.push(newdata.stc_pricelenid);
          break;
        }
      } else {
      }
    }
    this.setState({
      price_selected: new_arr,
    });
  };

  stc_setWarning(status) {
    this.setState({
      part1_projectStatus: status,
    });
    switch (status) {
      // โครงการปิดการขาย
      case 1:
        this.setState({
          tooltip_stc_sellsigned:
            "หน่วย ขายได้สะสม + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_remaining: "หน่วย เหลือขาย ต้องเท่ากับ(=) 0",
          tooltip_stc_newsigned:
            "หน่วย ขายได้ใหม่ในช่วงสำรวจ ต้องน้อยกว่า(<)หรือเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_newtransfer:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
          tooltip_stc_notconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_underconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_finished:
            "หน่วย สร้างเสร็จ + กำลังสร้าง + ยังไม่สร้าง ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newconstruct:
            "หน่วย เริ่มสร้างใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) กำลังสร้าง ของหน่วยในผังทั้งหมด",
          tooltip_stc_newfinished:
            "หน่วย สร้างเสร็จใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) สร้างเสร็จ",
          tooltip_stc_sellnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_rmnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) เหลือขาย",
        });
        break;
      // โครงการเปิดขายใหม่ในช่วงสำรวจ
      case 3:
        this.setState({
          tooltip_stc_sellsigned:
            "หน่วย ขายได้สะสม + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_remaining:
            "หน่วย เหลือขาย + ขายได้ทั้งหมด ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newsigned:
            "หน่วย ขายได้ใหม่ในช่วงสำรวจ ต้องเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_newtransfer:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
          tooltip_stc_notconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_underconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_finished:
            "หน่วย สร้างเสร็จ + กำลังสร้าง + ยังไม่สร้าง ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newconstruct:
            "หน่วย เริ่มสร้างใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) กำลังสร้าง ของหน่วยในผังทั้งหมด",
          tooltip_stc_newfinished:
            "หน่วย สร้างเสร็จใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) สร้างเสร็จ",
          tooltip_stc_sellnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_rmnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) เหลือขาย",
        });
        break;
      // โครงการในอนาคต
      case 4:
        this.setState({
          tooltip_stc_sellsigned: "หน่วย ขายได้สะสม ต้องเท่ากับ(=) 0",
          tooltip_stc_remaining:
            "หน่วย เหลือขาย + ขายได้ทั้งหมด ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newsigned: "หน่วย ขายได้ใหม่ในช่วงสำรวจ ต้องเท่ากับ(=) 0",
          tooltip_stc_transfered: "หน่วย โอนกรรมสิทธิ์แล้ว ต้องเท่ากับ(=) 0",
          tooltip_stc_newtransfer: "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องเท่ากับ(=) 0",
          tooltip_stc_notconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_underconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_finished:
            "หน่วย สร้างเสร็จ + กำลังสร้าง + ยังไม่สร้าง ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newconstruct:
            "หน่วย เริ่มสร้างใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) กำลังสร้าง ของหน่วยในผังทั้งหมด",
          tooltip_stc_newfinished:
            "หน่วย สร้างเสร็จใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) สร้างเสร็จ",
          tooltip_stc_sellnotconstruct: "หน่วย ยังไม่สร้าง ต้องเท่ากับ(=) 0",
          tooltip_stc_sellunderconstruct: "หน่วย กำลังสร้าง ต้องเท่ากับ(=) 0",
          tooltip_stc_sellfinished: "หน่วย สร้างเสร็จ ต้องเท่ากับ(=) 0",
          tooltip_stc_rmnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) เหลือขาย",
        });
        break;
      // โครงการเปิดและปิดการขายในช่วงสำรวจ
      case 6:
        this.setState({
          tooltip_stc_sellsigned:
            "หน่วย ขายได้สะสม + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_remaining: "หน่วย เหลือขาย ต้องเท่ากับ(=) 0",
          tooltip_stc_newsigned:
            "หน่วย ขายได้ใหม่ในช่วงสำรวจ ต้องเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_newtransfer:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
          tooltip_stc_notconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_underconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_finished:
            "หน่วย สร้างเสร็จ + กำลังสร้าง + ยังไม่สร้าง ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newconstruct:
            "หน่วย เริ่มสร้างใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) กำลังสร้าง ของหน่วยในผังทั้งหมด",
          tooltip_stc_newfinished:
            "หน่วย สร้างเสร็จใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) สร้างเสร็จ",
          tooltip_stc_sellnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_rmnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) เหลือขาย",
        });
        break;
      default:
        this.setState({
          tooltip_stc_sellsigned:
            "หน่วย ขายได้สะสม + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_remaining:
            "หน่วย เหลือขาย + ขายได้ทั้งหมด ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newsigned:
            "หน่วย ขายได้ใหม่ในช่วงสำรวจ ต้องน้อยกว่า(<)หรือเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ขายได้สะสม",
          tooltip_stc_newtransfer:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
          tooltip_stc_notconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_underconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_finished:
            "หน่วย สร้างเสร็จ + กำลังสร้าง + ยังไม่สร้าง ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_stc_newconstruct:
            "หน่วย เริ่มสร้างใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) กำลังสร้าง ของหน่วยในผังทั้งหมด",
          tooltip_stc_newfinished:
            "หน่วย สร้างเสร็จใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) สร้างเสร็จ",
          tooltip_stc_sellnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_sellfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) ขายได้",
          tooltip_stc_rmnotconstruct:
            "หน่วย ยังไม่สร้าง + กำลังสร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmunderconstruct:
            "หน่วย กำลังสร้าง + ยังไม่สร้าง + สร้างเสร็จ ต้องเท่ากับ(=) เหลือขาย",
          tooltip_stc_rmfinished:
            "หน่วย สร้างเสร็จ + ยังไม่สร้าง + กำลังสร้าง ต้องเท่ากับ(=) เหลือขาย",
        });
        break;
    }
  }

  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success" ? "บันทึกสำเร็จ" : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  surveyModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "save"
            ? "ไม่สามารถบันทึกเอกสารได้"
            : "ไม่สามารถส่งเอกสารได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "save"
          ? "กรุณาตรวจสอบและกรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการบันทึก"
          : "กรุณาตรวจสอบและกรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการส่ง"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 2500);
  }

  surveyModal_lock(conditionCase) {
    let error_text = "";
    switch (conditionCase) {
      case "no_maxunit":
        error_text =
          "หน่วยในแต่ละประเภทโครงการทั้งหมด รวมกันต้องเท่ากับหน่วยในผังโครงการทั้งหมด";
        break;
      case "no_uplow":
        error_text =
          "กรุณาใส่ช่วงราคาให้ครบถ้วน (ราคาต่ำสุด - สูงสุดในแต่ละประเภท)";
        break;
      case "remain_less":
        error_text = "หน่วยเหลือขายต้องเท่ากับ 0";
        break;
      case "remain_more":
        error_text = "หน่วยเหลือขายต้องมากกว่า 0";
        break;
      case "not_zero":
        error_text = "หน่วยไม่สอดคล้องกับสถานะโครงการ";
        break;
      default:
        error_text = "Something went wrong";
    }

    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={"ข้อมูลส่วนที่ 3 ไม่ถูกต้อง"}
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {error_text}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 2500);
  }

  getSecretKey() {
    let request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          let key = responseJson.data;
          let keyutf = CryptoJS.enc.Utf8.parse(key);
          let iv = CryptoJS.enc.Base64.parse(key);

          let aesStr = localStorage.getItem("session");
          let dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          let decStr = CryptoJS.enc.Utf8.stringify(dec);
          let jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  componentDidUpdate(previousProps) {
    if (
      JSON.stringify(previousProps.villasec3object) !==
      JSON.stringify(this.props.villasec3object)
    ) {
      if (this.props.villasec3object.length === 0) {
        this.setState({ stc_detail: [] });
      }
    }
    if (
      JSON.stringify(previousProps.villasec1part2object) !==
        JSON.stringify(this.props.villasec1part2object) &&
      "villa_projectstatus" in this.props.villasec1part2object
    ) {
      this.stc_setWarning(this.props.villasec1part2object.villa_projectstatus);
    }

    if (
      JSON.stringify(previousProps.villaSec2Object) !==
        JSON.stringify(this.props.villaSec2Object) ||
      JSON.stringify(previousProps.villaSec1Object) !==
        JSON.stringify(this.props.villaSec1Object)
    ) {
      let part2Data = this.props.villaSec2Object;
      let part1Data = this.props.villaSec1Object;
      if ("villa_projectstatus" in part1Data) {
        this.stc_setWarning(part1Data.villa_projectstatus);
      }
      this.get_allPrice();

      if (JSON.stringify(part2Data) === "{}") {
        //
      } else {
        this.generateCat_type(part2Data);
        this.generateCat_price(part2Data);
        this.generateCat_unit(part2Data);
        this.sum_maxUnit(part2Data);

        setTimeout(async () => {
          await this.getloweruper_price();
          await this.setOnEdit();
        }, 1000);
      }
    }
  }

  sum_maxUnit = (part2Data) => {
    let arr_unit = part2Data.cat_allunitinproject;
    let max = 0;
    for (let i = 0; i < arr_unit.length; i++) {
      max = max + parseInt(arr_unit[i]);
    }
    this.setState({
      part2_maxUnit: max,
    });
  };

  get_allPrice = () => {
    let body = {
      srpprice_cat: 3,
      srpprice_max: 1000000000,
      srpprice_min: 0,
    };

    let request = new Request(config.API_getprice, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let response_array = responseJson.data;
        switch (responseJson.code) {
          case 200:
            if (response_array.length === 0) {
              //
            } else {
              this.setState({
                price_allRange: response_array,
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  isResubmiting = () => {
    return this.state.projectStatus === 2 || this.state.projectStatus === "2";
  };

  isEditable = () => {
    return !(
      this.state.view_psurvey === true ||
      this.state.projectStatus === 1 ||
      this.state.projectStatus === 3 ||
      this.state.memberStatus === 2
    );
  };

  generateCat_type(part2Data) {
    let stc_detail_obj = [];
    let arraycat_type = part2Data.cat_type;
    let arraycat_floor = part2Data.cat_floor;
    let arraycat_width = part2Data.cat_width;

    for (let i = 0; i < arraycat_type.length; i++) {
      let merge_name = "";
      if (arraycat_type[i] === 1) {
        merge_name =
          "บ้านเดี่ยว " +
          arraycat_floor[i] +
          " ชั้น กว้าง " +
          arraycat_width[i] +
          " ม.";
      } else if (arraycat_type[i] === 2) {
        merge_name =
          "บ้านแฝด " +
          arraycat_floor[i] +
          " ชั้น กว้าง " +
          arraycat_width[i] +
          " ม.";
      } else if (arraycat_type[i] === 3) {
        merge_name =
          "ทาวน์เฮ้าส์ " +
          arraycat_floor[i] +
          " ชั้น กว้าง " +
          arraycat_width[i] +
          " ม.";
      } else if (arraycat_type[i] === 4) {
        merge_name =
          "อาคารพาณิชย์ " +
          arraycat_floor[i] +
          " ชั้น กว้าง " +
          arraycat_width[i] +
          " ม.";
      } else {
        merge_name = "ที่ดินเปล่า";
      }

      stc_detail_obj.push({
        value: i + 1,
        label: merge_name,
        cat_type: arraycat_type[i],
      });
      this.setState({
        part2_cattype: stc_detail_obj,
      });
    }
  }

  async getloweruper_price() {
    let array_catype = this.state.part2_cattype;
    let array_catprice = this.state.part2_catprice;

    let price_merge = [];
    let price_selectedBlank = [];

    for (let i = 0; i < array_catype.length; i++) {
      let body = {
        srpprice_cat: 3,
        srpprice_max: array_catprice[i].price_max,
        srpprice_min: array_catprice[i].price_min,
      };

      let request = new Request(config.API_getprice, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });

      await fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          let response_array = responseJson.data;
          switch (responseJson.code) {
            case 200:
              if (response_array.length === 0) {
                //
              } else if (response_array.length === 1) {
                price_merge.push({
                  id_val: i + 1,
                  price_id: [response_array[0].srpprice_id],
                });

                price_selectedBlank.push({
                  id_val: i + 1,
                  price_id: [],
                  label: array_catype[i].label,
                });
              } else {
                price_merge.push({
                  id_val: i + 1,
                  price_id: [
                    response_array[0].srpprice_id,
                    response_array[response_array.length - 1].srpprice_id,
                  ],
                });

                price_selectedBlank.push({
                  id_val: i + 1,
                  price_id: [],
                  label: array_catype[i].label,
                });
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    return this.setState({
      price_limit: price_merge,
      price_selected: price_selectedBlank,
    });
  }

  generateCat_price(part2Data) {
    let stc_detail_obj = [];
    let arraycat_type = part2Data.cat_type;
    let arraycat_minprice = part2Data.cat_minprice;
    let arraycat_maxprice = part2Data.cat_maxprice;

    for (let i = 0; i < arraycat_type.length; i++) {
      stc_detail_obj.push({
        value: i + 1,
        price_min: arraycat_minprice[i],
        price_max: arraycat_maxprice[i],
        price_type: arraycat_type[i],
      });
      this.setState({
        part2_catprice: stc_detail_obj,
      });
    }
  }

  generateCat_unit(part2Data) {
    let stc_detail_obj = [];
    let arraycat_type = part2Data.cat_type;
    let arraycat_allunit = part2Data.cat_allunitinproject;

    for (let i = 0; i < arraycat_type.length; i++) {
      stc_detail_obj.push({
        value: i + 1,
        unit_type: arraycat_allunit[i],
      });
      this.setState({
        part2_allunit: stc_detail_obj,
      });
    }
  }

  check_cattype(type, props) {
    let arraycat_type = this.state.part2_cattype;

    let match_val = arraycat_type.filter(function(obj) {
      return obj.value === type;
    });
    if (match_val[0].cat_type === 5) {
      this.setState({
        istype_land: true,
        stc_notconstruct_State: "success",
        stc_underconstruct_State: "success",
        stc_finished_State: "success",
        stc_newconstruct_State: "success",
        stc_newfinished_State: "success",
        stc_sellnotconstruct_State: "success",
        stc_sellunderconstruct_State: "success",
        stc_sellfinished_State: "success",
        stc_rmnotconstruct_State: "success",
        stc_rmunderconstruct_State: "success",
        stc_rmfinished_State: "success",
      });
    } else {
      this.setState({
        istype_land: false,
      });
    }

    return match_val[0].label;
  }

  // render edit type
  render_stctype(type) {
    return type;
  }
  // เช็ค string
  chk_stctype = (value) => {
    let arraystc_type = this.state.part2_cattype;

    if (typeof value === "string") {
      let match_val = arraystc_type.filter(function(obj) {
        return obj.label === value;
      });
      return match_val[0].value;
    } else {
      let match_val = arraystc_type.filter(function(obj) {
        return obj.value === value;
      });
      return match_val[0].value;
    }
  };

  // render edit price
  render_stcprice = (rowData) => {
    let arr_pricerange = this.state.price_allRange;

    let match_val = arr_pricerange.filter(function(obj) {
      return obj.srpprice_id === rowData.stc_pricelenid;
    });

    if (match_val.length !== 0) {
      return match_val[0].srpprice_info;
    } else {
      return rowData.srpprice_info;
    }
  };

  // my new function start here
  // -----------------------------------------------
  change(event, props, stateName) {
    switch (stateName) {
      // ประเภท
      case "stc_type":
        if (this.stc_selectType(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      // ช่วงราคา
      case "stc_pricelenid":
        if (this.stc_selectPrice(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      // ผังโครงการ
      case "stc_unit":
        if (typeof props.rowData.stc_type !== "number") {
          if (props.rowData.stc_type === "ที่ดินเปล่า") {
            this.setState({
              istype_land: true,
              stc_notconstruct_State: "",
              stc_underconstruct_State: "",
              stc_finished_State: "",
              stc_newconstruct_State: "",
              stc_newfinished_State: "",
              stc_rmnotconstruct_State: "",
              stc_rmunderconstruct_State: "",
              stc_rmfinished_State: "",
            });
          } else {
            this.setState({
              istype_land: false,
            });
          }
        }
        this.stc_typeUnit(event.target.value, props);
        break;
      // ขายได้สะสม
      case "stc_sellsigned":
        if (typeof props.rowData.stc_type !== "number") {
          if (props.rowData.stc_type === "ที่ดินเปล่า") {
            this.setState({
              istype_land: true,
              stc_notconstruct_State: "",
              stc_underconstruct_State: "",
              stc_finished_State: "",
              stc_newconstruct_State: "",
              stc_newfinished_State: "",
              stc_sellnotconstruct_State: "",
              stc_sellunderconstruct_State: "",
              stc_sellfinished_State: "",
              stc_rmnotconstruct_State: "",
              stc_rmunderconstruct_State: "",
              stc_rmfinished_State: "",
            });
          } else {
            this.setState({
              istype_land: false,
            });
          }
        }
        this.stc_typeSellsigned(event.target.value, props);
        break;
      // เหลือขาย
      case "stc_remaining":
        if (typeof props.rowData.stc_type !== "number") {
          if (props.rowData.stc_type === "ที่ดินเปล่า") {
            this.setState({
              istype_land: true,
              stc_notconstruct_State: "",
              stc_underconstruct_State: "",
              stc_finished_State: "",
              stc_newconstruct_State: "",
              stc_newfinished_State: "",
              stc_sellnotconstruct_State: "",
              stc_sellunderconstruct_State: "",
              stc_sellfinished_State: "",
              stc_rmnotconstruct_State: "",
              stc_rmunderconstruct_State: "",
              stc_rmfinished_State: "",
            });
          } else {
            this.setState({
              istype_land: false,
            });
          }
        }
        this.stc_typeRemaining(event.target.value, props);
        break;
      // ขายได้ใหม่ในช่วงสำรวจ
      case "stc_newsigned":
        if (typeof props.rowData.stc_type !== "number") {
          if (props.rowData.stc_type === "ที่ดินเปล่า") {
            this.setState({
              istype_land: true,
              stc_notconstruct_State: "",
              stc_underconstruct_State: "",
              stc_finished_State: "",
              stc_newconstruct_State: "",
              stc_newfinished_State: "",
              stc_sellnotconstruct_State: "",
              stc_sellunderconstruct_State: "",
              stc_sellfinished_State: "",
              stc_rmnotconstruct_State: "",
              stc_rmunderconstruct_State: "",
              stc_rmfinished_State: "",
            });
          } else {
            this.setState({
              istype_land: false,
            });
          }
        }
        this.stc_typeNewsigned(event.target.value, props);
        break;
      // โอนแล้ว
      case "stc_transfered":
        if (typeof props.rowData.stc_type !== "number") {
          if (props.rowData.stc_type === "ที่ดินเปล่า") {
            this.setState({
              istype_land: true,
              stc_notconstruct_State: "",
              stc_underconstruct_State: "",
              stc_finished_State: "",
              stc_newconstruct_State: "",
              stc_newfinished_State: "",
              stc_sellnotconstruct_State: "",
              stc_sellunderconstruct_State: "",
              stc_sellfinished_State: "",
              stc_rmnotconstruct_State: "",
              stc_rmunderconstruct_State: "",
              stc_rmfinished_State: "",
            });
          } else {
            this.setState({
              istype_land: false,
            });
          }
        }
        this.stc_typeTransfered(event.target.value, props);
        break;
      // โอนใหม่
      case "stc_newtransfer":
        if (typeof props.rowData.stc_type !== "number") {
          if (props.rowData.stc_type === "ที่ดินเปล่า") {
            this.setState({
              istype_land: true,
              stc_notconstruct_State: "",
              stc_underconstruct_State: "",
              stc_finished_State: "",
              stc_newconstruct_State: "",
              stc_newfinished_State: "",
              stc_sellnotconstruct_State: "",
              stc_sellunderconstruct_State: "",
              stc_sellfinished_State: "",
              stc_rmnotconstruct_State: "",
              stc_rmunderconstruct_State: "",
              stc_rmfinished_State: "",
            });
          } else {
            this.setState({
              istype_land: false,
            });
          }
        }
        this.stc_typeNewtransfer(event.target.value, props);
        break;
      // ยังไม่สร้าง
      case "stc_notconstruct":
        this.stc_typeNotconstruct(event.target.value, props);
        break;
      // กำลังสร้าง
      case "stc_underconstruct":
        this.stc_typeUnderconstruct(event.target.value, props);
        break;
      // สร้างเสร็จ
      case "stc_finished":
        this.stc_typeFinished(event.target.value, props);
        break;
      // เริ่มสร้างใหม่
      case "stc_newconstruct":
        this.stc_typeNewconstruct(event.target.value, props);
        break;
      // สร้างเสร็จใหม่
      case "stc_newfinished":
        this.stc_typeNewfinished(event.target.value, props);
        break;
      // ขายได้ยังไม่สร้าง
      case "stc_sellnotconstruct":
        this.stc_typeSellnotconstruct(event.target.value, props);
        break;
      // ขายได้กำลังสร้าง
      case "stc_sellunderconstruct":
        this.stc_typeSellunderconstruct(event.target.value, props);
        break;
      // ขายได้สร้างเสร์จ
      case "stc_sellfinished":
        this.stc_typeSellfinished(event.target.value, props);
        break;
      // เหลือขายยังไม่สร้าง
      case "stc_rmnotconstruct":
        this.stc_typeRmnotconstruct(event.target.value, props);
        break;
      // เหลือขายกำลังสร้าง
      case "stc_rmunderconstruct":
        this.stc_typeRmunderconstruct(event.target.value, props);
        break;
      // เหลือขายสร้างเสร็จ
      case "stc_rmfinished":
        this.stc_typeRmfinished(event.target.value, props);
        break;
      default:
        break;
    }
  }

  stc_selectType = (event, props) => {
    props.onChange(event);

    this.setState({
      disabled_stcUnit: false,
      stc_pricelenid_State: "error",
      stc_unit_State: "error",
    });

    this.stc_priceRange(event);
    this.stc_setUnit(event, props);
    this.check_cattype(event, props);
    return true;
  };

  stc_selectPrice = (event, props) => {
    props.onChange(event);
    if (this.state.istype_land) {
      this.setState({
        stc_pricelenid_State: "error",
        stc_unit_State: "error",
        stc_sellsigned_State: "error",
        stc_remaining_State: "error",
        stc_newsigned_State: "error",
        stc_transfered_State: "error",
        stc_newtransfer_State: "error",
        stc_notconstruct: event,
        stc_notconstruct_State: "success",
        stc_underconstruct: "0",
        stc_underconstruct_State: "success",
        stc_finished: "0",
        stc_finished_State: "success",
        stc_newconstruct_State: "success",
        stc_newfinished_State: "success",
        stc_rmnotconstruct_State: "success",
        stc_rmunderconstruct: "0",
        stc_rmunderconstruct_State: "success",
        stc_rmfinished: "0",
        stc_rmfinished_State: "success",
        stc_sellnotconstruct: "0",
        stc_sellnotconstruct_State: "success",
        stc_sellunderconstruct: "0",
        stc_sellunderconstruct_State: "success",
        stc_sellfinished: "0",
        stc_sellfinished_State: "success",
      });
    } else {
      this.setState({
        stc_pricelenid_State: "error",
        stc_unit_State: "error",
        stc_sellsigned_State: "error",
        stc_remaining_State: "error",
        stc_newsigned_State: "error",
        stc_transfered_State: "error",
        stc_newtransfer_State: "error",
        stc_notconstruct_State: "error",
        stc_underconstruct_State: "error",
        stc_finished_State: "error",
        stc_newconstruct_State: "error",
        stc_newfinished_State: "error",
        stc_rmnotconstruct_State: "error",
        stc_rmunderconstruct_State: "error",
        stc_rmfinished_State: "error",
        stc_sellnotconstruct_State: "error",
        stc_sellunderconstruct_State: "error",
        stc_sellfinished_State: "error",
      });
    }

    return true;
  };

  checkDisabledRow = (data, props) => {
    let takenPrice = this.state.price_selected;
    let filter_val = takenPrice.filter(function(obj) {
      if (typeof props.stc_type === "string") {
        return obj.label === props.stc_type;
      } else {
        return obj.id_val === props.stc_type;
      }
    });
    return data.map((price) => (
      <MenuItem
        classes={{
          root: this.props.classes.selectMenuItem,
        }}
        key={price.srpprice_id}
        value={price.srpprice_id}
        style={{ backgroundColor: "#fff" }}
        disabled={this.disabled_function(price.srpprice_id, filter_val)}
      >
        {price.srpprice_info}
      </MenuItem>
    ));
  };

  disabled_function = (price_id, filter_val) => {
    switch (filter_val.length) {
      case 0:
        return false;
      default:
        if (filter_val[0].price_id.indexOf(price_id) > -1) {
          return true;
        } else {
          return false;
        }
    }
  };

  seperateLimitData = (inputdata) => {
    let arrayprice_selected = this.state.price_selected;

    let new_arr = arrayprice_selected;

    for (let i = 0; i < arrayprice_selected.length; i++) {
      if (inputdata.stc_type === arrayprice_selected[i].label) {
        new_arr[i].price_id.push(inputdata.stc_pricelenid);
      } else {
        //
      }
    }
    this.setState({
      price_selected: new_arr,
    });
  };

  sumallUnit(unit) {
    let arr = unit || [];
    let stc_finished = 0;
    let stc_newconstruct = 0;
    let stc_newfinished = 0;
    let stc_newsigned = 0;
    let stc_newtransfer = 0;
    let stc_notconstruct = 0;
    let stc_remaining = 0;
    let stc_sellfinished = 0;
    let stc_sellnotconstruct = 0;
    let stc_sellunderconstruct = 0;
    let stc_rmfinished = 0;
    let stc_rmnotconstruct = 0;
    let stc_rmunderconstruct = 0;
    let stc_sellsigned = 0;
    let stc_transfered = 0;
    let stc_underconstruct = 0;
    let stc_unit = 0;

    for (let i = 0; i < arr.length; i++) {
      stc_finished = parseInt(stc_finished) + parseInt(arr[i].stc_finished);
      stc_newconstruct =
        parseInt(stc_newconstruct) + parseInt(arr[i].stc_newconstruct);
      stc_newfinished =
        parseInt(stc_newfinished) + parseInt(arr[i].stc_newfinished);
      stc_newsigned = parseInt(stc_newsigned) + parseInt(arr[i].stc_newsigned);
      stc_newtransfer =
        parseInt(stc_newtransfer) + parseInt(arr[i].stc_newtransfer);
      stc_notconstruct =
        parseInt(stc_notconstruct) + parseInt(arr[i].stc_notconstruct);
      stc_remaining = parseInt(stc_remaining) + parseInt(arr[i].stc_remaining);
      stc_rmfinished =
        parseInt(stc_rmfinished) + parseInt(arr[i].stc_rmfinished);
      stc_rmnotconstruct =
        parseInt(stc_rmnotconstruct) + parseInt(arr[i].stc_rmnotconstruct);
      stc_rmunderconstruct =
        parseInt(stc_rmunderconstruct) + parseInt(arr[i].stc_rmunderconstruct);
      stc_sellfinished =
        parseInt(stc_sellfinished) + parseInt(arr[i].stc_sellfinished);
      stc_sellnotconstruct =
        parseInt(stc_sellnotconstruct) + parseInt(arr[i].stc_sellnotconstruct);
      stc_sellunderconstruct =
        parseInt(stc_sellunderconstruct) +
        parseInt(arr[i].stc_sellunderconstruct);
      stc_sellsigned =
        parseInt(stc_sellsigned) + parseInt(arr[i].stc_sellsigned);
      stc_transfered =
        parseInt(stc_transfered) + parseInt(arr[i].stc_transfered);
      stc_underconstruct =
        parseInt(stc_underconstruct) + parseInt(arr[i].stc_underconstruct);
      stc_unit = parseInt(stc_unit) + parseInt(arr[i].stc_unit);
    }

    this.setState({
      Sum_stc_finished: stc_finished,
      Sum_stc_newconstruct: stc_newconstruct,
      Sum_stc_newfinished: stc_newfinished,
      Sum_stc_newsigned: stc_newsigned,
      Sum_stc_newtransfer: stc_newtransfer,
      Sum_stc_notconstruct: stc_notconstruct,
      Sum_stc_remaining: stc_remaining,
      Sum_stc_sellfinished: stc_sellfinished,
      Sum_stc_sellnotconstruct: stc_sellnotconstruct,
      Sum_stc_sellunderconstruct: stc_sellunderconstruct,
      Sum_stc_rmfinished: stc_rmfinished,
      Sum_stc_rmnotconstruct: stc_rmnotconstruct,
      Sum_stc_rmunderconstruct: stc_rmunderconstruct,
      Sum_stc_sellsigned: stc_sellsigned,
      Sum_stc_transfered: stc_transfered,
      Sum_stc_underconstruct: stc_underconstruct,
      Sum_stc_unit: stc_unit,
    });
  }

  // หน่วยในผังโครงการทั้งหมด
  stc_typeUnit = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let remaining = parseInt(props.rowData.stc_remaining);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let newsigned = parseInt(props.rowData.stc_newsigned);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let maxunit =
      this.state.stc_maxUnit && this.state.stc_maxUnit[0] ? true : false;
    if (maxunit === 0) {
      this.setState({
        stc_unit_State: "error",
      });
      return;
    }
    if (maxunit === true) {
      maxunit = this.state.stc_maxUnit[0].unit_type;
      if (typeof props.rowData.stc_type !== "number") {
        let theType = this.filter_typeval_reverse(props.rowData.stc_type);
        let arraycat_unit = this.state.part2_allunit.filter(
          (t) => t.value === theType
        )[0];
        maxunit = arraycat_unit.unit_type;
        this.setState({
          stc_maxUnit: [arraycat_unit],
          tooltip_stc_unit: "หน่วยประเภทบ้านที่เลือก รวมกันห้ามเกิน " + maxunit,
        });
        this.setState({
          stc_unit_placeholder: "หน่วยสูงสุด = " + maxunit,
        });
      }
    } else {
      let theType = this.filter_typeval_reverse(props.rowData.stc_type);
      let arraycat_unit = this.state.part2_allunit.filter(
        (t) => t.value === theType
      )[0];
      maxunit = arraycat_unit.unit_type;
      this.setState({
        stc_maxUnit: [arraycat_unit],
        tooltip_stc_unit: "หน่วยประเภทบ้านที่เลือก รวมกันห้ามเกิน " + maxunit,
      });
      this.setState({
        stc_unit_placeholder: "หน่วยสูงสุด = " + maxunit,
      });
    }

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      if (val <= maxunit) {
        switch (proStatus) {
          case 3:
            if (!isNaN(sellsigned) && !isNaN(remaining)) {
              if (val === sellsigned + remaining) {
                this.setState({
                  stc_unit_State: "success",
                  stc_sellsigned_State: "success",
                  stc_remaining_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_sellsigned_State: "error",
                  stc_remaining_State: "error",
                });
                return;
              }
            }
            if (!this.state.istype_land) {
              if (
                !isNaN(notconstruct) &&
                !isNaN(underconstruct) &&
                !isNaN(finished)
              ) {
                if (val === notconstruct + underconstruct + finished) {
                  this.setState({
                    stc_unit_State: "success",
                    stc_notconstruct_State: "success",
                    stc_underconstruct_State: "success",
                    stc_finished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_unit_State: "error",
                    stc_notconstruct_State: "error",
                    stc_underconstruct_State: "error",
                    stc_finished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(sellsigned) &&
                !isNaN(sellnotconstruct) &&
                !isNaN(sellunderconstruct) &&
                !isNaN(sellfinished)
              ) {
                if (
                  sellsigned ===
                  sellnotconstruct + sellunderconstruct + sellfinished
                ) {
                  this.setState({
                    stc_sellsigned_State: "success",
                    stc_sellnotconstruct_State: "success",
                    stc_sellunderconstruct_State: "success",
                    stc_sellfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_sellsigned_State: "error",
                    stc_sellnotconstruct_State: "error",
                    stc_sellunderconstruct_State: "error",
                    stc_sellfinished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(remaining) &&
                !isNaN(rmnotconstruct) &&
                !isNaN(rmunderconstruct) &&
                !isNaN(rmfinished)
              ) {
                if (
                  remaining ===
                  rmnotconstruct + rmunderconstruct + rmfinished
                ) {
                  this.setState({
                    stc_remaining_State: "success",
                    stc_rmnotconstruct_State: "success",
                    stc_rmunderconstruct_State: "success",
                    stc_rmfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_remaining_State: "error",
                    stc_rmnotconstruct_State: "error",
                    stc_rmunderconstruct_State: "error",
                    stc_rmfinished_State: "error",
                  });
                  return;
                }
              }
              if (!isNaN(transfered) && !isNaN(finished)) {
                if (transfered <= finished) {
                  this.setState({
                    stc_transfered_State: "success",
                    stc_finished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_transfered_State: "error",
                    stc_finished_State: "error",
                  });
                  return;
                }
              }
              if (!isNaN(transfered) && !isNaN(sellfinished)) {
                if (transfered <= sellfinished) {
                  this.setState({
                    stc_sellfinished_State: "success",
                    stc_transfered_State: "success",
                  });
                } else {
                  this.setState({
                    stc_sellfinished_State: "error",
                    stc_transfered_State: "error",
                  });
                  return;
                }
              }
            }
            if (!isNaN(sellsigned) && !isNaN(newsigned)) {
              if (sellsigned === newsigned) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_newsigned_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_newsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(sellsigned) && !isNaN(transfered)) {
              if (sellsigned >= transfered) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(newtransfer)) {
              if (transfered === newtransfer) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_newtransfer_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_newtransfer_State: "error",
                });
                return;
              }
            }
            return;
          case 4:
            if (!isNaN(remaining)) {
              if (val === remaining) {
                this.setState({
                  stc_unit_State: "success",
                  stc_remaining_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_remaining_State: "error",
                });
                return;
              }
            }
            if (this.state.istype_land) {
              this.setState({
                stc_notconstruct: val,
              });
            } else {
              if (
                !isNaN(notconstruct) &&
                !isNaN(underconstruct) &&
                !isNaN(finished)
              ) {
                if (val === notconstruct + underconstruct + finished) {
                  this.setState({
                    stc_unit_State: "success",
                    stc_notconstruct_State: "success",
                    stc_underconstruct_State: "success",
                    stc_finished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_unit_State: "error",
                    stc_notconstruct_State: "error",
                    stc_underconstruct_State: "error",
                    stc_finished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(sellsigned) &&
                !isNaN(sellnotconstruct) &&
                !isNaN(sellunderconstruct) &&
                !isNaN(sellfinished)
              ) {
                if (
                  sellsigned ===
                  sellnotconstruct + sellunderconstruct + sellfinished
                ) {
                  this.setState({
                    stc_sellsigned_State: "success",
                    stc_sellnotconstruct_State: "success",
                    stc_sellunderconstruct_State: "success",
                    stc_sellfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_sellsigned_State: "error",
                    stc_sellnotconstruct_State: "error",
                    stc_sellunderconstruct_State: "error",
                    stc_sellfinished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(remaining) &&
                !isNaN(rmnotconstruct) &&
                !isNaN(rmunderconstruct) &&
                !isNaN(rmfinished)
              ) {
                if (
                  remaining ===
                  rmnotconstruct + rmunderconstruct + rmfinished
                ) {
                  this.setState({
                    stc_remaining_State: "success",
                    stc_rmnotconstruct_State: "success",
                    stc_rmunderconstruct_State: "success",
                    stc_rmfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_remaining_State: "error",
                    stc_rmnotconstruct_State: "error",
                    stc_rmunderconstruct_State: "error",
                    stc_rmfinished_State: "error",
                  });
                  return;
                }
              }
            }
            if (!isNaN(sellsigned)) {
              if (sellsigned === 0) {
                this.setState({
                  stc_sellsigned_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(newsigned)) {
              if (newsigned === 0) {
                this.setState({
                  stc_newsigned_State: "success",
                });
              } else {
                this.setState({
                  stc_newsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered)) {
              if (transfered === 0) {
                this.setState({
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (!isNaN(newtransfer)) {
              if (newtransfer === 0) {
                this.setState({
                  stc_newtransfer_State: "success",
                });
              } else {
                this.setState({
                  stc_newtransfer_State: "error",
                });
                return;
              }
            }
            if (!isNaN(finished)) {
              if (finished >= 0) {
                this.setState({
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(sellfinished)) {
              if (sellfinished >= 0) {
                this.setState({
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            return;
          case 6:
            if (!isNaN(sellsigned) && !isNaN(remaining)) {
              if (val === sellsigned + remaining) {
                this.setState({
                  stc_unit_State: "success",
                  stc_sellsigned_State: "success",
                  stc_remaining_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_sellsigned_State: "error",
                  stc_remaining_State: "error",
                });
                return;
              }
            }
            if (!this.state.istype_land) {
              if (
                !isNaN(notconstruct) &&
                !isNaN(underconstruct) &&
                !isNaN(finished)
              ) {
                if (val === notconstruct + underconstruct + finished) {
                  this.setState({
                    stc_unit_State: "success",
                    stc_notconstruct_State: "success",
                    stc_underconstruct_State: "success",
                    stc_finished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_unit_State: "error",
                    stc_notconstruct_State: "error",
                    stc_underconstruct_State: "error",
                    stc_finished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(sellsigned) &&
                !isNaN(sellnotconstruct) &&
                !isNaN(sellunderconstruct) &&
                !isNaN(sellfinished)
              ) {
                if (
                  sellsigned ===
                  sellnotconstruct + sellunderconstruct + sellfinished
                ) {
                  this.setState({
                    stc_sellsigned_State: "success",
                    stc_sellnotconstruct_State: "success",
                    stc_sellunderconstruct_State: "success",
                    stc_sellfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_sellsigned_State: "error",
                    stc_sellnotconstruct_State: "error",
                    stc_sellunderconstruct_State: "error",
                    stc_sellfinished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(remaining) &&
                !isNaN(rmnotconstruct) &&
                !isNaN(rmunderconstruct) &&
                !isNaN(rmfinished)
              ) {
                if (
                  remaining ===
                  rmnotconstruct + rmunderconstruct + rmfinished
                ) {
                  this.setState({
                    stc_remaining_State: "success",
                    stc_rmnotconstruct_State: "success",
                    stc_rmunderconstruct_State: "success",
                    stc_rmfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_remaining_State: "error",
                    stc_rmnotconstruct_State: "error",
                    stc_rmunderconstruct_State: "error",
                    stc_rmfinished_State: "error",
                  });
                  return;
                }
              }
              if (!isNaN(transfered) && !isNaN(finished)) {
                if (transfered <= finished) {
                  this.setState({
                    stc_transfered_State: "success",
                    stc_finished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_transfered_State: "error",
                    stc_finished_State: "error",
                  });
                  return;
                }
              }
              if (!isNaN(transfered) && !isNaN(sellfinished)) {
                if (transfered <= sellfinished) {
                  this.setState({
                    stc_sellfinished_State: "success",
                    stc_transfered_State: "success",
                  });
                } else {
                  this.setState({
                    stc_sellfinished_State: "error",
                    stc_transfered_State: "error",
                  });
                  return;
                }
              }
            }
            if (!isNaN(sellsigned) && !isNaN(newsigned)) {
              if (sellsigned === newsigned) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_newsigned_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_newsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(sellsigned) && !isNaN(transfered)) {
              if (sellsigned >= transfered) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(newtransfer)) {
              if (transfered === newtransfer) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_newtransfer_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_newtransfer_State: "error",
                });
                return;
              }
            }
            return;
          default:
            if (!isNaN(sellsigned) && !isNaN(remaining)) {
              if (val === sellsigned + remaining) {
                this.setState({
                  stc_unit_State: "success",
                  stc_sellsigned_State: "success",
                  stc_remaining_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_sellsigned_State: "error",
                  stc_remaining_State: "error",
                });
                return;
              }
            }
            if (this.state.istype_land) {
              this.setState({
                stc_notconstruct: val,
              });
            } else {
              if (
                !isNaN(notconstruct) &&
                !isNaN(underconstruct) &&
                !isNaN(finished)
              ) {
                if (val === notconstruct + underconstruct + finished) {
                  this.setState({
                    stc_unit_State: "success",
                    stc_notconstruct_State: "success",
                    stc_underconstruct_State: "success",
                    stc_finished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_unit_State: "error",
                    stc_notconstruct_State: "error",
                    stc_underconstruct_State: "error",
                    stc_finished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(sellsigned) &&
                !isNaN(sellnotconstruct) &&
                !isNaN(sellunderconstruct) &&
                !isNaN(sellfinished)
              ) {
                if (
                  sellsigned ===
                  sellnotconstruct + sellunderconstruct + sellfinished
                ) {
                  this.setState({
                    stc_sellsigned_State: "success",
                    stc_sellnotconstruct_State: "success",
                    stc_sellunderconstruct_State: "success",
                    stc_sellfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_sellsigned_State: "error",
                    stc_sellnotconstruct_State: "error",
                    stc_sellunderconstruct_State: "error",
                    stc_sellfinished_State: "error",
                  });
                  return;
                }
              }
              if (
                !isNaN(remaining) &&
                !isNaN(rmnotconstruct) &&
                !isNaN(rmunderconstruct) &&
                !isNaN(rmfinished)
              ) {
                if (
                  remaining ===
                  rmnotconstruct + rmunderconstruct + rmfinished
                ) {
                  this.setState({
                    stc_remaining_State: "success",
                    stc_rmnotconstruct_State: "success",
                    stc_rmunderconstruct_State: "success",
                    stc_rmfinished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_remaining_State: "error",
                    stc_rmnotconstruct_State: "error",
                    stc_rmunderconstruct_State: "error",
                    stc_rmfinished_State: "error",
                  });
                  return;
                }
              }
              if (!isNaN(transfered) && !isNaN(finished)) {
                if (transfered <= finished) {
                  this.setState({
                    stc_transfered_State: "success",
                    stc_finished_State: "success",
                  });
                } else {
                  this.setState({
                    stc_transfered_State: "error",
                    stc_finished_State: "error",
                  });
                  return;
                }
              }
              if (!isNaN(transfered) && !isNaN(sellfinished)) {
                if (transfered <= sellfinished) {
                  this.setState({
                    stc_sellfinished_State: "success",
                    stc_transfered_State: "success",
                  });
                } else {
                  this.setState({
                    stc_sellfinished_State: "error",
                    stc_transfered_State: "error",
                  });
                  return;
                }
              }
            }
            if (!isNaN(sellsigned) && !isNaN(newsigned)) {
              if (sellsigned >= newsigned) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_newsigned_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_newsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(sellsigned) && !isNaN(transfered)) {
              if (sellsigned >= transfered) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(newtransfer)) {
              if (transfered >= newtransfer) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_newtransfer_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_newtransfer_State: "error",
                });
                return;
              }
            }
            return;
        }
      } else {
        this.setState({
          stc_unit_State: "error",
        });
        this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
        return;
      }
    }
    props.onChange("");
    this.setState({
      stc_unit_State: "error",
    });
    return;
  };

  // ขายได้สะสม
  stc_typeSellsigned = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let remaining = parseInt(props.rowData.stc_remaining);
    let newsigned = parseInt(props.rowData.stc_newsigned);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === val + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                val ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(remaining) &&
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (
                remaining ===
                rmnotconstruct + rmunderconstruct + rmfinished
              ) {
                this.setState({
                  stc_remaining_State: "success",
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_remaining_State: "error",
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(newsigned)) {
            if (val === newsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              stc_sellsigned_State: "success",
            });
            if (!isNaN(unit) && !isNaN(remaining)) {
              if (unit === remaining) {
                this.setState({
                  st_unit_State: "success",
                  st_remaining_State: "success",
                });
              } else {
                this.setState({
                  st_unit_State: "error",
                  st_remaining_State: "error",
                });
                return;
              }
            }
          } else {
            this.setState({
              stc_sellsigned_State: "error",
            });
            return;
          }
          if (!this.state.istype_land) {
            if (
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                val ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(remaining) &&
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (
                remaining ===
                rmnotconstruct + rmunderconstruct + rmfinished
              ) {
                this.setState({
                  stc_remaining_State: "success",
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_remaining_State: "error",
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(newsigned)) {
            if (newsigned === 0) {
              this.setState({
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished)) {
            if (finished >= 0) {
              this.setState({
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished >= 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === val + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                val ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(remaining) &&
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (
                remaining ===
                rmnotconstruct + rmunderconstruct + rmfinished
              ) {
                this.setState({
                  stc_remaining_State: "success",
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_remaining_State: "error",
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(newsigned)) {
            if (val === newsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === val + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                val ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(remaining) &&
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (
                remaining ===
                rmnotconstruct + rmunderconstruct + rmfinished
              ) {
                this.setState({
                  stc_remaining_State: "success",
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_remaining_State: "error",
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(newsigned)) {
            if (val >= newsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_sellsigned_State: "error",
    });
    return;
  };

  // เหลือขาย
  stc_typeRemaining = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let newsigned = parseInt(props.rowData.stc_newsigned);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 1:
          if (!isNaN(unit) && !isNaN(sellsigned)) {
            if (unit === val + sellsigned) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (this.state.istype_land) {
            this.setState({
              stc_rmnotconstruct: val,
            });
          } else {
            if (
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (val === rmnotconstruct + rmunderconstruct + rmfinished) {
                this.setState({
                  stc_remaining_State: "success",
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_remaining_State: "error",
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
          }
          if (val < 6) {
            this.setState({
              stc_remaining_State: "success",
            });
          } else {
            this.setState({
              stc_remaining_State: "error",
            });
            return;
          }
          if (!isNaN(sellsigned) && !isNaN(newsigned)) {
            if (sellsigned >= newsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned) && !isNaN(transfered)) {
            if (sellsigned >= transfered) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        case 3:
          if (!isNaN(unit) && !isNaN(sellsigned)) {
            if (unit === val + sellsigned) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (val === rmnotconstruct + rmunderconstruct + rmfinished) {
                this.setState({
                  stc_remaining_State: "success",
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_remaining_State: "error",
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(sellsigned) && !isNaN(newsigned)) {
            if (sellsigned === newsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned) && !isNaN(transfered)) {
            if (sellsigned >= transfered) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (!isNaN(unit)) {
            if (unit === val) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (this.state.istype_land) {
            this.setState({
              stc_rmnotconstruct: val,
            });
          } else {
            if (
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (val === rmnotconstruct + rmunderconstruct + rmfinished) {
                this.setState({
                  stc_remaining_State: "success",
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_remaining_State: "error",
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newsigned)) {
            if (newsigned === 0) {
              this.setState({
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished)) {
            if (finished >= 0) {
              this.setState({
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished >= 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(unit) && !isNaN(sellsigned)) {
            if (unit === val + sellsigned) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (this.state.istype_land) {
            this.setState({
              stc_rmnotconstruct: val,
            });
          } else {
            if (
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (val === rmnotconstruct + rmunderconstruct + rmfinished) {
                this.setState({
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
          }
          if (val < 6) {
            this.setState({
              stc_remaining_State: "success",
            });
          } else {
            this.setState({
              stc_remaining_State: "error",
            });
            return;
          }
          if (!isNaN(sellsigned) && !isNaN(newsigned)) {
            if (sellsigned === newsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned) && !isNaN(transfered)) {
            if (sellsigned >= transfered) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(unit) && !isNaN(sellsigned)) {
            if (unit === val + sellsigned) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (this.state.istype_land) {
            this.setState({
              stc_rmnotconstruct: val,
            });
          } else {
            if (
              !isNaN(rmnotconstruct) &&
              !isNaN(rmunderconstruct) &&
              !isNaN(rmfinished)
            ) {
              if (val === rmnotconstruct + rmunderconstruct + rmfinished) {
                this.setState({
                  stc_rmnotconstruct_State: "success",
                  stc_rmunderconstruct_State: "success",
                  stc_rmfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_rmnotconstruct_State: "error",
                  stc_rmunderconstruct_State: "error",
                  stc_rmfinished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(unit) &&
              !isNaN(notconstruct) &&
              !isNaN(underconstruct) &&
              !isNaN(finished)
            ) {
              if (unit === notconstruct + underconstruct + finished) {
                this.setState({
                  stc_unit_State: "success",
                  stc_notconstruct_State: "success",
                  stc_underconstruct_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_unit_State: "error",
                  stc_notconstruct_State: "error",
                  stc_underconstruct_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(sellsigned) && !isNaN(newsigned)) {
            if (sellsigned >= newsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned) && !isNaN(transfered)) {
            if (sellsigned >= transfered) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_remaining_State: "error",
    });
    return;
  };

  // ขายได้ใหม่ในช่วงสำรวจ
  stc_typeNewsigned = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(sellsigned)) {
            if (val === sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              stc_newsigned_State: "success",
            });
          } else {
            this.setState({
              stc_newsigned_State: "error",
            });
            return;
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished)) {
            if (finished >= 0) {
              this.setState({
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished >= 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(sellsigned)) {
            if (val === sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(sellsigned)) {
            if (val <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_newsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_newsigned_State: "error",
    });
    return;
  };

  // โอนแล้ว
  stc_typeTransfered = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(newtransfer)) {
            if (val === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (val <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(finished)) {
              if (val <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(sellfinished)) {
              if (val <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              stc_transfered_State: "success",
            });
          } else {
            this.setState({
              stc_transfered_State: "error",
            });
            return;
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished)) {
            if (finished >= 0) {
              this.setState({
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished >= 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(newtransfer)) {
            if (val === newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (val <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(finished)) {
              if (val <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(sellfinished)) {
              if (val <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          return;
        default:
          if (!isNaN(newtransfer)) {
            if (val >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (val <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(finished)) {
              if (val <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(sellfinished)) {
              if (val <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_transfered_State: "error",
    });
    return;
  };

  // โอนใหม่
  stc_typeNewtransfer = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(transfered)) {
            if (val === transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (unit === sellsigned + remaining) {
            this.setState({
              stc_unit_State: "success",
              stc_sellsigned_State: "success",
              stc_remaining_State: "success",
            });
          } else {
            this.setState({
              stc_unit_State: "error",
              stc_sellsigned_State: "error",
              stc_remaining_State: "error",
            });
            return;
          }
          if (!this.state.istype_land) {
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              stc_transfered_State: "success",
              stc_newtransfer_State: "success",
            });
          } else {
            this.setState({
              stc_transfered_State: "error",
              stc_newtransfer_State: "error",
            });
            return;
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished)) {
            if (finished >= 0) {
              this.setState({
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished >= 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(transfered)) {
            if (val === transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(transfered)) {
            if (val <= transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!this.state.istype_land) {
            if (!isNaN(transfered) && !isNaN(finished)) {
              if (transfered <= finished) {
                this.setState({
                  stc_transfered_State: "success",
                  stc_finished_State: "success",
                });
              } else {
                this.setState({
                  stc_transfered_State: "error",
                  stc_finished_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(sellfinished)) {
              if (transfered <= sellfinished) {
                this.setState({
                  stc_sellfinished_State: "success",
                  stc_transfered_State: "success",
                });
              } else {
                this.setState({
                  stc_sellfinished_State: "error",
                  stc_transfered_State: "error",
                });
                return;
              }
            }
            if (
              !isNaN(sellsigned) &&
              !isNaN(sellnotconstruct) &&
              !isNaN(sellunderconstruct) &&
              !isNaN(sellfinished)
            ) {
              if (
                sellsigned ===
                sellnotconstruct + sellunderconstruct + sellfinished
              ) {
                this.setState({
                  stc_sellsigned_State: "success",
                  stc_sellnotconstruct_State: "success",
                  stc_sellunderconstruct_State: "success",
                  stc_sellfinished_State: "success",
                });
              } else {
                this.setState({
                  stc_sellsigned_State: "error",
                  stc_sellnotconstruct_State: "error",
                  stc_sellunderconstruct_State: "error",
                  stc_sellfinished_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_newtransfer_State: "error",
    });
    return;
  };

  // ยังไม่สร้าง
  stc_typeNotconstruct = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let newconstruct = parseInt(props.rowData.stc_newconstruct);
    let newfinished = parseInt(props.rowData.stc_newfinished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (!isNaN(unit) && !isNaN(underconstruct) && !isNaN(finished)) {
            if (unit === val + underconstruct + finished) {
              this.setState({
                stc_unit_State: "success",
                stc_notconstruct_State: "success",
                stc_underconstruct_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_notconstruct_State: "error",
                stc_underconstruct_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(rmnotconstruct)) {
            if (val === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(newconstruct)) {
            if (underconstruct >= newconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_newconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_newconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(newfinished)) {
            if (finished >= newfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_newfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_newfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(unit) && !isNaN(underconstruct) && !isNaN(finished)) {
            if (unit === val + underconstruct + finished) {
              this.setState({
                stc_unit_State: "success",
                stc_notconstruct_State: "success",
                stc_underconstruct_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_notconstruct_State: "error",
                stc_underconstruct_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellnotconstruct) && !isNaN(rmnotconstruct)) {
            if (val === sellnotconstruct + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(underconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(rmunderconstruct)
          ) {
            if (underconstruct === sellunderconstruct + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(sellfinished) && !isNaN(rmfinished)) {
            if (finished === sellfinished + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(newconstruct)) {
            if (underconstruct >= newconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_newconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_newconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(newfinished)) {
            if (finished >= newfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_newfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_newfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(transfered)) {
            if (finished >= transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellfinished)) {
            if (transfered <= sellfinished) {
              this.setState({
                stc_sellfinished_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (
              sellsigned ===
              sellnotconstruct + sellunderconstruct + sellfinished
            ) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_notconstruct_State: "error",
    });
    return;
  };

  // กำลังสร้าง
  stc_typeUnderconstruct = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let newconstruct = parseInt(props.rowData.stc_newconstruct);
    let newfinished = parseInt(props.rowData.stc_newfinished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (!isNaN(unit) && !isNaN(notconstruct) && !isNaN(finished)) {
            if (unit === val + notconstruct + finished) {
              this.setState({
                stc_unit_State: "success",
                stc_notconstruct_State: "success",
                stc_underconstruct_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_notconstruct_State: "error",
                stc_underconstruct_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(rmunderconstruct)) {
            if (val === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newconstruct)) {
            if (val >= newconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_newconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_newconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(newfinished)) {
            if (finished >= newfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_newfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_newfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(unit) && !isNaN(notconstruct) && !isNaN(finished)) {
            if (unit === val + notconstruct + finished) {
              this.setState({
                stc_unit_State: "success",
                stc_notconstruct_State: "success",
                stc_underconstruct_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_notconstruct_State: "error",
                stc_underconstruct_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct) && !isNaN(rmunderconstruct)) {
            if (val === sellunderconstruct + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(notconstruct) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (notconstruct === sellnotconstruct + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(sellfinished) && !isNaN(rmfinished)) {
            if (finished === sellfinished + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newconstruct)) {
            if (val >= newconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_newconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_newconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(newfinished)) {
            if (finished >= newfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_newfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_newfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(transfered)) {
            if (finished >= transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellfinished)) {
            if (transfered <= sellfinished) {
              this.setState({
                stc_sellfinished_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (
              sellsigned ===
              sellnotconstruct + sellunderconstruct + sellfinished
            ) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_underconstruct_State: "error",
    });
    return;
  };

  // สร้างเสร็จ
  stc_typeFinished = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let newconstruct = parseInt(props.rowData.stc_newconstruct);
    let newfinished = parseInt(props.rowData.stc_newfinished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (!isNaN(unit) && !isNaN(notconstruct) && !isNaN(underconstruct)) {
            if (unit === val + notconstruct + underconstruct) {
              this.setState({
                stc_unit_State: "success",
                stc_notconstruct_State: "success",
                stc_underconstruct_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_notconstruct_State: "error",
                stc_underconstruct_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(rmfinished)) {
            if (val === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newfinished)) {
            if (val >= newfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_newfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_newfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(newconstruct)) {
            if (underconstruct >= newconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_newconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_newconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(unit) && !isNaN(notconstruct) && !isNaN(underconstruct)) {
            if (unit === val + notconstruct + underconstruct) {
              this.setState({
                stc_unit_State: "success",
                stc_notconstruct_State: "success",
                stc_underconstruct_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_notconstruct_State: "error",
                stc_underconstruct_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished) && !isNaN(rmfinished)) {
            if (val === sellfinished + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(notconstruct) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (notconstruct === sellnotconstruct + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(underconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(rmunderconstruct)
          ) {
            if (underconstruct === sellunderconstruct + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newfinished)) {
            if (val >= newfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_newfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_newfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(newconstruct)) {
            if (underconstruct >= newconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_newconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_newconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellfinished)) {
            if (transfered <= sellfinished) {
              this.setState({
                stc_sellfinished_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (
              sellsigned ===
              sellnotconstruct + sellunderconstruct + sellfinished
            ) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_finished_State: "error",
    });
    return;
  };

  // เริ่มสร้างใหม่
  stc_typeNewconstruct = (event, props) => {
    let val = parseInt(event);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      if (!isNaN(underconstruct)) {
        if (val <= underconstruct) {
          this.setState({
            stc_underconstruct_State: "success",
            stc_newconstruct_State: "success",
          });
        } else {
          this.setState({
            stc_underconstruct_State: "error",
            stc_newconstruct_State: "error",
          });
          return;
        }
      }
      return;
    }
    props.onChange("");
    this.setState({
      stc_newconstruct_State: "error",
    });
    return;
  };

  // สร้างเสร็จใหม่
  stc_typeNewfinished = (event, props) => {
    let val = parseInt(event);
    let finished = parseInt(props.rowData.stc_finished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      if (!isNaN(finished)) {
        if (val <= finished) {
          this.setState({
            stc_finished_State: "success",
            stc_newfinished_State: "success",
          });
        } else {
          this.setState({
            stc_finished_State: "error",
            stc_newfinished_State: "error",
          });
          return;
        }
      }
      return;
    }
    props.onChange("");
    this.setState({
      stc_newfinished_State: "error",
    });
    return;
  };

  // ขายได้ ยังไม่ก่อสร้าง
  stc_typeSellnotconstruct = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (val === 0) {
            this.setState({
              stc_sellnotconstruct_State: "success",
            });
          } else {
            this.setState({
              stc_sellnotconstruct_State: "error",
            });
            return;
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (sellsigned === val + sellunderconstruct + sellfinished) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === val + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(underconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(rmunderconstruct)
          ) {
            if (underconstruct === sellunderconstruct + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(sellfinished) && !isNaN(rmfinished)) {
            if (finished === sellfinished + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished) && !isNaN(transfered)) {
            if (sellfinished >= transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(finished)) {
            if (transfered <= finished) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_sellnotconstruct_State: "error",
    });
    return;
  };

  // ขายได้ กำลังสร้าง
  stc_typeSellunderconstruct = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (val === 0) {
            this.setState({
              stc_sellunderconstruct_State: "success",
            });
          } else {
            this.setState({
              stc_sellunderconstruct_State: "error",
            });
            return;
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }

          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (sellsigned === sellnotconstruct + val + sellfinished) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === val + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(notconstruct) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (notconstruct === sellnotconstruct + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(sellfinished) && !isNaN(rmfinished)) {
            if (finished === sellfinished + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished) && !isNaN(transfered)) {
            if (sellfinished >= transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(finished)) {
            if (transfered <= finished) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_sellunderconstruct_State: "error",
    });
    return;
  };

  // ขายได้ สร้างเสร็จ
  stc_typeSellfinished = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (val === 0) {
            this.setState({
              stc_sellfinished_State: "success",
            });
          } else {
            this.setState({
              stc_sellfinished_State: "error",
            });
            return;
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellunderconstruct)
          ) {
            if (sellsigned === sellnotconstruct + sellunderconstruct + val) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === val + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(notconstruct) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (notconstruct === sellnotconstruct + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(underconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(rmunderconstruct)
          ) {
            if (underconstruct === sellunderconstruct + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                stc_transfered_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(finished)) {
            if (transfered <= finished) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_sellfinished_State: "error",
    });
    return;
  };

  // เหลือขาย ยังไม่ก่อสร้าง
  stc_typeRmnotconstruct = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (
            !isNaN(remaining) &&
            !isNaN(rmunderconstruct) &&
            !isNaN(rmfinished)
          ) {
            if (remaining === val + rmunderconstruct + rmfinished) {
              this.setState({
                stc_remaining_State: "success",
                stc_rmnotconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_remaining_State: "error",
                stc_rmnotconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct)) {
            if (notconstruct === val) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (
            !isNaN(remaining) &&
            !isNaN(rmunderconstruct) &&
            !isNaN(rmfinished)
          ) {
            if (remaining === val + rmunderconstruct + rmfinished) {
              this.setState({
                stc_remaining_State: "success",
                stc_rmnotconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_remaining_State: "error",
                stc_rmnotconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(sellnotconstruct)) {
            if (notconstruct === sellnotconstruct + val) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(underconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(rmunderconstruct)
          ) {
            if (underconstruct === sellunderconstruct + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(sellfinished) && !isNaN(rmfinished)) {
            if (finished === sellfinished + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(finished)) {
            if (transfered <= finished) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellfinished)) {
            if (transfered <= sellfinished) {
              this.setState({
                stc_sellfinished_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (
              sellsigned ===
              sellnotconstruct + sellunderconstruct + sellfinished
            ) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_rmnotconstruct_State: "error",
    });
    return;
  };

  // เหลือขาย กำลังก่อสร้าง
  stc_typeRmunderconstruct = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmfinished = parseInt(props.rowData.stc_rmfinished);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (
            !isNaN(remaining) &&
            !isNaN(rmnotconstruct) &&
            !isNaN(rmfinished)
          ) {
            if (remaining === val + rmnotconstruct + rmfinished) {
              this.setState({
                stc_remaining_State: "success",
                stc_rmnotconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_remaining_State: "error",
                stc_rmnotconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct)) {
            if (underconstruct === val) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(rmfinished)) {
            if (finished === rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (
            !isNaN(remaining) &&
            !isNaN(rmnotconstruct) &&
            !isNaN(rmfinished)
          ) {
            if (remaining === val + rmnotconstruct + rmfinished) {
              this.setState({
                stc_remaining_State: "success",
                stc_rmnotconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_remaining_State: "error",
                stc_rmnotconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(sellunderconstruct)) {
            if (underconstruct === sellunderconstruct + val) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(notconstruct) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (notconstruct === sellnotconstruct + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(sellfinished) && !isNaN(rmfinished)) {
            if (finished === sellfinished + rmfinished) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(finished)) {
            if (transfered <= finished) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellfinished)) {
            if (transfered <= sellfinished) {
              this.setState({
                stc_sellfinished_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (
              sellsigned ===
              sellnotconstruct + sellunderconstruct + sellfinished
            ) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_rmunderconstruct_State: "error",
    });
    return;
  };

  // เหลือขาย สร้างเสร็จ
  stc_typeRmfinished = (event, props) => {
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.stc_unit);
    let sellsigned = parseInt(props.rowData.stc_sellsigned);
    let remaining = parseInt(props.rowData.stc_remaining);
    let transfered = parseInt(props.rowData.stc_transfered);
    let newtransfer = parseInt(props.rowData.stc_newtransfer);
    let notconstruct = parseInt(props.rowData.stc_notconstruct);
    let underconstruct = parseInt(props.rowData.stc_underconstruct);
    let finished = parseInt(props.rowData.stc_finished);
    let sellnotconstruct = parseInt(props.rowData.stc_sellnotconstruct);
    let sellunderconstruct = parseInt(props.rowData.stc_sellunderconstruct);
    let sellfinished = parseInt(props.rowData.stc_sellfinished);
    let rmnotconstruct = parseInt(props.rowData.stc_rmnotconstruct);
    let rmunderconstruct = parseInt(props.rowData.stc_rmunderconstruct);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(val).format("0"));
      switch (proStatus) {
        case 4:
          if (
            !isNaN(remaining) &&
            !isNaN(rmunderconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (remaining === rmnotconstruct + rmunderconstruct + val) {
              this.setState({
                stc_remaining_State: "success",
                stc_rmnotconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_remaining_State: "error",
                stc_rmnotconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished)) {
            if (finished === val) {
              this.setState({
                stc_finished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(notconstruct) && !isNaN(rmnotconstruct)) {
            if (notconstruct === rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(underconstruct) && !isNaN(rmunderconstruct)) {
            if (underconstruct === rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellsigned)) {
            if (sellsigned === 0) {
              this.setState({
                stc_sellsigned_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newtransfer)) {
            if (newtransfer === 0) {
              this.setState({
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellnotconstruct)) {
            if (sellnotconstruct === 0) {
              this.setState({
                stc_sellnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellnotconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellunderconstruct)) {
            if (sellunderconstruct === 0) {
              this.setState({
                stc_sellunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_sellunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(sellfinished)) {
            if (sellfinished === 0) {
              this.setState({
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (
            !isNaN(remaining) &&
            !isNaN(rmunderconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (remaining === rmnotconstruct + rmunderconstruct + val) {
              this.setState({
                stc_remaining_State: "success",
                stc_rmnotconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_remaining_State: "error",
                stc_rmnotconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(finished) && !isNaN(sellfinished)) {
            if (finished === sellfinished + val) {
              this.setState({
                stc_finished_State: "success",
                stc_sellfinished_State: "success",
                stc_rmfinished_State: "success",
              });
            } else {
              this.setState({
                stc_finished_State: "error",
                stc_sellfinished_State: "error",
                stc_rmfinished_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(notconstruct) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(rmnotconstruct)
          ) {
            if (notconstruct === sellnotconstruct + rmnotconstruct) {
              this.setState({
                stc_notconstruct_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_rmnotconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_notconstruct_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_rmnotconstruct_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(underconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(rmunderconstruct)
          ) {
            if (underconstruct === sellunderconstruct + rmunderconstruct) {
              this.setState({
                stc_underconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_rmunderconstruct_State: "success",
              });
            } else {
              this.setState({
                stc_underconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_rmunderconstruct_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(finished)) {
            if (transfered <= finished) {
              this.setState({
                stc_transfered_State: "success",
                stc_finished_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_finished_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellfinished)) {
            if (transfered <= sellfinished) {
              this.setState({
                stc_sellfinished_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellfinished_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                stc_transfered_State: "success",
                stc_newtransfer_State: "success",
              });
            } else {
              this.setState({
                stc_transfered_State: "error",
                stc_newtransfer_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(sellsigned)) {
            if (transfered <= sellsigned) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_transfered_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(unit) && !isNaN(sellsigned) && !isNaN(remaining)) {
            if (unit === sellsigned + remaining) {
              this.setState({
                stc_unit_State: "success",
                stc_sellsigned_State: "success",
                stc_remaining_State: "success",
              });
            } else {
              this.setState({
                stc_unit_State: "error",
                stc_sellsigned_State: "error",
                stc_remaining_State: "error",
              });
              return;
            }
          }
          if (
            !isNaN(sellsigned) &&
            !isNaN(sellnotconstruct) &&
            !isNaN(sellunderconstruct) &&
            !isNaN(sellfinished)
          ) {
            if (
              sellsigned ===
              sellnotconstruct + sellunderconstruct + sellfinished
            ) {
              this.setState({
                stc_sellsigned_State: "success",
                stc_sellnotconstruct_State: "success",
                stc_sellunderconstruct_State: "success",
                stc_sellfinished_State: "success",
              });
            } else {
              this.setState({
                stc_sellsigned_State: "error",
                stc_sellnotconstruct_State: "error",
                stc_sellunderconstruct_State: "error",
                stc_sellfinished_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      stc_rmfinished_State: "error",
    });
    return;
  };

  // trigger function
  stc_priceRange = (event) => {
    let arraycat_price = this.state.part2_catprice;
    // part2_stcprice
    let match_val = arraycat_price.filter(function(obj) {
      return obj.value === event;
    });

    let body = {
      srpprice_cat: 3,
      srpprice_max: match_val[0].price_max,
      srpprice_min: match_val[0].price_min,
    };

    let request = new Request(config.API_getprice, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let response_array = responseJson.data;
        switch (responseJson.code) {
          case 200:
            if (response_array.length === 0) {
              //
            } else {
              this.setState({
                stc_pricerange: response_array,
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  stc_setUnit = (event, props) => {
    let arraycat_unit = this.state.part2_allunit;

    let addedData = this.state.stc_detail;
    let all_type = this.state.part2_cattype;

    let unit_value = arraycat_unit.filter(function(obj) {
      return obj.value === event;
    });

    let match_val = all_type.filter(function(obj) {
      return obj.value === unit_value[0].value;
    });

    let match_result = addedData.filter(function(obj) {
      return obj.stc_type === match_val[0].label;
    });

    let rowmaxUnit = unit_value[0].unit_type;
    for (let i = 0; i < match_result.length; i++) {
      rowmaxUnit = parseInt(rowmaxUnit) - parseInt(match_result[i].stc_unit);
    }

    switch (match_result.length) {
      case 0:
        this.setState({
          stc_maxUnit: unit_value,
          tooltip_stc_unit:
            "หน่วยประเภทบ้านที่เลือก รวมกันห้ามเกิน " + unit_value[0].unit_type,
          stc_unit_placeholder: "หน่วยสูงสุด = " + unit_value[0].unit_type,
        });
        break;
      default:
        this.setState({
          stc_maxUnit: [{ value: unit_value[0].value, unit_type: rowmaxUnit }],
          tooltip_stc_unit:
            "หน่วยประเภทบ้านที่เลือก รวมกันห้ามเกิน " + rowmaxUnit,
          stc_unit_placeholder: "หน่วยสูงสุด = " + rowmaxUnit,
        });
        break;
    }
  };

  filter_typeval = (data) => {
    let arraycat_type = this.state.part2_cattype;
    let match_val = arraycat_type.filter(function(obj) {
      return obj.value === data;
    });
    return match_val[0].label;
  };
  filter_typeval_reverse = (label) => {
    let arraycat_type = this.state.part2_cattype;
    let match_val = arraycat_type.filter(function(obj) {
      return obj.label === label;
    });
    if (match_val.length > 0) {
      return match_val[0].value;
    } else {
      return null;
    }
  };
  checkDataInRowBeforeAdd = (rawData) => {
    let {
      stc_type_State,
      stc_floor_State,
      stc_pricelenid_State,
      stc_unit_State,
      stc_sellsigned_State,
      stc_remaining_State,
      stc_newsigned_State,
      stc_transfered_State,
      stc_newtransfer_State,
      stc_notconstruct_State,
      stc_underconstruct_State,
      stc_finished_State,
      stc_newconstruct_State,
      stc_newfinished_State,
      stc_sellnotconstruct_State,
      stc_sellunderconstruct_State,
      stc_sellfinished_State,
      stc_rmnotconstruct_State,
      stc_rmunderconstruct_State,
      stc_rmfinished_State,
    } = this.state;
    if (
      stc_type_State === "error" ||
      stc_floor_State === "error" ||
      stc_pricelenid_State === "error" ||
      stc_unit_State === "error" ||
      stc_sellsigned_State === "error" ||
      stc_remaining_State === "error" ||
      stc_newsigned_State === "error" ||
      stc_transfered_State === "error" ||
      stc_newtransfer_State === "error" ||
      stc_notconstruct_State === "error" ||
      stc_underconstruct_State === "error" ||
      stc_finished_State === "error" ||
      stc_newconstruct_State === "error" ||
      stc_newfinished_State === "error" ||
      stc_sellnotconstruct_State === "error" ||
      stc_sellunderconstruct_State === "error" ||
      stc_sellfinished_State === "error" ||
      stc_rmnotconstruct_State === "error" ||
      stc_rmunderconstruct_State === "error" ||
      stc_rmfinished_State === "error"
    ) {
      this.showNotification("warning", "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก");
      return false;
    } else {
      this.seperateLimitData(rawData);
      return true;
    }
  };
  checkDataInRowBeforeEdit = (rawData) => {
    let {
      stc_unit_State,
      stc_sellsigned_State,
      stc_remaining_State,
      stc_newsigned_State,
      stc_transfered_State,
      stc_newtransfer_State,
      stc_notconstruct_State,
      stc_underconstruct_State,
      stc_finished_State,
      stc_newconstruct_State,
      stc_newfinished_State,
      stc_sellnotconstruct_State,
      stc_sellunderconstruct_State,
      stc_sellfinished_State,
      stc_rmnotconstruct_State,
      stc_rmunderconstruct_State,
      stc_rmfinished_State,
    } = this.state;
    if (
      stc_unit_State === "error" ||
      stc_sellsigned_State === "error" ||
      stc_remaining_State === "error" ||
      stc_newsigned_State === "error" ||
      stc_transfered_State === "error" ||
      stc_newtransfer_State === "error" ||
      stc_notconstruct_State === "error" ||
      stc_underconstruct_State === "error" ||
      stc_finished_State === "error" ||
      stc_newconstruct_State === "error" ||
      stc_newfinished_State === "error" ||
      stc_sellnotconstruct_State === "error" ||
      stc_sellunderconstruct_State === "error" ||
      stc_sellfinished_State === "error" ||
      stc_rmnotconstruct_State === "error" ||
      stc_rmunderconstruct_State === "error" ||
      stc_rmfinished_State === "error"
    ) {
      this.showNotification("warning", "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก");
      return false;
    } else {
      this.seperateLimitData(rawData);
      return true;
    }
  };
  async call_merge_sec3() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.merge_data());
      }, 1000);
    });
  }

  merge_data() {
    let stc_detail_obj = {};
    if (this.state.stc_detail.length > 0) {
      this.state.stc_detail.map(function(value) {
        return section3keys.forEach(function(i) {
          if (!stc_detail_obj[i]) {
            stc_detail_obj[i] = [];
          }
          if (!(i in value)) {
            value[i] = Number(0);
          } else {
            if (i !== "stc_type") {
              value[i] = Number(value[i]);
            } else if (i === "stc_type") {
              value[i] = String(value[i]);
            }
          }
          stc_detail_obj[i].push(value[i]);
        });
      });
    }
    let homedata_section3 = {
      stc_type:
        stc_detail_obj && "stc_type" in stc_detail_obj
          ? stc_detail_obj["stc_type"]
          : [],
      stc_pricelenid:
        stc_detail_obj && "stc_pricelenid" in stc_detail_obj
          ? stc_detail_obj["stc_pricelenid"]
          : [],
      stc_unit:
        stc_detail_obj && "stc_unit" in stc_detail_obj
          ? stc_detail_obj["stc_unit"]
          : [],
      stc_sellsigned:
        stc_detail_obj && "stc_sellsigned" in stc_detail_obj
          ? stc_detail_obj["stc_sellsigned"]
          : [],
      stc_remaining:
        stc_detail_obj && "stc_remaining" in stc_detail_obj
          ? stc_detail_obj["stc_remaining"]
          : [],
      stc_newsigned:
        stc_detail_obj && "stc_newsigned" in stc_detail_obj
          ? stc_detail_obj["stc_newsigned"]
          : [],
      stc_transfered:
        stc_detail_obj && "stc_transfered" in stc_detail_obj
          ? stc_detail_obj["stc_transfered"]
          : [],
      stc_newtransfer:
        stc_detail_obj && "stc_newtransfer" in stc_detail_obj
          ? stc_detail_obj["stc_newtransfer"]
          : [],
      stc_notconstruct:
        stc_detail_obj && "stc_notconstruct" in stc_detail_obj
          ? stc_detail_obj["stc_notconstruct"]
          : [],
      stc_underconstruct:
        stc_detail_obj && "stc_underconstruct" in stc_detail_obj
          ? stc_detail_obj["stc_underconstruct"]
          : [],
      stc_finished:
        stc_detail_obj && "stc_finished" in stc_detail_obj
          ? stc_detail_obj["stc_finished"]
          : [],
      stc_newconstruct:
        stc_detail_obj && "stc_newconstruct" in stc_detail_obj
          ? stc_detail_obj["stc_newconstruct"]
          : [],
      stc_newfinished:
        stc_detail_obj && "stc_newfinished" in stc_detail_obj
          ? stc_detail_obj["stc_newfinished"]
          : [],
      stc_sellnotconstruct:
        stc_detail_obj && "stc_sellnotconstruct" in stc_detail_obj
          ? stc_detail_obj["stc_sellnotconstruct"]
          : [],
      stc_sellunderconstruct:
        stc_detail_obj && "stc_sellunderconstruct" in stc_detail_obj
          ? stc_detail_obj["stc_sellunderconstruct"]
          : [],
      stc_sellfinished:
        stc_detail_obj && "stc_sellfinished" in stc_detail_obj
          ? stc_detail_obj["stc_sellfinished"]
          : [],
      stc_rmnotconstruct:
        stc_detail_obj && "stc_rmnotconstruct" in stc_detail_obj
          ? stc_detail_obj["stc_rmnotconstruct"]
          : [],
      stc_rmunderconstruct:
        stc_detail_obj && "stc_rmunderconstruct" in stc_detail_obj
          ? stc_detail_obj["stc_rmunderconstruct"]
          : [],
      stc_rmfinished:
        stc_detail_obj && "stc_rmfinished" in stc_detail_obj
          ? stc_detail_obj["stc_rmfinished"]
          : [],
    };
    this.props.villa_sec3Object(homedata_section3);
    this.props.villaSec3_Object(homedata_section3);

    return homedata_section3;
  }

  async isValidated() {
    let body = await this.call_merge_sec3();
    let limit_value = this.state.price_limit;
    let selectd_value = this.state.price_selected;
    let proj_status = this.state.part1_projectStatus;

    let result_ja = true;
    let sumunit = 0;
    let sumunit_remain = 0;
    let sumunit_sellsigned = 0;
    let sumunit_newsigned = 0;
    let sumunit_transfered = 0;
    let sumunit_newtransfer = 0;
    let conditionCase = "";

    for (let i = 0; i < limit_value.length; i++) {
      if (limit_value[i].length !== 0) {
        let min = limit_value[i].price_id[0];
        let max = limit_value[i].price_id[1];
        if (min && selectd_value[i].price_id.indexOf(min) === -1) {
          conditionCase = "no_uplow";
          result_ja = false;
        }

        if (max && selectd_value[i].price_id.indexOf(max) === -1) {
          conditionCase = "no_uplow";
          result_ja = false;
        }
      }
    }
    if (result_ja) {
      for (let j = 0; j < body.stc_unit.length; j++) {
        sumunit = sumunit + parseInt(body.stc_unit[j]);
        sumunit_remain = sumunit_remain + parseInt(body.stc_remaining[j]);
        sumunit_sellsigned =
          sumunit_sellsigned + parseInt(body.stc_sellsigned[j]);
        sumunit_newsigned = sumunit_newsigned + parseInt(body.stc_newsigned[j]);
        sumunit_transfered =
          sumunit_transfered + parseInt(body.stc_transfered[j]);
        sumunit_newtransfer =
          sumunit_newtransfer + parseInt(body.stc_newtransfer[j]);

        if (proj_status === 1) {
          if (
            parseInt(body.stc_newsigned[j]) > parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_transfered[j]) > parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_newtransfer[j]) > parseInt(body.stc_transfered[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          }
        } else if (proj_status === 2) {
          if (
            parseInt(body.stc_newsigned[j]) > parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_transfered[j]) > parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_newtransfer[j]) > parseInt(body.stc_transfered[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          }
        } else if (proj_status === 3) {
          if (
            parseInt(body.stc_newsigned[j]) !== parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_transfered[j]) > parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_newtransfer[j]) !==
            parseInt(body.stc_transfered[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          }
        } else if (proj_status === 5) {
          if (parseInt(body.stc_newsigned[j]) !== 0) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_transfered[j]) > parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_newtransfer[j]) > parseInt(body.stc_transfered[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          }
        } else if (proj_status === 6) {
          if (
            parseInt(body.stc_newsigned[j]) !== parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_transfered[j]) > parseInt(body.stc_sellsigned[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          } else if (
            parseInt(body.stc_newtransfer[j]) !==
            parseInt(body.stc_transfered[j])
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          }
        }
      }

      if (result_ja) {
        if (sumunit === this.state.part2_maxUnit) {
          conditionCase = "";
          result_ja = true;
        } else {
          conditionCase = "no_maxunit";
          result_ja = false;
        }
        if (
          (proj_status === 2 || proj_status === 3 || proj_status === 5) &&
          sumunit_remain === 0
        ) {
          conditionCase = "remain_more";
          result_ja = false;
        }
        if ((proj_status === 1 || proj_status === 6) && sumunit_remain >= 1) {
          conditionCase = "remain_less";
          result_ja = false;
        }
        if (
          proj_status === 4 &&
          (sumunit_sellsigned !== 0 ||
            sumunit_newsigned !== 0 ||
            sumunit_transfered !== 0 ||
            sumunit_newtransfer !== 0)
        ) {
          conditionCase = "not_zero";
          result_ja = false;
        }
      }
    }
    switch (result_ja) {
      case true:
        if (
          body.stc_type.length === 0 ||
          body.stc_pricelenid.length === 0 ||
          body.stc_unit.length === 0 ||
          body.stc_sellsigned.length === 0 ||
          body.stc_remaining.length === 0 ||
          body.stc_newsigned.length === 0 ||
          body.stc_transfered.length === 0 ||
          body.stc_newtransfer.length === 0 ||
          body.stc_notconstruct.length === 0 ||
          body.stc_underconstruct.length === 0 ||
          body.stc_finished.length === 0 ||
          body.stc_newconstruct.length === 0 ||
          body.stc_newfinished.length === 0 ||
          body.stc_sellnotconstruct.length === 0 ||
          body.stc_sellunderconstruct.length === 0 ||
          body.stc_sellfinished.length === 0 ||
          body.stc_rmnotconstruct.length === 0 ||
          body.stc_rmunderconstruct.length === 0 ||
          body.stc_rmfinished.length === 0
        ) {
          this.surveyModal("error");
          return false;
        } else {
          return true;
        }
      default:
        this.surveyModal_lock(conditionCase);
        return false;
    }
  }

  async submit_save() {
    this.setState({
      btLoading: true,
    });
    let token = this.state.token;
    let part1 = this.props.villaSec1Object;
    let part2 = this.props.villaSec2Object;
    let body = await this.call_merge_sec3();

    if (
      body.stc_type.length === 0 ||
      body.stc_pricelenid.length === 0 ||
      body.stc_unit.length === 0 ||
      body.stc_sellsigned.length === 0 ||
      body.stc_remaining.length === 0 ||
      body.stc_newsigned.length === 0 ||
      body.stc_transfered.length === 0 ||
      body.stc_newtransfer.length === 0 ||
      body.stc_notconstruct.length === 0 ||
      body.stc_underconstruct.length === 0 ||
      body.stc_finished.length === 0 ||
      body.stc_newconstruct.length === 0 ||
      body.stc_newfinished.length === 0 ||
      body.stc_sellnotconstruct.length === 0 ||
      body.stc_sellunderconstruct.length === 0 ||
      body.stc_sellfinished.length === 0 ||
      body.stc_rmnotconstruct.length === 0 ||
      body.stc_rmunderconstruct.length === 0 ||
      body.stc_rmfinished.length === 0
    ) {
      this.surveyModal("save");
      this.setState({
        btLoading: false,
      });
    } else {
      let source = {
        ...part1,
        ...part2,
        ...body,
      };

      let target = this.props.villa_initial_data;
      let log_villa_sec_3 = { event: "Insert or update villa section 3" };
      let mergebody = Object.assign(target, source, log_villa_sec_3);

      let request = new Request(config.API_save_villa, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: token,
        }),
        body: JSON.stringify(mergebody),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            btLoading: false,
          });
          if (responseJson.code === 200) {
            this.alertModal("success");
          }
        })
        .catch(() => {
          this.setState({
            btLoading: false,
          });
        });
    }
  }

  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          onConfirm={() => this.setState({ alert: null })}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={"ยืนยัน"}
          cancelBtnText={"ยกเลิก"}
        >
          {message}
        </SweetAlert>
      ),
    });
  }
  handleResetSt = (idx) => () => {
    let tempBs = this.state.stc_detail;

    for (let prop in tempBs[idx]) {
      if (
        prop !== "id" &&
        prop !== "stc_type" &&
        prop !== "stc_floor" &&
        prop !== "stc_width" &&
        prop !== "stc_pricelenid" &&
        prop !== "stc_minprice" &&
        prop !== "stc_maxprice"
      ) {
        tempBs[idx][prop] = "";
      }
    }

    this.setState({
      stc_detail: tempBs,
    });
  };

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  resetAll_State() {
    this.setState({
      stc_type_State: "error",
      stc_floor_Data: "",
      stc_floor_State: "",
      stc_pricelenid_State: "",
      stc_unit_State: "",
      stc_sellsigned_State: "",
      stc_remaining_State: "",
      stc_newsigned_State: "",
      stc_transfered_State: "",
      stc_newtransfer_State: "",
      stc_notconstruct_State: "",
      stc_underconstruct_State: "",
      stc_finished_State: "",
      stc_newconstruct_State: "",
      stc_newfinished_State: "",
      stc_sellnotconstruct_State: "",
      stc_sellunderconstruct_State: "",
      stc_sellfinished_State: "",
      stc_rmnotconstruct_State: "",
      stc_rmunderconstruct_State: "",
      stc_rmfinished_State: "",
      disabled_stcUnit: false,
      tooltip_stc_unit: "",
      stc_maxUnit: [],
    });
  }

  sendState() {
    return this.state;
  }

  blockEdit(isEdit) {
    if (isEdit) {
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            zIndex: "10000",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            cursor: "not-allowed",
          }}
        ></div>
      );
    } else {
      //
    }
  }

  render() {
    const bt_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "120px",
      height: "40px",
      fontSize: "16px",
      borderRadius: "0.2rem",
      backgroundColor: "#384D97",
      color: "white",
    };
    const StyleHeader = {
      border: "1px solid #dddddd",
      textAlign: "center",
      maxWidth: "fit-content",
      borderLeft: "none",
      width: "6px",
      fontSize: "12px",
    };
    const StyleSubHeader = {
      border: "1px solid #dddddd",
      textAlign: "center",
      maxWidth: "fit-content",
      borderLeft: "none",
      fontSize: "12px",
    };
    const StyleTHeader = {
      fontSize: "12px",
      width: "100%",
      borderCollapse: "collapse",
      whiteSpace: "nowrap",
      textAlign: "center",
    };
    return (
      <GridContainer style={{ padding: "15px" }}>
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>ข้อมูลการขาย การโอนกรรมสิทธิ์ และการก่อสร้าง</b>
          </h4>
          <ListItem xs={6}>
            <ListItemText
              primary={<Typography variant="caption">สถานะโครงการ</Typography>}
              secondary={this.getProjectStatus()}
            ></ListItemText>
          </ListItem>
          <MaterialTable
            style={{
              width: "100%",
              backgroundColor: "#fff",
              textAlign: "center",
            }}
            components={{
              Header: (props) => (
                <thead style={StyleTHeader}>
                  <tr>
                    <td rowSpan="2" colSpan="2" style={{ ...StyleHeader }}>
                      ประเภท / ราคา
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      หน่วยในผังทั้งหมด
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      ขายได้สะสม
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      เหลือขาย
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      ขายได้ใหม่
                      <br />
                      ในช่วงสำรวจ
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      โอนแล้ว
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      โอนใหม่
                    </td>
                    <td colSpan="3" style={StyleHeader}>
                      การก่อสร้างของหน่วยในผัง
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      เริ่มสร้าง
                      <br />
                      ใหม่
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      สร้างเสร็จ
                      <br />
                      ใหม่
                    </td>
                    <td colSpan="3" style={StyleHeader}>
                      การก่อสร้างของหน่วยที่ขายได้
                    </td>
                    <td colSpan="3" style={StyleHeader}>
                      การก่อสร้างของหน่วยที่เหลือขาย
                    </td>
                    <td
                      rowSpan="3"
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "center",
                        borderRight: "none",
                      }}
                    >
                      การจัดการ
                    </td>
                  </tr>
                  <tr>
                    <td style={StyleHeader}>ยังไม่สร้าง</td>
                    <td style={StyleHeader}>กำลังสร้าง</td>
                    <td style={StyleHeader}>สร้างเสร็จ</td>
                    <td style={StyleHeader}>ยังไม่สร้าง</td>
                    <td style={StyleHeader}>กำลังสร้าง</td>
                    <td style={StyleHeader}>สร้างเสร็จ</td>
                    <td style={StyleHeader}>ยังไม่สร้าง</td>
                    <td style={StyleHeader}>กำลังสร้าง</td>
                    <td style={StyleHeader}>สร้างเสร็จ</td>
                  </tr>
                  <tr>
                    <td rowSpan="1" style={StyleHeader}>
                      ประเภท
                    </td>
                    <td rowSpan="1" style={StyleHeader}>
                      ราคา (บาท)
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_unit).format("0,0") +
                        " หน่วย / " +
                        numeral(this.state.part2_maxUnit).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_sellsigned).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_remaining).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_newsigned).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_transfered).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_newtransfer).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_notconstruct).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_underconstruct).format(
                        "0,0"
                      ) + " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_finished).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_newconstruct).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_newfinished).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_sellnotconstruct).format(
                        "0,0"
                      ) + " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_sellunderconstruct).format(
                        "0,0"
                      ) + " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_sellfinished).format("0,0") +
                        " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_rmnotconstruct).format(
                        "0,0"
                      ) + " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_rmunderconstruct).format(
                        "0,0"
                      ) + " หน่วย"}
                    </td>
                    <td style={StyleSubHeader}>
                      {numeral(this.state.Sum_stc_rmfinished).format("0,0") +
                        " หน่วย"}
                    </td>
                  </tr>
                </thead>
              ),
            }}
            localization={{
              header: {
                actions: "การจัดการ",
              },
              body: {
                addTooltip: "เพิ่มข้อมูล",
                editTooltip: "แก้ไขข้อมูล",
                deleteTooltip: "ลบข้อมูล",
                emptyDataSourceMessage: "ไม่มีข้อมูล",
                editRow: {
                  deleteText: "ยืนยันการลบข้อมูล",
                  saveTooltip: "บันทึก",
                  cancelTooltip: "ยกเลิก",
                },
              },
              toolbar: {
                searchTooltip: "ค้นหา",
                searchPlaceholder: "ค้นหา",
              },
              pagination: {
                labelRowsSelect: "แถว",
                labelDisplayedRows: " {from}-{to} จาก {count}",
                firstTooltip: "หน้าแรก",
                previousTooltip: "ก่อนหน้า",
                nextTooltip: "ถัดไป",
                lastTooltip: "สุดท้าย",
              },
            }}
            title=""
            columns={this.state.column}
            data={this.state.stc_detail}
            options={{
              pageSize: this.state.stc_detail
                ? this.state.stc_detail.length
                : 10,
              pageSizeOptions: [
                10,
                20,
                30,
                this.state.stc_detail ? this.state.stc_detail.length : 50,
              ],

              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0
                    ? "rgba(0, 0, 0, 0.03)"
                    : "#FFF",
              }),
              headerStyle: {
                fontSize: "0.9rem",
                fontWeight: "600",
              },
            }}
            icons={{
              Add: () => (
                <div style={bt_style}>
                  <Add style={{ marginRight: "10px" }} />
                  เพิ่มข้อมูล
                </div>
              ),
            }}
            editable={
              !(
                this.state.projectStatus === 1 ||
                this.state.projectStatus === 3 ||
                this.state.memberStatus === 2
              )
                ? {
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        section3keys.forEach((row) => {
                          if (!(row in newData)) {
                            newData[row] = "0";
                          }
                        });
                        if (this.state.istype_land) {
                          newData.stc_notconstruct = newData.stc_unit;
                          newData.stc_sellnotconstruct = newData.stc_sellsigned;
                          newData.stc_rmnotconstruct = newData.stc_remaining;
                        }
                        let unmappedType = newData.stc_type;
                        newData.stc_type = this.filter_typeval(
                          newData.stc_type
                        );
                        if (this.checkDataInRowBeforeAdd(newData)) {
                          setTimeout(() => {
                            this.resetAll_State();
                            let stc_detail = this.state.stc_detail;
                            newData.id = stc_detail.length;
                            stc_detail.push(newData);
                            this.setState({ stc_detail }, () => {
                              resolve();
                            });

                            this.sumallUnit(stc_detail);
                            this.resetAll_State();
                            resolve();
                          }, 1000);
                        } else {
                          newData.stc_type = unmappedType;
                          this.showNotification(
                            "warning",
                            "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                          );
                          reject();
                        }
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        section3keys.forEach((row) => {
                          if (!(row in newData)) {
                            newData[row] = "0";
                          }
                        });
                        if (this.state.istype_land) {
                          newData.stc_notconstruct = newData.stc_unit;
                          newData.stc_sellnotconstruct = newData.stc_sellsigned;
                          newData.stc_rmnotconstruct = newData.stc_remaining;
                        }
                        let stc_detail = this.state.stc_detail;
                        let index = stc_detail.indexOf(oldData);
                        if (this.checkDataInRowBeforeEdit(newData)) {
                          setTimeout(() => {
                            this.resetAll_State();
                            this.editSelectedPrice(newData, oldData);
                            stc_detail[index] = newData;
                            // console.log(stc_detail);
                            this.setState({ stc_detail }, () => {
                              resolve();
                            });
                            this.sumallUnit(stc_detail);
                            this.resetAll_State();
                            this.setState({ istype_land: false });
                            resolve();
                          }, 1000);
                        } else {
                          newData.tableData = {
                            id: index,
                          };
                          this.showNotification(
                            "warning",
                            "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                          );
                          reject();
                        }
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          let stc_detail = this.state.stc_detail;
                          let index = stc_detail.indexOf(oldData);
                          this.removeSelectedPrice(oldData);
                          stc_detail.splice(index, 1);
                          this.setState({ stc_detail }, () => {
                            resolve();
                          });

                          this.sumallUnit(stc_detail);
                          this.resetAll_State();
                          resolve();
                        }, 1000);
                      }),
                  }
                : {}
            }
          />
        </GridItem>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "0 15px",
            marginTop: "10px",
          }}
        >
          {(this.state.projectStatus !== 3 ||
            this.state.view_psurvey === false) &&
          this.isEditable() &&
          !this.isResubmiting() ? (
            <Button
              color="info"
              size="sm"
              style={{ fontSize: "16px" }}
              onClick={() => this.submit_save()}
              disabled={this.state.btLoading}
            >
              {this.loadingButton(this.state.btLoading)}
              บันทึก
            </Button>
          ) : null}
        </div>
      </GridContainer>
    );
  }
}

VillaStep3.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  surveyInfo: (payload) => dispatch(surveyInfo(payload)),
  villa_sec3Object: (payload) => dispatch(villa_sec3Object(payload)),
  villaSec3_Object: (payload) => dispatch(villaSec3_Object(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(VillaStep3));
