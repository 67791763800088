export function htmlTablePage(page, tableCode) {
  return `<div class="container">
<section style="margin: 15mm 5mm 0mm 5mm;">
    <div id="logo">
        <img src="https://admin.reicth.com/Hbg/images/logo/logo-txt.png" />
    </div>
    <div id="company">
    <h2 class="nameHead">ศูนย์ข้อมูลอสังหาริมทรัพย์</h2>
    <div class="name">ชั้น 18 อาคาร 2 เลขที่ 63 ถ.พระราม 9 ห้วยขวาง กรุงเทพ 10310</div>
    <div class="name">โทร. 02-202-1768 โทรสาร 02-643-1251-2</div>
    </div>
    <div class="clearfix"></div>
    <span>
    <h2 style="color: #0087C3; font-size:10px; clear: both;">
    printVal.refcode
    : รายละเอียด
    printVal.nameth
    </h2>
    </span>
    ${tableCode}
</section>
<!--จบหน้าสอง-->
</div>`;
}
