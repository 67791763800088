import Account from "@material-ui/icons/AccountBox";
import Equalizer from "@material-ui/icons/Equalizer";
import FAQicon from "@material-ui/icons/ContactSupport";
import Home from "@material-ui/icons/Home";
import Image from "@material-ui/icons/Image";
import File from "@material-ui/icons/InsertDriveFile";
import PermPhoneMsg from "@material-ui/icons/PermPhoneMsg";
import ManualIcon from "@material-ui/icons/CollectionsBookmark";
import GHDashboard from "views/Dashboard/GHdashboard.js";
import Charts from "views/GHBank/Contact.js";
import FAQ from "views/GHBank/FAQ.js";
import Manual from "views/GHBank/Manual.js";
import Report from "views/GHBank/Report.js";
// import ManageProject from "views/ManageProject/ManageProject.js";
import ManageImage from "views/ManageImage/ManageImage.js";
import ManageFile from "views/ManageFile/ManageFile.js";
import ManageUser from "views/ManageUser/ManageUser.js";
import AddAccount from "views/Pages/AddAccount.js";
import ForgetPasswordPage from "views/Pages/ForgetPassword.js";
import LoginPage from "views/Pages/LoginPage.js";
import NewPasswordPage from "views/Pages/NewPassword.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import UserProfile from "views/Pages/UserProfile.js";
import GHProject from "views/Project/GHproject.js";
import GHProjectQC from "views/ProjectQC/GHproject.js";
import ImageIcon from "@material-ui/icons/Image";
import WebAsset from "@material-ui/icons/WebAsset";
import Assignment from "@material-ui/icons/Assignment";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    thName: "หน้าหลัก",
    icon: Home,
    component: GHDashboard,
    layout: "",
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    invisible: true,
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/reset-page",
        name: "Reset password Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ForgetPasswordPage,
        layout: "/auth",
      },
      {
        path: "/",
        name: "Reset password Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: NewPasswordPage,
        layout: "/reset",
        invisible: true,
      },
    ],
  },
  {
    path: "/user-page",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    thName: "ข้อมูล",
    mini: "UP",
    rtlMini: "شع",
    component: UserProfile,
    layout: "",
    invisible: true,
  },
  {
    path: "/project",
    name: "Project",
    rtlName: "الحاجيات",
    thName: "โครงการ",
    icon: WebAsset,
    component: GHProject,
    layout: "",
  },
  // {
  //   path: "/manageproject",
  //   name: "Manage Project",
  //   rtlName: "لوحة القيادة",
  //   thName: "จัดการโครงการ",
  //   icon: Tab,
  //   component: ManageProject,
  //   layout: ""
  // },
  {
    path: "/manageimage",
    name: "Manage Image",
    rtlName: "لوحة القيادة",
    thName: "จัดการรูปภาพ",
    icon: ImageIcon,
    component: ManageImage,
    layout: "",
  },
  {
    path: "/managefile",
    name: "Manage File",
    rtlName: "لوحة القيادة",
    thName: "จัดการไฟล์",
    icon: File,
    component: ManageFile,
    layout: "",
  },
  {
    path: "/manageuser",
    name: "Manage User",
    rtlName: "الحاجيات",
    thName: "จัดการผู้ใช้งาน",
    icon: Account,
    component: ManageUser,
    layout: "",
  },
  {
    path: "/report",
    name: "Report",
    rtlName: "الحاجيات",
    thName: "รายงาน",
    icon: Equalizer,
    component: Report,
    layout: "",
  },
  {
    path: "/QC",
    name: "QC",
    rtlName: "الحاجيات",
    thName: "QC โครงการ",
    icon: Assignment,
    component: GHProjectQC,
    layout: "",
  },
  {
    path: "/faq",
    name: "FAQ",
    rtlName: "الرسوم البيانية",
    thName: "คำถามที่พบบ่อย",
    icon: FAQicon,
    component: FAQ,
    layout: "",
  },
  {
    path: "/manual",
    name: "Manual",
    rtlName: "الرسوم البيانية",
    thName: "คู่มือการใช้งาน",
    icon: ManualIcon,
    component: Manual,
    layout: "",
  },
  {
    path: "/contactus",
    name: "Contactus",
    rtlName: "الرسوم البيانية",
    thName: "ติดต่อเรา",
    icon: PermPhoneMsg,
    component: Charts,
    layout: "",
  },
  {
    path: "/addaccount",
    name: "Add New Account",
    rtlName: "ملف تعريفي للمستخدم",
    thName: "เพิ่มบัญชีผู้ใช้",
    mini: "UP",
    rtlMini: "شع",
    component: AddAccount,
    layout: "",
    invisible: true,
  },
];
export default dashRoutes;
