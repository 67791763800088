import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import GHBankLoading from "assets/img/reicLoading.gif";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button";
import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import switchIntl from "../../configure/switchIntl.js";
import "./GHproject.css";
import _ from "lodash";
import numeral from "numeral";
import {
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
} from "@material-ui/core";
import axios from "axios";
import DetailsQC from "./detailQC";

class GHProject extends React.Component {
  state = {
    currentSurveyYear: new Date().getFullYear,
    currentSurveyPeriodYear: new Date().getMonth() < 6 ? 1 : 2,
    filterYear: 0,
    filterPeriodYear: 0,
    showDetails: false,
  };
  componentDidMount() {
    this.getSecretKey();
  }
  // componentDidUpdate(prevProp) {}
  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
    }
  }

  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });

          self.getPsurvey();
          self.getListPsurvey();
          self.getRegTime();
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getRegTime = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_getRegTime}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          if (
            responseJson.data &&
            responseJson.data[0] &&
            responseJson.data[0].reg_year
          )
            if (
              responseJson.data[0].reg_year === 2563 ||
              responseJson.data[0].reg_year === 2564
            ) {
              this.setState({
                currentSurveyYear: responseJson.data[0].reg_year,
                currentSurveyHalfYear: responseJson.data[0].reg_halfyear,
                currentSurveyQuarterYear: responseJson.data[0].reg_quarter,

                filterYear: responseJson.data[0].reg_year,
                filterPeriodYear: "H" + responseJson.data[0].reg_halfyear,
                selectedCurrent:
                  responseJson.data[0].reg_year +
                  "-H" +
                  responseJson.data[0].reg_halfyear +
                  " (ปัจจุบัน)",
                selectedPrevious:
                  responseJson.data[0].reg_year +
                  "-H" +
                  responseJson.data[0].reg_halfyear +
                  " (ปัจจุบัน)",
              });
            } else {
              this.setState({
                currentSurveyYear: responseJson.data[0].reg_year,
                currentSurveyHalfYear: responseJson.data[0].reg_halfyear,
                currentSurveyQuarterYear: responseJson.data[0].reg_quarter,

                filterYear: responseJson.data[0].reg_year,
                filterPeriodYear: "Q" + responseJson.data[0].reg_quarter,
                selectedCurrent:
                  responseJson.data[0].reg_year +
                  "-Q" +
                  responseJson.data[0].reg_quarter +
                  " (ปัจจุบัน)",
                selectedPrevious:
                  responseJson.data[0].reg_year +
                  "-Q" +
                  responseJson.data[0].reg_quarter +
                  " (ปัจจุบัน)",
              });
            }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getPsurvey = () => {
    const { key } = this.state;
    if (!key) {
      // console.log("error key");
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_countpsurvey}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("success key");
        this.setState({ countpsurvey: responseJson });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getListPsurvey = () => {
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_adminpsurveyproject}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });

    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          const psurveyListFull =
            "data" in data &&
            0 in data.data &&
            "jsonb_build_object" in data.data[0] &&
            "features" in data.data[0].jsonb_build_object
              ? data.data[0].jsonb_build_object.features
              : [];
          const psurveyList = [];
          psurveyListFull.forEach(function(i, j) {
            if ("properties" in i) {
              psurveyList.push(i.properties);
            }
          });
          that.setState({ psurveyList });
        });
      })
      .catch(function(err) {});
  };

  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          {...("onConfirm" in status
            ? ""
            : { onConfirm: () => this.setState({ alert: null }) })}
          onCancel={() => this.setState({ alert: null })}
          //confirmBtnCssClass={}
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  downloadCSVPsurvey = async () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    var self = this;
    // const keyutf = CryptoJS.enc.Utf8.parse(key);
    // const iv = CryptoJS.enc.Base64.parse(key);

    // var aesStr = localStorage.getItem("session");
    // const dec = CryptoJS.AES.decrypt(
    //   { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
    //   keyutf,
    //   {
    //     iv: iv,
    //   }
    // );
    // const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const body = {
      year:
        (this.state.filterYear + "").substring(2) +
        "" +
        this.state.filterPeriodYear,
    };
    if (!this.state.filterYear || !this.state.filterPeriodYear) {
      self.successAlert("ไม่สามารถส่งออกไฟล์ CSV", "กรุณาเลือกปีช่วงสำรวจ", {
        status: "warning",
      });
      return;
    }
    this.setState({ loadingScreen: true });

    await axios
      .post(config.API_psurveyexp_reject, body, {
        headers: {
          "Content-Type": "application/json",
          token: self.state.tokenData,
        },
        timeout: 360000,
      })
      .then(function(response) {
        // console.log("test");
        // console.log(response);
        return response.data;
        // if (response) {
        //   console.log("test1")
        //     throw new Error("Not 2xx response")
        // } else {
        //   console.log("test2")
        //   return response.data
        // }
      })
      .then(function(data) {
        // console.log("test3");
        switch (data.code) {
          case 200:
            if (data.message === "no data") {
              // console.log("no data")
              // console.log("test4");
              self.successAlert(
                "ไม่มีข้อมูล",
                "ไม่มีข้อมูลในการส่งออกไฟล์ CSV",
                { status: "warning" }
              );
              return self.setState({ loadingScreen: false });
            } else {
              // console.log('start download')
              // console.log("test5");
              return setTimeout(() => {
                self.setState({ loadingScreen: false });
                return window.open(data.data);
              }, 2000);
            }
          default:
            // console.log("test6");
            // console.log("no data")
            self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
              status: "warning",
            });
            return self.setState({ loadingScreen: false });
        }
      })
      .catch(function(err) {
        // console.log("test7");
        self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
          status: "warning",
        });
        // console.log(err);
        return self.setState({ loadingScreen: false });
      });
  };

  render() {
    if (this.state.showDetails) {
      return (
        <DetailsQC
          handleClose={() =>
            this.setState({ ...this.state, showDetails: false })
          }
          data={this.state.detailData}
        />
      );
    }

    var that = this;
    const { classes, toggleLng } = this.props;
    const psurveyList = this.state.psurveyList ? this.state.psurveyList : [];

    // console.log(
    //   that.state.filterPeriodYear,
    //   (that.state.filterYear + "").substring(2)
    // );
    if (this.state.filterYear !== 0) {
      var psurveyListFilterY = psurveyList.filter((a) => {
        // console.log(that.state.filterYear, that.state.filterPeriodYear)
        return (
          a.projectid.substring(0, 4) ===
          (that.state.filterYear + "").substring(2) +
            that.state.filterPeriodYear
        );
      });
      // console.log(psurveyListFilterY)
    }

    var psurveyListGroup = _.groupBy(psurveyListFilterY, "project_type");
    // console.log(psurveyListGroup);
    let new_array = {},
      total_row,
      total_condo,
      total_villa,
      total_house;
    Object.keys(psurveyListGroup).map((item) => {
      new_array[item] = _(psurveyListGroup[item])
        .groupBy("org_name")
        .map(function(ite, itemId) {
          var obj = {};
          // console.log(ite);
          let zones = [];
          _.map(ite, (o) => {
            if (!zones.includes(o.zone)) {
              zones.push(o.zone);
            }
          });
          let count_approved = numeral(
            _.countBy(ite, (o) => o["condo_approved"] === 1).true || 0
          ).format("0,0");
          let count_rejected = numeral(
            _.countBy(ite, (o) => o["condo_approved"] === 2).true || 0
          ).format("0,0");
          let count_waited = numeral(
            _.countBy(ite, (o) => o["condo_approved"] === 3).true || 0
          ).format("0,0");
          let count_all = numeral(ite.length).format("0,0");
          obj[itemId] = {
            zones: zones
              .sort(function(a, b) {
                return a - b;
              })
              .join(","),
            count: count_all,
            count_waited,
            count_approved,
            count_rejected,
            datails: ite,
          };
          return obj;
        })
        .value();
      return null;
    });
    // console.log(new_array);
    total_condo = !_.isEmpty(new_array)
      ? new_array["condo"]
        ? new_array["condo"].length
        : 0
      : 0;
    total_villa = !_.isEmpty(new_array)
      ? new_array["villa"]
        ? new_array["villa"].length
        : 0
      : 0;
    total_house = !_.isEmpty(new_array)
      ? new_array["household"]
        ? new_array["household"].length
        : 0
      : 0;
    total_row = total_condo + total_villa + total_house;

    new_array &&
      _.forOwn(new_array, function(value, key) {
        // console.log(key, value);
        value.map((val) => {
          _.forOwn(val, function(va, k) {
            // console.log(k, va);
          });
          return null;
        });
        return null;
      });

    const renderTable = () => {
      let array = [];
      let html_array = [];
      let currentKey;
      if (new_array) {
        _.forOwn(new_array, function(value, key) {
          return value.map((val) => {
            return _.forOwn(val, function(va, k) {
              // console.log(k, va);
              // console.log(array, currentKey, key);
              if (currentKey === key) {
                array.push([k, ...Object.values(va)]);
              } else {
                array.push([key, k, ...Object.values(va)]);
              }
              currentKey = key;
            });
          });
        });
      }
      // console.log(array);
      array.map((arr, index) => {
        let innerTag = [];
        // console.log(arr);
        arr.map((ar, ind) => {
          // console.log(arr.length, ind);
          if (arr.length - 1 === ind) {
            return null;
          }
          if (arr.length - 2 === ind) {
            return innerTag.push(
              <TableCell component="th" scope="row">
                <Button
                  color="danger"
                  style={{ color: "white", fontWeight: 600 }}
                  disabled={ar === "0" ? true : false}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      showDetails: true,
                      detailData: arr[ind + 1],
                    });
                    // console.log(array[index]);
                  }}
                >
                  {ar}
                </Button>
              </TableCell>
            );
          }

          if (ar === "condo") {
            innerTag.push(
              <TableCell rowSpan={total_condo} component="th" scope="row">
                {ar}
              </TableCell>
            );
          } else if (ar === "villa") {
            innerTag.push(
              <TableCell rowSpan={total_villa} component="th" scope="row">
                {ar}
              </TableCell>
            );
          } else if (ar === "household") {
            innerTag.push(
              <TableCell rowSpan={total_house} component="th" scope="row">
                {"house"}
              </TableCell>
            );
          } else {
            innerTag.push(
              <TableCell component="th" scope="row">
                {ar}
              </TableCell>
            );
          }
          return null;
        });
        // console.log(innerTag);

        if (index === 0) {
          html_array.push(
            <TableRow key={index}>
              <TableCell rowSpan={total_row} component="th" scope="row">
                {parseInt(that.state.filterYear).toString() +
                  "-" +
                  that.state.filterPeriodYear}
              </TableCell>
              {innerTag}
            </TableRow>
          );
        } else {
          html_array.push(<TableRow key={index}>{innerTag}</TableRow>);
        }
        return null;
      });
      // console.log(html_array);
      return html_array;
    };

    // console.log(this.state);
    return (
      <div>
        <Row style={{ padding: "0px", margin: "0px" }}>
          <Col xs={12}>
            <Card style={{ padding: "15px" }}>
              <CardBody>
                <Row>
                  <Col>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.selectedCurrent}
                      onChange={(e) => {
                        this.setState({
                          selectedCurrent: e.target.value,
                          filterYear: parseInt(e.target.value.split("-")[0]),
                          filterPeriodYear: e.target.value
                            .split("-")[1]
                            .substring(0, 2),
                        });
                        // console.log(
                        //   this.state.selectedPrevious,
                        //   e.target.value
                        // );
                        if (this.state.selectedPrevious !== e.target.value) {
                          this.setState({
                            showEditPsurvey: false,
                          });
                        } else {
                          this.setState({
                            showEditPsurvey: true,
                          });
                        }
                      }}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value={"0-0"}
                      >
                        {switchIntl(toggleLng).project.select_survey_year}
                      </MenuItem>
                      {this.state.currentSurveyYear}
                      {(() => {
                        var yearList = [];
                        for (
                          let year = this.state.currentSurveyYear;
                          year >= 2563;
                          year--
                        ) {
                          if (year === 2563 || year === 2564) {
                            for (let half = 1; half <= 2; half++) {
                              let name = "";
                              if (half === 1) {
                                name =
                                  year +
                                  "-" +
                                  "H1" +
                                  (year === this.state.currentSurveyYear &&
                                  half === this.state.currentSurveyHalfYear
                                    ? " (ปัจจุบัน)"
                                    : "");
                              } else {
                                name =
                                  year +
                                  "-" +
                                  "H2" +
                                  (year === this.state.currentSurveyYear &&
                                  half === this.state.currentSurveyHalfYear
                                    ? " (ปัจจุบัน)"
                                    : "");
                              }
                              yearList.push(
                                <MenuItem
                                  value={name}
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  {name}
                                </MenuItem>
                              );
                            }
                          } else {
                            for (let quarter = 1; quarter <= 4; quarter++) {
                              let name = "";
                              if (quarter === 1) {
                                name =
                                  year +
                                  "-" +
                                  "Q1" +
                                  (year === this.state.currentSurveyYear &&
                                  quarter ===
                                    this.state.currentSurveyQuarterYear
                                    ? " (ปัจจุบัน)"
                                    : "");
                              } else if (quarter === 2) {
                                name = [
                                  year +
                                    "-" +
                                    "Q2" +
                                    (year === this.state.currentSurveyYear &&
                                    quarter ===
                                      this.state.currentSurveyQuarterYear
                                      ? " (ปัจจุบัน)"
                                      : ""),
                                  year +
                                    "-" +
                                    "H1" +
                                    (year === this.state.currentSurveyYear &&
                                    quarter ===
                                      this.state.currentSurveyQuarterYear
                                      ? " (ปัจจุบัน)"
                                      : ""),
                                ];
                              } else if (quarter === 3) {
                                name =
                                  year +
                                  "-" +
                                  "Q3" +
                                  (year === this.state.currentSurveyYear &&
                                  quarter ===
                                    this.state.currentSurveyQuarterYear
                                    ? " (ปัจจุบัน)"
                                    : "");
                              } else if (quarter === 4) {
                                name = [
                                  year +
                                    "-" +
                                    "Q4" +
                                    (year === this.state.currentSurveyYear &&
                                    quarter ===
                                      this.state.currentSurveyQuarterYear
                                      ? " (ปัจจุบัน)"
                                      : ""),
                                  year +
                                    "-" +
                                    "H2" +
                                    (year === this.state.currentSurveyYear &&
                                    quarter ===
                                      this.state.currentSurveyQuarterYear
                                      ? " (ปัจจุบัน)"
                                      : ""),
                                ];
                              }
                              if (Array.isArray(name)) {
                                name.map((item) =>
                                  yearList.push(
                                    <MenuItem
                                      value={item}
                                      classes={{
                                        root: classes.selectMenuItem,
                                      }}
                                    >
                                      {item}
                                    </MenuItem>
                                  )
                                );
                              } else {
                                yearList.push(
                                  <MenuItem
                                    value={name}
                                    classes={{
                                      root: classes.selectMenuItem,
                                    }}
                                  >
                                    {name}
                                  </MenuItem>
                                );
                              }
                            }
                          }
                        }
                        return yearList;
                      })()}
                    </Select>
                    <Button
                      color="info"
                      size="small"
                      onClick={() => this.downloadCSVPsurvey()}
                    >
                      {switchIntl(toggleLng).project.export_csv_rejected}
                    </Button>
                  </Col>
                </Row>

                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell rowSpan={2}>ช่วงเวลา</TableCell>
                        <TableCell rowSpan={2} numeric>
                          ประเภทโครงการ
                        </TableCell>
                        <TableCell rowSpan={2} numeric>
                          ชื่อบริษัท
                        </TableCell>
                        <TableCell rowSpan={2} numeric>
                          โซน
                        </TableCell>
                        <TableCell align="center" colSpan={4} numeric>
                          แบบสอบถาม
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell numeric>ทั้งหมด</TableCell>
                        <TableCell numeric>รอตรวจ</TableCell>
                        <TableCell numeric>ผ่าน</TableCell>
                        <TableCell numeric>ไม่ผ่าน</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{renderTable()}</TableBody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

GHProject.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(
  withStyles(regularFormsStyle)(GHProject)
);
