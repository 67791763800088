import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import * as config from "configure/configServer.js";
import Button from "components/CustomButtons/Button";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, Col, Row } from "reactstrap";
import { red } from "@material-ui/core/colors";
import switchIntl from "../../configure/switchIntl.js";
import "./ManageImage.css";
import axios from "axios";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

class GHDashboard extends React.Component {
  state = {
    psurveyCurTab: 0,
  };

  componentDidMount() {
    this.getSecretKey();
  }

  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });
          self.getAllImage();
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getAllImage = async () => {
    var that = this;
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var houseRequest = new Request(`${config.API_image}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ form: "household" }),
    });
    var condoRequest = new Request(`${config.API_image}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ form: "condo" }),
    });
    var villaRequest = new Request(`${config.API_image}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({ form: "villa" }),
    });

    await fetch(houseRequest)
      .then((response) => response.json())
      .then((responseJson) => {
        that.setState({ psurveyHouseList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });

    await fetch(condoRequest)
      .then((response) => response.json())
      .then((responseJson) => {
        that.setState({ psurveyCondoList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });

    await fetch(villaRequest)
      .then((response) => response.json())
      .then((responseJson) => {
        that.setState({ psurveyVillaList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  downloadCSVImage = async () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    var self = this;
    this.setState({ loadingScreen: true });

    await axios
      .post(config.API_psurveyexp_image, {
        headers: {
          "Content-Type": "application/json",
          token: self.state.tokenData,
        },
        timeout: 360000,
      })
      .then(function(response) {
        return response.data;
      })
      .then(function(data) {
        switch (data.code) {
          case 200:
            if (data.message === "no data") {
              self.successAlert(
                "ไม่มีข้อมูล",
                "ไม่มีข้อมูลในการส่งออกไฟล์ CSV",
                { status: "warning" }
              );
              return self.setState({ loadingScreen: false });
            } else {
              return setTimeout(() => {
                self.setState({ loadingScreen: false });
                return window.open(data.data);
              }, 2000);
            }
          default:
            self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
              status: "warning",
            });
            return self.setState({ loadingScreen: false });
        }
      })
      .catch(function(err) {
        self.successAlert("ไม่มีข้อมูล", "ไม่มีข้อมูลในการส่งออกไฟล์ CSV", {
          status: "warning",
        });
        return self.setState({ loadingScreen: false });
      });
  };

  render() {
    const { toggleLng } = this.props;
    const tableHeadHouseTable = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "index",
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.refcode,
        accessor: "household_refcode",
        filterMethod: (filter, row) => {
          return row.household_refcode.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.project_name,
        accessor: "household_nameth",
        filterMethod: (filter, row) => {
          return row.household_nameth.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.front_project,
        accessor: "household_frontprojectplaceimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="frontprojectplace"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.brochure,
        accessor: "household_brochuremapimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="brochure"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.construct,
        accessor: "household_currentconstructimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="construct"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.sale_office,
        headerStyle: { textAlign: "left" },
        accessor: "household_saleofficeimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="saleoffice"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.single_house,
        headerStyle: { textAlign: "left" },
        accessor: "household_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).house.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).house[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).house[0])
                }
                alt="house"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="house">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.twin_house,
        headerStyle: { textAlign: "left" },
        accessor: "household_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).twinhouse.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).twinhouse[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).twinhouse[0])
                }
                alt="twinhouse"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="twinhouse">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.town_house,
        headerStyle: { textAlign: "left" },
        accessor: "household_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).townhouse.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).townhouse[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).townhouse[0])
                }
                alt="townhouse"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="townhouse">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.commercial,
        headerStyle: { textAlign: "left" },
        accessor: "household_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).commercialbuilding.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).commercialbuilding[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value
                    ? ""
                    : JSON.parse(val.value).commercialbuilding[0])
                }
                alt="commercialbuilding"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="commercialbuilding">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.land,
        headerStyle: { textAlign: "left" },
        accessor: "household_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).land.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).land[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).land[0])
                }
                alt="land"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="land">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
    ];

    const tableHeadCondoTable = [
      {
        Header: switchIntl(toggleLng).manageimage.no,
        accessor: "index",
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.refcode,
        accessor: "condo_refcode",
        filterMethod: (filter, row) => {
          return row.condo_refcode.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.project_name,
        accessor: "condo_nameth",
        filterMethod: (filter, row) => {
          return row.condo_nameth.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.front_project,
        accessor: "condo_frontprojectplaceimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="frontprojectplace"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.brochure,
        accessor: "condo_brochuremapimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="brochure"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.construct,
        accessor: "condo_currentconstructimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="construct"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.sale_office,
        headerStyle: { textAlign: "left" },
        accessor: "condo_saleofficeimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="saleoffice"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.studio,
        headerStyle: { textAlign: "left" },
        accessor: "condo_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).house.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).house[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).house[0])
                }
                alt="house"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="house">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.one_bedroom,
        headerStyle: { textAlign: "left" },
        accessor: "condo_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).twinhouse.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).twinhouse[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).twinhouse[0])
                }
                alt="twinhouse"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="twinhouse">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.two_bedroom,
        headerStyle: { textAlign: "left" },
        accessor: "condo_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).townhouse.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).townhouse[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).townhouse[0])
                }
                alt="townhouse"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="townhouse">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.three_bedroom,
        headerStyle: { textAlign: "left" },
        accessor: "condo_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).commercialbuilding.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).commercialbuilding[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value
                    ? ""
                    : JSON.parse(val.value).commercialbuilding[0])
                }
                alt="commercialbuilding"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="commercialbuilding">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
    ];

    const tableHeadVillaTable = [
      {
        Header: switchIntl(toggleLng).manageimage.no,
        accessor: "index",
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.refcode,
        accessor: "villa_refcode",
        filterMethod: (filter, row) => {
          return row.villa_refcode.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.project_name,
        accessor: "villa_nameth",
        filterMethod: (filter, row) => {
          return row.villa_nameth.indexOf(filter.value) >= 0;
        },
      },
      {
        Header: switchIntl(toggleLng).manageimage.front_project,
        accessor: "villa_frontprojectplaceimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="frontprojectplace"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.brochure,
        accessor: "villa_brochuremapimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="brochure"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.construct,
        accessor: "villa_currentconstructimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="construct"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.sale_office,
        headerStyle: { textAlign: "left" },
        accessor: "villa_saleofficeimg",
        filterable: false,
        Cell: (val) =>
          val.value && val.value[0] !== "" && val.value.length > 0 ? (
            <a href={config.psurveyPath + val.value[0]} target={"blank"}>
              <img
                height={100}
                width={100}
                src={config.psurveyPath + val.value[0]}
                alt="saleoffice"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="filebrochure">
              <CancelIcon fontSize="large" style={{ color: red[500] }} />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.single_house,
        headerStyle: { textAlign: "left" },
        accessor: "villa_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).house.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).house[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).house[0])
                }
                alt="house"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="house">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.twin_house,
        headerStyle: { textAlign: "left" },
        accessor: "villa_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).twinhouse.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).twinhouse[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).twinhouse[0])
                }
                alt="twinhouse"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="twinhouse">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.town_house,
        headerStyle: { textAlign: "left" },
        accessor: "villa_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).townhouse.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).townhouse[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).townhouse[0])
                }
                alt="townhouse"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="townhouse">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.commercial,
        headerStyle: { textAlign: "left" },
        accessor: "villa_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).commercialbuilding.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).commercialbuilding[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value
                    ? ""
                    : JSON.parse(val.value).commercialbuilding[0])
                }
                alt="commercialbuilding"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="commercialbuilding">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
      {
        Header: switchIntl(toggleLng).manageimage.land,
        headerStyle: { textAlign: "left" },
        accessor: "villa_exhouseimg",
        filterable: false,
        Cell: (val) =>
          JSON.parse(val.value).land.length > 0 ? (
            <a
              href={
                config.psurveyPath +
                (!val.value ? "" : JSON.parse(val.value).land[0])
              }
              target={"blank"}
            >
              <img
                height={100}
                width={100}
                src={
                  config.psurveyPath +
                  (!val.value ? "" : JSON.parse(val.value).land[0])
                }
                alt="land"
              >
                {}
              </img>
            </a>
          ) : (
            <IconButton aria-label="land">
              <CancelIcon
                fontSize="large"
                style={{ justifyItems: "center", color: red[500] }}
              />
            </IconButton>
          ),
      },
    ];

    return (
      <Row style={{ padding: "0px", margin: "0px" }}>
        <Col xs={12}>
          <Card style={{ padding: "15px" }}>
            <CardBody>
              <AntTabs
                value={this.state.psurveyCurTab ? this.state.psurveyCurTab : 0}
                onChange={(e, v) => this.setState({ psurveyCurTab: v })}
                aria-label="ant example"
              >
                <AntTab label={switchIntl(toggleLng).manageimage.house} />
                <AntTab label={switchIntl(toggleLng).manageimage.condo} />
                <AntTab label={switchIntl(toggleLng).manageimage.villa} />
                <Button
                  color="info"
                  size="small"
                  onClick={() => this.downloadCSVImage()}
                >
                  {switchIntl(toggleLng).project.export_csv_image}
                </Button>
              </AntTabs>

              <TabPanel value={this.state.psurveyCurTab} index={0}>
                <ReactTable
                  data={this.state.psurveyHouseList}
                  filterable
                  columns={tableHeadHouseTable}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "index",
                      desc: false,
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel value={this.state.psurveyCurTab} index={1}>
                <ReactTable
                  data={this.state.psurveyCondoList}
                  filterable
                  columns={tableHeadCondoTable}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "index",
                      desc: false,
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel value={this.state.psurveyCurTab} index={2}>
                <ReactTable
                  data={this.state.psurveyVillaList}
                  filterable
                  columns={tableHeadVillaTable}
                  defaultPageSize={5}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  defaultSorted={[
                    {
                      id: "index",
                      desc: false,
                    },
                  ]}
                />
              </TabPanel>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

GHDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withStyles(modalStyle)(GHDashboard));
