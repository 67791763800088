/* eslint-disable no-unused-vars */
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
// import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
// import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
// import Menu from "@material-ui/icons/Menu";
// import PersonAdd from "@material-ui/icons/PersonAdd";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// import LockOpen from "@material-ui/icons/LockOpen";
// import MonetizationOn from "@material-ui/icons/MonetizationOn";

// core components
import Button from "components/CustomButtons/Button";

// core redux
import { connect } from "react-redux";
import setLanguage from "actions/setLanguage";

import authNavbarStyle from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const activeStyle = { fontWeight: "bold" };

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.activeTH = this.activeTH.bind(this);
    this.activeEN = this.activeEN.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
    setLanguage: PropTypes.string.isRequired,
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }

  activeTH(lng) {
    if (lng === "TH") {
      return activeStyle;
    }
  }

  activeEN(lng) {
    if (lng === "EN") {
      return activeStyle;
    }
  }

  render() {
    const { classes, color, brandText } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color,
    });
    var list = (
      <List className={classes.list}>
        <ListItem
          className={classes.listItem}
          onClick={() => {
            this.props.setLanguage("TH");
          }}
        >
          <a
            href="#/"
            style={this.activeTH(this.props.toggleLng)}
            className="switchLanguageButton"
          >
            ไทย
          </a>
        </ListItem>
        <ListItem className={classes.listItem}> | </ListItem>
        <ListItem
          className={classes.listItem}
          onClick={() => {
            this.props.setLanguage("EN");
          }}
        >
          <a
            href="#/"
            style={this.activeEN(this.props.toggleLng)}
            className="switchLanguageButton"
          >
            ENG
          </a>
        </ListItem>
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={classes.flex} />
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <div style={{ float: "right", zIndex: 0 }}>
                <a
                  href="#/"
                  style={this.activeTH(this.props.toggleLng)}
                  className="mobileLanguageSwitchBT"
                  onClick={() => {
                    this.props.setLanguage("TH");
                  }}
                >
                  ไทย
                </a>
                |
                <a
                  href="#/"
                  style={this.activeEN(this.props.toggleLng)}
                  className="mobileLanguageSwitchBT"
                  onClick={() => {
                    this.props.setLanguage("EN");
                  }}
                >
                  ENG
                </a>
              </div>
            </div>
          </Hidden>
          <Hidden smDown>{list}</Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

AuthNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (payload) => dispatch(setLanguage(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(authNavbarStyle)(AuthNavbar));
