/*eslint-disable*/

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Info from "@material-ui/icons/Info";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";

import { API_get_cate_condo } from "configure/configServer.js";

import { connect } from "react-redux";
import setLanguage from "actions/setLanguage";
import tokenSession from "actions/tokenSession.js";
import setHomeType from "actions/setHomeType.js";
import setMinMaxHouse from "actions/setMinMaxHouse.js";
import setDropDownHouseForSection2 from "actions/setDropDownHouseForSection2.js";
import setHousePriceForSection2 from "actions/setHousePriceForSection2.js";
import { conditionalExpression } from "@babel/types";
import numeral from "numeral";

class HomeType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDescription: false,
      value: 0,
      asset_type: "2",
      checked: [],
      house_type: [],
      house_min: 0,
      house_max: 0,
      twinhouse_min: 0,
      twinhouse_max: 0,
      townhouse_min: 0,
      townhouse_max: 0,
      shophouse_min: 0,
      shophouse_max: 0,
      land_min: 0,
      land_max: 0,
      house_min_state: 0,
      house_max_state: 0,
      twinhouse_min_state: 0,
      twinhouse_max_state: 0,
      townhouse_min_state: 0,
      townhouse_max_state: 0,
      shophouse_min_state: 0,
      shophouse_max_state: 0,
      land_min_state: 0,
      land_max_state: 0,
      allprice_info: null,
      allHouse_info: {
        home: 1,
        twinhouse: 0,
        townhouse: 0,
        shophouse: 0,
        land: 0,
      },
      lookup_asset: [{ name: "สตูดิโอ", value: 1, min: 0, max: 0 }],
      price_minmax_subtype: null,
    };
    this.getAssetCatergory = this.getAssetCatergory.bind(this);
    this.change = this.change.bind(this);
    this.isValidDigit = this.isValidDigit.bind(this);
    this.isDigit = this.isDigit.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.hideDescription = this.hideDescription.bind(this);
  }

  componentDidMount() {
    this.getAssetCatergory();

    this.handleToggle("1");

    if (this.props.assetType.includes("h")) {
      this.setState({ asset_type: 1 });
    } else if (this.props.assetType.includes("c")) {
      this.setState({ asset_type: 2 });
    } else {
      this.setState({ asset_type: 3 });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetSubtype !== this.props.assetSubtype) {
      let selected = [];
      //console.log("PRICE === ");
      //console.log(this.props.price);
      if (this.props.assetSubtype) {
        if (this.props.assetSubtype.chk1 === 1) {
          selected.push(1);
          let priceInfo = this.props.price.filter((price) => {
            return price.plen_form === 1;
          });
          this.setState({
            house_min: priceInfo[0].plen_min,
            house_max: priceInfo[0].plen_max,
          });
        }
        if (this.props.assetSubtype.chk2 === 1) {
          selected.push(2);
          let priceInfo = this.props.price.filter((price) => {
            return price.plen_form === 2;
          });
          this.setState({
            twinhouse_min: priceInfo[0].plen_min,
            twinhouse_max: priceInfo[0].plen_max,
          });
        }
        if (this.props.assetSubtype.chk3 === 1) {
          selected.push(3);
          let priceInfo = this.props.price.filter((price) => {
            return price.plen_form === 3;
          });
          this.setState({
            townhouse_min: priceInfo[0].plen_min,
            townhouse_max: priceInfo[0].plen_max,
          });
        }
        if (this.props.assetSubtype.chk4 === 1) {
          selected.push(4);
          let priceInfo = this.props.price.filter((price) => {
            return price.plen_form === 4;
          });
          this.setState({
            shophouse_min: priceInfo[0].plen_min,
            shophouse_max: priceInfo[0].plen_max,
          });
        }
        if (this.props.assetSubtype.chk5 === 1) {
          selected.push(5);
          let priceInfo = this.props.price.filter((price) => {
            return price.plen_form === 5;
          });
          this.setState({
            land_min: priceInfo[0].plen_min,
            land_max: priceInfo[0].plen_max,
          });
        }
      }
      this.setState({ checked: selected }, () => {
        this.formatData();
      });
    }
  }

  handleToggle(value) {
    let self = this;
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = this.state.checked;
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked }, () => {
      self.formatData();
    });
  }

  handleAssetType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getAssetCatergory = (event) => {
    let self = this;

    var request = new Request(API_get_cate_condo, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          //console.log(responseJson.data);
          self.setState({ house_type: responseJson.data });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  change = (event, stateName, type) => {
    let self = this;
    this.setState({ [stateName]: event.target.value }, () => {
      let min = self.state[type + "_min"];
      let max = self.state[type + "_max"];

      if (max < min) {
        self.setState({ [stateName + "_state"]: "error" });
      } else {
        self.setState({ [stateName + "_state"]: "success" });
      }

      this.formatData();
    });
  };

  isDigit(value) {
    var textRex = /^\d*[1-9]\d*$/;
    if (textRex.test(value)) {
      return true;
    }
    return false;
  }

  isValidDigit(event, stateName, type) {
    let val = event.target.value;
    let self = this;
    if (!this.isDigit(val) && val < 1000) {
      this.setState({ [stateName + "_state"]: "error" });
    } else {
      var state_min = type + "_min";
      var state_max = type + "_max";

      let min = parseFloat({ ...self.state[state_min] });
      let max = parseFloat({ ...self.state[state_max] });

      if (min > max) {
        this.setState({ [stateName + "_state"]: "error" });
      } else {
        this.setState({ [stateName + "_state"]: "success" });
      }
    }
  }

  formatData() {
    let self = this;
    let houseSubType = this.state.checked;
    let price_minmax_subtype = {
      house: [0, 0],
      twinhouse: [0, 0],
      townhouse: [0, 0],
      shophouse: [0, 0],
      land: [0, 0],
    };

    let subtype_selected = {
      home: 0,
      twinhouse: 0,
      townhouse: 0,
      shophouse: 0,
      land: 0,
    };

    let lookup_asset = [];

    houseSubType.map((props, key) => {
      switch (props) {
        case 1:
          price_minmax_subtype.house = [
            numeral(self.state.house_min).value(),
            numeral(self.state.house_max).value(),
          ];
          subtype_selected.home = 1;
          lookup_asset.push({
            name: "สตูดิโอ",
            value: "1",
            min: numeral(self.state.house_min).value(),
            max: numeral(self.state.house_max).value(),
          });
          break;
        case 2:
          price_minmax_subtype.twinhouse = [
            numeral(self.state.twinhouse_min).value(),
            numeral(self.state.twinhouse_max).value(),
          ];
          subtype_selected.twinhouse = 1;
          lookup_asset.push({
            name: "1 ห้องนอน",
            value: "2",
            min: numeral(self.state.twinhouse_min).value(),
            max: numeral(self.state.twinhouse_max).value(),
          });
          break;
        case 3:
          price_minmax_subtype.townhouse = [
            numeral(self.state.townhouse_min).value(),
            numeral(self.state.townhouse_max).value(),
          ];
          subtype_selected.townhouse = 1;
          lookup_asset.push({
            name: "2 ห้องนอน",
            value: "3",
            min: numeral(self.state.townhouse_min).value(),
            max: numeral(self.state.townhouse_max).value(),
          });
          break;
        case 4:
          price_minmax_subtype.shophouse = [
            numeral(self.state.shophouse_min).value(),
            numeral(self.state.shophouse_max).value(),
          ];
          subtype_selected.shophouse = 1;
          lookup_asset.push({
            name: "3 ห้องนอนขึ้นไป",
            value: "4",
            min: numeral(self.state.shophouse_min).value(),
            max: numeral(self.state.shophouse_max).value(),
          });
          break;
        default:
          break;
      }
    });
    this.setState({ price_minmax_subtype: price_minmax_subtype });
    this.setState({ lookup_asset: lookup_asset });
    this.setState({ allHouse_info: subtype_selected });

    this.setProps(price_minmax_subtype, lookup_asset, subtype_selected);
  }

  setProps(price_minmax_subtype, lookup_asset, subtype_selected) {
    this.props.setHousePriceForSection2(price_minmax_subtype);
    this.props.setDropDownHouseForSection2(lookup_asset);
    this.props.setMinMaxHouse(price_minmax_subtype);
    this.props.setHomeType(subtype_selected);
  }

  // setProps = (price_minmax_subtype, lookup_asset, subtype_selected) => {
  //   let self = this;

  //   //set house price for section2
  //   this.setState({ price_minmax_subtype: price_minmax_subtype }, () => {
  //     let info = self.state.price_minmax_subtype;
  //     self.props.setHousePriceForSection2(price_minmax_subtype);
  //   });

  //   //set house type for section2
  //   this.setState({ lookup_asset: lookup_asset }, () => {
  //     let info = self.state.lookup_asset;
  //     self.props.setDropDownHouseForSection2(info);
  //   });

  //   //save min max
  //   this.setState({ allprice_info: price_minmax_subtype }, () => {
  //     let info = self.state.allprice_info;
  //     self.props.setMinMaxHouse(info);
  //   });

  //   this.setState({ allHouse_info: subtype_selected }, () => {
  //     let info = self.state.allHouse_info;
  //     self.props.setHomeType(info);
  //   });
  // }

  showDescription() {
    this.setState({ showDescription: true });
  }
  hideDescription() {
    this.setState({ showDescription: false });
  }
  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    let house_type = this.state.house_type;
    let key_hometype = 1;
    // eslint-disable-next-line prettier/prettier

    let optionItems = house_type.map((house) => {
      // eslint-disable-next-line react/jsx-key
      key_hometype++;
      return (
        <FormControlLabel
          key={key_hometype}
          control={
            <Checkbox
              tabIndex={-1}
              onClick={() => this.handleToggle(house.value)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              checked={
                this.state.checked.indexOf(house.value) !== -1 ? true : false
              }
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{ checked: classes.checked }}
            />
          }
          classes={{ label: classes.label }}
          label={house.label}
        />
      );
    });
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4>
            ประเภทที่อยู่อาศัยในโครงการ{" "}
            <Info
              onMouseEnter={this.showDescription}
              onMouseLeave={this.hideDescription}
            ></Info>
          </h4>
          {this.state.showDescription && (
            <div
              style={{
                borderStyle: "solid",
                borderColor: "grey",
                padding: "10px",
              }}
            >
              <h4 style={{ color: "black" }}>
                คำนิยามประเภทที่อยู่อาศัยในโครงการ
              </h4>
              <p style={{ color: "black" }}>
                <b>ห้องชุดแบบสตูดิโอ </b> -
                ห้องชุดที่ไม่มีการกั้นหรือแบ่งส่วนพื้นที่ของห้องนอน{" "}
              </p>{" "}
            </div>
          )}
          <FormControl fullWidth className={classes.selectFormControl} disabled>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={this.state.asset_type}
              onChange={this.handleAssetType}
              inputProps={{
                name: "asset_type",
                id: "asset_type",
                disabled: true,
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                }}
                value="1"
              >
                บ้านจัดสรร
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                }}
                value="2"
              >
                อาคารชุด
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                }}
                value="3"
              >
                บ้านพักตากอากาศ
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <br />
        <GridItem>{optionItems}</GridItem>
        <br />
        <br />
        <GridItem>
          <h4>ราคาต่ำสุด - สูงสุด</h4>
        </GridItem>
        {this.state.checked.includes(1) && (
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>สตูดิโอ</FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.house_min_state === "success"}
                    error={this.state.house_min_state === "error"}
                    labelText={<span>ราคาต่ำสุด</span>}
                    id="min_house"
                    formControlProps={{
                      fullWidth: true,
                      type: "number",
                    }}
                    inputProps={{
                      placeholder: "ราคาต่ำสุด",
                      value: numeral(this.state.house_min).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "house_min", "house"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "house_min", "house")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.house_max_state === "success"}
                    error={this.state.house_max_state === "error"}
                    labelText={<span>ราคาสูงสุด</span>}
                    id="max_house"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาสูงสุด",
                      value: numeral(this.state.house_max).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "house_max", "house"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "house_max", "house")
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}
        {this.state.checked.includes(2) && (
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                1 ห้องนอน
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.twinhouse_min_state === "success"}
                    error={this.state.twinhouse_min_state === "error"}
                    labelText={<span>ราคาต่ำสุด</span>}
                    id="min_twinhouse"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาต่ำสุด",
                      value: numeral(this.state.twinhouse_min).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "twinhouse_min", "twinhouse"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "twinhouse_min", "twinhouse")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.twinhouse_max_state === "success"}
                    error={this.state.twinhouse_max_state === "error"}
                    labelText={<span>ราคาสูงสุด</span>}
                    id="max_twinhouse"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาสูงสุด",
                      value: numeral(this.state.twinhouse_max).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "twinhouse_max", "twinhouse"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "twinhouse_max", "twinhouse")
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}
        {this.state.checked.includes(3) && (
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                2 ห้องนอน
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.townhouse_min_state === "success"}
                    error={this.state.townhouse_min_state === "error"}
                    labelText={<span>ราคาต่ำสุด</span>}
                    id="min_townhouse"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาต่ำสุด",
                      value: numeral(this.state.townhouse_min).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "townhouse_min", "townhouse"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "townhouse_min", "townhouse")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.townhouse_max_state === "success"}
                    error={this.state.townhouse_max_state === "error"}
                    labelText={<span>ราคาสูงสุด</span>}
                    id="max_townhouse"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาสูงสุด",
                      value: numeral(this.state.townhouse_max).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "townhouse_max", "townhouse"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "townhouse_max", "townhouse")
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}

        {this.state.checked.includes(4) && this.props.assetType !== "v" ? (
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                3 ห้องนอนขึ้นไป
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.shophouse_min_state === "success"}
                    error={this.state.shophouse_min_state === "error"}
                    labelText={<span>ราคาต่ำสุด</span>}
                    id="min_shophouse"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาต่ำสุด",
                      value: numeral(this.state.shophouse_min).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "shophouse_min", "shophouse"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "shophouse_min", "shophouse")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.shophouse_max_state === "success"}
                    error={this.state.shophouse_max_state === "error"}
                    labelText={<span>ราคาสูงสุด</span>}
                    id="max_shophouse"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาสูงสุด",
                      value: numeral(this.state.shophouse_max).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "shophouse_max", "shophouse"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "shophouse_max", "shophouse")
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        ) : null}
        {this.state.checked.includes(5) && (
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                ที่ดินเปล่า
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.land_min_state === "success"}
                    error={this.state.land_min_state === "error"}
                    labelText={<span>ราคาต่ำสุด</span>}
                    id="min_land"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาต่ำสุด",
                      value: numeral(this.state.land_min).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "land_min", "land"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "land_min", "land")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success={this.state.land_max_state === "success"}
                    error={this.state.land_max_state === "error"}
                    labelText={<span>ราคาสูงสุด</span>}
                    id="max_land"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "ราคาสูงสุด",
                      value: numeral(this.state.land_max).format(0, 0),
                      onChange: (event) =>
                        this.change(event, "land_max", "land"),
                      // onBlur: event =>
                      //   this.isValidDigit(event, "land_max", "land")
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}
      </GridContainer>
    );
  }
}

HomeType.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setHomeType: (payload) => dispatch(setHomeType(payload)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setMinMaxHouse: (payload) => dispatch(setMinMaxHouse(payload)),
  setDropDownHouseForSection2: (payload) =>
    dispatch(setDropDownHouseForSection2(payload)),
  setHousePriceForSection2: (payload) =>
    dispatch(setHousePriceForSection2(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HomeType));
