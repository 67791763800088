import React from "react";
import MaterialTable from "material-table";
import * as config from "configure/configServer.js";

const VillaImportView = (props) => {
  const [data, setData] = React.useState();
  const getData = async () => {
    let dataFilter = await fetch(`${config.API_showvillatemp}`, {
      method: "POST",
    }).then((res) => res.json());
    // console.log(dataFilter.data);
    setData(dataFilter.data);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <MaterialTable
        options={{
          showTitle: false,
        }}
        columns={[
          { title: "ID", field: "id" },
          { title: "ProjectId", field: "villa_projectid" },
          { title: "ProjQNo", field: "villa_refcode" },
          { title: "Zone", field: "zone" },
          { title: "ProjNameTh", field: "villa_nameth" },
          { title: "ApprovedStatus", field: "approved_status" },
          { title: "ProjectType", field: "project_type" },
          { title: "ProjectStatus", field: "cstats_name" },
          { title: "OwnershipStatus", field: "villa_typeowner" },
          { title: "StockMarketStatus", field: "stockmarket" },
          { title: "UnitType", field: "unit_type" },
          { title: "UnitStock", field: "stc_unit" },
          { title: "ContractAll", field: "stc_sellsigned" },
          { title: "Remaining", field: "stc_remaining" },
          { title: "NewContract", field: "stc_newsigned" },
          { title: "TransferedAll", field: "stc_transfered" },
          { title: "TransferedNew", field: "stc_newtransfer" },
          { title: "NotConstruct", field: "stc_notconstruct" },
          { title: "UnderConstruct", field: "stc_underconstruct" },
          { title: "FinishedConstruct", field: "stc_finished" },
          { title: "NewConstruct", field: "stc_newconstruct" },
          { title: "NewFinishedConstruct", field: "stc_newfinished" },
          { title: "Remain-NotConstruct", field: "stc_rmnotconstruct" },
          { title: "Remain-UnderConstruct", field: "stc_rmunderconstruct" },
          { title: "Remain-FinishedConstruct", field: "stc_rmfinished" },
          { title: "OldQNo", field: "villa_oldprojid" },
          { title: "OldRefcode", field: "villa_oldrefcode" },
          { title: "ProjNameEn", field: "villa_nameeng" },
          { title: "AddrhouseNo", field: "villa_address" },
          { title: "AddrMoo", field: "villa_moo" },
          { title: "AddrSoi", field: "villa_soi" },
          { title: "AddrRoad", field: "villa_road" },
          { title: "Sub-District", field: "villa_subdistrict" },
          { title: "District", field: "villa_district" },
          { title: "Province", field: "villa_province" },
          { title: "Postalcode", field: "villa_postalcode" },
          { title: "Lat", field: "lat" },
          { title: "Long", field: "lng" },
          { title: "ProjectArea", field: "project_area" },
          { title: "ProjectAreaValue", field: "area" },
          { title: "Rai", field: "villa_rai" },
          { title: "Ngan", field: "villa_ngan" },
          { title: "Sqwa", field: "villa_sqwa" },
          { title: "AllocationPermit", field: "allocate" },
          { title: "AllocationPermitValue", field: "permitvalue" },
          { title: "SaleType", field: "saletype" },
          { title: "SalePhase", field: "salephase" },
          { title: "DateStartSale", field: "villa_datefirstsell" },
          { title: "DateStartBuild", field: "villa_dateconstruct" },
          { title: "DateEndBuild", field: "villa_datefinish" },
          { title: "LandMinPricePerSqwa", field: "villa_minprice" },
          { title: "LandMaxPricePerSqwa", field: "villa_maxprice" },
          { title: "TotalFloor", field: "cat_floor" },
          { title: "FrontWidth", field: "cat_width" },
          { title: "LandAreaMin", field: "cat_minarea" },
          { title: "LandAreaHigh", field: "cat_maxarea" },
          { title: "houseAreaMin", field: "cat_minuseablearea" },
          { title: "houseAreaMax", field: "cat_maxuseablearea" },
          { title: "Bedroom", field: "cat_bedroom" },
          { title: "Bathroom", field: "cat_bathroom" },
          { title: "SalePriceLow", field: "cat_minprice" },
          { title: "SalePriceHigh", field: "cat_maxprice" },
          { title: "Discount", field: "cat_discount" },
          { title: "TotalUnit_Type", field: "totalunit_type" },
          { title: "TotalUnit_All", field: "totalunit_all" },
          { title: "ProjectValue", field: "villa_projectprice" },
          { title: "Price", field: "srpprice_info" },
          { title: "Facilities", field: "facility" },
          { title: "FacilitiesValue", field: "facility_value" },
          { title: "CompanyType", field: "company_type" },
          { title: "Developer_Name", field: "c_name" },
          { title: "Dev_Address", field: "c_address" },
          { title: "Dev_Floor", field: "c_floor" },
          { title: "Dev_BuildingName", field: "c_namebuilding" },
          { title: "Dev_Moo", field: "c_moo" },
          { title: "Dev_Soi", field: "c_soi" },
          { title: "Dev_Road", field: "c_road" },
          { title: "Dev_Subdistrict", field: "c_subdistrict" },
          { title: "Dev_District", field: "c_district" },
          { title: "Dev_Province", field: "c_province" },
          { title: "Dev_Tel", field: "c_tel" },
          { title: "SaleOfficerTel", field: "c_tel_saleofficer" },
          { title: "SaleOfficeTel", field: "villa_tel_saleoffice" },
          { title: "SaleOffice_Fax", field: "c_fax" },
          { title: "Website", field: "villa_projectwebsite" },
          { title: "SurveyCompany", field: "org_name" },
          { title: "SurveyerName", field: "villa_explorers" },
          { title: "SurveyDate", field: "villa_explorerdate" },
          { title: "Remarks", field: "villa_note" },
          { title: "Promotions", field: "villa_saleimprove" },
          { title: "Project for Elder", field: "villa_forelder" },
          { title: "villa_id", field: "villa_id" },
          { title: "c_id", field: "c_id" },
          { title: "cat_id", field: "cat_id" },
          { title: "cat_allunitinproject", field: "cat_allunitinproject" },
          { title: "stc_id", field: "stc_id" },
        ]}
        data={data}
      />
      {/* <Button
        color="primary"
        variant="contained"
        onClick={() => props.handleClose()}
      >
        ยกเลิก
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => props.handleClose()}
      >
        อัพเดท
      </Button> */}
    </div>
  );
};

export default VillaImportView;
