import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ChartIcon from "@material-ui/icons/BarChart";
import TableChart from "@material-ui/icons/TableChart";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import moment from "moment";

import Graph from "views/Report/Graph.js";
import FilterMain from "views/Report/FilterMain.js";
import FilterMainCP from "views/Report/FilterMainCP.js";
import TableView from "views/Report/TableView.js";
import TableViewCP from "views/Report/TableViewCP.js";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Button from "components/CustomButtons/Button.js";
import { connect } from "react-redux";
import setGraphData from "DataControl/actions/setGraphData.js";

import Map from "@material-ui/icons/Map";
import MapView from "views/Report/MapView.js";
import setPointsOnMap from "DataControl/actions/setPointsOnMap.js";
import CardBody from "components/Card/CardBody";

class Dashboard extends React.Component {
  state = {
    value: 0,
    multipleSelect: [],
    projectStatusSelect: [],
    prov: "",
    amphoe: "",
    tambon: "",
    selectedValue: "a",
    asset_type: "",
    view: 1,
  };

  componentDidMount() {
    moment.locale("th");
  }
  handleFilter = (event, value) => {
    this.setState({ selectedValue: event.target.value });
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };
  handleMultiple = (event) => {
    this.setState({ multipleSelect: event.target.value });
  };
  handleProjectStatus = (event) => {
    this.setState({ projectStatusSelect: event.target.value });
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleAssetType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showGraph = (type) => {
    if (type === 1) {
      this.setState({ view: 1 });
    } else if (type === 2) {
      this.setState({ view: 2 });
    } else {
      this.setState({ view: 3 });
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Tabs
        headerColor="info"
        tabs={[
          {
            tabName: (
              <h4>
                <b>รายงานข้อมูลสมาชิก</b>
              </h4>
            ),
            tabContent: (
              <div>
                <GridContainer>
                  <GridItem xs={12}>
                    <Button
                      color={
                        this.state.view === 1 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(1)}
                    >
                      {" "}
                      <ChartIcon /> กราฟ
                    </Button>
                    <Button
                      color={
                        this.state.view === 2 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(2)}
                    >
                      {" "}
                      <TableChart /> ตาราง
                    </Button>
                    <Button
                      color={
                        this.state.view === 3 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(3)}
                    >
                      {" "}
                      <Map /> แผนที่
                    </Button>
                  </GridItem>
                  <GridItem xs={12}>
                    {this.state.view !== 3 && (
                      <CardBody>
                        <FilterMain />
                      </CardBody>
                    )}
                  </GridItem>
                </GridContainer>
                {this.state.view === 1 && <Graph />}
                {this.state.view === 2 && <TableView />}
                {this.state.view === 3 && (
                  <div style={{ height: "70vh", marginTop: "15px" }}>
                    <MapView></MapView>
                  </div>
                )}
              </div>
            ),
          },
          {
            tabName: (
              <h4>
                <b>ภาพรวมตลาด</b>
              </h4>
            ),
            tabContent: (
              <div>
                <GridContainer>
                  <GridItem xs={12}>
                    <Button
                      color={
                        this.state.view === 1 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(1)}
                    >
                      {" "}
                      <ChartIcon /> กราฟ
                    </Button>
                    <Button
                      color={
                        this.state.view === 2 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(2)}
                    >
                      {" "}
                      <TableChart /> ตาราง
                    </Button>
                    <Button
                      color={
                        this.state.view === 3 ? "info" : classes.classTable
                      }
                      onClick={() => this.showGraph(3)}
                    >
                      {" "}
                      <Map /> แผนที่
                    </Button>
                  </GridItem>
                  <GridItem xs={12}>
                    {this.state.view !== 3 && (
                      <CardBody>
                        <FilterMainCP />
                      </CardBody>
                    )}
                  </GridItem>
                </GridContainer>
                {this.state.view === 1 && <Graph />}
                {this.state.view === 2 && <TableViewCP />}
                {this.state.view === 3 && (
                  <div style={{ height: "70vh", marginTop: "15px" }}>
                    <MapView></MapView>
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setGraphData: (token) => dispatch(setGraphData(token)),
  setPointsOnMap: (payload) => dispatch(setPointsOnMap(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
