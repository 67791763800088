import React from "react";
import MaterialTable from "material-table";
import TabView from "./TabView";
import CondoImportView from "./CondoImportView";

const CondoErrorView = ({ data }) => {
  // console.log(data);
  return (
    <div>
      <MaterialTable
        options={{
          showTitle: false,
        }}
        columns={[
          { title: "ID", field: "id" },
          { title: "ProjectId", field: "ProjectId" },
          { title: "ProjQNo", field: "ProjQNo" },
          { title: "Zone", field: "Zone" },
          { title: "ProjNameTh", field: "ProjNameTh" },
          { title: "ApprovedStatus", field: "ApprovedStatus" },
          { title: "ProjectType", field: "ProjectType" },
          { title: "ProjectStatus", field: "ProjectStatus" },
          { title: "OwnershipStatus", field: "OwnershipStatus" },
          { title: "StockMarketStatus", field: "StockMarketStatus" },
          { title: "UnitType", field: "UnitType" },
          { title: "UnitStock", field: "UnitStock" },
          { title: "ContractAll", field: "ContractAll" },
          { title: "Remaining", field: "Remaining" },
          { title: "NewContract", field: "NewContract" },
          { title: "TransferedAll", field: "TransferedAll" },
          { title: "TransferedNew", field: "TransferedNew" },
          { title: "OldQNo", field: "OldQNo" },
          { title: "OldRefcode", field: "OldRefcode" },
          { title: "ProjNameEn", field: "ProjNameEn" },
          { title: "AddrHouseNo", field: "AddrHouseNo" },
          { title: "AddrMoo", field: "AddrMoo" },
          { title: "AddrSoi", field: "AddrSoi" },
          { title: "AddrRoad", field: "AddrRoad" },
          { title: "Sub-District", field: "Sub-District" },
          { title: "District", field: "District" },
          { title: "Province", field: "Province" },
          { title: "Postalcode", field: "Postalcode" },
          { title: "Lat", field: "Lat" },
          { title: "Long", field: "Long" },
          { title: "ProjectArea", field: "ProjectArea" },
          { title: "ProjectAreaValue", field: "ProjectAreaValue" },
          { title: "Rai", field: "Rai" },
          { title: "Ngan", field: "Ngan" },
          { title: "Sqwa", field: "Sqwa" },
          { title: "BuildingPermit", field: "BuildingPermit" },
          { title: "BuildingPermitvalue", field: "BuildingPermitvalue" },
          { title: "EIA_Approval", field: "EIA_Approval" },
          { title: "BuildRegistered", field: "BuildRegistered" },
          { title: "BuildRegisteredvalue", field: "BuildRegisteredvalue" },
          { title: "SaleType", field: "SaleType" },
          { title: "SalePhase", field: "SalePhase" },
          {
            title: "SalePhaseAmoutBuilding",
            field: "SalePhaseAmoutBuilding",
          },
          { title: "DateStartSale", field: "DateStartSale" },
          { title: "DateStartBuild", field: "DateStartBuild" },
          { title: "DateEndBuild", field: "DateEndBuild" },
          { title: "MinPricePerSqm", field: "MinPricePerSqm" },
          { title: "MaxPricePerSqm", field: "MaxPricePerSqm" },
          { title: "AvgpricePerSqm", field: "AvgpricePerSqm" },
          { title: "BuildingName", field: "BuildingName" },
          { title: "TotalFloor", field: "TotalFloor" },
          { title: "TotalUnitPerBld", field: "TotalUnitPerBld" },
          { title: "BuildingStatus", field: "BuildingStatus" },
          { title: "StatusDate", field: "StatusDate" },
          { title: "RoomAreaMin", field: "RoomAreaMin" },
          { title: "RoomAreaMax", field: "RoomAreaMax" },
          { title: "SalePriceLow", field: "SalePriceLow" },
          { title: "SalePriceHigh", field: "SalePriceHigh" },
          { title: "Discount", field: "Discount" },
          { title: "TotalUnit_Type", field: "TotalUnit_Type" },
          { title: "TotalUnit_All", field: "TotalUnit_All" },
          { title: "ProjectValue", field: "ProjectValue" },
          { title: "Price", field: "Price" },
          { title: "Facilities", field: "Facilities" },
          { title: "FacilitiesValue", field: "FacilitiesValue" },
          { title: "CompanyType", field: "CompanyType" },
          { title: "Developer_Name", field: "Developer_Name" },
          { title: "Dev_Address", field: "Dev_Address" },
          { title: "Dev_Floor", field: "Dev_Floor" },
          { title: "Dev_BuildingName", field: "Dev_BuildingName" },
          { title: "Dev_Moo", field: "Dev_Moo" },
          { title: "Dev_Soi", field: "Dev_Soi" },
          { title: "Dev_Road", field: "Dev_Road" },
          { title: "Dev_Subdistrict", field: "Dev_Subdistrict" },
          { title: "Dev_District", field: "Dev_District" },
          { title: "Dev_Province", field: "Dev_Province" },
          { title: "Dev_Tel", field: "Dev_Tel" },
          { title: "SaleOfficerTel", field: "SaleOfficerTel" },
          { title: "SaleOfficeTel", field: "SaleOfficeTel" },
          { title: "SaleOffice_Fax", field: "SaleOffice_Fax" },
          { title: "Website", field: "Website" },
          { title: "SurveyCompany", field: "SurveyCompany" },
          { title: "SurveyerName", field: "SurveyerName" },
          { title: "SurveyDate", field: "SurveyDate" },
          { title: "Remarks", field: "Remarks" },
          { title: "Promotions", field: "Promotions" },
          { title: "Project for Elder", field: "Project for Elder" },
          { title: "condo_id", field: "condo_id" },
          { title: "projectpoint_id", field: "projectpoint_id" },
          { title: "bs_id", field: "bs_id" },
          { title: "sellroom_id", field: "sellroom_id" },
          { title: "st_id", field: "st_id" },
          { title: "st_buildingname", field: "st_buildingname" },
          { title: "c_id", field: "c_id" },
        ]}
        data={data}
      />
    </div>
  );
};

const OverallView = ({ data }) => {
  return (
    <TabView
      errorTab={<CondoErrorView data={data} />}
      dataTab={<CondoImportView />}
    />
  );
};

export default OverallView;
