import React from "react";
import MaterialTable from "material-table";
import * as config from "configure/configServer.js";

const CondoImportView = (props) => {
  const [data, setData] = React.useState();
  const getData = async () => {
    let dataFilter = await fetch(`${config.API_showcondotemp}`, {
      method: "POST",
    }).then((res) => res.json());
    // console.log(dataFilter.data);
    setData(dataFilter.data);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <MaterialTable
        options={{
          showTitle: false,
        }}
        columns={[
          { title: "ID", field: "id" },
          { title: "ProjectId", field: "condo_projectid" },
          { title: "ProjQNo", field: "condo_refcode" },
          { title: "Zone", field: "zone" },
          { title: "ProjNameTh", field: "condo_nameth" },
          { title: "ApprovedStatus", field: "approved_status" },
          { title: "ProjectType", field: "project_type" },
          { title: "ProjectStatus", field: "cstats_name" },
          { title: "OwnershipStatus", field: "condo_typeowner" },
          { title: "StockMarketStatus", field: "stockmarket" },
          { title: "UnitType", field: "unit_type" },
          { title: "UnitStock", field: "st_allunit" },
          { title: "ContractAll", field: "st_allsigned" },
          { title: "Remaining", field: "st_remaining" },
          { title: "NewContract", field: "st_newsigned" },
          { title: "TransferedAll", field: "st_transfered" },
          { title: "TransferedNew", field: "st_newtransfered" },
          { title: "OldQNo", field: "condo_oldprojid" },
          { title: "OldRefcode", field: "condo_oldrefcode" },
          { title: "ProjNameEn", field: "condo_nameeng" },
          { title: "AddrHouseNo", field: "condo_address" },
          { title: "AddrMoo", field: "condo_moo" },
          { title: "AddrSoi", field: "condo_soi" },
          { title: "AddrRoad", field: "condo_road" },
          { title: "Sub-District", field: "condo_subdistrict" },
          { title: "District", field: "condo_district" },
          { title: "Province", field: "condo_province" },
          { title: "Postalcode", field: "condo_postalcode" },
          { title: "Lat", field: "lat" },
          { title: "Long", field: "lng" },
          { title: "ProjectArea", field: "project_area" },
          { title: "ProjectAreaValue", field: "area" },
          { title: "Rai", field: "condo_rai" },
          { title: "Ngan", field: "condo_ngan" },
          { title: "Sqwa", field: "condo_sqwa" },
          { title: "BuildingPermit", field: "permit" },
          { title: "BuildingPermitvalue", field: "permitvalue" },
          { title: "EIA_Approval", field: "eiaapproval" },
          { title: "BuildRegistered", field: "registed" },
          { title: "BuildRegisteredvalue", field: "registedvalue" },
          { title: "SaleType", field: "saletype" },
          { title: "SalePhase", field: "salephase" },
          {
            title: "SalePhaseAmoutBuilding",
            field: "condo_presaleamoutbuilding",
          },
          { title: "DateStartSale", field: "condo_datefirstsell" },
          { title: "DateStartBuild", field: "condo_dateconstruct" },
          { title: "DateEndBuild", field: "condo_datefinish" },
          { title: "MinPricePerSqm", field: "condo_minprice" },
          { title: "MaxPricePerSqm", field: "condo_maxprice" },
          { title: "AvgpricePerSqm", field: "condo_averageprice" },
          { title: "BuildingName", field: "sellroom_buildingname" },
          { title: "TotalFloor", field: "bs_totalfloor" },
          { title: "TotalUnitPerBld", field: "bs_totalunit" },
          { title: "BuildingStatus", field: "buildingstatus" },
          { title: "StatusDate", field: "statusdate" },
          { title: "RoomAreaMin", field: "sellroom_minarea" },
          { title: "RoomAreaMax", field: "sellroom_maxarea" },
          { title: "SalePriceLow", field: "sellroom_minprice" },
          { title: "SalePriceHigh", field: "sellroom_maxprice" },
          { title: "Discount", field: "sellroom_discount" },
          { title: "TotalUnit_Type", field: "sellroom_allunitinproject" },
          { title: "TotalUnit_All", field: "totalunit_all" },
          { title: "ProjectValue", field: "condo_projectprice" },
          { title: "Price", field: "srpprice_info" },
          { title: "Facilities", field: "facility" },
          { title: "FacilitiesValue", field: "facility_value" },
          { title: "CompanyType", field: "company_type" },
          { title: "Developer_Name", field: "c_name" },
          { title: "Dev_Address", field: "c_address" },
          { title: "Dev_Floor", field: "c_floor" },
          { title: "Dev_BuildingName", field: "c_namebuilding" },
          { title: "Dev_Moo", field: "c_moo" },
          { title: "Dev_Soi", field: "c_soi" },
          { title: "Dev_Road", field: "c_road" },
          { title: "Dev_Subdistrict", field: "c_subdistrict" },
          { title: "Dev_District", field: "c_district" },
          { title: "Dev_Province", field: "c_province" },
          { title: "Dev_Tel", field: "c_tel" },
          { title: "SaleOfficerTel", field: "c_tel_saleofficer" },
          { title: "SaleOfficeTel", field: "condo_tel_saleoffice" },
          { title: "SaleOffice_Fax", field: "c_fax" },
          { title: "Website", field: "condo_projectwebsite" },
          { title: "SurveyCompany", field: "org_name" },
          { title: "SurveyerName", field: "condo_explorers" },
          { title: "SurveyDate", field: "condo_explorerdate" },
          { title: "Remarks", field: "condo_note" },
          { title: "Promotions", field: "condo_saleimprove" },
          { title: "Project for Elder", field: "condo_forelder" },
          { title: "MixedUse", field: "condo_mixeduse" },
          { title: "condo_id", field: "condo_id" },
          { title: "projectpoint_id", field: "projectpoint_id" },
          { title: "bs_id", field: "bs_id" },
          { title: "sellroom_id", field: "sellroom_id" },
          { title: "st_id", field: "st_id" },
          { title: "st_buildingname", field: "st_buildingname" },
          { title: "c_id", field: "c_id" },
        ]}
        data={data}
      />
      {/* <Button
        color="primary"
        variant="contained"
        onClick={() => props.handleClose()}
      >
        ยกเลิก
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => props.handleClose()}
      >
        อัพเดท
      </Button> */}
    </div>
  );
};

export default CondoImportView;
