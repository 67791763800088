/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes, { number } from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInputTooltips.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import numeral from "numeral";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-thai-datepickers";
import "moment/locale/th";

import { connect } from "react-redux";

import setBoundary from "actions/survey/setBoundary.js";
import setProv from "actions/survey/setProvAmphoeTambon.js";

import condo_sec1_part2Object from "actions/survey/condo/condo_sec1_part2Data.js";
import project_datatable from "actions/survey/psurvey_editproj.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class PublishInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      survey_registered: "",
      survey_registeredVal: "",
      survey_constructapprove: "",
      survey_salesDate: null,
      survey_salesDateState: "",
      // survey_constructedDate: null,
      // survey_constructedDateState: "",
      // survey_finishDate: null,
      // survey_finishDateState: "",
      survey_prostatus: "",
      survey_prostatusState: "",
      survey_minprice: 0,
      survey_minpriceState: "",
      survey_minpriceStateName: "",
      survey_maxprice: 0,
      survey_maxpriceState: "",
      survey_maxpriceStateName: "",
      survey_averageprice: 0,
      survey_averagepriceState: "",
      survey_averagepriceStateName: "",
      survey_constructapproveState: "",
      survey_constructapproveVal: "",
      survey_envapprove: "",
      survey_constructapproveValState: "",
      survey_saleimprove: "",
      survey_saleimproveState: "",
      survey_projectprice: 0,
      survey_projectpriceState: "",
      checked: [],
      checkedState: "",
      other_utility: "",
      other_utilityState: "",
      // check project condition
      isallow_status1: false,
      isallow_status2: false,
      isallow_status3: false,
      isallow_status4: false,
      isallow_status5: false,
      isallow_status6: false,
      openSnack: false,
      alertSnack: false,
      survey_placeholderMinprice: "",
      survey_placeholderMaxprice: "",
      survey_placeholderAvgprice: "",
    };
    this.handle_projectStatus = this.handle_projectStatus.bind(this);
    this.handle_constructApprove = this.handle_constructApprove.bind(this);
    this.handle_envapprove = this.handle_envapprove.bind(this);
    this.handle_registered = this.handle_registered.bind(this);
    this.handle_registeredVal = this.handle_registeredVal.bind(this);
    this.showOtherUtilities = this.showOtherUtilities.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      this.props.project_datatable(undefined);
    } else {
      this.get_projectData(this.props.proj_dataTable, false);
      // console.log(this.props.proj_dataTable);
    }
  }
  componentDidUpdate(previousProps) {
    if (
      JSON.stringify(previousProps.oldproj_data) !==
      JSON.stringify(this.props.oldproj_data)
    ) {
      this.get_projectData(this.props.oldproj_data, true);
    }
    if (
      previousProps.project_id !== this.props.project_id &&
      this.props.project_id &&
      this.state.survey_salesDate
    ) {
      this.setProperStatus(this.state.survey_salesDate, this.props.project_id);
    }
  }
  renderPlaceholder = (value, type) => {
    const number = numeral(value);

    switch (type) {
      case "min":
        if (value === 0 || value === "") {
          return "ราคาต่ำสุด";
        } else {
          return "ราคาต่ำสุด (ราคาเก่า: " + number.format("0,0") + ")";
        }
      case "max":
        if (value === 0 || value === "") {
          return "ราคาสูงสุด";
        } else {
          return "ราคาสูงสุด (ราคาเก่า: " + number.format("0,0") + ")";
        }
      case "avg":
        if (value === 0 || value === "") {
          return "ราคาเฉลี่ย";
        } else {
          return "ราคาเฉลี่ย (ราคาเก่า: " + number.format("0,0") + ")";
        }
      default:
        break;
    }
  };
  get_projectData = (value, isOld) => {
    const edit_data = value.data[0];
    if (!isOld) {
      const exp = this.array_jsonremove(value.unity);
      this.showOtherUtilities(exp);
      edit_data.arrayChk = exp;
      this.setState({
        survey_envapprove: edit_data.condo_envapprove,
        survey_registered: this.check_editRegister(edit_data.condo_registered),
        survey_salesDate:
          moment(edit_data.condo_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.condo_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1970" ||
          edit_data.condo_datefirstsell === null ||
          edit_data.condo_datefirstsell === undefined
            ? null
            : moment(edit_data.condo_datefirstsell),
        // survey_constructedDate:
        //   moment(edit_data.condo_dateconstruct).format("DD/MM/YYYY") ===
        //     "01/01/1900" ||
        //   moment(edit_data.condo_dateconstruct).format("DD/MM/YYYY") ===
        //     "01/01/1970" ||
        //   edit_data.condo_dateconstruct === null ||
        //   edit_data.condo_dateconstruct === undefined
        //     ? null
        //     : moment(edit_data.condo_dateconstruct),
        // survey_finishDate:
        //   moment(edit_data.condo_datefinish).format("DD/MM/YYYY") ===
        //     "01/01/1900" ||
        //   moment(edit_data.condo_datefinish).format("DD/MM/YYYY") ===
        //     "01/01/1970" ||
        //   edit_data.condo_datefinish === null ||
        //   edit_data.condo_datefinish === undefined
        //     ? null
        //     : moment(edit_data.condo_datefinish),
        survey_prostatus: JSON.stringify(edit_data.condo_projectstatus),
        survey_maxprice: edit_data.condo_maxprice,
        survey_minprice: edit_data.condo_minprice,
        survey_averageprice: edit_data.condo_averageprice,
        survey_constructapprove: this.check_editAllocate(
          edit_data.condo_constructapprove
        ),
        survey_saleimprove: edit_data.condo_saleimprove,
        survey_projectprice: edit_data.condo_projectprice,
        checked: exp,
      });
    } else {
      this.setState({
        survey_envapprove: edit_data.condo_envapprove,
        survey_registered: this.check_editRegister(edit_data.condo_registered),
        survey_salesDate:
          moment(edit_data.condo_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.condo_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1970" ||
          edit_data.condo_datefirstsell === null ||
          edit_data.condo_datefirstsell === undefined
            ? null
            : moment(edit_data.condo_datefirstsell),
        // survey_constructedDate:
        //   moment(edit_data.condo_dateconstruct).format("DD/MM/YYYY") ===
        //     "01/01/1900" ||
        //   moment(edit_data.condo_dateconstruct).format("DD/MM/YYYY") ===
        //     "01/01/1970" ||
        //   edit_data.condo_dateconstruct === null ||
        //   edit_data.condo_dateconstruct === undefined
        //     ? null
        //     : moment(edit_data.condo_dateconstruct),
        // survey_finishDate:
        //   moment(edit_data.condo_datefinish).format("DD/MM/YYYY") ===
        //     "01/01/1900" ||
        //   moment(edit_data.condo_datefinish).format("DD/MM/YYYY") ===
        //     "01/01/1970" ||
        //   edit_data.condo_datefinish === null ||
        //   edit_data.condo_datefinish === undefined
        //     ? null
        //     : moment(edit_data.condo_datefinish),
        survey_constructapprove: this.check_editAllocate(
          edit_data.condo_constructapprove
        ),
      });
    }
    // console.log("getfirstsell", edit_data.condo_datefirstsell);
    // console.log("getdatefinish", edit_data.condo_datefinish);
    this.setAllSuccess(edit_data, isOld);
  };

  setProperStatus(firstsale, projectid) {
    var projectid_quater = this.getQuater(projectid);
    if (
      moment(firstsale).format("DD/MM/YYYY") === "01/01/1900" ||
      moment(firstsale).format("DD/MM/YYYY") === "01/01/1970"
    ) {
      return;
    }
    switch (projectid_quater[3]) {
      case "Q":
        this.saledateQuarterFilter(new Date(firstsale), projectid_quater);
        break;
      case "H":
        this.saledateHalfFilter(new Date(firstsale), projectid_quater);
        break;
      default:
        break;
    }
  }

  array_jsonremove(unity) {
    const arr = [];
    for (let i = 0; i < unity.length; i++) {
      if (unity[i].unity_detail !== "") {
        arr.push(unity[i].unity_detail);
      }
    }
    // console.log(arr)
    return arr;
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  getQuater(projectid) {
    // console.log("tes", this.props.getregtime)
    if (this.props.getregtime) {
      // new project
      var year = this.props.getregyear;
      var halfyear = this.props.getreghalf;
      var quarter = this.props.getregquarter;
      // console.log("test 1", [year, halfyear, quarter])
      return [year, halfyear, quarter];
    } else {
      // existing project
      // alert(projectid)
      if (projectid) {
        let year = parseInt(projectid.substring(0, 2)) + 2500 - 543;
        let period = projectid.substring(2, 3);
        if (period === "Q") {
          let quarter = parseInt(projectid.substring(3, 4));
          let halfyear = quarter <= 2 ? 1 : 2;
          // console.log([year, halfyear, quarter]);
          // console.log("test 2", [year, halfyear, quarter])
          return [year, halfyear, quarter, period];
        } else {
          let halfyear = parseInt(projectid.substring(3, 4));
          let quarter = halfyear === 1 ? 2 : 4;
          // console.log([year, halfyear, quarter])
          // console.log("test 3", [year, halfyear, quarter])
          return [year, halfyear, quarter, period];
        }
      }
      return null;
    }
  }
  setSaleDate = (date) => {
    // console.log(this.props.project_id)
    var projectid_quater = this.getQuater(this.props.project_id);
    if (!projectid_quater) {
      // console.log("quarter")
      return alert("กรุณาสร้างเลขที่แบบสอบถามก่อนเลือกวันที่เปิดขาย");
    }
    //var projectid_quater = [1,2020];
    this.setState({ survey_prostatus: "", survey_prostatusState: "error" });
    // console.log(projectid_quater)
    // this.saledateFilter(date, projectid_quater);

    switch (projectid_quater[3]) {
      case "Q":
        this.saledateQuarterFilter(date, projectid_quater);
        break;
      case "H":
        this.saledateHalfFilter(date, projectid_quater);
        break;
      default:
        this.setState({
          survey_salesDate: null,
          survey_salesDateState: "error",
          openSnack: true,
        });
        break;
    }

    setTimeout(() => {
      this.setState({
        openSnack: false,
      });
    }, 5000);
  };

  // setconstructedDate = (date) => {
  //   // console.log(this.props.getregtime)
  //   try {
  //     const valformat = moment(new Date(date)).format();
  //     this.setState({
  //       survey_constructedDate: valformat,
  //       survey_constructedDateState: "success",
  //     });
  //   } catch (err) {
  //     //
  //   }
  // };

  // setfinishDate = (date) => {
  //   try {
  //     const valformat = moment(new Date(date)).format();
  //     this.setState({
  //       survey_finishDate: valformat,
  //       survey_finishDateState: "success",
  //     });
  //   } catch (err) {
  //     //
  //   }
  // };

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const intval = parseInt(numeral(value).value());
    if (intval > 0) {
      return true;
    }
    return false;
  }
  verifyNote(value) {
    var nameRex = /^[a-zA-Zก-๙0-9+=%._,/()-\s/]+$/;
    var noQuote = /^[^'"]*$/;
    var noNewline = /^[^\r\n]*$/;
    if (nameRex.test(value) && noQuote.test(value) && noNewline.test(value)) {
      return true;
    }
    return false;
  }
  verifyNo(value) {
    var nameRex = /^[a-zA-Zก-๙0-9._/-\s/]+$/;
    if (value === 1 || value === "1") {
      return false;
    }
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyPriceRange(value, flag, cval) {
    var intval = parseInt(numeral(value).value());
    var intcval = [
      parseInt(numeral(cval[0]).value()),
      parseInt(numeral(cval[1]).value()),
    ];
    // }
    switch (flag) {
      case "min-value":
        // intval min
        // intcval[0] max
        // intcval[1] avg
        if (isNaN(intval)) {
          intval = 0;
        }
        if (isNaN(intcval[0])) {
          intcval[0] = 0;
        }
        if (isNaN(intcval[1])) {
          intcval[1] = 0;
        }
        // console.log("min check", intval, intcval[0], intcval[1]);
        if (intval === 0 && intcval[0] === 0 && intcval[1] === 0) {
          // console.log("0 min");
          this.setState({
            survey_minpriceState: "success",
            survey_averagepriceState: "success",
            survey_maxpriceState: "success",
            survey_minpriceStateName: "",
            survey_averagepriceStateName: "",
            survey_maxpriceStateName: "",
          });
          return true;
        }
        if (intval < 1000) {
          // console.log("1 min");
          this.setState({
            survey_minpriceState: "error",
            survey_minpriceStateName: "มูลค่าต่ำกว่า 1,000",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_minpriceStateName: "",
          });
        }
        if (intcval[0] >= intval * 10) {
          // console.log("2 min");
          this.setState({
            survey_maxpriceState: "error",
            survey_maxpriceStateName: "มูลค่าเกินราคาต่ำสุด 10 เท่า",
          });
          return false;
        } else {
          this.setState({
            survey_maxpriceState: "success",
            survey_maxpriceStateName: "",
          });
        }
        if (intval > intcval[0]) {
          // console.log("3 min");
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
          });
        }
        if (intval > intcval[1] || intcval[1] > intcval[0]) {
          // console.log("4 min");
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
            survey_averagepriceState: "error",
            survey_averagepriceStateName: "ต้องอยู่ระหว่างราคาต่ำสุดกับสูงสุด",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
            survey_averagepriceState: "success",
            survey_averagepriceStateName: "",
          });
        }
        // console.log("min success");
        this.setState({
          survey_minpriceState: "success",
          survey_averagepriceState: "success",
          survey_maxpriceState: "success",
          survey_minpriceStateName: "",
          survey_averagepriceStateName: "",
          survey_maxpriceStateName: "",
        });
        return true;
      case "max-value":
        // intval max
        // intcval[0] min
        // intcval[1] avg
        if (isNaN(intval)) {
          intval = 0;
        }
        if (isNaN(intcval[0])) {
          intcval[0] = 0;
        }
        if (isNaN(intcval[1])) {
          intcval[1] = 0;
        }
        // console.log("max check", intval, intcval[0], intcval[1]);
        if (intval === 0 && intcval[0] === 0 && intcval[1] === 0) {
          // console.log("0 max");
          this.setState({
            survey_minpriceState: "success",
            survey_averagepriceState: "success",
            survey_maxpriceState: "success",
            survey_minpriceStateName: "",
            survey_averagepriceStateName: "",
            survey_maxpriceStateName: "",
          });
          return true;
        }
        if (intcval[0] < 1000) {
          // console.log("1 max");
          this.setState({
            survey_minpriceState: "error",
            survey_minpriceStateName: "มูลค่าต่ำกว่า 1,000",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_minpriceStateName: "",
          });
        }
        if (intval >= intcval[0] * 10) {
          // console.log("2 max");
          this.setState({
            survey_maxpriceState: "error",
            survey_maxpriceStateName: "มูลค่าเกินราคาต่ำสุด 10 เท่า",
          });
          return false;
        } else {
          this.setState({
            survey_maxpriceState: "success",
            survey_maxpriceStateName: "",
          });
        }
        if (intcval[0] > intval) {
          // console.log("3 max");
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
          });
        }
        if (intcval[0] > intcval[1] || intcval[1] > intval) {
          // console.log("4 max");
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
            survey_averagepriceState: "error",
            survey_averagepriceStateName: "ต้องอยู่ระหว่างราคาต่ำสุดกับสูงสุด",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
            survey_averagepriceState: "success",
            survey_averagepriceStateName: "",
          });
        }
        // console.log("max success");
        this.setState({
          survey_minpriceState: "success",
          survey_averagepriceState: "success",
          survey_maxpriceState: "success",
          survey_minpriceStateName: "",
          survey_averagepriceStateName: "",
          survey_maxpriceStateName: "",
        });
        return true;
      case "average-value":
        // intval avg
        // intcval[0] min
        // intcval[1] max
        if (isNaN(intval)) {
          intval = 0;
        }
        if (isNaN(intcval[0])) {
          intcval[0] = 0;
        }
        if (isNaN(intcval[1])) {
          intcval[1] = 0;
        }
        // console.log("avg check", intval, intcval[0], intcval[1]);
        if (intval === 0 && intcval[0] === 0 && intcval[1] === 0) {
          // console.log("0 avg");
          this.setState({
            survey_minpriceState: "success",
            survey_averagepriceState: "success",
            survey_maxpriceState: "success",
            survey_minpriceStateName: "",
            survey_averagepriceStateName: "",
            survey_maxpriceStateName: "",
          });
          return true;
        }
        if (intcval[0] < 1000) {
          // console.log("1 avg");
          this.setState({
            survey_minpriceState: "error",
            survey_minpriceStateName: "มูลค่าต่ำกว่า 1,000",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_minpriceStateName: "",
          });
        }
        if (intcval[1] >= intcval[0] * 10) {
          // console.log("2 avg");
          this.setState({
            survey_maxpriceState: "error",
            survey_maxpriceStateName: "มูลค่าเกินราคาต่ำสุด 10 เท่า",
          });
          return false;
        } else {
          this.setState({
            survey_maxpriceState: "success",
            survey_maxpriceStateName: "",
          });
        }
        if (intcval[0] > intcval[1]) {
          // console.log("3 avg");
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
          });
        }
        if (intcval[0] > intval || intval > intcval[1]) {
          // console.log("4 avg");
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
            survey_averagepriceState: "error",
            survey_averagepriceStateName: "ต้องอยู่ระหว่างราคาต่ำสุดกับสูงสุด",
          });
          return false;
        } else {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
            survey_averagepriceState: "success",
            survey_averagepriceStateName: "",
          });
        }
        // console.log("avg success");
        this.setState({
          survey_minpriceState: "success",
          survey_averagepriceState: "success",
          survey_maxpriceState: "success",
          survey_minpriceStateName: "",
          survey_averagepriceStateName: "",
          survey_maxpriceStateName: "",
        });
        return true;
      default:
        break;
    }
  }

  // validate forms
  change(event, stateName, type, compareValue) {
    let value = event.target.value;
    switch (type) {
      case "other_utility":
        if (this.verifyNote(event.target.value)) {
          this.setState({ other_utility: value });
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "name":
        if (this.verifyNote(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyPriceRange(event.target.value, type, compareValue)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyPriceRange(event.target.value, type, compareValue)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "average-value":
        if (this.verifyPriceRange(event.target.value, type, compareValue)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "survey_constructapproveVal":
        if (
          this.verifyNo(event.target.value) &&
          (event.target.value.length > 1 || event.target.value === "-")
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  saledateQuarterFilter = (date, quater) => {
    // console.log(date)
    // console.log(quater)
    const quaterDate = moment(date).quarter();
    // const halfYearDate = moment(date).month() <= 5 ? 1 : 2;
    this.setState({
      survey_salesDate: moment(date),
      survey_salesDateState: "success",
    });
    // console.log(quaterDate);
    // console.log(date.year());
    // console.log(quater[0]);
    // console.log(quater[1]);
    if (moment(date).year() === quater[0] && quater[2] === quaterDate) {
      // console.log('this peroid')
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: false,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: false,
      });
    } else if (
      moment(date).year() < quater[0] ||
      (moment(date).year() === quater[0] && quater[2] > quaterDate)
    ) {
      // console.log('past peroid')
      this.setState({
        isallow_status1: false,
        isallow_status2: false,
        isallow_status3: true,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: true,
      });
    } else if (
      moment(date).year() > quater[0] ||
      (moment(date).year() === quater[0] && quater[2] < quaterDate)
    ) {
      // console.log('future peroid')
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: true,
        isallow_status4: false,
        isallow_status5: true,
        isallow_status6: true,
      });
    }
  };

  saledateHalfFilter = (date, quater) => {
    // console.log(date)
    // console.log(quater)
    // const quaterDate = moment(date).quarter();
    const halfYearDate = moment(date).month() <= 5 ? 1 : 2;
    this.setState({
      survey_salesDate: moment(date),
      survey_salesDateState: "success",
    });
    // console.log(halfYearDate)
    // console.log(date.year())
    // console.log(quater[0])
    // console.log(quater[1])
    if (moment(date).year() === quater[0] && quater[1] === halfYearDate) {
      // console.log('this peroid')
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: false,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: false,
      });
    } else if (
      moment(date).year() < quater[0] ||
      (moment(date).year() === quater[0] && quater[1] > halfYearDate)
    ) {
      // console.log('past peroid')
      this.setState({
        isallow_status1: false,
        isallow_status2: false,
        isallow_status3: true,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: true,
      });
    } else if (
      moment(date).year() > quater[0] ||
      (moment(date).year() === quater[0] && quater[1] < halfYearDate)
    ) {
      // console.log('future peroid')
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: true,
        isallow_status4: false,
        isallow_status5: true,
        isallow_status6: true,
      });
    }
  };

  // handle trigger function
  // check add org trigger?
  validateRegister() {
    const {
      survey_salesDate,
      // survey_constructedDate,
      // survey_finishDate,
      survey_prostatus,
      survey_maxprice,
      survey_minprice,
      survey_averageprice,
      survey_constructapprove,
      survey_saleimprove,
      survey_projectprice,
      survey_constructapproveVal,
      checked,
      survey_registered,
      survey_registeredVal,
      survey_envapprove,
    } = this.state;
    let newChecked = [...checked];

    newChecked = newChecked.filter((props) => {
      return (
        props === "security" ||
        (props === "other") | (props === "clubhouse") | (props === "pool")
      );
    });

    if (newChecked.indexOf("other") !== -1) {
      newChecked.push(this.state.other_utility);
    } else {
      this.setState({ other_utility: "" });
    }

    setTimeout(() => {
      var body = {
        condo_envapprove: !survey_envapprove ? "0" : survey_envapprove,
        condo_constructapprove:
          survey_constructapprove === "1" ? survey_constructapproveVal : "1",
        condo_registered:
          survey_registered === "1" ? survey_registeredVal : "1",
        condo_datefirstsell:
          survey_salesDate === "" || survey_salesDate === null
            ? "01/01/1900"
            : moment(new Date(survey_salesDate)).format("DD/MM/YYYY"),
        // condo_dateconstruct:
        //   survey_constructedDate === "" || survey_constructedDate === null
        //     ? "01/01/1900"
        //     : moment(new Date(survey_constructedDate)).format("DD/MM/YYYY"),
        // condo_datefinish:
        //   survey_finishDate === "" || survey_finishDate === null
        //     ? "01/01/1900"
        //     : moment(new Date(survey_finishDate)).format("DD/MM/YYYY"),
        condo_projectstatus:
          survey_prostatus === "" ? 0 : parseInt(survey_prostatus),
        condo_maxprice: numeral(survey_maxprice).value(),
        condo_averageprice: numeral(survey_averageprice).value(),
        condo_minprice: numeral(survey_minprice).value(),
        condo_saleimprove:
          survey_saleimprove === "" ? "N_A" : survey_saleimprove,
        condo_projectprice: numeral(survey_projectprice).value(),
        unity_detail: newChecked.length === 0 ? [""] : newChecked,
      };
      this.props.condo_sec1_part2Object(body);
    }, 500);
  }

  validateState(state) {
    const {
      survey_salesDateState,
      // survey_constructedDateState,
      // survey_finishDateState,
      survey_prostatusState,
      survey_maxpriceState,
      survey_minpriceState,
      survey_constructapprove,
      survey_constructapproveState,
      survey_constructapproveValState,
      survey_saleimproveState,
      survey_projectpriceState,
      checkedState,
      other_utilityState,
    } = this.state;

    switch (state) {
      case "save":
        if (
          survey_salesDateState === "error" ||
          // survey_constructedDateState === "error" ||
          // survey_finishDateState === "error" ||
          survey_prostatusState === "error" ||
          survey_maxpriceState === "error" ||
          survey_minpriceState === "error" ||
          survey_constructapproveState === "error" ||
          survey_saleimproveState === "error" ||
          survey_projectpriceState === "error" ||
          (other_utilityState === "error" &&
            this.state.checked.indexOf("other") !== -1) ||
          checkedState === "error"
        ) {
          return false;
        } else {
          return true;
        }
      case "submit":
        if (
          survey_salesDateState === "success" &&
          // survey_constructedDateState === "success" &&
          // survey_finishDateState === "success" &&
          survey_prostatusState === "success" &&
          survey_saleimproveState === "success" &&
          survey_maxpriceState === "success" &&
          survey_minpriceState === "success" &&
          ((survey_constructapproveValState === "success" &&
            survey_constructapprove === "1") ||
            (survey_constructapproveState === "success" &&
              survey_constructapprove === "2")) &&
          survey_projectpriceState === "success"
        ) {
          return true;
        } else {
          this.setAllEmpty();
          return false;
        }
      default:
        break;
    }
  }

  showOtherUtilities(unity) {
    let others = unity.filter((props) => {
      return (
        props !== "clubhouse" &&
        props !== "pool" &&
        props !== "security" &&
        props !== "other"
      );
    });
    if (others.length !== 0) {
      var otherText = "";
      others.forEach(function(ot, ix) {
        if (ix === 0) {
          otherText = otherText + ot;
        } else {
          otherText = otherText + " " + ot;
        }
      });
      this.setState({ other_utility: otherText }, () => {
        if (!this.state.checked.includes("other")) {
          this.handleToggle("other");
        }
      });
      return others[0];
    } else {
      return "";
    }
  }
  setAllSuccess(edit_data, isOld) {
    // console.log(edit_data)
    if (
      edit_data["condo_datefirstsell"] !== null &&
      edit_data["condo_datefirstsell"] !== undefined
    ) {
      this.setState({ survey_salesDateState: "success" });
    }
    // if (
    //   edit_data["condo_dateconstruct"] !== null &&
    //   edit_data["condo_dateconstruct"] !== undefined
    // ) {
    //   this.setState({ survey_constructedDateState: "success" });
    // }
    // if (
    //   edit_data["condo_datefinish"] !== null &&
    //   edit_data["condo_datefinish"] !== undefined
    // ) {
    //   this.setState({ survey_finishDateState: "success" });
    // }
    if (!isOld) {
      if (
        edit_data["condo_projectstatus"] !== "" &&
        edit_data["condo_projectstatus"] !== 0 &&
        edit_data["condo_projectstatus"] !== "0"
      ) {
        this.setState({ survey_prostatusState: "success" });
      }
    }
    if (!isOld) {
      if (edit_data["condo_minprice"] !== 0) {
        this.setState({ survey_minpriceState: "success" });
      }
      if (edit_data["condo_averageprice"] !== 0) {
        this.setState({ survey_averagepriceState: "success" });
      }
      if (edit_data["condo_maxprice"] !== 0) {
        this.setState({ survey_maxpriceState: "success" });
      }
    }
    if (edit_data["condo_constructapprove"] !== "") {
      this.setState({ survey_constructapproveState: "success" });
    }
    if (edit_data["condo_registered"] !== "") {
      this.setState({ survey_registeredState: "success" });
    }
    if (edit_data["condo_constructapprove"] !== "") {
      this.setState({ survey_constructapproveValState: "success" });
    }
    if (edit_data["condo_registered"] !== "") {
      this.setState({ survey_registeredValState: "success" });
    }
    if (edit_data["condo_envapprove"] !== "") {
      this.setState({ survey_envapproveState: "success" });
    }
    if (!isOld) {
      if (edit_data["condo_saleimprove"] !== "") {
        this.setState({ survey_saleimproveState: "success" });
      }
      if (edit_data.arrayChk.length !== 0) {
        this.setState({ checkedState: "success" });
      }
      if (edit_data["condo_projectprice"] !== 0) {
        this.setState({ survey_projectpriceState: "success" });
      }
    }
  }
  setAllEmpty() {
    let arrayChk = this.state.checked;
    // console.log("this.state.survey_salesDate ",this.state.survey_salesDate)
    if (
      this.state.survey_salesDate === null ||
      this.state.survey_salesDate === undefined
    ) {
      this.setState({ survey_salesDateState: "error" });
    }
    // if (
    //   this.state.survey_constructedDate === null ||
    //   this.state.survey_constructedDate === undefined
    // ) {
    //   this.setState({ survey_constructedDateState: "error" });
    // }
    // if (
    //   this.state.survey_finishDate === null ||
    //   this.state.survey_finishDate === undefined
    // ) {
    //   this.setState({ survey_finishDateState: "error" });
    // }
    if (
      !this.state.survey_prostatus ||
      this.state.survey_prostatus === "" ||
      this.state.survey_prostatus === 0
    ) {
      this.setState({ survey_prostatusState: "error" });
    }
    if (this.state.survey_minprice === 0) {
      this.setState({ survey_minpriceState: "error" });
    }
    if (this.state.survey_averageprice === 0) {
      this.setState({ survey_averagepriceState: "error" });
    }
    if (this.state.survey_maxprice === 0) {
      this.setState({ survey_maxpriceState: "error" });
    }
    if (this.state.survey_constructapprove === "") {
      this.setState({ survey_constructapproveState: "error" });
    }
    if (this.state.survey_constructapproveVal === "") {
      this.setState({ survey_constructapproveValState: "error" });
    }
    if (this.state.survey_envapprove === "") {
      this.setState({ survey_envapproveState: "error" });
    }
    if (this.state.survey_registered === "") {
      this.setState({ survey_registeredState: "error" });
    }
    if (arrayChk.length === 0) {
      this.setState({ checkedState: "error" });
    }
    if (this.state.survey_projectprice === 0) {
      this.setState({ survey_projectpriceState: "error" });
    }
    if (this.state.survey_saleimprove === "") {
      this.setState({ survey_saleimproveState: "error" });
    }
  }

  check_allocate(allocate) {
    if (allocate === "1") {
      return this.state.survey_constructapproveVal === ""
        ? 0
        : this.state.survey_constructapproveVal;
    } else {
      return allocate;
    }
  }

  check_editAllocate(allocate) {
    if (allocate === "") {
      return "";
    } else if (allocate === "1" || allocate === "0") {
      return "2";
    } else {
      this.setState({
        survey_constructapproveVal: allocate,
      });
      return "1";
    }
  }

  check_editRegister(allocate) {
    if (allocate === "") {
      return "";
    } else if (allocate === "1" || allocate === "0") {
      return "2";
    } else {
      this.setState({
        survey_registeredVal: allocate,
      });
      return "1";
    }
  }

  handleToggle(value) {
    // console.log('hand')
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];

    newChecked = newChecked.filter((props) => {
      return (
        props === "security" ||
        (props === "other") | (props === "clubhouse") | (props === "pool")
      );
    });

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log(newChecked)
    this.setState({
      checked: newChecked,
      checkedState: "success",
    });
  }

  // function call radio survey ownertype
  handle_projectStatus(event) {
    this.setState({
      survey_prostatus: event.target.value,
      survey_prostatusState: "success",
    });
  }
  handle_constructApprove(event) {
    if (event.target.value === "1") {
      this.setState({
        survey_constructapprove: event.target.value,
        survey_constructapproveState: "success",
        survey_constructapproveValState: "error",
      });
    } else {
      this.setState({
        survey_constructapprove: event.target.value,
        survey_constructapproveState: "success",
        survey_constructapproveValState: "success",
      });
    }
  }

  handle_registered(event) {
    if (event.target.value === "1") {
      this.setState({
        survey_registered: event.target.value,
        survey_registeredState: "success",
        survey_registeredValState: "error",
      });
    } else {
      this.setState({
        survey_registered: "2",
        survey_registeredState: "success",
        survey_registeredValState: "success",
      });
    }
  }

  handle_registeredVal(event) {
    if (
      !event.target.value ||
      event.target.value === "" ||
      (event.target.value.length === 1 && event.target.value !== "-")
    ) {
      this.setState({
        survey_registeredVal: event.target.value,
        survey_registeredValState: "error",
      });
    } else {
      this.setState({
        survey_registeredVal: event.target.value,
        survey_registeredValState: "success",
      });
    }
  }

  handle_envapprove(event) {
    if (
      event.target.value !== "1" ||
      event.target.value !== "2" ||
      event.target.value !== "3"
    ) {
      this.setState({
        survey_envapprove: event.target.value,
        survey_envapproveState: "success",
      });
    } else {
      this.setState({
        survey_envapproveState: "error",
      });
    }
  }

  // validation = (currentDate) => {
  //   var selectedDate = this.state.survey_constructedDate;
  //   return currentDate.isAfter(selectedDate);
  // };

  renderCurrency(value) {
    const number = numeral(value);
    return number.format("0,0");
  }

  render() {
    const { classes } = this.props;
    const checkArray = this.state.checked;

    return (
      <div>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message="กรุณาเลือกตำแหน่งโครงการ ก่อนทำการเลือกวันที่เปิดขาย"
          open={this.state.openSnack}
          closeNotification={() => this.setState({ openSnack: false })}
          close
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormControl
              fullWidth
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "24px",
                verticalAlign: "unset",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  clearable="true"
                  label={
                    <div
                      style={{
                        color:
                          this.state.survey_salesDateState === "error"
                            ? "red"
                            : this.state.survey_salesDateState === "success"
                            ? "green"
                            : "black",
                      }}
                    >
                      วันที่เปิดขาย
                    </div>
                  }
                  format="DD/MM/YYYY"
                  pickerHeaderFormat="ddd D MMM"
                  yearOffset={543}
                  variant="inline"
                  // placeholder="กดเพื่อเลือกวันที่เปิดขาย"
                  onChange={this.setSaleDate}
                  value={this.state.survey_salesDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            {/* <FormControl
              fullWidth
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "24px",
                verticalAlign: "unset",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  clearable="true"
                  label={
                    <div
                      style={{
                        color:
                          this.state.survey_constructedDateState === "error"
                            ? "red"
                            : this.state.survey_constructedDateState ===
                              "success"
                            ? "green"
                            : "black",
                      }}
                    >
                      วันที่เริ่มก่อสร้าง / คาดว่าจะเริ่มก่อสร้าง
                    </div>
                  }
                  format="DD/MM/YYYY"
                  pickerHeaderFormat="ddd D MMM"
                  yearOffset={543}
                  variant="inline"
                  // placeholder="กดเพื่อเลือกวันที่เริ่มก่อสร้าง / คาดว่าจะเริ่มก่อสร้าง"
                  onChange={this.setconstructedDate}
                  value={this.state.survey_constructedDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "24px",
                verticalAlign: "unset",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  clearable="true"
                  label={
                    <div
                      style={{
                        color:
                          this.state.survey_finishDateState === "error"
                            ? "red"
                            : this.state.survey_finishDateState === "success"
                            ? "green"
                            : "black",
                      }}
                    >
                      วันที่แล้วเสร็จ / คาดว่าจะแล้วเสร็จ
                    </div>
                  }
                  format="DD/MM/YYYY"
                  pickerHeaderFormat="ddd D MMM"
                  yearOffset={543}
                  variant="inline"
                  // placeholder="กดเพื่อเลือกวันที่แล้วเสร็จ / คาดว่าจะแล้วเสร็จ"
                  onChange={this.setfinishDate}
                  value={this.state.survey_finishDate}
                  minDate={
                    new Date(
                      moment(this.state.survey_constructedDate).add(180, "days")
                    )
                  }
                />
              </MuiPickersUtilsProvider>
            </FormControl> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_prostatusState === "error"
                    ? "red"
                    : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              สถานะโครงการ
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status1}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "1"}
                    onChange={this.handle_projectStatus}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการปิดการขาย"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status2}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "2"}
                    onChange={this.handle_projectStatus}
                    value="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อยู่ระหว่างขาย"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status3}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "3"}
                    onChange={this.handle_projectStatus}
                    value="3"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="เปิดขายใหม่ในช่วงสำรวจ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status4}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "4"}
                    onChange={this.handle_projectStatus}
                    value="4"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการในอนาคต"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status5}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "5"}
                    onChange={this.handle_projectStatus}
                    value="5"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการชะลอการขาย"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status6}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "6"}
                    onChange={this.handle_projectStatus}
                    value="6"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการเปิดและปิดการขายในช่วงสำรวจ"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{ float: "none" }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              ราคาที่ขายในโครงการ (บาท/ตารางเมตร)
            </FormLabel>
            <CustomInput
              success={this.state.survey_minpriceState === "success"}
              error={this.state.survey_minpriceState === "error"}
              labelText="ราคาต่ำสุด"
              id="minprize"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.renderCurrency(this.state.survey_minprice),
                endAdornment:
                  this.state.survey_minpriceState === "error" ? (
                    <InputAdornment position="end">
                      <p
                        style={{
                          width: "105px",
                          margin: "0",
                          textAlign: "end",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {this.state.survey_minpriceStateName}
                      </p>
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                onChange: (event) =>
                  this.change(event, "survey_minprice", "min-value", [
                    this.state.survey_maxprice,
                    this.state.survey_averageprice,
                  ]),
                type: "text",
              }}
            />

            <CustomInput
              success={this.state.survey_maxpriceState === "success"}
              error={this.state.survey_maxpriceState === "error"}
              labelText="ราคาสูงสุด"
              id="maxprize"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.renderCurrency(this.state.survey_maxprice),
                endAdornment:
                  this.state.survey_maxpriceState === "error" ? (
                    <InputAdornment position="end">
                      <p
                        style={{
                          width: "105px",
                          margin: "0",
                          textAlign: "end",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {this.state.survey_maxpriceStateName}
                      </p>
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                onChange: (event) =>
                  this.change(event, "survey_maxprice", "max-value", [
                    this.state.survey_minprice,
                    this.state.survey_averageprice,
                  ]),
                type: "text",
              }}
            />
            <CustomInput
              success={this.state.survey_averagepriceState === "success"}
              error={this.state.survey_averagepriceState === "error"}
              labelText="ราคาเฉลี่ย"
              id="averageprice"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.renderCurrency(this.state.survey_averageprice),
                endAdornment:
                  this.state.survey_averagepriceState === "error" ? (
                    <InputAdornment position="end">
                      <p
                        style={{
                          width: "105px",
                          margin: "0",
                          textAlign: "end",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {this.state.survey_averagepriceStateName}
                      </p>
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                onChange: (event) =>
                  this.change(event, "survey_averageprice", "average-value", [
                    this.state.survey_minprice,
                    this.state.survey_maxprice,
                  ]),
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_constructapproveState === "error"
                    ? "red"
                    : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              การขออนุญาตก่อสร้าง
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_constructapprove === "1"}
                    onChange={this.handle_constructApprove}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ได้รับใบอนุญาตก่อสร้างแล้ว"
              />
              {this.state.survey_constructapprove === "1" ? (
                <CustomInput
                  success={
                    this.state.survey_constructapproveValState === "success"
                  }
                  error={this.state.survey_constructapproveValState === "error"}
                  labelText="เลขที่"
                  id="survey_constructapproveVal"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: this.state.survey_constructapproveVal,
                    placeholder: "123/456",
                    onChange: (event) =>
                      this.change(
                        event,
                        "survey_constructapproveVal",
                        "survey_constructapproveVal"
                      ),
                    type: "text",
                  }}
                />
              ) : null}
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_constructapprove === "2"}
                    onChange={this.handle_constructApprove}
                    value="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อยู่ระหว่างยื่นฯ"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_envapproveState === "error"
                    ? "red"
                    : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              การขออนุญาตสิ่งแวดล้อม (EIA)
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_envapprove === "1"}
                    onChange={this.handle_envapprove}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ได้รับอนุมัติ EIA แล้ว"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_envapprove === "2"}
                    onChange={this.handle_envapprove}
                    value="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อยู่ระหว่างยื่นฯ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_envapprove === "3"}
                    onChange={this.handle_envapprove}
                    value="3"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ไม่อยู่ในข่ายที่ต้องยื่นฯ"
              />
            </div>
          </GridItem>
          <GridItem>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_registeredState === "error"
                    ? "red"
                    : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              การจดทะเบียนอาคารชุด
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_registered === "1"}
                    onChange={this.handle_registered}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="จดทะเบียนอาคารชุดแล้ว"
              />
              {this.state.survey_registered === "1" ? (
                <CustomInput
                  success={this.state.survey_registeredValState === "success"}
                  error={this.state.survey_registeredValState === "error"}
                  labelText="เลขที่"
                  id="survey_registeredVal"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: this.state.survey_registeredVal,
                    placeholder: "123/456",
                    onChange: (event) => this.handle_registeredVal(event),
                    type: "text",
                  }}
                />
              ) : null}
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_registered === "2"}
                    onChange={this.handle_registered}
                    value="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ยังไม่จดทะเบียน"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color: this.state.checkedState === "error" ? "red" : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              สาธารณูปโภค (สิ่งอำนวยความสะดวกในโครงการ)
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("security") !== -1
                        ? true
                        : false
                    }
                    onClick={() => this.handleToggle("security")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รปภ."
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("clubhouse") !== -1
                        ? true
                        : false
                    }
                    onClick={() => this.handleToggle("clubhouse")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="สโมสร/คลับเฮ้าส์"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("pool") !== -1 ? true : false
                    }
                    onClick={() => this.handleToggle("pool")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="สระว่ายน้ำ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("other") !== -1 ? true : false
                    }
                    onClick={() => this.handleToggle("other")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อื่นๆ"
              />
            </div>
            {checkArray.includes("other") ? (
              <CustomInput
                success={this.state.other_utilityState === "success"}
                error={this.state.other_utilityState === "error"}
                labelText="โปรดระบุ"
                tooltipText={"หากมีมากกว่า 1 อย่าง กรุณาเว้นด้วย SpaceBar"}
                id="other_unity"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: this.state.other_utility,
                  onChange: (event) =>
                    this.change(event, "other_utility", "other_utility"),
                  type: "text",
                }}
              />
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{ float: "none" }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              รายการส่งเสริมการขาย
            </FormLabel>
            <FormControl fullWidth className={classes.selectFormControl}>
              <TextField
                id="survey_saleimprove"
                success={this.state.survey_saleimproveState === "success"}
                error={this.state.survey_saleimproveState === "error"}
                label="โปรดระบุ"
                multiline
                rowsMax="4"
                value={this.state.survey_saleimprove}
                onChange={(event) =>
                  this.change(event, "survey_saleimprove", "name")
                }
                className={classes.textField}
                margin="normal"
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={this.state.survey_projectpriceState === "success"}
              error={this.state.survey_projectpriceState === "error"}
              labelText="มูลค่าโครงการ (บาท)"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.renderCurrency(this.state.survey_projectprice),
                onChange: (event) =>
                  this.change(event, "survey_projectprice", "number"),
                type: "text",
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PublishInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBoundary: (payload) => dispatch(setBoundary(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  condo_sec1_part2Object: (payload) =>
    dispatch(condo_sec1_part2Object(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(PublishInfo));
