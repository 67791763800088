/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// switch language function
import switchIntl from "configure/switchIntl.js";

function Footer({ ...props }) {
  const { classes, fluid, black, rtlActive, lang } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.blackColor]: black,
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.blackColor]: black,
    });
  var block = cx({
    [classes.block]: true,
    [classes.blackColor]: black,
  });
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <p style={{ color: "black", textAlign: "center" }}>
              {rtlActive ? "توقيت الإبداعية" : switchIntl(lang).login.footer_1}
              &copy; {new Date().getFullYear()}{" "}
              {switchIntl(lang).login.footer_2}
              <br />
              {rtlActive
                ? ", مصنوعة مع الحب لشبكة الإنترنت أفضل"
                : switchIntl(lang).login.footer_3}
              <br />
              {rtlActive
                ? ", مصنوعة مع الحب لشبكة الإنترنت أفضل"
                : switchIntl(lang).login.footer_4}
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
