import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import NavPills from "components/NavPills/NavPills.js";
import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import React from "react";
import ChartistGraph from "react-chartist";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { simpleBarChart } from "variables/charts.js";
import switchIntl from "../../configure/switchIntl.js";
import numeral from "numeral";
import "./GHdashboard.css";

const template = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
};

const pieChart = {
  options: {
    responsive: true,
    elements: {
      center: {
        text: "100%",
      },
    },
    legend: {
      display: true,
      position: "bottom",
      align: "middle",
      labels: {
        fontStyle: "bold",
        fontSize: 14,
        fontColor: "black",
        usePointStyle: true,
      },
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          return " " + data["labels"][tooltipItem["index"]];
        },
      },
    },
    plugins: {
      datalabels: {
        color: "white",
        display: function(ctx) {
          return ctx.dataset.data[ctx.dataIndex] > 10;
        },
        font: {
          weight: "bold",
        },
        offset: 0,
        padding: 0,
      },
      aspectRatio: 3 / 2,
      cutoutPercentage: 8,
      layout: {
        padding: 16,
      },
      elements: {
        line: {
          fill: false,
        },
        point: {
          hoverRadius: 7,
          radius: 5,
        },
      },
    },
  },
};

const yearOffset = 60;

class GHDashboard extends React.Component {
  state = {
    value: 0,
    countsrp: [{}],
    key: false,
    curSrpYear: new Date().getFullYear() + "",
    curPsurveyYear: new Date().getFullYear() + "", // option
    year: "",
    optionYear: [],
  };

  componentDidMount() {
    this.getSecretKey();
    this.OptionYear();
  }

  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });

          self.getSrp();
          self.getDraftSrp();
          self.getDraftPsurvey();
          self.getPsurveyCount();
          self.getSrpMonth();
          self.getPsurveyMonth();
          self.getSrpAccount();
          self.getPsurveyAccount();
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch(() => {
        // console.log(error);
      });
  };

  OptionYear = () => {
    let now = new Date();
    let thisYear = now.getFullYear();
    let minYear = thisYear - yearOffset;
    let maxYear = thisYear + yearOffset;
    let optionYear = [];
    for (var i = maxYear; i > minYear; i--) {
      optionYear.push(i);
    }
    this.setState({ optionYear: optionYear, year: thisYear });
  };

  getSrpMonth = () => {
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = dec ? CryptoJS.enc.Utf8.stringify(dec) : {};
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_srp_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({
        year: this.state.curSrpYear ? this.state.curSrpYear : 0,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let newLog = Object.assign({}, template);
        newLog.series[0] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (responseJson.code === 200) {
          let tempData = responseJson.data;
          tempData.forEach(function(i) {
            newLog.labels.forEach(function(j, k) {
              if (i.mon === j) {
                newLog.series[0][k] = i.count_user;
              }
            });
          });
        } else {
          newLog.labels.forEach(function(j, k) {
            newLog.series[0][k] = 0;
          });
        }
        that.setState({ countsrpmonth: newLog });
      })
      .catch(() => {
        // console.log(error);
      });
  };
  getPsurveyMonth = () => {
    var that = this;

    const { key } = this.state;
    if (!key) {
      this.setState({ countpsurveymonth: template });
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = dec ? CryptoJS.enc.Utf8.stringify(dec) : {};
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_psurvey_acclog}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({
        year: this.state.curPsurveyYear ? this.state.curPsurveyYear : 0,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let newLog = Object.assign({}, template);
        newLog.series = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
        if (responseJson.code === 200 && "data" in responseJson) {
          let tempData = responseJson.data;
          tempData.forEach(function(i) {
            newLog.labels.forEach(function(j, k) {
              if (i.mon === j) {
                newLog.series[0][k] = i.count_user;
              }
            });
          });
        } else {
          // console.log(newLog)
        }
        that.setState({ countpsurveymonth: newLog });
      })
      .catch(() => {
        // console.log(error);
      });
  };

  getSrp = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = dec ? CryptoJS.enc.Utf8.stringify(dec) : {};
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_countsrp}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            countsrp: responseJson.data,
            countapprovedsrp: responseJson.data[0].approved,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  };

  getDraftSrp = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_draftsrp}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        this.setState({ countdraftsrp: responseJson.data[0].count });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getPsurveyCount = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = dec ? CryptoJS.enc.Utf8.stringify(dec) : {};
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_countpsurvey}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if ("code" in responseJson && responseJson.code === 200) {
          this.setState({ countpsurvey: responseJson });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  };

  getDraftPsurvey = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(`${config.API_draftpsurvey}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ draftpsurveyList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getSrpAccount = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = dec ? CryptoJS.enc.Utf8.stringify(dec) : {};
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_countsrpaccount}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ countSrpAccount: responseJson });
      })
      .catch(() => {
        // console.log(error);
      });
  };

  getPsurveyAccount = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = dec ? CryptoJS.enc.Utf8.stringify(dec) : {};
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(`${config.API_countpsurveyaccount}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ countPsurveyAccount: responseJson });
      })
      .catch(() => {
        // console.log(error);
      });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };
  handle_year = (event) => {
    let year = event.target.value;
    this.setState({ year: year });
  };

  render() {
    const { toggleLng } = this.props;
    const {
      countpsurvey,
      countSrpAccount,
      countPsurveyAccount,
      countdraftsrp,
      countapprovedsrp,
    } = this.state;

    const draftSrp = parseInt(countdraftsrp);
    const approvedSrp = parseInt(countapprovedsrp);

    var draftpsurveyList = this.state.draftpsurveyList
      ? this.state.draftpsurveyList
      : [];

    const draftPsurvey = parseInt(draftpsurveyList.length)
      ? draftpsurveyList.length
      : 0;
    const waitPsurvey =
      countpsurvey && "data1" in countpsurvey && 0 in countpsurvey.data1
        ? parseInt(
            countpsurvey.data1[0].condo_wait !== null
              ? countpsurvey.data1[0].condo_wait
              : 0
          ) +
          parseInt(
            countpsurvey.data2[0].household_wait !== null
              ? countpsurvey.data2[0].household_wait
              : 0
          ) +
          parseInt(
            countpsurvey.data3[0].villa_wait !== null
              ? countpsurvey.data3[0].villa_wait
              : 0
          )
        : 0;
    const approvedPsurvey =
      countpsurvey && "data1" in countpsurvey && 0 in countpsurvey.data1
        ? parseInt(
            countpsurvey.data1[0].condo_approved !== null
              ? countpsurvey.data1[0].condo_approved
              : 0
          ) +
          parseInt(
            countpsurvey.data2[0].household_approved !== null
              ? countpsurvey.data2[0].household_approved
              : 0
          ) +
          parseInt(
            countpsurvey.data3[0].villa_approved !== null
              ? countpsurvey.data3[0].villa_approved
              : 0
          )
        : 0;
    const rejectPsurvey =
      countpsurvey && "data1" in countpsurvey && 0 in countpsurvey.data1
        ? parseInt(
            countpsurvey.data1[0].condo_reject !== null
              ? countpsurvey.data1[0].condo_reject
              : 0
          ) +
          parseInt(
            countpsurvey.data2[0].household_reject !== null
              ? countpsurvey.data2[0].household_reject
              : 0
          ) +
          parseInt(
            countpsurvey.data3[0].villa_reject !== null
              ? countpsurvey.data3[0].villa_reject
              : 0
          )
        : 0;

    const waitSrpAccount =
      countSrpAccount && "wait" in countSrpAccount ? countSrpAccount.wait : 0;
    const approvedSrpAccount =
      countSrpAccount && "appv" in countSrpAccount ? countSrpAccount.appv : 0;
    const notapprovedSrpAccount =
      countSrpAccount && "notappv" in countSrpAccount
        ? countSrpAccount.notappv
        : 0;
    const allSrpAccount =
      countSrpAccount && "all" in countSrpAccount
        ? countSrpAccount.all
        : parseInt(waitSrpAccount) +
          parseInt(notapprovedSrpAccount) +
          parseInt(approvedSrpAccount);

    const waitPsurveyAccount =
      countPsurveyAccount && "wait" in countPsurveyAccount
        ? countPsurveyAccount.wait
        : 0;
    const approvedPsurveyAccount =
      countPsurveyAccount && "appv" in countPsurveyAccount
        ? countPsurveyAccount.appv
        : 0;
    const notapprovedPsurveyAccount =
      countPsurveyAccount && "notappv" in countPsurveyAccount
        ? countPsurveyAccount.notappv
        : 0;
    const allPsurveyAccount =
      countPsurveyAccount && "all" in countPsurveyAccount
        ? countPsurveyAccount.all
        : parseInt(waitPsurveyAccount) +
          parseInt(approvedPsurveyAccount) +
          parseInt(notapprovedPsurveyAccount);

    return (
      <div>
        <NavPills
          color="warning"
          tabs={[
            {
              tabButton: "PSurvey Application",
              tabContent: (
                <Row style={{ padding: "0px", margin: "0px" }}>
                  <Col xl={4} lg={4} md={4} sm={5} className="cardLeft">
                    <Card className="card-stats">
                      <CardBody>
                        <div className="card-title-dashboard">
                          {switchIntl(toggleLng).dashboard.approvedProject}
                        </div>
                        <div className="card-number-dashboard">
                          <span style={{ color: "#008F00" }}>
                            {numeral(approvedPsurvey).format("0,0")}
                          </span>
                        </div>
                      </CardBody>
                    </Card>

                    <Card className="card-stats">
                      <CardBody>
                        <div className="card-title-dashboard">
                          {switchIntl(toggleLng).dashboard.waitProject}
                        </div>
                        <div className="card-number-dashboard">
                          <span style={{ color: "darkorange" }}>
                            {numeral(waitPsurvey).format("0,0")}
                          </span>
                        </div>
                      </CardBody>
                    </Card>

                    <div className="bottomCardStat">
                      <Card
                        className="card-stats bottomCardStatElement"
                        style={{ margin: 0 }}
                      >
                        <CardBody className="statCardBody">
                          <div className="card-title-dashboard">
                            {switchIntl(toggleLng).dashboard.overallProject}
                          </div>
                          <div className="box-chart">
                            <Pie
                              data={{
                                labels: [
                                  numeral(approvedPsurvey).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard
                                      .approvedProject,
                                  numeral(rejectPsurvey).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard
                                      .rejectedProject,
                                  numeral(waitPsurvey).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard.waitProject,
                                  numeral(draftPsurvey).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard
                                      .draftProject,
                                ],
                                datasets: [
                                  {
                                    backgroundColor: [
                                      "#008F00",
                                      "#CA180B",
                                      "#E68900",
                                      "#0000AA",
                                    ],
                                    data: [
                                      approvedPsurvey,
                                      rejectPsurvey,
                                      waitPsurvey,
                                      draftPsurvey,
                                    ],
                                    datalabels: {
                                      labels: {
                                        value: {
                                          align: "center",
                                          backgroundColor: "white",
                                          borderColor: "white",
                                          borderWidth: 2,
                                          borderRadius: 4,
                                          color: (ctx) => {
                                            return ctx.dataset.backgroundColor;
                                          },
                                          formatter: (value, ctx) => {
                                            let datasets =
                                              ctx.chart.data.datasets;
                                            let sum = datasets[0].data.reduce(
                                              (a, b) => a + b,
                                              0
                                            );
                                            let percentage =
                                              Math.round((value / sum) * 100) +
                                              "%";
                                            return percentage;
                                          },
                                          padding: 4,
                                        },
                                      },
                                    },
                                  },
                                ],
                              }}
                              options={pieChart.options}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={7} className="cardRight">
                    <div className="upperCardContainer">
                      <Card className="card-stats">
                        <CardBody>
                          <div
                            style={{
                              float: "left",
                              fontWeight: "bold",
                              fontSize: "1.5em",
                            }}
                          >
                            {
                              switchIntl(toggleLng).dashboard
                                .numberAccountPsurvey
                            }
                          </div>
                          <div style={{ float: "right" }}>
                            <Select
                              value={
                                this.state.curPsurveyYear
                                  ? this.state.curPsurveyYear
                                  : new Date().getFullYear() + ""
                              }
                              onChange={(e) =>
                                this.setState(
                                  { curPsurveyYear: e.target.value },
                                  () => this.getPsurveyMonth()
                                )
                              }
                            >
                              <MenuItem
                                value={new Date().getFullYear() - 2 + ""}
                              >
                                {new Date().getFullYear() - 2 + 543 + ""}
                              </MenuItem>
                              <MenuItem
                                value={new Date().getFullYear() - 1 + ""}
                              >
                                {new Date().getFullYear() - 1 + 543 + ""}
                              </MenuItem>
                              <MenuItem value={new Date().getFullYear() + ""}>
                                {new Date().getFullYear() + 543 + ""}
                              </MenuItem>
                            </Select>
                          </div>
                          <div className="box-bar-chart">
                            <ChartistGraph
                              className="ct-chart-blue-colors"
                              data={
                                this.state.countpsurveymonth
                                  ? this.state.countpsurveymonth
                                  : template
                              }
                              type="Bar"
                              options={{ height: "350px" }}
                              responsiveOptions={
                                simpleBarChart.responsiveOptions
                              }
                              listener={simpleBarChart.animation}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="bottomCardContainer">
                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.allAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span className="text-info">
                                {numeral(allPsurveyAccount).format("0,0")}
                              </span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  className="text-info"
                                  style={{ marginTop: "-8px" }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.approvedAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span style={{ color: "#7FCE00" }}>
                                {numeral(approvedPsurveyAccount).format("0,0")}
                              </span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  style={{
                                    color: "#7FCE00",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.waitAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span style={{ color: "#FFC165" }}>
                                {numeral(waitPsurveyAccount).format("0,0")}
                              </span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  style={{
                                    color: "#FFC165",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>

                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.expiredAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span style={{ color: "#707070" }}>{0}</span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  style={{
                                    color: "#707070",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>
                </Row>
              ),
            },
            {
              tabButton: "SRP Application",
              tabContent: (
                <Row style={{ padding: "0px", margin: "0px" }}>
                  <Col xl={4} lg={4} md={4} sm={5} className="cardLeft">
                    <Card className="card-stats">
                      <CardBody>
                        <div className="card-title-dashboard">
                          {switchIntl(toggleLng).dashboard.sentProject}
                        </div>
                        <div className="card-number-dashboard">
                          <span style={{ color: "#008F00" }}>
                            {numeral(approvedSrp).format("0,0")}
                          </span>
                        </div>
                      </CardBody>
                    </Card>

                    <Card className="card-stats">
                      <CardBody>
                        <div className="card-title-dashboard">
                          {switchIntl(toggleLng).dashboard.draftProject}
                        </div>
                        <div className="card-number-dashboard">
                          <span style={{ color: "blue" }}>
                            {numeral(draftSrp).format("0,0")}
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="bottomCardStat">
                      <Card
                        className="card-stats bottomCardStatElement"
                        style={{ margin: 0 }}
                      >
                        <CardBody className="statCardBody">
                          <div className="card-title-dashboard">
                            {switchIntl(toggleLng).dashboard.overallProject}
                          </div>
                          <div className="box-chart">
                            <Pie
                              data={{
                                labels: [
                                  numeral(approvedSrp).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard.sentProject,
                                  numeral(draftSrp).format("0,0") +
                                    " " +
                                    switchIntl(toggleLng).dashboard
                                      .draftProject,
                                ],
                                datasets: [
                                  {
                                    backgroundColor: ["#008F00", "#0000AA"],
                                    data: [approvedSrp, draftSrp],
                                    datalabels: {
                                      labels: {
                                        value: {
                                          align: "center",
                                          backgroundColor: "white",
                                          borderColor: "white",
                                          borderWidth: 2,
                                          borderRadius: 4,
                                          color: (ctx) => {
                                            return ctx.dataset.backgroundColor;
                                          },
                                          formatter: (value, ctx) => {
                                            let datasets =
                                              ctx.chart.data.datasets;
                                            let sum = datasets[0].data.reduce(
                                              (a, b) => a + b,
                                              0
                                            );
                                            let percentage =
                                              Math.round((value / sum) * 100) +
                                              "%";
                                            return percentage;
                                          },
                                          padding: 4,
                                        },
                                      },
                                    },
                                  },
                                ],
                              }}
                              options={pieChart.options}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={7} className="cardRight">
                    <div className="upperCardContainer">
                      <Card className="card-stats">
                        <CardBody>
                          <div
                            style={{
                              float: "left",
                              fontWeight: "bold",
                              fontSize: "1.5em",
                            }}
                          >
                            {switchIntl(toggleLng).dashboard.numberAccountSrp}
                          </div>
                          <div style={{ float: "right" }}>
                            <Select
                              value={
                                this.state.curSrpYear
                                  ? this.state.curSrpYear
                                  : new Date().getFullYear() + ""
                              }
                              onChange={(e) =>
                                this.setState(
                                  { curSrpYear: e.target.value },
                                  () => this.getSrpMonth()
                                )
                              }
                            >
                              <MenuItem
                                value={new Date().getFullYear() - 2 + ""}
                              >
                                {new Date().getFullYear() - 2 + 543 + ""}
                              </MenuItem>
                              <MenuItem
                                value={new Date().getFullYear() - 1 + ""}
                              >
                                {new Date().getFullYear() - 1 + 543 + ""}
                              </MenuItem>
                              <MenuItem value={new Date().getFullYear() + ""}>
                                {new Date().getFullYear() + 543 + ""}
                              </MenuItem>
                            </Select>
                          </div>
                          <div className="box-bar-chart">
                            <ChartistGraph
                              className="ct-chart-blue-colors"
                              data={
                                this.state.countsrpmonth
                                  ? this.state.countsrpmonth
                                  : template
                              }
                              type="Bar"
                              options={{ height: "350px" }}
                              responsiveOptions={
                                simpleBarChart.responsiveOptions
                              }
                              listener={simpleBarChart.animation}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="bottomCardContainer">
                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.allAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span className="text-info">
                                {numeral(allSrpAccount).format("0,0")}
                              </span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  className="text-info"
                                  style={{ marginTop: "-8px" }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>

                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.approvedAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span style={{ color: "#7FCE00" }}>
                                {numeral(approvedSrpAccount).format("0,0")}
                              </span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  style={{
                                    color: "#7FCE00",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.waitAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span style={{ color: "#FFC165" }}>
                                {numeral(waitSrpAccount).format("0,0")}
                              </span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  style={{
                                    color: "#FFC165",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>

                      <div className="outerCardContainer">
                        <Card className="cardContainer">
                          <CardBody className="cardBody">
                            <div className="title">
                              {switchIntl(toggleLng).dashboard.expiredAccount}
                            </div>
                            <div className="card-number-dashboard">
                              <span style={{ color: "#707070" }}>{0}</span>
                              <div className="pull-right">
                                <AccountCircle
                                  fontSize="large"
                                  style={{
                                    color: "#707070",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

GHDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(
  withStyles(dashboardStyle, chartsStyle)(GHDashboard)
);
