/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";

import PropTypes from "prop-types";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as config from "configure/configServer.js";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SweetAlert from "react-bootstrap-sweetalert";
import { ClipLoader, PulseLoader } from "react-spinners";
import Close from "@material-ui/icons/Close";
import AccountBox from "@material-ui/icons/AccountBox";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Col,
  Row,
} from "reactstrap";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";

import switchIntl from "configure/switchIntl.js";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      psurveyPath: config.requestUrl,
      srpPath: config.requestUrl,
      checked: [],
      alert: null,
      show: false,
      // check company exist
      hasCompany: true,
      hasCompanyState: "",
      renderCompany: [],
      // register form
      registerFname: "",
      registerFnameState: "",
      registerLname: "",
      registerLnameState: "",
      registerPhone: "",
      registerPhoneState: "",
      registerEmail: "",
      registerEmailState: "",
      registerUsername: "",
      registerUsernameState: "",
      registerPassword: "",
      registerPasswordState: "",
      registerConfirmPassword: "",
      registerConfirmPasswordState: "",
      registerOrganization: "",
      registerOrganizationState: "",
      registerCheckbox: false,
      registerCheckboxState: "",
      registerCaptcha: "",
      registerCaptchaState: "",
      // addorg form
      addOrgGuild: "",
      addOrgGuildState: "",
      addOrgName: "",
      addOrgNameState: "",
      addOrgNo: "",
      addOrgNoState: "",
      addOrgBuilding: "",
      addOrgBuildingState: "",
      addOrgFloor: "",
      addOrgFloorState: "",
      addOrgSoi: "",
      addOrgSoiState: "",
      addOrgStreet: "",
      addOrgStreetState: "",
      addOrgProv: "",
      addOrgProvState: "",
      addOrgSubdist: "",
      addOrgSubdistState: "",
      addOrgDist: "",
      addOrgDistState: "",
      addOrgPhone: "",
      addOrgPhoneState: "",
      addOrgFax: "",
      addOrgFaxState: "",
      addOrgSalePhone: "",
      addOrgSalePhoneState: "",
      addOrgUrl: "",
      addOrgUrlState: "",
      // add new company
      newCompanyAsso: "",
      province: [],
      amphoe: [],
      tambon: [],
      // bt loading
      btLoading: false,
      orgLoading: false,
      disButton: false,
      popperOpen: false,
      orgnameValue: "",
      easypass: false,
      guild: [],
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.validateRegister = this.validateRegister.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getProvince = this.getProvince.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.getSecretKey();
  }
  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });

          self.getProvince();
          self.getGuild();
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getGuild(event) {
    var request = new Request(config.API_get_guild, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson.data;
        this.setState({
          guild: data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  // start of recaptcha callback function
  recaptchaCallback = () => {
    return null;
  };
  verifyCallback = (recaptchaToken) => {
    if (recaptchaToken !== null) {
      this.setState({
        registerCaptcha: recaptchaToken,
        registerCaptchaState: "success",
      });
    }
  };
  expiredCallback = () => {
    this.setState({ registerCaptcha: "", registerCaptchaState: "error" });
  };
  // end of recaptcha callback function

  // loading function
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }
  loadingOrg(isLoading) {
    if (isLoading) {
      return (
        <PulseLoader size={8} color={"gray"} loading={this.state.loading} />
      );
    } else {
      return null;
    }
  }
  // end of loading function

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  handleSelectedGuild = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      addOrgGuildState: "success",
    });
  };
  // validate forms
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    let value = event.target.value;
    switch (type) {
      case "name":
        if (this.verifyName(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "namenumber":
        if (this.verifyNameNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "website":
        if (this.verifyWebSite(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (
          this.verifyPhone(event.target.value) &&
          value.length >= stateNameEqualTo &&
          value.length <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "username":
        if (this.verifyUser(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyPassword(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is meet profile details, false otherwise
  verifyName(value) {
    var nameRex = /^[A-Za-zก-๙\s]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyNameNumber(value) {
    var nameRex = /^[A-Za-z0-9ก-๙/\s]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyWebSite(value) {
    var nameRex = /^[A-Za-z0-9.:/]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is phone, false otherwise
  verifyPhone(value) {
    var phoneRex = /^0[0-9/-]+$/;
    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is username, false otherwise
  verifyUser(value) {
    var userRex = /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/;
    if (userRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is password, false otherwise
  verifyPassword(value) {
    var passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,32}$/;
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // === end of handle input forms ===
  // validate forms

  // handle trigger function
  // check add org trigger?
  validateRegister() {
    if (this.state.registerFnameState === "") {
      this.setState({ registerFnameState: "error" });
    }
    if (this.state.registerLnameState === "") {
      this.setState({ registerLnameState: "error" });
    }
    if (this.state.registerPhoneState === "") {
      this.setState({ registerPhoneState: "error" });
    }
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerUsernameState === "") {
      this.setState({ registerUsernameState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerOrganizationState === "") {
      this.setState({ registerOrganizationState: "error" });
    }

    if (this.state.member_type === "0" || !this.state.member_type) {
      this.setState({ member_typeState: "error" });
    }

    if (this.state.hasCompany || this.state.member_type === "1") {
      if (
        this.state.registerFnameState === "success" &&
        this.state.registerLnameState === "success" &&
        this.state.registerPhoneState === "success" &&
        this.state.registerEmailState === "success" &&
        this.state.registerUsernameState === "success" &&
        this.state.registerPasswordState === "success" &&
        this.state.registerConfirmPasswordState === "success" &&
        (this.state.registerOrganizationState === "success" ||
          this.state.member_type === "1")
      ) {
        this.callRegister();
      } else {
        this.dynamicAlert(
          "warning",
          switchIntl(this.props.toggleLng).sweetalert.registerError.title,
          switchIntl(this.props.toggleLng).sweetalert.registerError.content,
          switchIntl(this.props.toggleLng).sweetalert.registerError.conBtTxt,
          "",
          false,
          this.hideAlert
        );
      }
    } else {
      this.setState({ registerOrganizationState: "" });
      if (this.state.addOrgNameState === "") {
        this.setState({ addOrgNameState: "error" });
      }
      if (this.state.addOrgNoState === "") {
        this.setState({ addOrgNoState: "error" });
      }
      if (this.state.addOrgBuildingState === "") {
        this.setState({ addOrgBuildingState: "error" });
      }
      if (this.state.addOrgFloorState === "") {
        this.setState({ addOrgFloorState: "error" });
      }
      if (this.state.addOrgSoiState === "") {
        this.setState({ addOrgSoiState: "error" });
      }
      if (this.state.addOrgStreetState === "") {
        this.setState({ addOrgStreetState: "error" });
      }
      if (this.state.addOrgProvState === "") {
        this.setState({ addOrgProvState: "error" });
      }
      if (this.state.addOrgSubdistState === "") {
        this.setState({ addOrgSubdistState: "error" });
      }
      if (this.state.addOrgDistState === "") {
        this.setState({ addOrgDistState: "error" });
      }
      if (this.state.addOrgPhoneState === "") {
        this.setState({ addOrgPhoneState: "error" });
      }
      if (this.state.addOrgFaxState === "") {
        this.setState({ addOrgFaxState: "error" });
      }
      if (this.state.addOrgSalePhoneState === "") {
        this.setState({ addOrgSalePhoneState: "error" });
      }
      if (this.state.addOrgUrlState === "") {
        this.setState({ addOrgUrlState: "error" });
      }
      if (this.state.addOrgGuildState === "") {
        this.setState({ addOrgGuildState: "error" });
      }
      if (
        this.state.registerFnameState === "success" &&
        this.state.registerLnameState === "success" &&
        this.state.registerPhoneState === "success" &&
        this.state.registerEmailState === "success" &&
        this.state.registerUsernameState === "success" &&
        this.state.registerPasswordState === "success" &&
        this.state.registerConfirmPasswordState === "success" &&
        this.state.addOrgNameState === "success" &&
        this.state.addOrgNoState === "success" &&
        this.state.addOrgBuildingState === "success" &&
        this.state.addOrgFloorState === "success" &&
        this.state.addOrgSoiState === "success" &&
        this.state.addOrgStreetState === "success" &&
        this.state.addOrgProvState === "success" &&
        this.state.addOrgSubdistState === "success" &&
        this.state.addOrgDistState === "success" &&
        this.state.addOrgPhoneState === "success" &&
        this.state.addOrgFaxState === "success" &&
        this.state.addOrgSalePhoneState === "success" &&
        this.state.addOrgUrlState === "success" &&
        this.state.member_type !== "0" &&
        this.state.member_type
      ) {
        this.callNewOrgRegister();
      } else {
        this.dynamicAlert(
          "warning",
          switchIntl(this.props.toggleLng).sweetalert.registerError.title,
          switchIntl(this.props.toggleLng).sweetalert.registerError.content,
          switchIntl(this.props.toggleLng).sweetalert.registerError.conBtTxt,
          "",
          false,
          this.hideAlert
        );
      }
    }
  }

  // handle close addorg
  setDefault() {
    this.setState({
      hasCompany: true,
      registerOrganization: "",
      registerOrganizationState: "",
      orgnameValue: "",
      addOrgNameState: "",
      addOrgNoState: "",
      addOrgBuildingState: "",
      addOrgFloorState: "",
      addOrgSoiState: "",
      addOrgStreetState: "",
      addOrgProvState: "",
      addOrgSubdistState: "",
      addOrgDistState: "",
      addOrgPhoneState: "",
      addOrgFaxState: "",
      addOrgSalePhoneState: "",
      addOrgUrlState: "",
      addOrgGuildState: "",
    });
  }

  // check easy password
  easyPassword(password) {
    var body = {
      password: password,
    };

    var request = new Request(
      `${config.API_easypassword}`,
      {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      }
    );

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            easypass: false,
          });
        } else {
          this.setState({
            easypass: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  // register function
  callRegister() {
    this.setState({ btLoading: true, disButton: true });
    this.easyPassword(this.state.registerPassword);

    if (!this.state.easypass) {
      var body = {
        member_name: this.state.registerFname,
        member_lname: this.state.registerLname,
        member_tel: this.state.registerPhone,
        member_email: this.state.registerEmail,
        member_username: this.state.registerUsername,
        member_passwd: this.state.registerPassword,
        member_status: 1, //active
        member_verify: 1, //approved
        // eslint-disable-next-line react/prop-types
        member_profimg: this.props.getBase64,
        member_org:
          this.state.member_type === "1" ? 1 : this.state.registerOrganization,
        member_type: this.state.member_type,
      };

      var request = new Request(
        `${this.state.member_type === "2"
          ? config.API_register_psurvey
          : this.state.member_type === "3"
            ? config.API_register_srp
            : config.API_register_admin
        }`,
        {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(body),
        }
      );

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({ btLoading: false, disButton: false });
          if (responseJson.code === 200) {
            this.dynamicAlert(
              "success",
              switchIntl(this.props.toggleLng).sweetalert.registerSuccess.title,
              switchIntl(this.props.toggleLng).sweetalert.registerSuccess.title,
              switchIntl(this.props.toggleLng).sweetalert.registerSuccess
                .conBtTxt,
              "",
              false,
              () => {
                setTimeout(() => {
                  window.location.replace("/manageuser");
                }, 1000);
              }
            );
          } else if (responseJson.code === 400) {
            this.dynamicAlert(
              "warning",
              switchIntl(this.props.toggleLng).sweetalert.registerFail.title,
              switchIntl(this.props.toggleLng).sweetalert.registerFail.content,
              switchIntl(this.props.toggleLng).sweetalert.registerFail.conBtTxt,
              "",
              false,
              this.hideAlert
            );
          } else {
            this.dynamicAlert(
              "warning",
              switchIntl(this.props.toggleLng).sweetalert.registerExist.title,
              switchIntl(this.props.toggleLng).sweetalert.registerExist.content,
              switchIntl(this.props.toggleLng).sweetalert.registerExist
                .conBtTxt,
              "",
              false,
              this.hideAlert
            );
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }

  // register with new orgfunction
  callNewOrgRegister() {
    this.setState({ btLoading: true, disButton: true });
    this.easyPassword(this.state.registerPassword);

    var org_body = {
      org_name: this.state.addOrgName,
      org_address: this.state.addOrgNo,
      org_namebuilding: this.state.addOrgBuilding,
      org_floor: this.state.addOrgFloor,
      org_soi: this.state.addOrgSoi,
      org_road: this.state.addOrgStreet,
      org_subdistrict: this.state.addOrgDist,
      org_district: this.state.addOrgSubdist,
      org_province: this.state.addOrgProv,
      org_tel: this.state.addOrgPhone,
      org_fax: this.state.addOrgFax,
      org_saletel: this.state.addOrgSalePhone,
      org_website: this.state.addOrgUrl,
      org_guild: this.state.addOrgGuild,
    };
    var request = new Request(config.API_addOrg, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(org_body),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ btLoading: false, disButton: false });
        if (responseJson.code === 200 && "data" in responseJson) {
          this.setState(
            {
              registerOrganization:
                "org_id" in responseJson.data ? responseJson.data.org_id : 1,
            },
            () => this.callRegister()
          );
        } else {
          this.dynamicAlert(
            "warning",
            switchIntl(this.props.toggleLng).sweetalert.companyExist.title,
            switchIntl(this.props.toggleLng).sweetalert.companyExist.content,
            switchIntl(this.props.toggleLng).sweetalert.companyExist.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  // start of dynsmic alert handled
  dynamicAlert(
    alertType,
    title,
    content,
    conTxt,
    canTxt,
    showCancel,
    confirmFunction,
    cancelFunction
  ) {
    this.setState({
      alert: (
        <SweetAlert
          type={alertType}
          style={{
            display: "block",
            color: "black",
            fontSize: "18px",
          }}
          customClass="modalContent"
          title={title}
          onConfirm={confirmFunction}
          onCancel={cancelFunction}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={conTxt}
          cancelBtnText={canTxt}
          showCancel={showCancel}
        >
          {content}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  // end of dynsmic alert handled

  // auto complete org field
  getOrg(event) {
    let value = event.target.value;
    this.setState({ orgnameValue: value, registerOrganizationState: "error" });

    var body = {
      org_name: value,
      org_instm: 0,
    };

    var orgPath = `${config.API_get_org}`;
    if (this.state.member_type === "2") {
      orgPath = `${config.API_get_reg_org}`;
    }

    if (value.length >= 2) {
      this.setState({ orgLoading: true });
      var request = new Request(orgPath, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson.data;
          if (data.length >= 1) {
            this.setState({
              popperOpen: true,
              renderCompany: responseJson.data,
              orgLoading: false,
            });
          } else {
            this.setState({
              popperOpen: false,
              orgLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            popperOpen: false,
            renderCompany: [],
            registerOrganizationState: "error",
            orgLoading: false,
          });
        });
    } else {
      this.setState({
        popperOpen: false,
        renderCompany: [],
        registerOrganizationState: "error",
        orgLoading: false,
      });
    }
  }
  // end of auto complete org field

  // get province
  getProvince() {
    this.setState({ amphoe: [], tambon: [] });
    var body = {
      pro_id: 0,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let prov = responseJson.data.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        this.setState({
          province: prov,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  getAmphoe(prov_val) {
    this.setState({ amphoe: [], addOrgSubdist: "" });
    var body = {
      pro_id: prov_val,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          amphoe: responseJson.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  getTambon(prov_val, amp_val) {
    this.setState({ tambon: [], addOrgDist: "" });
    var body = {
      pro_id: prov_val,
      amp_id: amp_val,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          tambon: responseJson.data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  handleClose() {
    this.setState({ popperOpen: false });
  }
  handleSelect(value) {
    this.setState({
      popperOpen: false,
      registerOrganization: value.org_id,
      registerOrganizationState: "success",
      orgnameValue: value.org_name,
    });
  }

  handleSelectedProv = (event) => {
    this.setState({
      addOrgSubdist: "",
      addOrgDist: "",
      [event.target.name]: event.target.value,
      addOrgProvState: "success",
    });
    this.getAmphoe(event.target.value);
  };
  handleSelectedSub = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      addOrgSubdistState: "success",
    });
    this.getTambon(this.state.addOrgProv, event.target.value);
  };
  handleSelectedDist = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      addOrgDistState: "success",
    });
  };

  render() {
    const { classes } = this.props;

    let style =
      this.state.registerOrganizationState === "error" ? "red" : "green";

    let style_prov = this.state.addOrgProvState === "error" ? "red" : "green";
    let style_amp = this.state.addOrgSubdistState === "error" ? "red" : "green";
    let style_tam = this.state.addOrgDistState === "error" ? "red" : "green";

    let optionItems = this.state.renderCompany.map((com) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={com.org_id}
        value={com.org_id}
        style={{ backgroundColor: "#fff" }}
        onClick={() => this.handleSelect(com)}
      >
        {com.org_name}
      </MenuItem>
    ));

    let provinceItems = this.state.province.map((prov) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={prov.label}
        value={prov.value}
        style={{ backgroundColor: "#fff" }}
      >
        {prov.label}
      </MenuItem>
    ));

    let amphoneItems = this.state.amphoe.map((amp) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={amp.label}
        value={amp.value}
        style={{ backgroundColor: "#fff" }}
      >
        {amp.label}
      </MenuItem>
    ));

    let tambonItems = this.state.tambon.map((tam) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={tam.label}
        value={tam.value}
        style={{ backgroundColor: "#fff" }}
      >
        {tam.label}
      </MenuItem>
    ));
    let guildItems = this.state.guild.map((guild) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={guild.guild_id}
        value={guild.guild_id}
        style={{ backgroundColor: "#fff" }}
      >
        {guild.guild_name}
      </MenuItem>
    ));
    return (
      <Row
        justify="center"
        style={{ justifyContent: "center", margin: "auto" }}
      >
        {this.state.alert}
        <Col xs={12} sm={10} md={7}>
          <form>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <AccountBox />
                </CardIcon>
                <h3 className={classes.infoText} style={{ color: "#707070" }}>
                  {switchIntl(this.props.toggleLng).addaccount.header}
                </h3>
              </CardHeader>
              <CardBody style={{ justify: "center" }}>
                <FormControl
                  error={
                    !this.state.member_type || this.state.member_type === "0"
                  }
                >
                  <div>
                    {switchIntl(this.props.toggleLng).addaccount.userrole}
                    &nbsp;
                    <Select
                      value={
                        this.state.member_type ? this.state.member_type : "0"
                      }
                      onChange={(e) =>
                        this.setState({
                          member_type: e.target.value,
                          orgnameValue: "",
                          registerOrganizationState: "",
                        })
                      }
                      style={{ width: "200px" }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="0"
                      >
                        {"เลือกสิทธิ์ผู้ใช้งาน"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="1"
                      >
                        {"Admin"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="2"
                      >
                        {"PSurvey"}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="3"
                      >
                        {"SRP"}
                      </MenuItem>
                    </Select>
                  </div>
                </FormControl>
                <Row justify="center">
                  <Col xs={12} sm={12} md={12}>
                    <Row justify="center">
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <CustomInput
                          success={this.state.registerFnameState === "success"}
                          error={this.state.registerFnameState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).addaccount
                              .firstname
                          }
                          id="registerFname"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) =>
                              this.change(event, "registerFname", "name"),
                            type: "text",
                            endAdornment:
                              this.state.registerFnameState === "error" ? (
                                <InputAdornment position="end">
                                  <Close
                                    className={classes.danger}
                                    style={{ color: "red" }}
                                  />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <CustomInput
                          success={this.state.registerLnameState === "success"}
                          error={this.state.registerLnameState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).addaccount.lastname
                          }
                          id="registerLname"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) =>
                              this.change(event, "registerLname", "name"),
                            type: "text",
                            endAdornment:
                              this.state.registerLnameState === "error" ? (
                                <InputAdornment position="end">
                                  <Close
                                    className={classes.danger}
                                    style={{ color: "red" }}
                                  />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </Col>
                    </Row>
                    <CustomInput
                      success={this.state.registerPhoneState === "success"}
                      error={this.state.registerPhoneState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).addaccount.phone
                      }
                      id="registerPhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) =>
                          this.change(event, "registerPhone", "phone", 9, 10),
                        type: "number",
                        endAdornment:
                          this.state.registerPhoneState === "error" ? (
                            <InputAdornment position="end">
                              <Close
                                className={classes.danger}
                                style={{ color: "red" }}
                              />
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                    />
                  </Col>
                </Row>
                <CustomInput
                  success={this.state.registerEmailState === "success"}
                  error={this.state.registerEmailState === "error"}
                  labelText={switchIntl(this.props.toggleLng).addaccount.email}
                  id="registerEmail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "registerEmail", "email"),
                    type: "email",
                    endAdornment:
                      this.state.registerEmailState === "error" ? (
                        <InputAdornment position="end">
                          <Close
                            className={classes.danger}
                            style={{ color: "red" }}
                          />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
                <CustomInput
                  success={this.state.registerUsernameState === "success"}
                  error={this.state.registerUsernameState === "error"}
                  labelText={
                    switchIntl(this.props.toggleLng).addaccount.username
                  }
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      return this.change(event, "registerUsername", "username");
                    },
                    type: "text",
                    endAdornment:
                      this.state.registerUsernameState === "error" ? (
                        <InputAdornment position="end">
                          <Close
                            className={classes.danger}
                            style={{ color: "red" }}
                          />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
                <CustomInput
                  success={this.state.registerPasswordState === "success"}
                  error={this.state.registerPasswordState === "error"}
                  labelText={
                    switchIntl(this.props.toggleLng).addaccount.password
                  }
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "registerPassword", "password"),
                    type: "password",
                    autoComplete: "off",
                    endAdornment:
                      this.state.registerPasswordState === "error" ? (
                        <InputAdornment position="end">
                          {this.state.easypass ? (
                            <p>Password easy</p>
                          ) : (
                            <Close
                              className={classes.danger}
                              style={{ color: "red" }}
                            />
                          )}
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
                {this.state.registerPasswordState === "error" ? (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "red",
                      textAlign: "start",
                    }}
                  >
                    <p style={{ margin: 0 }}>
                      - รหัสผ่านจำเป็นต้องมีทั้งตัวเลขและตัวหนังสือ
                    </p>
                    <p style={{ margin: 0 }}>
                      -
                      รหัสผ่านจำเป็นต้องมีทั้งตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่และมีตัวเลขอย่างน้อย
                      1 ตัว
                    </p>
                    <p style={{ margin: 0 }}>
                      - รหัสผ่านจำเป็นต้องมีความยาวอย่างน้อย 8 ตัวแต่ไม่เกิน 32
                      ตัว
                    </p>
                  </div>
                ) : null}
                <CustomInput
                  success={
                    this.state.registerConfirmPasswordState === "success"
                  }
                  error={this.state.registerConfirmPasswordState === "error"}
                  labelText={
                    switchIntl(this.props.toggleLng).addaccount.conpassword
                  }
                  id="confirmpassword"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(
                        event,
                        "registerConfirmPassword",
                        "equalTo",
                        "registerPassword"
                      ),
                    type: "password",
                    autoComplete: "off",
                    endAdornment:
                      this.state.registerConfirmPasswordState === "error" ? (
                        <InputAdornment position="end">
                          <p
                            style={{
                              width: "150px",
                              margin: "0",
                              textAlign: "end",
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            {
                              switchIntl(this.props.toggleLng).register
                                .noconpass
                            }
                          </p>
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
                {this.state.member_type === "1" ? null : (
                  <div>
                    <CustomInput
                      success={
                        this.state.registerOrganizationState === "success"
                      }
                      error={this.state.registerOrganizationState === "error"}
                      labelText={
                        switchIntl(this.props.toggleLng).register.company
                      }
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: this.state.orgnameValue,
                        onChange: (event) => this.getOrg(event),
                        ref: (node) => {
                          this.anchorEl = node;
                        },
                        endAdornment:
                          this.state.registerOrganizationState === "error" ? (
                            <InputAdornment position="end">
                              {this.state.orgLoading ? (
                                <div style={{ width: "50px" }}>
                                  {this.loadingOrg(this.state.orgLoading)}
                                </div>
                              ) : null}
                              <p
                                style={{
                                  width: "105px",
                                  margin: "0",
                                  textAlign: "end",
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                {
                                  switchIntl(this.props.toggleLng).register
                                    .noorg
                                }
                              </p>
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                        disabled: !this.state.hasCompany,
                      }}
                    />
                    <Popper
                      open={this.state.popperOpen}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                      placement="bottom-end"
                      className={
                        "MuiPaper-root MuiMenu-paper MuiPaper-elevation8 MuiPopover-paper MuiPaper-rounded"
                      }
                      style={{
                        maxHeight: "200px",
                        minWidth: "50%",
                        overflow: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <Paper className={classes.selectMenu}>
                          {optionItems}
                        </Paper>
                      </ClickAwayListener>
                    </Popper>

                    <div style={{ textAlign: "end" }}>
                      {this.state.hasCompany
                        ? switchIntl(this.props.toggleLng).register.addorg
                        : null}
                      <span
                        style={{ cursor: "pointer", fontWeight: 500 }}
                        onClick={() => {
                          !this.state.hasCompany
                            ? this.setDefault()
                            : this.setState({
                              hasCompany: false,
                              registerOrganization: "",
                              registerOrganizationState: "",
                              orgnameValue: "",
                            });
                        }}
                      >
                        {!this.state.hasCompany
                          ? switchIntl(this.props.toggleLng).register.canceladd
                          : switchIntl(this.props.toggleLng).register
                            .addorglink}
                      </span>
                    </div>
                    {this.state.hasCompany ? null : (
                      <div>
                        {this.state.member_type !== "3" ? null : (
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{
                              margin: "0 0 17px 0",
                              position: "relative",
                              paddingTop: "27px",
                              verticalAlign: "unset",
                            }}
                            error={
                              !this.state.addOrgGuild ||
                              this.state.addOrgGuild === "0"
                            }
                          >
                            <InputLabel
                              className={classes.selectLabel}
                              style={{
                                top: "10px",
                                color:
                                  this.state.addOrgGuildState === ""
                                    ? "#AAAAAA"
                                    : style_prov,
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "1.42857",
                              }}
                            >
                              {
                                switchIntl(this.props.toggleLng).register
                                  .association
                              }
                            </InputLabel>
                            <Select
                              style={{
                                marginTop: "0px",
                                textAlign: "start",
                                fontSize: "16px",
                              }}
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.addOrgGuild}
                              onChange={this.handleSelectedGuild}
                              inputProps={{
                                name: "addOrgGuild",
                                id: "addOrgGuild",
                              }}
                            >
                              {guildItems}
                            </Select>
                          </FormControl>
                        )}

                        <CustomInput
                          success={this.state.addOrgNameState === "success"}
                          error={this.state.addOrgNameState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgname
                          }
                          id="orgname"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            onChange: (event) =>
                              this.change(event, "addOrgName", "namenumber"),
                          }}
                        />
                        <Row justify="center">
                          <Col xs={12} sm={4} md={4}>
                            <CustomInput
                              success={this.state.addOrgNoState === "success"}
                              error={this.state.addOrgNoState === "error"}
                              labelText={
                                switchIntl(this.props.toggleLng).register
                                  .orgaddress
                              }
                              id="orgno"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                onChange: (event) =>
                                  this.change(event, "addOrgNo", "namenumber"),
                              }}
                            />
                          </Col>
                          <Col xs={12} sm={4} md={4}>
                            <CustomInput
                              success={
                                this.state.addOrgBuildingState === "success"
                              }
                              error={this.state.addOrgBuildingState === "error"}
                              labelText={
                                switchIntl(this.props.toggleLng).register
                                  .orgbuilding
                              }
                              id="orgbuilding"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                onChange: (event) =>
                                  this.change(
                                    event,
                                    "addOrgBuilding",
                                    "namenumber"
                                  ),
                              }}
                            />
                          </Col>
                          <Col xs={12} sm={4} md={4}>
                            <CustomInput
                              success={
                                this.state.addOrgFloorState === "success"
                              }
                              error={this.state.addOrgFloorState === "error"}
                              labelText={
                                switchIntl(this.props.toggleLng).register
                                  .orgfloor
                              }
                              id="orgfloor"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                onChange: (event) =>
                                  this.change(
                                    event,
                                    "addOrgFloor",
                                    "namenumber"
                                  ),
                              }}
                            />
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col xs={12} sm={6} md={6}>
                            <CustomInput
                              success={this.state.addOrgSoiState === "success"}
                              error={this.state.addOrgSoiState === "error"}
                              labelText={
                                switchIntl(this.props.toggleLng).register.orgsoi
                              }
                              id="orgsoi"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                onChange: (event) =>
                                  this.change(event, "addOrgSoi", "namenumber"),
                              }}
                            />
                          </Col>
                          <Col xs={12} sm={6} md={6}>
                            <CustomInput
                              success={
                                this.state.addOrgStreetState === "success"
                              }
                              error={this.state.addOrgStreetState === "error"}
                              labelText={
                                switchIntl(this.props.toggleLng).register
                                  .orgroad
                              }
                              id="orgstreet"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                onChange: (event) =>
                                  this.change(
                                    event,
                                    "addOrgStreet",
                                    "namenumber"
                                  ),
                              }}
                            />
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col xs={12} sm={4} md={4}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                              style={{
                                margin: "0 0 17px 0",
                                position: "relative",
                                paddingTop: "27px",
                                verticalAlign: "unset",
                              }}
                            >
                              <InputLabel
                                className={classes.selectLabel}
                                style={{
                                  top: "10px",
                                  color:
                                    this.state.addOrgProvState === ""
                                      ? "#AAAAAA"
                                      : style_prov,
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "1.42857",
                                }}
                              >
                                {
                                  switchIntl(this.props.toggleLng).register
                                    .orgprov
                                }
                              </InputLabel>
                              <Select
                                style={{
                                  marginTop: "0px",
                                  textAlign: "start",
                                  fontSize: "16px",
                                }}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.addOrgProv}
                                onChange={this.handleSelectedProv}
                                inputProps={{
                                  name: "addOrgProv",
                                  id: "addOrgProv",
                                }}
                              >
                                {provinceItems}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col xs={12} sm={4} md={4}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                              style={{
                                margin: "0 0 17px 0",
                                position: "relative",
                                paddingTop: "27px",
                                verticalAlign: "unset",
                              }}
                            >
                              <InputLabel
                                className={classes.selectLabel}
                                style={{
                                  top: "10px",
                                  color:
                                    this.state.addOrgSubdistState === ""
                                      ? "#AAAAAA"
                                      : style_amp,
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "1.42857",
                                }}
                              >
                                {
                                  switchIntl(this.props.toggleLng).register
                                    .orgsubdis
                                }
                              </InputLabel>
                              <Select
                                style={{
                                  marginTop: "0px",
                                  textAlign: "start",
                                  fontSize: "16px",
                                }}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.addOrgSubdist}
                                onChange={this.handleSelectedSub}
                                inputProps={{
                                  name: "addOrgSubdist",
                                  id: "addOrgSubdist",
                                }}
                              >
                                {amphoneItems}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col xs={12} sm={4} md={4}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                              style={{
                                margin: "0 0 17px 0",
                                position: "relative",
                                paddingTop: "27px",
                                verticalAlign: "unset",
                              }}
                            >
                              <InputLabel
                                className={classes.selectLabel}
                                style={{
                                  top: "10px",
                                  color:
                                    this.state.addOrgDistState === ""
                                      ? "#AAAAAA"
                                      : style_tam,
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "1.42857",
                                }}
                              >
                                {
                                  switchIntl(this.props.toggleLng).register
                                    .orgdis
                                }
                              </InputLabel>
                              <Select
                                style={{
                                  marginTop: "0px",
                                  textAlign: "start",
                                  fontSize: "16px",
                                }}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.addOrgDist}
                                onChange={this.handleSelectedDist}
                                inputProps={{
                                  name: "addOrgDist",
                                  id: "addOrgDist",
                                }}
                              >
                                {tambonItems}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                        <CustomInput
                          success={this.state.addOrgPhoneState === "success"}
                          error={this.state.addOrgPhoneState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgtel
                          }
                          id="orgphone"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "number",
                            onChange: (event) =>
                              this.change(event, "addOrgPhone", "phone", 9, 10),
                          }}
                        />
                        <CustomInput
                          success={this.state.addOrgFaxState === "success"}
                          error={this.state.addOrgFaxState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgfax
                          }
                          id="orgfax"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "number",
                            onChange: (event) =>
                              this.change(event, "addOrgFax", "phone", 9, 10),
                          }}
                        />
                        <CustomInput
                          success={
                            this.state.addOrgSalePhoneState === "success"
                          }
                          error={this.state.addOrgSalePhoneState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgset
                          }
                          id="orgsalephone"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "number",
                            onChange: (event) =>
                              this.change(
                                event,
                                "addOrgSalePhone",
                                "phone",
                                9,
                                10
                              ),
                          }}
                        />
                        <CustomInput
                          success={this.state.addOrgUrlState === "success"}
                          error={this.state.addOrgUrlState === "error"}
                          labelText={
                            switchIntl(this.props.toggleLng).register.orgweb
                          }
                          id="orgurl"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            onChange: (event) =>
                              this.change(event, "addOrgUrl", "website"),
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <div style={{ marginLeft: "auto", marginRight: 0 }}>
                  <NavLink
                    to={"#"}
                    style={{ width: "100%", paddingRight: "5px" }}
                    onClick={
                      this.state.disButton ? null : this.validateRegister
                    }
                  >
                    <Button
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorLogin"
                    >
                      {this.loadingButton(this.state.btLoading)}
                      {switchIntl(this.props.toggleLng).addaccount.addBt}
                    </Button>
                  </NavLink>
                  <NavLink
                    to={"/auth/"}
                    style={{ width: "100%", paddingLeft: "5px" }}
                  >
                  </NavLink>
                </div>
              </CardFooter>
            </Card>
          </form>
        </Col>

        <Col xs={12} sm={10} md={1}></Col>
        <Col xs={12} sm={10} md={3}>
          <form>
            <Card>
              <CardBody style={{ justify: "center" }}>
                <Row justify="center">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ marginTop: "-50px" }}>
                      <PictureUpload />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </form>
        </Col>
      </Row>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(
  withStyles(registerPageStyle, withStyles, makeStyles)(RegisterPage)
);
