/* eslint-disable no-console */
import InputAdornment from "@material-ui/core/InputAdornment";

import withStyles from "@material-ui/core/styles/withStyles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import GHBankLogoPSurvey from "assets/img/logoPsurvey.png";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  API_easypassword,
  API_getold_resetpasswd,
  recaptchaKey,
  requestUrl,
} from "configure/configServer.js";

import switchIntl from "configure/switchIntl.js";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Recaptcha from "react-recaptcha";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { ClipLoader } from "react-spinners";

class ForgetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      alert: null,
      show: false,
      resetEmail: "",
      resetEmailState: "",
      resetCaptcha: "",
      resetCaptchaState: "",
      btLoading: false,
      disButton: false,
      fullURL: null,
      togglehide: true,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.recaptchaCallback = this.recaptchaCallback.bind(this);
    this.checkNewPassword = this.checkNewPassword.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
  };

  componentDidMount() {
    let url = window.location.href;
    let token = url.split("/reset/");
    this.setState({
      fullURL: url,
      token: token[1],
    });
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  recaptchaCallback() {
    return null;
  }

  // === start of handle input forms ===
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        if (this.verifyPassword(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  // function that returns true if value is email, false otherwise
  verifyPassword(value) {
    var passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,32}$/;
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }

  // handle trigger function
  checkNewPassword() {
    if (this.state.newpassword !== this.state.confirmnewpassword) {
      this.setState({ confirmnewpasswordState: "error" });
    } else if (
      this.state.newpasswordState === "success" &&
      this.state.confirmnewpasswordState === "success" &&
      this.state.resetCaptchaState === "success"
    ) {
      this.easyPassword(this.state.newpassword);
    } else {
      this.dynamicAlert(
        "warning",
        switchIntl(this.props.toggleLng).sweetalert.newPass.warning.title,
        switchIntl(this.props.toggleLng).sweetalert.newPass.warning.content,
        switchIntl(this.props.toggleLng).sweetalert.newPass.warning.conBtTxt,
        "",
        false,
        this.hideAlert
      );
    }
  }

  // start of recaptcha callback function
  recaptchaCallback = () => {
    return null;
  };
  verifyCallback = (recaptchaToken) => {
    if (recaptchaToken !== null) {
      this.setState({
        resetCaptcha: recaptchaToken,
        resetCaptchaState: "success",
      });
    }
  };
  expiredCallback = () => {
    this.setState({ resetCaptcha: "", resetCaptchaState: "error" });
  };
  // end of recaptcha callback function

  // start of dynsmic alert handled
  dynamicAlert(
    alertType,
    title,
    content,
    conTxt,
    canTxt,
    showCancel,
    confirmFunction,
    cancelFunction
  ) {
    this.setState({
      alert: (
        <SweetAlert
          type={alertType}
          style={{
            display: "block",
            color: "black",
            fontSize: "18px",
          }}
          customClass="modalContent"
          title={title}
          onConfirm={confirmFunction}
          onCancel={cancelFunction}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={conTxt}
          cancelBtnText={canTxt}
          showCancel={showCancel}
        >
          {content}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  // end of dynsmic alert handled

  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  // loading function
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }
  // end of loading function

  // check old password
  oldpassword = (password) => {
    var body = {
      password: password,
      token: this.state.token,
    };
    var request = new Request(API_getold_resetpasswd, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.requestResetPassword();
        } else if (responseJson.code === 202) {
          this.dynamicAlert(
            "warning",
            switchIntl(this.props.toggleLng).sweetalert.newPass.expire.title,
            switchIntl(this.props.toggleLng).sweetalert.newPass.expire.content,
            switchIntl(this.props.toggleLng).sweetalert.newPass.expire.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        } else {
          this.dynamicAlert(
            "error",
            switchIntl(this.props.toggleLng).sweetalert.newPass.oldpasswd.title,
            switchIntl(this.props.toggleLng).sweetalert.newPass.oldpasswd
              .content,
            switchIntl(this.props.toggleLng).sweetalert.newPass.oldpasswd
              .conBtTxt,
            "",
            false,
            this.hideAlert
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  showpassword = (toggle) => {
    if (toggle) {
      return "password";
    } else {
      return "text";
    }
  };
  // check easy password
  easyPassword = (password) => {
    var body = {
      password: password,
    };

    var request = new Request(API_easypassword, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            easypass: false,
          });
          this.oldpassword(password);
        } else {
          this.setState({
            easypass: true,
          });
          alert("Sorry, easy password");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // request reset password
  requestResetPassword() {
    this.setState({ btLoading: true, disButton: true });
    var body = {
      member_passwd: this.state.newpassword,
    };

    let url = requestUrl + "/reset/" + this.state.token;
    var request = new Request(url, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ btLoading: false, disButton: false });
        if (responseJson.code === 200) {
          this.dynamicAlert(
            "success",
            switchIntl(this.props.toggleLng).sweetalert.newPass.success.title,
            switchIntl(this.props.toggleLng).sweetalert.newPass.success.content,
            switchIntl(this.props.toggleLng).sweetalert.newPass.success
              .conBtTxt,
            "",
            false,
            this.hideAlert
          );
          setTimeout(() => {
            window.location.replace("/auth/login");
          }, 2000);
        } else if (responseJson.code === 202) {
          this.dynamicAlert(
            "warning",
            switchIntl(this.props.toggleLng).sweetalert.newPass.expire.title,
            switchIntl(this.props.toggleLng).sweetalert.newPass.expire.content,
            switchIntl(this.props.toggleLng).sweetalert.newPass.expire.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        } else {
          this.dynamicAlert(
            "error",
            switchIntl(this.props.toggleLng).sweetalert.newPass.error.title,
            switchIntl(this.props.toggleLng).sweetalert.newPass.error.content,
            switchIntl(this.props.toggleLng).sweetalert.newPass.error.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  // end of request reset password

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.alert}
        <GridContainer justify="center" style={{ marginBottom: "20px" }}>
          <GridItem xs={12} sm={6} md={4}>
            <img src={GHBankLogoPSurvey} width="100%" alt="ghlogo" />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  style={{ background: "#283F88" }}
                  color="info"
                >
                  <h4 className={classes.cardTitle}>
                    {switchIntl(this.props.toggleLng).newPass.header}
                  </h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={this.state.newpasswordState === "success"}
                    error={this.state.newpasswordState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).newPass.newpassword
                    }
                    id="newpassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(event, "newpassword", "text"),
                      type: this.showpassword(this.state.togglehide),
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.togglehide ? (
                            <VisibilityIcon
                              onClick={() =>
                                this.setState({
                                  togglehide: !this.state.togglehide,
                                })
                              }
                              className={classes.danger}
                              style={{ color: "#D2D2D2", cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={() =>
                                this.setState({
                                  togglehide: !this.state.togglehide,
                                })
                              }
                              className={classes.danger}
                              style={{ color: "#D2D2D2", cursor: "pointer" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    success={this.state.confirmnewpasswordState === "success"}
                    error={this.state.confirmnewpasswordState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).newPass
                        .confirmnewpassword
                    }
                    id="confirmnewpassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(
                          event,
                          "confirmnewpassword",
                          "equalTo",
                          "newpassword"
                        ),
                      type: this.showpassword(this.state.togglehide),
                    }}
                  />
                  {this.state.confirmnewpasswordState === "error" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        textAlign: "start",
                      }}
                    >
                      <p style={{ margin: 0 }}>รหัสผ่านไม่ตรงกัน</p>
                    </div>
                  ) : null}

                  {this.state.newpasswordState === "error" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        textAlign: "start",
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        - รหัสผ่านจำเป็นต้องมีทั้งตัวเลขและตัวหนังสือ
                      </p>
                      <p style={{ margin: 0 }}>
                        -
                        รหัสผ่านจำเป็นต้องมีทั้งตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่และมีตัวเลขอย่างน้อย
                        1 ตัว
                      </p>
                      <p style={{ margin: 0 }}>
                        - รหัสผ่านจำเป็นต้องมีความยาวอย่างน้อย 8 ตัวแต่ไม่เกิน
                        32 ตัว
                      </p>
                    </div>
                  ) : null}
                  <div className="recaptchaContainer">
                    <Recaptcha
                      sitekey={recaptchaKey}
                      render="explicit"
                      onloadCallback={this.recaptchaCallback}
                      verifyCallback={this.verifyCallback}
                      expiredCallback={this.expiredCallback}
                    />
                  </div>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <NavLink
                    to={"#"}
                    style={{ width: "100%", paddingRight: "5px" }}
                    onClick={
                      this.state.disButton ? null : this.checkNewPassword
                    }
                  >
                    <Button
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorLogin"
                      disabled={this.state.disButton}
                    >
                      {this.loadingButton(this.state.btLoading)}
                      {switchIntl(this.props.toggleLng).newPass.resetBt}
                    </Button>
                  </NavLink>
                  <NavLink
                    to={"/auth/login-page"}
                    style={{ width: "100%", paddingLeft: "5px" }}
                  >
                    <Button
                      simple
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorReg"
                    >
                      {switchIntl(this.props.toggleLng).newPass.backBT}
                    </Button>
                  </NavLink>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ForgetPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(
  withStyles(loginPageStyle)(ForgetPasswordPage)
);
