import React from "react";

// GHBank assets
import GHBankLoading from "assets/img/reicLoading.gif";

export default function loadingScreen(isLoading) {
  if (isLoading === true) {
    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          backgroundColor: "rgba(255,255,255,1)",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99999,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img src={GHBankLoading} style={{ height: "300px", width: "300px" }} alt="ghlogo" />
      </div>
    );
  } else {
    return null;
  }
}
