/* eslint-disable no-console */
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Call from "@material-ui/icons/Call";
import Close from "@material-ui/icons/Close";
import Language from "@material-ui/icons/Language";
import Place from "@material-ui/icons/Place";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import GHbankMap from "../../assets/img/map.jpg";
import switchIntl from "../../configure/switchIntl.js";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      alert: null,
      isSubmited: false,
      key: null,
      showForm: true,
      contact_name_state: null,
      contact_org_state: null,
      contact_phone_state: null,
      contact_email_state: null,
      contact_text_state: null,
      showFullPic: false,
      contactUsMassegeList: [],
      curTab: 0,
    };
    this.warningAlert = this.warningAlert.bind(this);
    this.successAlert = this.successAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
    loadingSet: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.getSecretKey();
  }
  openMessageModal = (indx) => {
    this.setState({ showMessageModal: true });
    this.setState({
      curMessageBox: {
        name: this.state.contactUsMassegeList[indx].con_name,
        org: this.state.contactUsMassegeList[indx].con_department,
        email: this.state.contactUsMassegeList[indx].con_email,
        tel: this.state.contactUsMassegeList[indx].con_phone,
        message: this.state.contactUsMassegeList[indx].con_msg,
      },
    });
  };

  getContactList = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = {};
    var request = new Request(`${config.API_list_contactus}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ contactUsMassegeList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  saveNewAddress = () => {
    this.successAlert();
  };
  replyToAccount = () => {
    this.successAlert();
    this.setState({ showMessageModal: false });
  };

  handleSubmit = () => {
    if (
      this.state.contact_name_state === "success" &&
      this.state.contact_org_state === "success" &&
      this.state.contact_phone_state === "success" &&
      this.state.contact_email_state === "success" &&
      this.state.contact_text_state === "success"
    ) {
      this.sendEmail();
      this.setState({ isSubmited: true });
    } else {
      this.warningAlert(
        switchIntl(this.props.toggleLng).contact.warn_fill_all_msg
      );
    }
  };

  openLink = (value) => {
    switch (value) {
      case 1:
        window.open("https://www.facebook.com/REICFan");
        break;
      case 2:
        window.open("https://twitter.com/REICFan");
        break;
      case 3:
        window.open("https://line.me/R/ti/p/@kfu5011k");
        break;
      case 4:
        window.open("https://www.youtube.com/user/REICPR");
        break;
      default:
        window.open("https://www.youtube.com/user/REICPR");
        break;
    }
  };

  validateThaiEnCharacters = (event, stateName) => {
    var input_data = event.target.value;
    const regex = /^[A-Za-zก-๙ ]*$/;
    let validCharacters = regex.test(input_data);
    if (validCharacters) {
      this.setState({ [stateName + "_state"]: "success" });
    } else {
      this.setState({ [stateName + "_state"]: "error" });
    }
    this.setState({ stateName: input_data });
  };

  validateEmail = (event, stateName) => {
    var email = event.target.value;
    const regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;
    let validEmail = regex.test(email);
    if (validEmail) {
      this.setState({ [stateName + "_state"]: "success" });
    } else {
      this.setState({ [stateName + "_state"]: "error" });
    }
    this.setState({ stateName: email });
  };

  validateMessage = (event, stateName) => {
    let msg = event.target.value;
    const regex = /^[. /0-9A-Za-zก-๙ ]*$/;
    let ValidCharacters = regex.test(msg);
    if ((event.target.value.length < 10) | !ValidCharacters) {
      this.setState({ [stateName + "_state"]: "error" });
    } else {
      this.setState({ [stateName + "_state"]: "success" });
    }
    this.setState({ stateName: msg });
  };

  validateNumber = (event, stateName) => {
    var tel = event.target.value;
    const regex = /^0[0-9]*$/;
    let validNumber = regex.test(tel);

    if (tel.length === 0 || !validNumber || tel.length > 11 || tel.length < 9) {
      this.setState({ [stateName + "_state"]: "error" });
    } else {
      this.setState({ [stateName + "_state"]: "success" });
    }
    this.setState({ stateName: tel });
  };

  warningAlert = (msg) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={switchIntl(this.props.toggleLng).contact.sendmail}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          {msg}
        </SweetAlert>
      ),
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={switchIntl(this.props.toggleLng).contact.get_msg}
          onConfirm={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          {switchIntl(this.props.toggleLng).contact.thankyou_foradmin}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  reload = () => {
    this.setState({
      showForm: false,
    });
  };
  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });

          self.getContactList();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  sendEmail = () => {
    const { key } = this.state;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    let self = this;
    let param = {
      name: this.state.contact_name,
      department: this.state.contact_org,
      tel: this.state.contact_tel,
      email: this.state.contact_email,
      msg: this.state.contact_text,
    };

    var request = new Request(config.API_contact_us, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(param),
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          switch (data.code) {
            case 200:
              self.successAlert();
              break;
            default:
              self.warningAlert(
                switchIntl(self.props.toggleLng).contact.try_again
              );
              break;
          }
        });
      })
      .catch(function(err) {
        // console.log(err);
      });
    return false;
  };

  render() {
    const { classes, toggleLng } = this.props;
    var self = this;
    let hrefLink = "#";

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });

    const google_map = (
      <iframe
        title="ghbank"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.281140314384!2d100.56771551483057!3d13.761912090340916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29e8953db66ef%3A0x54443a512159cbd4!2z4Lio4Li54LiZ4Lii4LmM4LiC4LmJ4Lit4Lih4Li54Lil4Lit4Liq4Lix4LiH4Lir4Liy4Lij4Li04Lih4LiX4Lij4Lix4Lie4Lii4LmMIOC4mOC4meC4suC4hOC4suC4o-C4reC4suC4hOC4suC4o-C4quC4h-C5gOC4hOC4o-C4suC4sOC4q-C5jA!5e0!3m2!1sen!2sth!4v1561955366377!5m2!1sen!2sth"
        width="100%"
        height="360px"
        frameBorder="0"
        style={{ border: 0, borderRadius: "6px", padding: "1px" }}
        allowFullScreen
      ></iframe>
    );

    const srp_content = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          href={hrefLink}
          onClick={() => this.setState({ showFullPic: true })}
        >
          <img
            src={GHbankMap}
            alt="logo"
            style={{ width: "auto", height: "360px" }}
          />
        </div>
      </div>
    );
    let contactUsMassegeList = [];
    if (this.state.contactUsMassegeList) {
      this.state.contactUsMassegeList.forEach(function(message, messageIndex) {
        if (messageIndex < 10) {
          contactUsMassegeList.push([
            message["con_name"],
            message["con_department"],
            message["con_phone"],
            message["con_email"],
            message["con_msg"],
            <div
              style={{ color: "#0DA1C4", textDecoration: "underline" }}
              onClick={() => self.openMessageModal(messageIndex)}
            >
              {switchIntl(toggleLng).contact.seedetailAndreply}
            </div>,
          ]);
        }
      });
    } else {
      contactUsMassegeList = [
        [
          "อังคณา สุวรรณ",
          "ชลบุรี เรียลเอสเตท",
          "082-123-1234",
          "sada2@gmail.com",
          "สอบถามข้อมูลหน่อยค่ะ",
          <div onClick={() => this.openMessageModal(0)}>
            {switchIntl(toggleLng).contact.seedetailAndreply}
          </div>,
        ],
        [
          "ชีพชัย ใจมั่นคง",
          "มหานคร อสังหา",
          "082-123-1235",
          "sada2@gmail.com",
          "สอบถามข้อมูลหน่อยค่ะ",
          <div onClick={() => this.openMessageModal(1)}>
            {switchIntl(toggleLng).contact.seedetailAndreply}
          </div>,
        ],
        [
          "บัวลอย อาจหาญ",
          "ชลบุรี เรียลเอสเตท",
          "082-123-1236",
          "sada2@gmail.com",
          "สอบถามข้อมูลหน่อยค่ะ",
          <div onClick={() => this.openMessageModal(2)}>
            {switchIntl(toggleLng).contact.seedetailAndreply}
          </div>,
        ],
        [
          "มินิ อุดมสุข",
          "ชลบุรี เรียลเอสเตท",
          "082-123-1237",
          "sada2@gmail.com",
          "สอบถามข้อมูลหน่อยค่ะ",
          <div onClick={() => this.openMessageModal(3)}>
            {switchIntl(toggleLng).contact.seedetailAndreply}
          </div>,
        ],
      ];
    }

    const contactUsMassegeListColumn = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "index", // String-based value accessors!
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).contact.tableList_head1,
        accessor: "con_name", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).contact.tableList_head2,
        accessor: "con_department", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).contact.tableList_head3,
        accessor: "con_phone", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).contact.tableList_head4,
        accessor: "con_email", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).contact.tableList_head5,
        accessor: "con_msg", // String-based value accessors!
      },
      {
        Header: switchIntl(toggleLng).contact.tableList_head6,
        accessor: "member_id",
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
        headerStyle: { textAlign: "left" },

        Cell: (val) => (
          <div
            style={{ color: "#0DA1C4", textDecoration: "underline" }}
            onClick={() => self.openMessageModal(val.index)}
          >
            {switchIntl(toggleLng).contact.seedetailAndreply}
          </div>
        ),
      },
    ];

    return (
      <div>
        <Dialog
          maxWidth="xl"
          open={
            this.state.showMessageModal === undefined ||
            this.state.showMessageModal === false
              ? false
              : true
          }
          transition={Transition}
          style={{ width: "100%" }}
          onClose={() =>
            this.setState({ showMessageModal: false, curMessageBox: false })
          }
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent
            style={{ backgroundColor: "#eeeeee", width: 1200 }}
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <Button
              onClick={() =>
                this.setState({ showMessageModal: false, curMessageBox: false })
              }
              justIcon
              round
              color="white"
              style={{ position: "absolute", right: "50px", zIndex: "100" }}
            >
              <Close className={classes.icons} />
            </Button>

            <h3>{switchIntl(toggleLng).contact.contactInformation}</h3>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: "40px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.3m" }}>
                      {switchIntl(toggleLng).contact.tableList_head1}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.4em", marginTop: "10px" }}>
                      {this.state.curMessageBox
                        ? this.state.curMessageBox.name
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: "40px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.3m" }}>
                      {switchIntl(toggleLng).contact.tableList_head2}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.4em", marginTop: "10px" }}>
                      {this.state.curMessageBox
                        ? this.state.curMessageBox.org
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: "40px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.3m" }}>
                      {switchIntl(toggleLng).contact.tableList_head3}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.4em", marginTop: "10px" }}>
                      {this.state.curMessageBox
                        ? this.state.curMessageBox.tel
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: "40px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.3m" }}>
                      {switchIntl(toggleLng).contact.tableList_head4}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.4em", marginTop: "10px" }}>
                      {this.state.curMessageBox
                        ? this.state.curMessageBox.email
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row style={{ marginTop: "10px" }}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.3m" }}>
                      {switchIntl(toggleLng).contact.tableList_head5}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      multiline
                      rows="4"
                      disabled
                      defaultValue={
                        this.state.curMessageBox
                          ? this.state.curMessageBox.message
                          : ""
                      }
                      width="100%"
                      style={{ width: "100%" }}
                      InputProps={{
                        style: { width: "100%" },
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Button
                  href={
                    "mailto:" +
                    (this.state.curMessageBox
                      ? this.state.curMessageBox.email
                      : "") +
                    "?Subject=GHBank%20Admin%20Reply"
                  }
                  target={"_top"}
                >
                  {switchIntl(this.props.toggleLng).contact.replyButton}
                </Button>
              </Col>
            </Row>
          </DialogContent>
        </Dialog>
        <GridContainer style={{ width: "95%" }}>
          {this.state.alert}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {this.state.showForm ? (
              <Card>
                <CardHeader>
                  <h4>
                    {switchIntl(this.props.toggleLng).contact.contact_nameList}
                  </h4>
                </CardHeader>
                <ReactTable
                  data={
                    this.state.contactUsMassegeList
                      ? this.state.contactUsMassegeList
                      : []
                  }
                  filterable
                  columns={contactUsMassegeListColumn}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
                <CardBody></CardBody>
              </Card>
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardBody>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7} lg={7}>
                    <div style={{ width: "95%" }}>
                      <AntTabs
                        value={this.state.curTab}
                        onChange={(e, v) => this.setState({ curTab: v })}
                        aria-label="ant example"
                      >
                        <AntTab
                          label={
                            switchIntl(this.props.toggleLng).contact.title_nav1
                          }
                        />
                        <AntTab
                          label={
                            switchIntl(this.props.toggleLng).contact.title_nav2
                          }
                        />
                      </AntTabs>
                      <TabPanel value={this.state.curTab} index={0}>
                        <div style={{ marginTop: "20px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              className={classes.cardCategory}
                              style={{
                                marginLeft: "10px",
                                fontSize: "18px",
                                color: "black",
                                paddingBottom: "10px",
                              }}
                            >
                              <Place />
                              {switchIntl(this.props.toggleLng).contact.address}
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p>
                              <div
                                href="https://www.reic.or.th/"
                                className={classes.cardCategory}
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "18px",
                                  color: "black",
                                  paddingBottom: "10px",
                                }}
                              >
                                <Language />{" "}
                                {switchIntl(this.props.toggleLng).contact.url}
                              </div>
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              className={classes.cardCategory}
                              style={{
                                marginLeft: "10px",
                                fontSize: "18px",
                                color: "black",
                                paddingBottom: "10px",
                              }}
                            >
                              <Call />{" "}
                              {switchIntl(this.props.toggleLng).contact.phone}
                            </p>
                          </div>
                          <div>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Button
                                justIcon
                                round
                                color="facebook"
                                onClick={() => this.openLink(1)}
                              >
                                <i className={"fab fa-facebook"} />
                              </Button>
                              <Button
                                justIcon
                                round
                                color="twitter"
                                onClick={() => this.openLink(2)}
                              >
                                <i className={"fab fa-twitter"} />
                              </Button>
                              <Button
                                justIcon
                                round
                                color="success"
                                onClick={() => this.openLink(3)}
                              >
                                <i className={"fab fa-line"} />
                              </Button>
                              <Button
                                justIcon
                                round
                                color="danger"
                                onClick={() => this.openLink(4)}
                              >
                                <i className={"fab fa-youtube"} />
                              </Button>
                            </GridItem>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={this.state.curTab} index={1}>
                        <div style={{ marginTop: "20px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              className={classes.cardCategory}
                              style={{
                                marginLeft: "10px",
                                fontSize: "18px",
                                color: "black",
                                paddingBottom: "10px",
                              }}
                            >
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={1}>
                                  <Place />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={11}
                                  style={{ width: "35vw" }}
                                >
                                  <TextField
                                    id="standard-full-width"
                                    multiline
                                    fullWidth
                                    rows="3"
                                    value={
                                      switchIntl(this.props.toggleLng).contact
                                        .address
                                    }
                                  />
                                </Col>
                              </Row>
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              className={classes.cardCategory}
                              style={{
                                marginLeft: "10px",
                                fontSize: "18px",
                                color: "black",
                                paddingBottom: "10px",
                              }}
                            >
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={1}>
                                  <Language />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={11}
                                  style={{ width: "35vw" }}
                                >
                                  <TextField
                                    fullWidth
                                    defaultValue={
                                      switchIntl(this.props.toggleLng).contact
                                        .url
                                    }
                                  />
                                </Col>
                              </Row>
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              className={classes.cardCategory}
                              style={{
                                marginLeft: "10px",
                                fontSize: "18px",
                                color: "black",
                                paddingBottom: "10px",
                              }}
                            >
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={1}>
                                  <Call />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={11}
                                  style={{ width: "35vw" }}
                                >
                                  <TextField
                                    fullWidth
                                    defaultValue={
                                      switchIntl(this.props.toggleLng).contact
                                        .phone
                                    }
                                  />
                                </Col>
                              </Row>
                            </p>
                          </div>

                          <div>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Button
                                justIcon
                                round
                                color="facebook"
                                onClick={() => this.openLink(1)}
                              >
                                <i className={" fab fa-facebook"} />
                              </Button>
                              <Button
                                justIcon
                                round
                                color="twitter"
                                onClick={() => this.openLink(2)}
                              >
                                <i className={" fab fa-twitter"} />
                              </Button>
                              <Button
                                justIcon
                                round
                                color="success"
                                onClick={() => this.openLink(3)}
                              >
                                <i className={" fab fa-line"} />
                              </Button>
                              <Button
                                justIcon
                                round
                                color="danger"
                                onClick={() => this.openLink(4)}
                              >
                                <i className={" fab fa-youtube"} />
                              </Button>
                              <div>
                                <Button
                                  color="success"
                                  onClick={() => this.saveNewAddress()}
                                >
                                  {
                                    switchIntl(this.props.toggleLng).userprofile
                                      .submit
                                  }
                                </Button>
                              </div>
                            </GridItem>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5} lg={5}>
                    <NavPills
                      color="warning"
                      tabs={[
                        {
                          tabButton: switchIntl(this.props.toggleLng).contact
                            .googlemap,
                          tabContent: google_map,
                        },
                        {
                          tabButton: switchIntl(this.props.toggleLng).contact
                            .graphicmap,
                          tabContent: srp_content,
                        },
                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          {this.state.showFullPic ? (
            <Dialog
              fullScreen
              open={this.state.showFullPic}
              transition={Transition}
              style={{ width: "100%" }}
              keepMounted
              onClose={() => this.setState({ showFullPic: false })}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description"
            >
              <DialogContent
                style={{ backgroundColor: "#eeeeee" }}
                id="modal-slide-description"
                className={classes.modalBody}
              >
                <Button
                  onClick={() => this.setState({ showFullPic: false })}
                  justIcon
                  round
                  color="white"
                  style={{ position: "absolute", right: "50px", zIndex: "100" }}
                >
                  <Close className={classes.icons} />
                </Button>
                <Card>
                  <img
                    src={GHbankMap}
                    alt="logo"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Card>
              </DialogContent>
            </Dialog>
          ) : null}
        </GridContainer>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withStyles(sweetAlertStyle)(Contact));
