/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInputTooltips.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import numeral from "numeral";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-thai-datepickers";
import MomentUtils from "@date-io/moment";

import "moment/locale/th";

import { connect } from "react-redux";

import setBoundary from "actions/survey/setBoundary.js";
import setProv from "actions/survey/setProvAmphoeTambon.js";
import setCatType1 from "actions/survey/setCatType1.js";
import setCatType2 from "actions/survey/setCatType2.js";
import setCatType3 from "actions/survey/setCatType3.js";
import setCatType4 from "actions/survey/setCatType4.js";
import setCatType5 from "actions/survey/setCatType5.js";

import villa_sec1_part2Object from "actions/survey/villa/villa_sec1_part2Data.js";
import project_datatable from "actions/survey/psurvey_editproj.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class PublishInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      survey_salesDate: null,
      survey_salesDateState: "",
      survey_constructedDate: null,
      survey_constructedDateState: "",
      survey_finishDate: null,
      survey_finishDateState: "",
      survey_prostatus: "",
      survey_prostatusState: "",
      survey_minprice: 0,
      survey_minpriceState: "",
      survey_minpriceStateName: "",
      survey_maxprice: 0,
      survey_maxpriceState: "",
      survey_maxpriceStateName: "",
      survey_allocate: "",
      survey_allocateState: "",
      survey_allocateVal: "",
      survey_allocateValState: "",
      // survey_unitydetail: "",
      // survey_unitydetailState: "",
      survey_saleimprove: "",
      survey_saleimproveState: "",
      survey_projectprice: 0,
      survey_projectpriceState: "",
      checked: [],
      checkedState: "",
      other_utility: "",
      other_utilityState: "",
      other_utility_value: "",
      // check porject condition
      isallow_status1: false,
      isallow_status2: false,
      isallow_status3: false,
      isallow_status4: false,
      isallow_status5: false,
      isallow_status6: false,
      openSnack: false,
      alertSnack: false,
      survey_placeholderMinprice: "",
      survey_placeholderMaxprice: "",

      cat_type_check: true,
      is_cat_type_5: false,
      cat_type_1: false,
      cat_type_2: false,
      cat_type_3: false,
      cat_type_4: false,
      cat_type_5: false,
    };

    this.handle_projectStatus = this.handle_projectStatus.bind(this);
    this.handle_allocate = this.handle_allocate.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      this.props.project_datatable(undefined);
    } else {
      this.get_projectData(this.props.proj_dataTable, false);
    }
  }

  componentDidUpdate(previousProps) {
    if (
      JSON.stringify(previousProps.oldproj_data) !==
      JSON.stringify(this.props.oldproj_data)
    ) {
      this.get_projectData(this.props.oldproj_data, true);
    }
    if (
      previousProps.project_id !== this.props.project_id &&
      this.props.project_id &&
      this.state.survey_salesDate
    ) {
      this.setProperStatus(this.state.survey_salesDate, this.props.project_id);
    }
  }
  setProperStatus(firstsale, projectid) {
    var projectid_quater = this.getQuater(projectid);
    if (
      moment(firstsale).format("DD/MM/YYYY") === "01/01/1900" ||
      moment(firstsale).format("DD/MM/YYYY") === "01/01/1970"
    ) {
      return;
    }
    switch (projectid_quater[3]) {
      case "Q":
        this.saledateQuarterFilter(new Date(firstsale), projectid_quater);
        break;
      case "H":
        this.saledateHalfFilter(new Date(firstsale), projectid_quater);
        break;
      default:
        break;
    }
  }
  get_projectData = (value, isOld) => {
    // console.log("get_projectData")
    // console.log(value)
    // console.log("this.props", value);
    // console.log("this.props", value.cat);
    const edit_data = value.data[0];
    if (value.cat && value.cat.length !== 0) {
      for (let index = 0; index < value.cat.length; index++) {
        const element = value.cat[index];
        // console.log(index, "cat_type", element.cat_type);
        if (element.cat_type === 1) {
          this.setState({ cat_type_1: true }, () => this.checkValidType());
          this.props.setCatType1(true);
        }
        if (element.cat_type === 2) {
          this.setState({ cat_type_2: true }, () => this.checkValidType());
          this.props.setCatType2(true);
        }
        if (element.cat_type === 3) {
          this.setState({ cat_type_3: true }, () => this.checkValidType());
          this.props.setCatType3(true);
        }
        if (element.cat_type === 4) {
          this.setState({ cat_type_4: true }, () => this.checkValidType());
          this.props.setCatType4(true);
        }
        if (element.cat_type === 5) {
          this.setState({ cat_type_5: true }, () => this.checkValidType());
          this.props.setCatType5(true);
        }
      }
    }
    if (!isOld) {
      const exp = this.array_jsonremove(value.unity);
      this.showOtherUtilities(exp);
      edit_data.arrayChk = exp;
      // console.log(moment(edit_data.villa_datefirstsell).format("DD/MM/YYYY"))
      this.setState({
        survey_salesDate:
          moment(edit_data.villa_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.villa_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1970"
            ? null
            : moment(edit_data.villa_datefirstsell),
        survey_constructedDate:
          moment(edit_data.villa_dateconstruct).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.villa_dateconstruct).format("DD/MM/YYYY") ===
            "01/01/1970"
            ? null
            : moment(edit_data.villa_dateconstruct),
        survey_finishDate:
          moment(edit_data.villa_datefinish).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.villa_datefinish).format("DD/MM/YYYY") ===
            "01/01/1970"
            ? null
            : moment(edit_data.villa_datefinish),
        survey_prostatus: JSON.stringify(edit_data.villa_projectstatus),
        survey_maxprice: edit_data.villa_maxprice,
        survey_minprice: edit_data.villa_minprice,
        survey_allocate: this.check_editAllocate(edit_data.villa_allocate),
        survey_saleimprove: edit_data.villa_saleimprove,
        survey_projectprice: edit_data.villa_projectprice,
        checked: exp,
      });
    } else {
      this.setState({
        survey_salesDate:
          moment(edit_data.villa_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.villa_datefirstsell).format("DD/MM/YYYY") ===
            "01/01/1970"
            ? null
            : moment(edit_data.villa_datefirstsell),
        survey_constructedDate:
          moment(edit_data.villa_dateconstruct).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.villa_dateconstruct).format("DD/MM/YYYY") ===
            "01/01/1970"
            ? null
            : moment(edit_data.villa_dateconstruct),
        survey_finishDate:
          moment(edit_data.villa_datefinish).format("DD/MM/YYYY") ===
            "01/01/1900" ||
          moment(edit_data.villa_datefinish).format("DD/MM/YYYY") ===
            "01/01/1970"
            ? null
            : moment(edit_data.villa_datefinish),
        survey_allocate: this.check_editAllocate(edit_data.villa_allocate),
      });
    }

    this.setAllSuccess(edit_data, "villa", isOld);
  };
  setAllSuccess(edit_data, type, isOld) {
    // console.log(edit_data)
    if (
      edit_data[type + "_datefirstsell"] !== null &&
      edit_data[type + "_datefirstsell"] !== undefined
    ) {
      this.setState({ survey_salesDateState: "success" });
    }
    if (
      edit_data[type + "_dateconstruct"] !== null &&
      edit_data[type + "_dateconstruct"] !== undefined
    ) {
      this.setState({ survey_constructedDateState: "success" });
    }
    if (
      edit_data[type + "_datefinish"] !== null &&
      edit_data[type + "_datefinish"] !== undefined
    ) {
      this.setState({ survey_finishDateState: "success" });
    }
    if (!isOld) {
      if (
        edit_data[type + "_projectstatus"] !== "" &&
        edit_data[type + "_projectstatus"] !== 0 &&
        edit_data[type + "_projectstatus"] !== "0"
      ) {
        this.setState({ survey_prostatusState: "success" });
      }
    }
    if (!isOld) {
      if (edit_data[type + "_minprice"] !== 0) {
        this.setState({ survey_minpriceState: "success" });
      }
      if (edit_data[type + "_averageprice"] !== 0) {
        this.setState({ survey_averagepriceState: "success" });
      }
      if (edit_data[type + "_maxprice"] !== 0) {
        this.setState({ survey_maxpriceState: "success" });
      }
    }
    if (edit_data[type + "_constructapprove"] !== "") {
      this.setState({ survey_constructapproveState: "success" });
    }
    if (edit_data[type + "_registered"] !== "") {
      this.setState({ survey_registeredState: "success" });
    }
    if (edit_data[type + "_constructapprove"] !== "") {
      this.setState({ survey_constructapproveValState: "success" });
    }
    if (edit_data[type + "_allocate"] !== "") {
      this.setState({ survey_allocateState: "success" });
      if (
        edit_data[type + "_allocate"] === "2" ||
        edit_data[type + "_allocate"] === "3" ||
        edit_data[type + "_allocate"] === "4" ||
        edit_data[type + "_allocate"] === "5"
      ) {
        this.setState({ survey_allocateValState: "success" });
      } else if (
        edit_data[type + "_allocate"] === "-" ||
        edit_data[type + "_allocate"].length > 1
      ) {
        this.setState({ survey_allocateValState: "success" });
      }
    }
    if (edit_data[type + "_registered"] !== "") {
      this.setState({ survey_registeredValState: "success" });
    }
    if (edit_data[type + "_envapprove"] !== "") {
      this.setState({ survey_envapproveState: "success" });
    }
    if (!isOld) {
      if (edit_data[type + "_saleimprove"] !== "") {
        this.setState({ survey_saleimproveState: "success" });
      }
      if (edit_data.arrayChk.length !== 0) {
        this.setState({ checkedState: "success" });
      }
      if (edit_data[type + "_projectprice"] !== 0) {
        this.setState({ survey_projectpriceState: "success" });
      }
    }
  }
  showOtherUtilities(unity) {
    let others = unity.filter((props) => {
      return (
        props !== "clubhouse" &&
        props !== "pool" &&
        props !== "security" &&
        props !== "other"
      );
    });
    if (others.length !== 0) {
      var otherText = "";
      others.forEach(function(ot, ix) {
        if (ix === 0) {
          otherText = otherText + ot;
        } else {
          otherText = otherText + " " + ot;
        }
      });
      this.setState({ other_utility_value: otherText }, () => {
        if (!this.state.checked.includes("other")) {
          this.handleToggle("other");
        }
      });
    } else {
      //
    }
  }

  array_jsonremove(unity) {
    const arr = [];
    for (let i = 0; i < unity.length; i++) {
      if (unity[i].unity_detail !== "") {
        arr.push(unity[i].unity_detail);
      }
    }
    return arr;
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  getQuater(projectid) {
    if (this.props.getregtime) {
      // new project
      let year = this.props.getregyear;
      let halfyear = this.props.getreghalf;
      let quarter = this.props.getregquarter;
      return [year, halfyear, quarter];
    } else {
      // existing project
      // alert(projectid)
      if (projectid) {
        let year = parseInt(projectid.substring(0, 2)) + 2500 - 543;
        let period = projectid.substring(2, 3);
        if (period === "Q") {
          let quarter = parseInt(projectid.substring(3, 4));
          let halfyear = quarter <= 2 ? 1 : 2;
          return [year, halfyear, quarter, period];
        } else {
          let halfyear = parseInt(projectid.substring(3, 4));
          let quarter = halfyear === 1 ? 2 : 4;
          return [year, halfyear, quarter, period];
        }
      }
      return null;
    }
  }

  setSaleDate = (date) => {
    var projectid_quater = this.getQuater(this.props.project_id);
    if (!projectid_quater) {
      return alert("กรุณาสร้างเลขที่แบบสอบถามก่อนเลือกวันที่เปิดขาย");
    }
    // console.log(projectid_quater)
    // projectid_quater = [1,2020];
    this.setState({ survey_prostatus: "", survey_prostatusState: "error" });

    switch (projectid_quater[3]) {
      case "Q":
        this.saledateQuarterFilter(date, projectid_quater);
        break;
      case "H":
        this.saledateHalfFilter(date, projectid_quater);
        break;
      default:
        this.setState({
          survey_salesDate: null,
          survey_salesDateState: "error",
          openSnack: true,
        });
        break;
    }

    setTimeout(() => {
      this.setState({
        openSnack: false,
      });
    }, 5000);
  };

  saledateQuarterFilter = (date, quater) => {
    // console.log(date)
    // console.log(quater)
    const quaterDate = moment(date).quarter();
    // const halfYearDate = moment(date).month() <= 5 ? 1 : 2;
    this.setState({
      survey_salesDate: moment(date),
      survey_salesDateState: "success",
    });
    // console.log(halfYearDate)
    // console.log(date.year())
    // console.log(quater[0])
    // console.log(quater[1])
    if (moment(date).year() === quater[0] && quater[2] === quaterDate) {
      // console.log("this period");
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: false,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: false,
      });
    } else if (
      moment(date).year() < quater[0] ||
      (moment(date).year() === quater[0] && quater[2] > quaterDate)
    ) {
      // console.log("past period");
      this.setState({
        isallow_status1: false,
        isallow_status2: false,
        isallow_status3: true,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: true,
      });
    } else if (
      moment(date).year() > quater[0] ||
      (moment(date).year() === quater[0] && quater[2] < quaterDate)
    ) {
      // console.log("future period");
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: true,
        isallow_status4: false,
        isallow_status5: true,
        isallow_status6: true,
      });
    }
  };

  saledateHalfFilter = (date, quater) => {
    // console.log(date)
    // console.log(quater)
    // const quaterDate = moment(date).quarter();
    const halfYearDate = moment(date).month() <= 5 ? 1 : 2;
    this.setState({
      survey_salesDate: moment(date),
      survey_salesDateState: "success",
    });
    // console.log(halfYearDate)
    // console.log(date.year())
    // console.log(quater[0])
    // console.log(quater[1])
    if (moment(date).year() === quater[0] && quater[1] === halfYearDate) {
      // console.log("this period");
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: false,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: false,
      });
    } else if (
      moment(date).year() < quater[0] ||
      (moment(date).year() === quater[0] && quater[1] > halfYearDate)
    ) {
      // console.log("past period");
      this.setState({
        isallow_status1: false,
        isallow_status2: false,
        isallow_status3: true,
        isallow_status4: true,
        isallow_status5: false,
        isallow_status6: true,
      });
    } else if (
      moment(date).year() > quater[0] ||
      (moment(date).year() === quater[0] && quater[1] < halfYearDate)
    ) {
      // console.log("future period");
      this.setState({
        isallow_status1: true,
        isallow_status2: true,
        isallow_status3: true,
        isallow_status4: false,
        isallow_status5: true,
        isallow_status6: true,
      });
    }
  };

  setconstructedDate = (date) => {
    try {
      // const valformat = moment(new Date(date)).format();
      this.setState({
        survey_constructedDate: moment(date),
        survey_constructedDateState: "success",
      });
    } catch (err) {
      //
    }
  };

  setfinishDate = (date) => {
    try {
      // const valformat = moment(new Date(date)).format();
      this.setState({
        survey_finishDate: moment(date),
        survey_finishDateState: "success",
      });
    } catch (err) {
      //
    }
  };

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const intval = parseInt(numeral(value).value());
    if (intval > 0) {
      return true;
    }
    return false;
  }
  verifyNote(value) {
    var nameRex = /^[a-zA-Zก-๙0-9+=%._,/()-\s/]+$/;
    var noQuote = /^[^'"]*$/;
    var noNewline = /^[^\r\n]*$/;
    if (nameRex.test(value) && noQuote.test(value) && noNewline.test(value)) {
      return true;
    }
    return false;
  }
  verifyNo(value) {
    var nameRex = /^[0-9/]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyProjPrice(value) {
    const intval = parseInt(numeral(value).value());
    if (intval > 0) {
      return true;
    }
    return false;
  }

  verifyPriceRange(value, flag, cval) {
    const intval = parseInt(numeral(value).value());
    const intcval = parseInt(numeral(cval).value());
    const exmaxVal = parseInt(this.state.survey_placeholderMaxprice);
    const exminVal = parseInt(this.state.survey_placeholderMinprice);
    switch (flag) {
      case "max-value":
        if (intval === exmaxVal) {
          this.setState({
            alertSnack: true,
          });

          setTimeout(() => {
            this.setState({
              alertSnack: false,
            });
          }, 2500);
        }
        if (intval === 0 && intcval === 0) {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
            survey_maxpriceStateName: "",
          });
          return true;
        }
        if (intval > intcval * 10) {
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
            survey_maxpriceStateName: "มูลค่าเกิน 10 เท่า",
          });
          return false;
        }
        if (intval >= intcval) {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
            survey_maxpriceStateName: "",
          });
          return true;
        }
        if (intval < intcval) {
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
            survey_maxpriceStateName: "",
          });
          return false;
        }
        this.setState({
          survey_minpriceState: "error",
          survey_maxpriceState: "error",
          survey_maxpriceStateName: "",
        });
        return false;
      case "min-value":
        if (intval === exminVal) {
          this.setState({
            alertSnack: true,
          });

          setTimeout(() => {
            this.setState({
              alertSnack: false,
            });
          }, 2500);
        }
        if (intval === 0 && intcval === 0) {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
            survey_maxpriceStateName: "",
          });
          return true;
        }
        if (intval < 1000) {
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
            survey_minpriceStateName: "มูลค่าต่ำกว่า 1,000",
          });
          return false;
        }
        if (intval <= intcval) {
          this.setState({
            survey_minpriceState: "success",
            survey_maxpriceState: "success",
            survey_minpriceStateName: "",
          });
          return true;
        }
        if (intval > intcval) {
          this.setState({
            survey_minpriceState: "error",
            survey_maxpriceState: "error",
            survey_minpriceStateName: "",
          });
          return false;
        }
        this.setState({
          survey_minpriceState: "error",
          survey_maxpriceState: "error",
          survey_minpriceStateName: "",
        });
        return false;
      default:
        break;
    }
  }

  // validate forms
  change(event, stateName, type, compareValue) {
    let value = event.target.value;
    switch (type) {
      case "other_utility":
        if (this.verifyNote(event.target.value)) {
          this.setState({ other_utility: value });
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "name":
        if (this.verifyNote(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyPriceRange(event.target.value, type, compareValue)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyPriceRange(event.target.value, type, compareValue)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "survey_allocateVal":
        if (
          this.verifyNote(event.target.value) &&
          (event.target.value.length > 1 || event.target.value === "-")
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "project_price":
        if (this.verifyProjPrice(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  // handle trigger function
  // check add org trigger?
  validateRegister() {
    const {
      survey_salesDate,
      survey_constructedDate,
      survey_finishDate,
      survey_prostatus,
      survey_maxprice,
      survey_minprice,
      survey_allocate,
      survey_saleimprove,
      survey_projectprice,
      checked,
    } = this.state;
    let newChecked = [...checked];

    newChecked = newChecked.filter((props) => {
      return (
        props === "security" ||
        (props === "other") | (props === "clubhouse") | (props === "pool")
      );
    });

    if (newChecked.indexOf("other") !== -1) {
      newChecked.push(this.state.other_utility_value);
    } else {
      this.setState({ other_utility: "" });
    }

    setTimeout(() => {
      var body = {
        villa_datefirstsell:
          survey_salesDate === ""
            ? "01/01/1900"
            : moment(new Date(survey_salesDate)).format("DD/MM/YYYY"),
        villa_dateconstruct:
          survey_constructedDate === ""
            ? "01/01/1900"
            : moment(new Date(survey_constructedDate)).format("DD/MM/YYYY"),
        household_datefinish:
          survey_finishDate === ""
            ? "01/01/1900"
            : this.state.is_cat_type_5
            ? moment(new Date(survey_constructedDate)).format("DD/MM/YYYY")
            : moment(new Date(survey_finishDate)).format("DD/MM/YYYY"),
        villa_projectstatus:
          survey_prostatus === "" ? 0 : parseInt(survey_prostatus),
        villa_maxprice: numeral(survey_maxprice).value(),
        villa_minprice: numeral(survey_minprice).value(),
        villa_allocate:
          survey_allocate === "" ? 0 : this.check_allocate(survey_allocate),
        villa_saleimprove:
          survey_saleimprove === "" ? "N_A" : survey_saleimprove,
        villa_projectprice: numeral(survey_projectprice).value(),
        unity_detail: newChecked.length === 0 ? [""] : newChecked,
      };

      this.props.villa_sec1_part2Object(body);
    }, 500);
  }

  validateState(state) {
    const {
      survey_salesDateState,
      survey_constructedDateState,
      survey_finishDateState,
      survey_prostatusState,
      survey_maxpriceState,
      survey_minpriceState,
      survey_allocateState,
      survey_allocateValState,
      survey_saleimproveState,
      survey_projectpriceState,
      checkedState,
      other_utilityState,
      cat_type_1,
      cat_type_2,
      cat_type_3,
      cat_type_4,
      cat_type_5,
    } = this.state;
    // console.log({
    //   survey_salesDateState,
    //   survey_constructedDateState,
    //   survey_finishDateState,
    //   survey_prostatusState,
    //   survey_maxpriceState,
    //   survey_minpriceState,
    //   survey_allocateState,
    //   survey_allocateValState,
    //   survey_saleimproveState,
    //   survey_projectpriceState,
    //   checkedState,
    //   other_utilityState,
    // });
    // console.log({
    //   survey_salesDateState,
    //   survey_constructedDateState,
    //   survey_finishDateState,
    //   survey_prostatusState,
    //   survey_maxpriceState,
    //   survey_minpriceState,
    //   survey_allocateState,
    //   survey_allocateValState,
    //   survey_saleimproveState,
    //   survey_projectpriceState,
    //   checkedState,
    //   other_utilityState
    // })

    switch (state) {
      case "save":
        if (
          survey_salesDateState === "error" ||
          survey_constructedDateState === "error" ||
          survey_finishDateState === "error" ||
          survey_prostatusState === "error" ||
          survey_maxpriceState === "error" ||
          survey_minpriceState === "error" ||
          survey_allocateState === "error" ||
          survey_allocateValState === "error" ||
          survey_saleimproveState === "error" ||
          survey_projectpriceState === "error" ||
          (other_utilityState === "error" &&
            this.state.checked.indexOf("other") !== -1) ||
          checkedState === "error" ||
          (cat_type_1 === false &&
            cat_type_2 === false &&
            cat_type_3 === false &&
            cat_type_4 === false &&
            cat_type_5 === false)
        ) {
          return false;
        } else {
          return true;
        }
      case "submit":
        if (
          survey_salesDateState === "success" &&
          survey_constructedDateState === "success" &&
          survey_finishDateState === "success" &&
          survey_prostatusState === "success" &&
          survey_saleimproveState === "success" &&
          survey_maxpriceState === "success" &&
          survey_minpriceState === "success" &&
          survey_allocateState === "success" &&
          survey_allocateValState === "success" &&
          survey_projectpriceState === "success" &&
          checkedState === "success" &&
          (cat_type_1 === true ||
            cat_type_2 === true ||
            cat_type_3 === true ||
            cat_type_4 === true ||
            cat_type_5 === true)
        ) {
          return true;
        } else {
          this.setAllEmpty();
          return false;
        }
      default:
        break;
    }
  }

  resetAllState() {
    this.setState({
      survey_salesDateState: "",
      survey_constructedDateState: "",
      survey_finishDateState: "",
      survey_prostatusState: "",
      survey_maxpriceState: "",
      survey_minpriceState: "",
      survey_allocateState: "",
      survey_allocateValState: "",
      survey_saleimproveState: "",
      survey_projectpriceState: "",
      other_utilityState: "",
      checkedState: "",
    });
  }
  setAllEmpty() {
    let arrayChk = this.state.checked;

    if (this.state.survey_salesDate === null) {
      this.setState({ survey_salesDateState: "error" });
    }
    if (this.state.survey_constructedDate === null) {
      this.setState({ survey_constructedDateState: "error" });
    }
    if (this.state.survey_finishDate === null) {
      this.setState({ survey_finishDateState: "error" });
    }
    if (
      !this.state.survey_prostatus ||
      this.state.survey_prostatus === "" ||
      this.state.survey_prostatus === 0
    ) {
      this.setState({ survey_prostatusState: "error" });
    }
    if (this.state.survey_minprice === 0) {
      this.setState({ survey_minpriceState: "error" });
    }
    if (this.state.survey_maxprice === 0) {
      this.setState({ survey_maxpriceState: "error" });
    }
    if (this.state.survey_allocate === "") {
      this.setState({ survey_allocateState: "error" });
    }
    if (
      this.state.survey_allocate === "1" &&
      this.state.survey_allocate !== "-" &&
      (this.state.survey_allocateVal === "" ||
        this.state.survey_allocateVal.length < 1)
    ) {
      this.setState({ survey_allocateValState: "error" });
    }
    if (arrayChk.length === 0) {
      this.setState({ checkedState: "error" });
    }
    if (this.state.survey_projectprice === 0) {
      this.setState({ survey_projectpriceState: "error" });
    }
    if (this.state.survey_saleimprove === "") {
      this.setState({ survey_saleimproveState: "error" });
    }
  }

  check_allocate(allocate) {
    if (allocate === "1") {
      return this.state.survey_allocateVal === ""
        ? 0
        : this.state.survey_allocateVal;
    } else {
      return allocate;
    }
  }

  check_editAllocate(allocate) {
    if (allocate === "0") {
      return null;
    }
    const unity = allocate.toString();
    switch (unity) {
      case "2":
        return allocate;
      case "3":
        return allocate;
      case "4":
        return allocate;
      case "5":
        return allocate;
      default:
        this.setState({
          survey_allocateVal: allocate,
        });
        return "1";
    }
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
      checkedState: "success",
    });
  }

  checkValidType() {
    if (
      this.state.cat_type_1 === false &&
      this.state.cat_type_2 === false &&
      this.state.cat_type_3 === false &&
      this.state.cat_type_4 === false &&
      this.state.cat_type_5 === false
    ) {
      this.setState({ cat_type_check: true });
    } else {
      this.setState({ cat_type_check: false });
    }
    if (this.state.cat_type_5 === true) {
      if (
        this.state.cat_type_1 === true ||
        this.state.cat_type_2 === true ||
        this.state.cat_type_3 === true ||
        this.state.cat_type_4 === true
      ) {
        this.setState({ is_cat_type_5: false });
      } else {
        this.setState({ is_cat_type_5: true });
      }
    } else {
      this.setState({ is_cat_type_5: false });
    }
  }

  // function call radio survey ownertype
  handle_projectStatus(event) {
    this.setState({
      survey_prostatus: event.target.value,
      survey_prostatusState: "success",
    });
  }
  handle_allocate(event) {
    if (event.target.value === "1") {
      this.setState({
        survey_allocate: event.target.value,
        survey_allocateState: "success",
        survey_allocateValState: "error",
      });
    } else {
      this.setState({
        survey_allocate: event.target.value,
        survey_allocateState: "success",
        survey_allocateValState: "success",
      });
    }
  }

  validation = (currentDate) => {
    var selectedDate = this.state.survey_constructedDate;
    return currentDate.isAfter(selectedDate);
  };

  renderCurrency = (value) => {
    const number = numeral(value);
    if (value === 0 || value === "") {
      return "";
    } else {
      return number.format("0,0");
    }
  };

  renderPlaceholder = (value, type) => {
    const number = numeral(value);
    switch (type) {
      case "min":
        if (value === 0 || value === "") {
          return "ราคาต่ำสุด";
        } else {
          return "ราคาต่ำสุด (ราคาเก่า: " + number.format("0,0") + ")";
        }
      case "max":
        if (value === 0 || value === "") {
          return "ราคาสูงสุด";
        } else {
          return "ราคาสูงสุด (ราคาเก่า: " + number.format("0,0") + ")";
        }
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    const checkArray = this.state.checked;

    return (
      <div>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message="กรุณาเลือกตำแหน่งโครงการ ก่อนทำการเลือกวันที่เปิดขาย"
          open={this.state.openSnack}
          closeNotification={() => this.setState({ openSnack: false })}
          close
        />
        <Snackbar
          place="tc"
          color="warning"
          icon={AddAlert}
          message="ราคาในช่วงการสำรวจ H2 ไม่ต่างจาก H1"
          open={this.state.alertSnack}
          closeNotification={() => this.setState({ alertSnack: false })}
          close
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <GridContainer>
              <FormLabel
                style={{
                  float: "none",
                  marginBottom: "15px",
                  color: this.state.checkedState === "error" ? "red" : "black",
                }}
                className={
                  classes.labelHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                ประเภทบ้านในโครงการ
              </FormLabel>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.cat_type_1}
                        onClick={() => {
                          this.setState(
                            { cat_type_1: !this.state.cat_type_1 },
                            () => this.checkValidType()
                          );
                          this.props.setCatType1(!this.props.cat_type_1);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="บ้านเดี่ยว"
                  />
                </div>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.cat_type_2}
                        onClick={() => {
                          this.setState(
                            { cat_type_2: !this.state.cat_type_2 },
                            () => this.checkValidType()
                          );
                          this.props.setCatType2(!this.props.cat_type_2);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="บ้านแฝด"
                  />
                </div>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.cat_type_3}
                        onClick={() => {
                          this.setState(
                            { cat_type_3: !this.state.cat_type_3 },
                            () => this.checkValidType()
                          );
                          this.props.setCatType3(!this.props.cat_type_3);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="ทาวน์เฮาส์ หรือ ทาวน์โฮม"
                  />
                </div>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.cat_type_4}
                        onClick={() => {
                          this.setState(
                            { cat_type_4: !this.state.cat_type_4 },
                            () => this.checkValidType()
                          );
                          this.props.setCatType4(!this.props.cat_type_4);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="อาคารพาณิชย์"
                  />
                </div>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.cat_type_5}
                        onClick={() => {
                          this.setState(
                            { cat_type_5: !this.state.cat_type_5 },
                            () => this.checkValidType()
                          );
                          this.props.setCatType5(!this.props.cat_type_5);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="ที่ดินเปล่า"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <FormControl
              fullWidth
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "24px",
                verticalAlign: "unset",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  clearable="true"
                  label={
                    <div
                      style={{
                        color:
                          this.state.survey_salesDateState === "error"
                            ? "red"
                            : this.state.survey_salesDateState === "success"
                            ? "green"
                            : "black",
                      }}
                    >
                      วันที่เปิดขาย
                    </div>
                  }
                  format="DD/MM/YYYY"
                  yearOffset={543}
                  variant="inline"
                  // placeholder="กดเพื่อเลือกวันที่เปิดขาย"
                  onChange={this.setSaleDate}
                  value={this.state.survey_salesDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "24px",
                verticalAlign: "unset",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  clearable="true"
                  label={
                    <div
                      style={{
                        color:
                          this.state.survey_constructedDateState === "error"
                            ? "red"
                            : this.state.survey_constructedDateState ===
                              "success"
                            ? "green"
                            : "black",
                      }}
                    >
                      วันที่เริ่มก่อสร้าง / คาดว่าจะเริ่มก่อสร้าง
                    </div>
                  }
                  format="DD/MM/YYYY"
                  yearOffset={543}
                  variant="inline"
                  // placeholder="กดเพื่อเลือกวันที่เริ่มก่อสร้าง / คาดว่าจะเริ่มก่อสร้าง"
                  onChange={this.setconstructedDate}
                  value={this.state.survey_constructedDate}
                  disabled={this.state.cat_type_check}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl
              fullWidth
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "24px",
                verticalAlign: "unset",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"th"}>
                <DatePicker
                  clearable="true"
                  label={
                    <div
                      style={{
                        color:
                          this.state.survey_finishDateState === "error"
                            ? "red"
                            : this.state.survey_finishDateState === "success"
                            ? "green"
                            : "black",
                      }}
                    >
                      วันที่แล้วเสร็จ / คาดว่าจะแล้วเสร็จ
                    </div>
                  }
                  format="DD/MM/YYYY"
                  yearOffset={543}
                  variant="inline"
                  // placeholder="กดเพื่อเลือกวันที่แล้วเสร็จ / คาดว่าจะแล้วเสร็จ"
                  onChange={
                    this.state.is_cat_type_5 ? () => {} : this.setfinishDate
                  }
                  value={
                    this.state.is_cat_type_5
                      ? this.state.survey_constructedDate
                      : this.state.survey_finishDate
                  }
                  disabled={
                    this.state.cat_type_check || this.state.is_cat_type_5
                  }
                  minDate={
                    this.state.is_cat_type_5
                      ? null
                      : new Date(
                          moment(this.state.survey_constructedDate).add(
                            180,
                            "days"
                          )
                        )
                  }
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_prostatusState === "error"
                    ? "red"
                    : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              สถานะโครงการ
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status1}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "1"}
                    onChange={this.handle_projectStatus}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      disabled: classes.disabledCheckboxAndRadio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการปิดการขาย"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status2}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "2"}
                    onChange={this.handle_projectStatus}
                    value="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      disabled: classes.disabledCheckboxAndRadio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อยู่ระหว่างขาย"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status3}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "3"}
                    onChange={this.handle_projectStatus}
                    value="3"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      disabled: classes.disabledCheckboxAndRadio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="เปิดขายใหม่ในช่วงสำรวจ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status4}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "4"}
                    onChange={this.handle_projectStatus}
                    value="4"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      disabled: classes.disabledCheckboxAndRadio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการในอนาคต"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status5}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "5"}
                    onChange={this.handle_projectStatus}
                    value="5"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      disabled: classes.disabledCheckboxAndRadio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการชะลอการขาย"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                disabled={this.state.isallow_status6}
                control={
                  <Radio
                    checked={this.state.survey_prostatus === "6"}
                    onChange={this.handle_projectStatus}
                    value="6"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      disabled: classes.disabledCheckboxAndRadio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="โครงการเปิดและปิดการขายในช่วงสำรวจ"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{ float: "none" }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              ราคาที่ดินเพิ่ม - ลด ในโครงการ (บาท/ตารางวา)
            </FormLabel>
            <CustomInput
              success={this.state.survey_minpriceState === "success"}
              error={this.state.survey_minpriceState === "error"}
              labelText={this.renderPlaceholder(
                this.state.survey_placeholderMinprice,
                "min"
              )}
              id="minprize"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.renderCurrency(this.state.survey_minprice),
                endAdornment:
                  this.state.survey_minpriceState === "error" ? (
                    <InputAdornment position="end">
                      <p
                        style={{
                          width: "105px",
                          margin: "0",
                          textAlign: "end",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {this.state.survey_minpriceStateName}
                      </p>
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                onChange: (event) =>
                  this.change(
                    event,
                    "survey_minprice",
                    "min-value",
                    this.state.survey_maxprice
                  ),
                type: "text",
              }}
            />
            <CustomInput
              success={this.state.survey_maxpriceState === "success"}
              error={this.state.survey_maxpriceState === "error"}
              labelText={this.renderPlaceholder(
                this.state.survey_placeholderMaxprice,
                "max"
              )}
              id="maxprize"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.renderCurrency(this.state.survey_maxprice),
                endAdornment:
                  this.state.survey_maxpriceState === "error" ? (
                    <InputAdornment position="end">
                      <p
                        style={{
                          width: "105px",
                          margin: "0",
                          textAlign: "end",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {this.state.survey_maxpriceStateName}
                      </p>
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                onChange: (event) =>
                  this.change(
                    event,
                    "survey_maxprice",
                    "max-value",
                    this.state.survey_minprice
                  ),
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_allocateState === "error" ? "red" : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              การขออนุญาตจัดสรรที่ดิน
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_allocate === "1"}
                    onChange={this.handle_allocate}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ขออนุญาตจัดสรรแล้ว"
              />
              {this.state.survey_allocate === "1" ? (
                <CustomInput
                  success={this.state.survey_allocateValState === "success"}
                  error={this.state.survey_allocateValState === "error"}
                  labelText="เลขที่"
                  id="survey_allocateVal"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: this.state.survey_allocateVal,
                    placeholder: "123/456",
                    onChange: (event) =>
                      this.change(
                        event,
                        "survey_allocateVal",
                        "survey_allocateVal"
                      ),
                    type: "text",
                  }}
                />
              ) : null}
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_allocate === "2"}
                    onChange={this.handle_allocate}
                    value="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อยู่ระหว่างยื่นฯ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_allocate === "4"}
                    onChange={this.handle_allocate}
                    value="4"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ยังไม่ยื่นฯ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_allocate === "3"}
                    onChange={this.handle_allocate}
                    value="3"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ไม่ได้ขออนุญาต"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_allocate === "5"}
                    onChange={this.handle_allocate}
                    value="5"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="เลี่ยงจัดสรร"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color: this.state.checkedState === "error" ? "red" : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              สาธารณูปโภค (สิ่งอำนวยความสะดวกในโครงการ)
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("security") !== -1
                        ? true
                        : false
                    }
                    onClick={() => this.handleToggle("security")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รปภ."
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("clubhouse") !== -1
                        ? true
                        : false
                    }
                    onClick={() => this.handleToggle("clubhouse")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="สโมสร/คลับเฮ้าส์"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("pool") !== -1 ? true : false
                    }
                    onClick={() => this.handleToggle("pool")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="สระว่ายน้ำ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={
                      this.state.checked.indexOf("other") !== -1 ? true : false
                    }
                    onClick={() => this.handleToggle("other")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="อื่นๆ"
              />
            </div>
            {checkArray.includes("other") ? (
              <CustomInput
                success={this.state.other_utilityState === "success"}
                error={this.state.other_utilityState === "error"}
                labelText="โปรดระบุ"
                tooltipText={"หากมีมากกว่า 1 อย่าง กรุณาเว้นด้วย SpaceBar"}
                id="other_unity"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: this.state.other_utility_value,
                  onChange: (event) =>
                    this.change(event, "other_utility_value", "other_utility"),
                  type: "text",
                }}
              />
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{ float: "none" }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              รายการส่งเสริมการขาย
            </FormLabel>
            <FormControl fullWidth className={classes.selectFormControl}>
              <TextField
                id="survey_saleimprove"
                success={this.state.survey_saleimproveState === "success"}
                error={this.state.survey_saleimproveState === "error"}
                label="โปรดระบุ"
                multiline
                rowsMax="4"
                value={this.state.survey_saleimprove}
                onChange={(event) =>
                  this.change(event, "survey_saleimprove", "name")
                }
                className={classes.textField}
                margin="normal"
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={this.state.survey_projectpriceState === "success"}
              error={this.state.survey_projectpriceState === "error"}
              labelText="มูลค่าโครงการ (บาท)"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.renderCurrency(this.state.survey_projectprice),
                onChange: (event) =>
                  this.change(event, "survey_projectprice", "project_price"),
                type: "text",
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PublishInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBoundary: (payload) => dispatch(setBoundary(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  villa_sec1_part2Object: (payload) =>
    dispatch(villa_sec1_part2Object(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  setCatType1: (payload) => dispatch(setCatType1(payload)),
  setCatType2: (payload) => dispatch(setCatType2(payload)),
  setCatType3: (payload) => dispatch(setCatType3(payload)),
  setCatType4: (payload) => dispatch(setCatType4(payload)),
  setCatType5: (payload) => dispatch(setCatType5(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(PublishInfo));
