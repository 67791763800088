import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
// import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import Delete from "@material-ui/icons/Delete";
// import Edit from "@material-ui/icons/Edit";
import Minimize from "@material-ui/icons/ExpandLess";
import Maximize from "@material-ui/icons/ExpandMore";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// import DownloadIcon from "@material-ui/icons/CloudDownload";
// import { CSVLink } from "react-csv";
// import CryptoJS from "crypto-js";
// import moment from "moment";
// import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
import setLanguage from "DataControl/actions/setLanguage";
import tokenSession from "DataControl/actions/tokenSession.js";
import setGraphData from "DataControl/actions/setGraphData.js";
import setGraphData_open_newsell from "DataControl/actions/setGraphData_open_newsell.js";
import setGraphData_absorption from "DataControl/actions/setGraphData_absorption.js";
import setShowGraph from "DataControl/actions/setShowGraph.js";
import setGraphData_transfer_acc from "DataControl/actions/setGraphData_transfer_acc.js";
import setGraphData_sell_acc from "DataControl/actions/setGraphData_sell_acc.js";
import setGraphData_sell_acc_transfer from "DataControl/actions/setGraphData_sell_acc_transfer.js";
import setGraphData_leftsell from "DataControl/actions/setGraphData_leftsell.js";

// switch language function
// import switchIntl from "configure/switchIntl.js";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// import filterFactory from "react-bootstrap-table2-filter";
// import paginationFactory from "react-bootstrap-table2-paginator";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

// const translations = {
//   previousText: "ก่อนหน้า",
//   nextText: "ต่อไป",
//   loadingText: "กำลังโหลด...",
//   noDataText: "ไม่พบข้อมูล",
//   pageText: "หน้า",
//   ofText: "จาก",
//   rowsText: "แถว",
//   // Accessibility Labels
//   pageJumpText: "ไปที่หน้า",
//   rowsSelectorText: "จำนวนแถวต่อหน้า",
// };

const columns = [
  {
    dataField: "province_t",
    text: "จังหวัด",
    sort: false,
    footer: "",
    footerStyle: { backgroundColor: "#ffe082" },
  },
  {
    dataField: "amphoe_t",
    text: "อำเภอ",
    sort: false,
    footer: "รวม",
    footerStyle: { backgroundColor: "#ffe082" },
  },
  {
    dataField: "name_cat",
    text: "ประเภท",
    sort: false,
    style: { backgroundColor: "#ffffb3" },
    footerStyle: { backgroundColor: "#ffe082" },
    // footer: (columnData, column, columnIndex) =>
    //   columnData.reduce((acc, item) => {
    //     let val = parseFloat(item);
    //     if (!isNaN(val)) {
    //       return acc + val;
    //     } else {
    //       return acc;
    //     }
    //   }, 0)
    footer: "",
  },
  {
    dataField: "numofproj",
    text: "ข้อมูลบริษัทท่าน",
    sort: false,
    style: { backgroundColor: "#ffffb3" },
    footerStyle: { backgroundColor: "#ffe082" },
    footer: (columnData, column, columnIndex) =>
      columnData.reduce((acc, item) => {
        let val = parseFloat(item);
        if (!isNaN(val)) {
          return acc + val;
        } else {
          return acc;
        }
      }, 0),
  },
  {
    dataField: "numofprojall",
    text: "ข้อมูลสมาชิกทั้งหมด",
    sort: false,
    style: { backgroundColor: "#ffffb3" },
    footerStyle: { backgroundColor: "#ffe082" },
    footer: (columnData, column, columnIndex) =>
      columnData.reduce((acc, item) => {
        let val = parseFloat(item);
        if (!isNaN(val)) {
          return acc + val;
        } else {
          return acc;
        }
      }, 0),
  },
  // {
  //   dataField: "all_condo",
  //   text: "ภาพรวมตลาด",
  //   sort: false,
  //   footerStyle: { backgroundColor: "#ffe082" },
  //   formatter: (cell, row) => {
  //     return "อยู่ระหว่างประมวลข้อมูล";
  //   },
  {
    dataField: "QoQ",
    text: "QoQ",
    sort: false,
    footerStyle: { backgroundColor: "#ffe082" },
    formatter: (row) =>
      !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
    footer: "",
  },
  {
    dataField: "YoY",
    text: "YoY",
    sort: false,
    footerStyle: { backgroundColor: "#ffe082" },
    formatter: (row) =>
      !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,

    // footer: (columnData, column, columnIndex) =>
    //   columnData.reduce((acc, item) => {
    //     let val = parseFloat(item);
    //     if (!isNaN(val)) {
    //       return acc + val;
    //     } else {
    //       return acc;
    //     }
    //   }, 0)
    footer: "",
  },
  {
    dataField: "YTD",
    text: "YTD",
    sort: false,
    footerStyle: { backgroundColor: "#ffe082" },
    formatter: (row) =>
      !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
    footer: "",
  },
];

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      place: "tc",
      color: "warning",
      message: "ไม่มีข้อมูลรายงานในช่วงเวลาที่เลือก",
      open: false,
      columns: {
        //จำนวนโครงการทั้งหมด
        0: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: true,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: true,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: true,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: true,
            footer: "",
            formatter: () => {
              return "";
            },
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "numofproj",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "numofprojall",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยเหลือขายทั้งหมด
        1: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: true,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: true,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: true,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: true,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "leftsell",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "leftsellall",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยเหลือขายสร้างเสร็จพร้อมโอน
        2: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "sellaccumtransfer",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "sellaccumtransferall",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยในผังโครงการทั้งหมด
        3: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "unit",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "unit_all",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,

            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //ขายได้ใหม่
        4: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "sellnew",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "sellnew_all",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยโอนสะสม
        5: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "transfer",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "transferall",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //โครงการเปิดขายใหม่
        6: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "numofproj",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "numofprojall",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          //   // footer: (columnData, column, columnIndex) =>
          //   //   columnData.reduce((acc, item) => {
          //   //     let val = parseFloat(item);
          //   //     if (!isNaN(val)) {
          //   //       return acc + val;
          //   //     } else {
          //   //       return acc;
          //   //     }
          //   //   }, 0)
          //   footer:""
          // },
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยขายได้สะสม
        7: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "sellaccum",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "sellaccum_all",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          //   // footer: (columnData, column, columnIndex) =>
          //   //   columnData.reduce((acc, item) => {
          //   //     let val = parseFloat(item);
          //   //     if (!isNaN(val)) {
          //   //       return acc + val;
          //   //     } else {
          //   //       return acc;
          //   //     }
          //   //   }, 0)
          //   footer:""
          // }
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยขายได้รอโอนสะสม (backlog)
        8: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "transferbacklog",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "transferbacklog_all",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          //   // footer: (columnData, column, columnIndex) =>
          //   //   columnData.reduce((acc, item) => {
          //   //     let val = parseFloat(item);
          //   //     if (!isNaN(val)) {
          //   //       return acc + val;
          //   //     } else {
          //   //       return acc;
          //   //     }
          //   //   }, 0)
          //   footer:""
          // }
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยเปิดขายใหม่
        9: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "sum_open_new",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "sum_open_newall",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          //   // footer: (columnData, column, columnIndex) =>
          //   //   columnData.reduce((acc, item) => {
          //   //     let val = parseFloat(item);
          //   //     if (!isNaN(val)) {
          //   //       return acc + val;
          //   //     } else {
          //   //       return acc;
          //   //     }
          //   //   }, 0)
          //   footer:""
          // }
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
        ],
        //หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง
        10: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
            footer: "",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "amphoe_t",
            text: "อำเภอ/เขต",
            sort: false,
            footer: "รวม",
            footerStyle: { backgroundColor: "#ffe082" },
          },
          {
            dataField: "name_cat",
            text: "ประเภท",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "pricelength",
            text: "ราคา",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
          {
            dataField: "leftsell",
            text: "ข้อมูลบริษัทท่าน",
            sort: false,
            style: { backgroundColor: "#ffffb3" },
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          {
            dataField: "leftsellall",
            text: "ข้อมูลสมาชิกทั้งหมด",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: (columnData, column, columnIndex) =>
              columnData.reduce((acc, item) => {
                let val = parseFloat(item);
                if (!isNaN(val)) {
                  return acc + val;
                } else {
                  return acc;
                }
              }, 0),
          },
          // {
          //   dataField: "all",
          //   text: "ภาพรวมตลาด",
          //   sort: false,
          //   footerStyle: { backgroundColor: "#ffe082" },
          //   formatter: (cell, row) => {
          //     return "อยู่ระหว่างประมวลข้อมูล";
          //   },
          //   // footer: (columnData, column, columnIndex) =>
          //   //   columnData.reduce((acc, item) => {
          //   //     let val = parseFloat(item);
          //   //     if (!isNaN(val)) {
          //   //       return acc + val;
          //   //     } else {
          //   //       return acc;
          //   //     }
          //   //   }, 0)
          //   footer:""
          // }
          {
            dataField: "QoQ",
            text: "QoQ",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            footer: "",
          },
          {
            dataField: "YoY",
            text: "YoY",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            formatter: (row) =>
              !isNaN(parseFloat(row)) ? parseFloat(row).toFixed(2) : row,
            // footer: (columnData, column, columnIndex) =>
            //   columnData.reduce((acc, item) => {
            //     let val = parseFloat(item);
            //     if (!isNaN(val)) {
            //       return acc + val;
            //     } else {
            //       return acc;
            //     }
            //   }, 0)
            footer: "",
          },
          {
            dataField: "YTD",
            text: "YTD",
            sort: false,
            footerStyle: { backgroundColor: "#ffe082" },
            footer: "",
          },
        ],
        //อัตราดูดซับต่อเดือน
        11: [
          {
            dataField: "province_t",
            text: "จังหวัด",
            sort: false,
          },
          {
            dataField: "cat_name",
            text: "ประเภท",
            sort: false,
          },
          {
            dataField: "absorption",
            text: "ข้อมูลบริษัทท่าน",
          },
          {
            dataField: "absorptionall",
            text: "ข้อมูลสมาชิกทั้งหมด",
          },
        ],
      },
      showGraph0: true,
      showGraph1: true,
      showGraph2: true,
      showGraph3: true,
      showGraph4: true,
      showGraph5: true,
      showGraph6: true,
      showGraph7: true,
      showGraph8: true,
      showGraph9: true,
      showGraph10: true,
      showGraph11: true,
      showGraph12: true,
      search: "",
      leftsell: [],
      leftselltransfer: [],
      all_unit: [],
      new_sell: [],
      transfer_acc: [],
      new_project: [],
      sell_acc: [],
      wait_transfer: [],
      open_new_unit: [],
      unit_leftsell_notbuild: [],
      all_project: [],
      data: [
        {
          prov: "จังหวัด",
          amphoe: "อำเภอ",
          you_house: "บ้านจัดสรร",
          you_condo: "อาคารชุด",
          you_villa: "บ้านพักตากอากาศ",
          member_house: "บ้านจัดสรร",
          member_condo: "อาคารชุด",
          member_villa: "บ้านพักตากอากาศ",
          all_house: "บ้านจัดสรร",
          all_condo: "อาคารชุด",
          all_villa: "บ้านพักตากอากาศ",
        },
        {
          prov: "-",
          amphoe: "-",
          you_house: "-",
          you_condo: "-",
          you_villa: "-",
          member_house: "-",
          member_condo: "-",
          member_villa: "-",
          all_house: "-",
          all_condo: "-",
          all_villa: "-",
        },
        {
          prov: "-",
          amphoe: "-",
          you_house: "12",
          you_condo: "1",
          you_villa: "3",
          member_house: "4",
          member_condo: "7",
          member_villa: "8",
          all_house: "ไม่มีข้อมูล",
          all_condo: "ไม่มีข้อมูล",
          all_villa: "ไม่มีข้อมูล",
        },
      ],
      alert: null,
    };
  }

  componentDidUpdate(prevProps) {
    //1. จำนวนโครงการทั้งหมด
    if (prevProps.graphData_projectall !== this.props.graphData_projectall) {
      this.setState({ all_project: this.props.graphData_projectall });
      if (this.props.graphData_projectall.length === 0) {
        this.setState({
          open: true,
        });
      }
    }

    //2.หน่วยเหลือขายทั้งหมด
    if (prevProps.graphData_graphView !== this.props.graphData_graphView) {
      this.setState({ leftsell: this.props.graphData_graphView });
    }

    //3. หน่วยเหลือขายสร้างเสร็จพร้อมโอน
    if (prevProps.graphData_graphView !== this.props.graphData_graphView) {
      // this.setState({ leftsell: this.props.graphData });
      //console.log("หน่วยเหลือขายสร้างเสร็จพร้อมโอน");
      //console.log(this.props.graphData)
      this.setState({ leftselltransfer: this.props.graphData_graphView });
    }

    //4.หน่วยในผังโครงการทั้งหมด
    if (prevProps.graphData_allunit !== this.props.graphData_allunit) {
      this.setState({ all_unit: this.props.graphData_allunit });
    }

    //5.หน่วยขายใหม่
    if (prevProps.graphData_newsell !== this.props.graphData_newsell) {
      //console.log(this.props.graphData_newsell)
      this.setState({ new_sell: this.props.graphData_newsell });
    }

    //6.หน่วยโอนสะสม
    if (
      prevProps.graphData_transfer_acc !== this.props.graphData_transfer_acc
    ) {
      this.setState({ transfer_acc: this.props.graphData_transfer_acc });
    }

    //7.โครงการที่เปิดขายใหม่ในไตรมาสนี้
    if (prevProps.graphData_newproject !== this.props.graphData_newproject) {
      //console.log("โครงการที่เปิดขายใหม่ในไตรมาสนี้...");
      //console.log(prevProps.graphData_newproject);
      this.setState({ new_project: this.props.graphData_newproject });
    }

    //8.หน่วยขายได้สะสม
    if (prevProps.graphData_sell_acc !== this.props.graphData_sell_acc) {
      //console.log(this.props.graphData_sell_acc);
      this.setState({ sell_acc: this.props.graphData_sell_acc });
    }

    //9.หน่วยขายได้รอโอนสะสม
    if (
      prevProps.graphData_sell_acc_transfer !==
      this.props.graphData_sell_acc_transfer
    ) {
      //console.log(this.props.graphData_sell_acc_transfer);
      this.setState({ wait_transfer: this.props.graphData_sell_acc_transfer });
    }

    //10.หน่วยเปิดขายใหม่
    if (
      prevProps.graphData_open_newsell !== this.props.graphData_open_newsell
    ) {
      //console.log(this.props.graphData_open_newsell);
      this.setState({ open_new_unit: this.props.graphData_open_newsell });
    }

    //11.หน่วยเหลือขายยังไม่ได้ก่อสร้าง
    if (prevProps.graphData_leftsell !== this.props.graphData_leftsell) {
      //console.log("ยังไม่ก่อสร้าง..");
      //console.log(this.props.graphData_leftsell);
      this.setState({ unit_leftsell_notbuild: this.props.graphData_leftsell });
    }

    //12.อัตราดูดซับต่อเดือน
    if (prevProps.absorption !== this.props.absorption) {
      //console.log(this.props.absorption)
    }
  }

  addFilterPlaceholder = () => {
    const filters = document.querySelectorAll("div.rt-th > input");
    for (let filter of filters) {
      filter.placeholder = "ค้นหาที่นี่..";
    }
  };

  getTableProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          border: "solid 1px black",
        },
      };
    }
    return {};
  };

  render() {
    // const { classes } = this.props;
    // let data = this.state.data;
    // if (this.state.search) {
    //   data = data.filter((row) => {
    //     return (
    //       row.firstName.includes(this.state.search) ||
    //       row.lastName.includes(this.state.search) ||
    //       String(row.age).includes(this.state.search)
    //     );
    //   });
    // }

    const names = [
      { dataname: "leftsell", name: "หน่วยเหลือขายทั้งหมด", id: 1 },
      {
        dataname: "leftselltransfer",
        name: "หน่วยเหลือขายสร้างเสร็จพร้อมโอน",
        id: 2,
      },
      { dataname: "all_unit", name: "หน่วยในผังโครงการทั้งหมด", id: 3 },
      { dataname: "new_sell", name: "หน่วยขายได้ใหม่", id: 4 },
      { dataname: "transfer_acc", name: "หน่วยโอนสะสม", id: 5 },
      { dataname: "new_project", name: "โครงการเปิดขายใหม่", id: 6 },
      { dataname: "sell_acc", name: "หน่วยขายได้สะสม", id: 7 },
      {
        dataname: "wait_transfer",
        name: "หน่วยขายได้รอโอนสะสม (backlog)",
        id: 8,
      },
      { dataname: "open_new_unit", name: "หน่วยเปิดขายใหม่", id: 9 },
      {
        dataname: "unit_leftsell_notbuild",
        name: "หน่วยเหลือขายที่ยังไม่ได้ก่อสร้าง",
        id: 10,
      },
    ];
    let graph_unit = names.map((props) => {
      if (this.props.showgraph.indexOf(props.id) !== -1) {
        let statename = "showGraph" + props.id;
        return (
          // eslint-disable-next-line react/jsx-key
          <GridItem xs={12}>
            <Card>
              {/* <a href="#"> */}
              <CardHeader
                onClick={() =>
                  this.state[statename]
                    ? this.setState({ [statename]: false })
                    : this.setState({ [statename]: true })
                }
              >
                <h4>
                  {props.name}
                  {this.state[statename] ? (
                    <Minimize style={{ float: "right" }} />
                  ) : (
                    <Maximize style={{ float: "right" }} />
                  )}
                </h4>
              </CardHeader>
              {/* </a> */}
              {this.state[statename] && (
                <ToolkitProvider
                  keyField={props.dataname}
                  data={this.state[props.dataname]}
                  columns={this.state.columns[props.id]}
                  search
                >
                  {(props) => (
                    <div>
                      <BootstrapTable
                        key={props.id}
                        {...props.baseProps}
                        bordered={false}
                        striped
                        hover
                        condensed
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </GridItem>
        );
      }
      return null;
    });
    return (
      <GridContainer>
        {this.state.alert}
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        {this.props.showgraph.indexOf(0) !== -1 && (
          <GridItem xs={12}>
            <Card>
              {/* <a href="#"> */}
              <CardHeader
                onClick={() =>
                  this.state.showGraph0
                    ? this.setState({ showGraph0: false })
                    : this.setState({ showGraph0: true })
                }
              >
                <h4>
                  จำนวนโครงการทั้งหมด
                  {this.state.showGraph0 ? (
                    <Minimize style={{ float: "right" }} />
                  ) : (
                    <Maximize style={{ float: "right" }} />
                  )}
                </h4>
              </CardHeader>
              {/* </a> */}
              {this.state.showGraph0 && (
                <ToolkitProvider
                  keyField="id"
                  data={this.state.all_project}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <BootstrapTable
                        {...props.baseProps}
                        bordered={false}
                        striped
                        hover
                        condensed
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </GridItem>
        )}
        {graph_unit}
        {this.props.showgraph.indexOf(11) !== -1 && (
          <GridItem xs={12}>
            <Card>
              {/* <a href="#"> */}
              <CardHeader
                onClick={() =>
                  this.state.showGraph11
                    ? this.setState({ showGraph11: false })
                    : this.setState({ showGraph11: true })
                }
              >
                <h4>
                  อัตราดูดซับต่อเดือน
                  {this.state.showGraph11 ? (
                    <Minimize style={{ float: "right" }} />
                  ) : (
                    <Maximize style={{ float: "right" }} />
                  )}
                </h4>
              </CardHeader>
              {/* </a> */}
              {this.state.showGraph11 && (
                <ToolkitProvider
                  keyField="id"
                  data={this.props.absorption}
                  columns={this.state.columns[11]}
                >
                  {(props) => (
                    <div>
                      <BootstrapTable
                        {...props.baseProps}
                        bordered={false}
                        striped
                        hover
                        condensed
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

ReactTables.propTypes = {
  classes: PropTypes.object,
  graphData: PropTypes.any,
  showgraph: PropTypes.any,
  graphData_open_newsell: PropTypes.any,
  graphData_sell_acc: PropTypes.any,
  graphData_transfer_acc: PropTypes.any,
  setGraphData_allunit: PropTypes.any,
  graphData_newproject: PropTypes.any,
  graphData_projectall: PropTypes.any,
  graphData_leftsellall: PropTypes.any,
  graphData_leftsell: PropTypes.any,
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  tokenSession: (token) => dispatch(tokenSession(token)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setGraphData: (payload) => dispatch(setGraphData(payload)),
  setGraphData_open_newsell: (payload) =>
    dispatch(setGraphData_open_newsell(payload)),
  setGraphData_absorption: (payload) =>
    dispatch(setGraphData_absorption(payload)),
  setShowGraph: (payload) => dispatch(setShowGraph(payload)),
  setGraphData_transfer_acc: (payload) =>
    dispatch(setGraphData_transfer_acc(payload)),
  setGraphData_sell_acc: (payload) => dispatch(setGraphData_sell_acc(payload)),
  setGraphData_sell_acc_transfer: (payload) =>
    dispatch(setGraphData_sell_acc_transfer(payload)),
  setGraphData_leftsell: (payload) => dispatch(setGraphData_leftsell(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReactTables));
