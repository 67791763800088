import { createStore } from "redux";
import rootReducer from "reducers/rootReducer";

function configureStore(
  state = {
    surveyCondition: "new",

    sessionModal: null,
    toggleLng: "TH",
    tokenSess: "",
    getBase64: "",
    logoutModal: null,
    setHomeSubType: null,
    minmax_house: {
      house: [0, 0],
      twinhouse: [0, 0],
      townhouse: [0, 0],
      shophouse: [0, 0],
      land: [0, 0],
    },
    saleinfo: {
      unitall: {
        "0": 0,
      },
    },
    showModal_detail: false,
    projectIDHome: "",
    noOfProjects: {
      no_of_allproject: 0,
      no_of_send_project: 0,
      no_of_saved_project: 0,
    },
    project_status: 1,
    houseInfoSection1: {
      srp_projectid: "-",
      srp_name: "-",
      srp_selldate: "-",
      srp_address: "-",
      lookup_asset: [
        { name: "บ้านเดี่ยว", value: 1, min: 0, max: 0 },
        { name: "บ้านแฝด", value: 2, min: 0, max: 0 },
        { name: "ทาวน์เฮาส์ หรือ ทาวน์โฮม", value: 3, min: 0, max: 0 },
        { name: "อาคารพาณิชย์", value: 4, min: 0, max: 0 },
        { name: "ที่ดินเปล่า", value: 5, min: 0, max: 0 },
      ],
    },
    pointsOnMap: "",
    isCreateModal: false,

    hometype: {
      home: 1,
      land: 0,
      shophouse: 0,
      townhouse: 0,
      twinhouse: 0,
    },
    prov_amphoe_tambon: {
      prov_id: null,
      amp_id: null,
      tam_id: null,
    },
    surveytype: "",
    latlon: { lat: 0, lng: 0 },
    oldproj_data: {},
    project_id: undefined,
    press_save: false,
    house_initial_data: {
      household_userid: 0,
      c_address: "N_A",
      c_district: "N_A",
      c_fax: "N_A",
      c_floor: "N_A",
      c_name: "N_A",
      c_namebuilding: "N_A",
      c_province: "N_A",
      c_road: "N_A",
      c_soi: "N_A",
      c_subdistrict: "N_A",
      c_tel: "N_A",
      household_address: "N_A",
      household_allocate: "N_A",
      household_brochuremapimg: [""],
      household_currentconstructimg: [""],
      household_dateconstruct: "01/01/1900",
      household_datefinish: "01/01/1900",
      household_datefirstsell: "01/01/1900",
      household_district: "1001",
      household_exhouseimg: [""],
      household_explorerdate: "01/01/1900",
      household_explorers: "N_A",
      household_filebrochure: "N_A",
      household_filequotation: "N_A",
      household_filesurvey: "N_A",
      household_forelder: "N_A",
      household_frontprojectplaceimg: [""],
      household_googlemapimg: [""],
      household_maxprice: 0,
      household_minprice: 0,
      household_moo: "N_A",
      household_nameeng: "N_A",
      household_nameth: "N_A",
      household_ngan: 0,
      household_note: "N_A",
      household_postalcode: "N_A",
      household_projectid: "N_A",
      household_oldprojid: "N_A",
      household_projectplanimg: [""],
      household_projectprice: 0,
      household_projectstatus: 0,
      household_projectwebsite: "N_A",
      household_province: "10",
      household_rai: 0,
      household_road: "N_A",
      household_saleimprove: "N_A",
      household_saleofficeimg: [""],
      household_soi: "N_A",
      household_sqwa: 0,
      household_subdistrict: "100101",
      household_tel_saleoffice: "N_A",
      household_typearea: "N_A",
      household_typeowner: "N_A",
      household_typesell: "N_A",
      lat: 0,
      lon: 0,
      unity_detail: [""],
      cat_type: [0],
      cat_floor: [0],
      cat_width: [0],
      cat_minarea: [0],
      cat_maxarea: [0],
      cat_minuseablearea: [0],
      cat_maxuseablearea: [0],
      cat_bedroom: [0],
      cat_bathroom: [0],
      cat_minprice: [0],
      cat_maxprice: [0],
      cat_discount: [0],
      cat_allunitinproject: [0],
      stc_type: [0],
      //stc_floor: [0],
      stc_pricelenid: [0],
      stc_unit: [0],
      stc_sellsigned: [0],
      stc_remaining: [0],
      stc_newsigned: [0],
      stc_transfered: [0],
      stc_newtransfer: [0],
      stc_notconstruct: [0],
      stc_underconstruct: [0],
      stc_finished: [0],
      stc_newconstruct: [0],
      stc_newfinished: [0],
      stc_sellnotconstruct: [0],
      stc_sellunderconstruct: [0],
      stc_sellfinished: [0],
      stc_rmnotconstruct: [0],
      stc_rmunderconstruct: [0],
      stc_rmfinished: [0],
      ex_id: "",
    },
    villa_initial_data: {
      villa_userid: 0,
      c_address: "N_A",
      c_district: "N_A",
      c_fax: "N_A",
      c_floor: "N_A",
      c_name: "N_A",
      c_namebuilding: "N_A",
      c_province: "N_A",
      c_road: "N_A",
      c_soi: "N_A",
      c_subdistrict: "N_A",
      c_tel: "N_A",
      villa_address: "N_A",
      villa_allocate: "N_A",
      villa_brochuremapimg: [""],
      villa_currentconstructimg: [""],
      villa_dateconstruct: "01/01/1900",
      villa_datefinish: "01/01/1900",
      villa_datefirstsell: "01/01/1900",
      villa_district: "1001",
      villa_exhouseimg: [""],
      villa_explorerdate: "01/01/1900",
      villa_explorers: "N_A",
      villa_filebrochure: "N_A",
      villa_filequotation: "N_A",
      villa_filesurvey: "N_A",
      villa_forelder: "N_A",
      villa_frontprojectplaceimg: [""],
      villa_googlemapimg: [""],
      villa_maxprice: 0,
      villa_minprice: 0,
      villa_moo: "N_A",
      villa_nameeng: "N_A",
      villa_nameth: "N_A",
      villa_ngan: 0,
      villa_note: "N_A",
      villa_postalcode: "N_A",
      villa_projectid: "N_A",
      villa_oldprojid: "N_A",
      villa_projectplanimg: [""],
      villa_projectprice: 0,
      villa_projectstatus: 0,
      villa_projectwebsite: "N_A",
      villa_province: "10",
      villa_rai: 0,
      villa_road: "N_A",
      villa_saleimprove: "N_A",
      villa_saleofficeimg: [""],
      villa_soi: "N_A",
      villa_sqwa: 0,
      villa_subdistrict: "100101",
      villa_tel_saleoffice: "N_A",
      villa_typearea: "N_A",
      villa_typeowner: "N_A",
      villa_typesell: "N_A",
      lat: 0,
      lon: 0,
      unity_detail: [""],
      cat_type: [0],
      cat_floor: [0],
      cat_width: [0],
      cat_minarea: [0],
      cat_maxarea: [0],
      cat_minuseablearea: [0],
      cat_maxuseablearea: [0],
      cat_bedroom: [0],
      cat_bathroom: [0],
      cat_minprice: [0],
      cat_maxprice: [0],
      cat_discount: [0],
      cat_allunitinproject: [0],
      stc_type: [0],
      // stc_floor: [0],
      stc_pricelenid: [0],
      stc_unit: [0],
      stc_sellsigned: [0],
      stc_remaining: [0],
      stc_newsigned: [0],
      stc_transfered: [0],
      stc_newtransfer: [0],
      stc_notconstruct: [0],
      stc_underconstruct: [0],
      stc_finished: [0],
      stc_newconstruct: [0],
      stc_newfinished: [0],
      stc_sellnotconstruct: [0],
      stc_sellunderconstruct: [0],
      stc_sellfinished: [0],
      stc_rmnotconstruct: [0],
      stc_rmunderconstruct: [0],
      stc_rmfinished: [0],
      ex_id: "",
    },
    condo_initial_data: {
      condo_userid: 0,
      condo_presalephase: "N_A",
      condo_presaleproject: "N_A",
      condo_presaleamoutbuilding: 0,
      c_instm: 0,
      c_address: "N_A",
      c_district: "N_A",
      c_fax: "N_A",
      c_floor: "N_A",
      c_name: "N_A",
      c_namebuilding: "N_A",
      c_province: "N_A",
      c_road: "N_A",
      c_soi: "N_A",
      c_subdistrict: "N_A",
      c_tel: "N_A",
      condo_address: "N_A",
      condo_allocate: "N_A",
      condo_brochuremapimg: [""],
      condo_constructapprove: "N_A",
      condo_envapprove: 0, // integer
      condo_registered: "N_A",
      condo_currentconstructimg: ["N_A"],
      condo_dateconstruct: "01/01/1900",
      condo_datefinish: "01/01/1900",
      condo_datefirstsell: "01/01/1900",
      condo_district: "1001",
      condo_exhouseimg: ["N_A"],
      condo_explorerdate: "01/01/1900",
      condo_explorers: "N_A",
      condo_filebrochure: "N_A",
      condo_filequotation: "N_A",
      condo_filesurvey: "N_A",
      condo_forelder: "N_A",
      condo_mixeduse: "N_A",
      condo_frontprojectplaceimg: ["N_A"],
      condo_googlemapimg: ["N_A"],
      condo_maxprice: 0,
      condo_minprice: 0,
      condo_averageprice: 0,
      condo_moo: "N_A",
      condo_nameeng: "N_A",
      condo_nameth: "N_A",
      condo_ngan: 0,
      condo_note: "N_A",
      condo_postalcode: "N_A",
      condo_projectid: "N_A",
      condo_oldprojid: "N_A",
      condo_projectplanimg: ["N_A"],
      condo_projectprice: 0,
      condo_projectstatus: 0,
      condo_projectwebsite: "N_A",
      condo_province: "10",
      condo_rai: 0,
      condo_road: "N_A",
      condo_saleimprove: "N_A",
      condo_saleofficeimg: ["N_A"],
      condo_soi: "N_A",
      condo_sqwa: 0,
      condo_subdistrict: "100101",
      condo_tel_saleoffice: "N_A",
      condo_typearea: "N_A",
      condo_typeowner: "N_A",
      condo_typesell: "N_A",
      lat: 0,
      lon: 0,
      unity_detail: ["N_A"],
      sellroom_buildingname: ["N_A"],
      sellroom_floortype: [0],
      sellroom_type: [0],
      sellroom_minarealicense: [0],
      sellroom_maxarealicense: [0],
      sellroom_minarea: [0],
      sellroom_maxarea: [0],
      sellroom_minprice: [0],
      sellroom_maxprice: [0],
      sellroom_discount: [0],
      sellroom_allunitinproject: [0],
      st_buildingname: ["N_A"],
      st_roomtype: [0],
      st_pricelenid: [0],
      st_allunit: [0],
      st_allsigned: [0],
      st_remaining: [0],
      st_newsigned: [0],
      st_transfered: [0],
      st_newtransfered: [0],
      ex_id: "",
      bs_name: ["N_A"],
      bs_totalfloor: [0],
      bs_totalunit: [0],
      bs_notbuild: [0],
      bs_startbuild: ["01/01/1900"],
      bs_finishedbuild: ["01/01/1900"],
    },
    householdSec1Object: {},
    housesec1part1object: {},
    housesec1part2object: {},
    housesec1part3object: {},
    housesec1part1objectloc: {},
    householdSec2Object: {},
    householdSec3Object: {},
    condosec1part1object: 0,
    condosec2object: [],
    condoSec2Object: {},
    condosec3object: [],
    condosec4object: [],
    villaSec1Object: {},
    villaSec2Object: {},
    villaSec3Object: {},
    villaSec4Object: {},
    villasec1part1object: {},
    villasec2object: [],
    villasec3object: [],
    villasec4object: [],

    absorption: [],
    graphData: [],
    graphData_graphView: [],
    showgraph: [],
    cat_type_1: false,
    cat_type_2: false,
    cat_type_3: false,
    cat_type_4: false,
    cat_type_5: false,
  }
) {
  return createStore(rootReducer, state);
}

export default configureStore;
