/* eslint-disable no-console */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import switchIntl from "../../configure/switchIntl.js";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqList: [
        {
          question: "ฉันจำรหัสผ่านไม่ได้",
          answer:
            "ท่านสามารถเปลี่ยนรหัสผ่านได้ที่หน้าลงชื่อเข้าสู่ระบบ โดยกดคลิกที่ 'ลืมรหัสผ่าน' จากนั้นทำการกรอกอีเมล์ที่ท่านได้ลงทะบียนไว้กับระบบ ระบบจะส่งวิธีการตั้งค่ารหัสผ่านใหม่ไปยังอีเมล์นั้น",
        },
        {
          question: "อยากเปลี่ยนรหัสผ่าน ต้องทำอย่างไร",
          answer:
            ' ท่านสามารถเปลี่ยนรหัสผ่านได้โดยการคลิกที่ไอคอนรูปคนมุมบนขวา แล้วไปที่เมนู "เปลี่ยนรหัสผ่าน" จากนั้นทำการตั้งค่ารหัสผ่านใหม่โดยกรอกรายละเอียดลงในแบบฟอร์ม',
        },
        {
          question: "ฉันไม่สามารถนำเข้าข้อมูลได้ เกิดจากสาเหตุใด",
          answer:
            "ระบบนี้จะเปิดให้ลงข้อมูลในช่วงสำรวจเท่านั้น หากผ่านช่วงสำรวจไป ท่านจะไม่สามารถเพิ่มข้อมูลได้",
        },
      ],
      value: 0,
      alert: null,
      isSubmited: false,
      key: null,
      showForm: true,
      contact_name_state: null,
      contact_org_state: null,
      contact_phone_state: null,
      contact_email_state: null,
      contact_text_state: null,
      showFullPic: false,
      contactUsMassegeList: [],
      curTab: 0,
    };
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
    loadingSet: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.getSecretKey();
  }
  getSecretKey = () => {
    let self = this;
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          self.setState({
            key: responseJson.data,
          });

          self.getPsurveyFaqList();
          self.getSrpFaqList();
        } else {
          window.location.replace("/auth/login-page");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getPsurveyFaqList = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = { form: "psurvey" };
    var request = new Request(`${config.API_faqs}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ psurveyFaqList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getSrpFaqList = () => {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = { form: "srp" };
    var request = new Request(`${config.API_faqs}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ srpFaqList: responseJson.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  saveNewAddress = () => {
    this.successAlert();
  };
  editFaq = (form, id) => {
    let { toggleLng } = this.props;
    this.successAlert(
      toggleLng === "TH" ? "ยืนยันลบแก้ไขคำถาม" : "Confirm Update Question",
      toggleLng === "TH" ? "ยืนยันแก้ไขคำถาม" : "Confirm Update Question",
      {
        info: true,
        onConfirm: () => {
          var that = this;
          const { key } = this.state;
          if (!key) {
            return;
          }
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          const tokenData = jsonDec.token;
          let body = {
            id: this.state.curId,
            ques: this.state.curQuestion,
            ans: this.state.curAnswer,
            form: this.state.curForm,
          };
          var request = new Request(`${config.API_updatefaq}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });

          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.successAlert(
                  that.props.toggleLng === "TH"
                    ? "แก้ไขคำถามสำเร็จ"
                    : "FAQ Add Completed",
                  that.props.toggleLng === "TH"
                    ? "แก้ไขคำถามสำเร็จ"
                    : "FAQ Add Completed",
                  { success: true }
                );
                that.getSrpFaqList();
                that.setState({ showMessageModal: false });
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };

  removeFaq = (form, id) => {
    var that = this;
    let { toggleLng } = this.props;

    this.successAlert(
      toggleLng === "TH" ? "ยืนยันลบคำถาม" : "Confirm Remove Question",
      toggleLng === "TH" ? "ยืนยันลบคำถาม" : "Confirm Remove Question",
      {
        info: true,
        onConfirm: () => {
          const { key } = this.state;
          if (!key) {
            return;
          }
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("session");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          const tokenData = jsonDec.token;
          let body = {
            id,
            form,
          };
          var request = new Request(`${config.API_delfaq}`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              token: tokenData,
            }),
            body: JSON.stringify(body),
          });

          fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.code === 200) {
                that.successAlert(
                  that.props.toggleLng === "TH"
                    ? "ลบคำถามสำเร็จ"
                    : "FAQ Add Completed",
                  that.props.toggleLng === "TH"
                    ? "ลบคำถามสำเร็จ"
                    : "FAQ Add Completed",
                  { success: true }
                );
                that.getPsurveyFaqList();
                that.getSrpFaqList();
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        },
        confirmBtnText: switchIntl(toggleLng).sweetalert.onLogout.conBtTxt,
        cancelBtnText: switchIntl(toggleLng).sweetalert.onLogout.canBtTxt,
        showCancel: true,
      }
    );
  };

  submitSrpFaq = () => {
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = {
      ques: this.state.newQuestionSrp,
      ans: this.state.newAnswerSrp,
      form: "srp",
    };
    var request = new Request(`${config.API_addfaq}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "เพิ่มคำถามสำเร็จ"
              : "FAQ Add Completed",
            that.props.toggleLng === "TH"
              ? "เพิ่มคำถามสำเร็จ"
              : "FAQ Add Completed",
            { success: true }
          );
          that.getSrpFaqList();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  submitPsurveyFaq = () => {
    var that = this;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("session");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    let body = {
      ques: this.state.newQuestionPsurvey,
      ans: this.state.newAnswerPsurvey,
      form: "psurvey",
    };
    var request = new Request(`${config.API_faqs}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          that.successAlert(
            that.props.toggleLng === "TH"
              ? "เพิ่มคำถามสำเร็จ"
              : "FAQ Add Completed",
            that.props.toggleLng === "TH"
              ? "เพิ่มคำถามสำเร็จ"
              : "FAQ Add Completed",
            { success: true }
          );
          that.getPsurveyFaqList();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          {...("onConfirm" in status
            ? ""
            : { onConfirm: () => this.setState({ alert: null }) })}
          onCancel={() => this.setState({ alert: null })}
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  openMessageModal = (form, id) => {
    this.setState({
      curQuestion: this.state[form + "FaqList"].filter((e) => e.id === id)[0]
        .ques,
      curAnswer: this.state[form + "FaqList"].filter((e) => e.id === id)[0].ans,
      curId: id,
      curForm: form,
      showMessageModal: true,
    });
  };

  render() {
    const { classes, toggleLng } = this.props;
    var self = this;
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });

    const { srpFaqList, psurveyFaqList } = this.state;

    const srpFaqListColumn = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "id", // String-based value accessors!
        headerStyle: { textAlign: "left" },
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).faq.tableList_head1,
        accessor: "ques", // String-based value accessors!\
        headerStyle: { textAlign: "left" },
        width: 300,
      },
      {
        Header: switchIntl(toggleLng).faq.tableList_head2,
        accessor: "ans", // String-based value accessors!
        headerStyle: { textAlign: "left" },
        width: 300,
      },
      {
        Header: switchIntl(toggleLng).faq.tableList_head3,
        accessor: "member_id",
        headerStyle: { textAlign: "left" },
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
        Cell: (val) => (
          <div>
            <div
              style={{ color: "#0DA1C4", textDecoration: "underline" }}
              onClick={() => self.openMessageModal("srp", val.original.id)}
            >
              {switchIntl(toggleLng).faq.seedetail}
            </div>
            &nbsp;
            <div
              style={{ color: "#0DA1C4", textDecoration: "underline" }}
              onClick={() => self.removeFaq("srp", val.original.id)}
            >
              {switchIntl(toggleLng).faq.remove}
            </div>
          </div>
        ),
      },
    ];

    const psurveyFaqListColumn = [
      {
        Header: switchIntl(toggleLng).faq.no,
        accessor: "id", // String-based value accessors!
        headerStyle: { textAlign: "left" },
        width: 80,
        filterable: false,
        Cell: (row) => {
          return JSON.stringify(row.row._index + 1);
        },
      },
      {
        Header: switchIntl(toggleLng).faq.tableList_head1,
        accessor: "ques", // String-based value accessors!\
        headerStyle: { textAlign: "left" },
        width: 300,
      },
      {
        Header: switchIntl(toggleLng).faq.tableList_head2,
        accessor: "ans", // String-based value accessors!
        headerStyle: { textAlign: "left" },
        width: 300,
      },
      {
        Header: switchIntl(toggleLng).faq.tableList_head3,
        accessor: "member_id",
        headerStyle: { textAlign: "left" },
        filterable: false, // String-based value accessors!
        sortable: false, // String-based value accessors!
        Cell: (val) => (
          <div>
            <div
              style={{ color: "#0DA1C4", textDecoration: "underline" }}
              onClick={() => self.openMessageModal("psurvesy", val.original.id)}
            >
              {switchIntl(toggleLng).faq.seedetail}
            </div>
            &nbsp;
            <div
              style={{ color: "#0DA1C4", textDecoration: "underline" }}
              onClick={() => self.removeFaq("psurvey", val.original.id)}
            >
              {switchIntl(toggleLng).faq.remove}
            </div>
          </div>
        ),
      },
    ];

    return (
      <div>
        <Dialog
          maxWidth="xl"
          open={
            this.state.showMessageModal === undefined ||
            this.state.showMessageModal === false
              ? false
              : true
          }
          transition={Transition}
          style={{ width: "100%" }}
          onClose={() =>
            this.setState({ showMessageModal: false, curMessageBox: false })
          }
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent
            style={{ backgroundColor: "#eeeeee", width: 1200 }}
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <Button
              onClick={() =>
                this.setState({ showMessageModal: false, curMessageBox: false })
              }
              justIcon
              round
              color="white"
              style={{ position: "absolute", right: "50px", zIndex: "100" }}
            >
              <Close className={classes.icons} />
            </Button>

            <h3>{switchIntl(toggleLng).faq.modalHeader}</h3>

            <br />
            <Row style={{ marginTop: "10px" }}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.3m" }}>
                      {switchIntl(toggleLng).faq.question}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      multiline
                      rows="4"
                      onChange={(e) =>
                        this.setState({ curQuestion: e.target.value })
                      }
                      width="100%"
                      style={{ width: "100%" }}
                      InputProps={{
                        style: { width: "100%" },
                        width: "100%",
                        value: this.state.curQuestion || "",
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div style={{ fontSize: "1.3m" }}>
                      {switchIntl(toggleLng).faq.answer}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      multiline
                      rows="4"
                      onChange={(e) =>
                        this.setState({ curAnswer: e.target.value })
                      }
                      width="100%"
                      style={{ width: "100%" }}
                      InputProps={{
                        style: { width: "100%" },
                        width: "100%",
                        value: this.state.curAnswer || "",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Button onClick={() => this.editFaq()}>
                  {switchIntl(this.props.toggleLng).faq.submitbutton}
                </Button>
              </Col>
            </Row>
          </DialogContent>
        </Dialog>
        {this.state.alert}
        <NavPills
          color="warning"
          tabs={[
            {
              tabButton: "PSurvey Application",
              tabContent: (
                <Row style={{ padding: "0px", margin: "0px" }}>
                  <Col xs={12}>
                    <Card style={{ padding: "15px" }}>
                      <CardBody>
                        <ReactTable
                          data={psurveyFaqList}
                          filterable
                          columns={psurveyFaqListColumn}
                          defaultPageSize={10}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "id",
                              asc: true,
                            },
                          ]}
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h4>
                          {switchIntl(this.props.toggleLng).faq.headerAdd}
                        </h4>
                      </CardHeader>

                      <CardBody>
                        เพิ่ม คำถาม:
                        <TextField
                          fullWidth
                          onChange={(e) =>
                            this.setState({
                              newQuestionPsurvey: e.target.value,
                            })
                          }
                          value={this.state.newQuestionPsurvey}
                        />
                        คำตอบ:
                        <TextField
                          fullWidth
                          onChange={(e) =>
                            this.setState({ newAnswerPsurvey: e.target.value })
                          }
                          value={this.state.newAnswerPsurvey}
                        />
                        รูปแบบ: {"PSurvey"}
                        <Button onClick={() => this.submitPsurveyFaq()}>
                          เพิ่ม
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ),
            },
            {
              tabButton: "SRP Application",
              tabContent: (
                <Row style={{ padding: "0px", margin: "0px" }}>
                  <Col xs={12}>
                    <Card style={{ padding: "15px" }}>
                      <CardBody>
                        <ReactTable
                          data={srpFaqList}
                          filterable
                          columns={srpFaqListColumn}
                          defaultPageSize={10}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          defaultSorted={[
                            {
                              id: "id",
                              asc: true,
                            },
                          ]}
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h4>
                          {switchIntl(this.props.toggleLng).faq.headerAdd}
                        </h4>
                      </CardHeader>

                      <CardBody>
                        เพิ่ม คำถาม:
                        <TextField
                          fullWidth
                          onChange={(e) =>
                            this.setState({ newQuestionSrp: e.target.value })
                          }
                          value={this.state.newQuestionSrp || ""}
                        />
                        คำตอบ:
                        <TextField
                          fullWidth
                          onChange={(e) =>
                            this.setState({ newAnswerSrp: e.target.value })
                          }
                          value={this.state.newAnswerSrp || ""}
                        />
                        รูปแบบ: {"Srp"}
                        <Button onClick={() => this.submitSrpFaq()}>
                          เพิ่ม
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withStyles(sweetAlertStyle)(Contact));
