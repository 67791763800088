/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes, { array } from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import { BeatLoader } from "react-spinners";
import * as config from "configure/configServer.js";

import villa_sec1_part4Object from "actions/survey/villa/villa_sec1_part4Data.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Close from "@material-ui/icons/Close";

import notiToggle from "actions/survey/notificationToggle.js";

import FormLabel from "@material-ui/core/FormLabel";
import Button from "components/CustomButtons/Button.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import defaultImage from "assets/img/image_placeholder.png";
import defaultAvatar from "assets/img/placeholder.jpg";
import defaultFile from "assets/img/image_fileholder.jpg";
import fileSuccess from "assets/img/image_fileSuccess.jpg";

import { connect } from "react-redux";

import setBoundary from "actions/survey/setBoundary.js";
import setProv from "actions/survey/setProvAmphoeTambon.js";
import imageuploading from "actions/uploading_image.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import project_datatable from "actions/survey/psurvey_editproj.js";

class ImageInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      default: "hello",
      service: "a",
      ownerType: "ownerA",
      file: [],
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      imageArray: [],
      base64Array: [],
      imgPlace: [],
      imgPlacePreview: defaultImage,
      imgCon: [],
      imgConPreview: defaultImage,
      imgBou: [],
      imgBouPreview: defaultImage,
      imgPlan: [],
      imgPlanPreview: defaultImage,
      fileSurvey: "",
      fileSurveyPreview: defaultFile,
      fileSurveyName: "",
      fileBorchure: "",
      fileBorchurePreview: defaultFile,
      fileBorchureName: "",
      fileQuote: "",
      fileQuotePreview: defaultFile,
      fileQuoteName: "",
      exImageSingle: [],
      exImageDouble: [],
      exImageTownvilla: [],
      exImageCommercial: [],
      exImageLand: [],
      errorNoti: false,
      img10Noti: false,
      imgLoading: false,
      file10Noti: false,
      filespeNoti: false,

      villa_saleofficeimg: "",
      villa_saleofficeimgLoading: false,
      villa_saleofficeimgState: "",
      villa_currentconstructimg: "",
      villa_currentconstructimgLoading: false,
      villa_currentconstructimgState: "",
      villa_projectplanimg: "",
      villa_projectplanimgLoading: false,
      villa_projectplanimgState: "",
      villa_brochuremapimg: "",
      villa_brochuremapimgLoading: false,
      villa_brochuremapimgState: "",
      villa_exhouseimg: [],
      villa_exhouseimg_single: [],
      villa_exhouseimg_double: [],
      villa_exhouseimg_townhouse: [],
      villa_exhouseimg_commercial: [],
      villa_exhouseimg_land: [],
      file_survey: "",
      file_surveyLoading: false,
      file_surveyState: "",
      file_borchure: "",
      file_borchureLoading: false,
      file_borchureState: "",
      file_quote: "",
      file_quoteLoading: false,
      file_quoteState: "",
    };
    this.handleOwnerType = this.handleOwnerType.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      this.props.project_datatable(undefined);
    } else {
      this.get_projectData(this.props.proj_dataTable);
    }
  }

  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  get_projectData = (value) => {
    const edit_data = value.data[0];
    const eximageParse =
      value.data[0].villa_exhouseimg &&
      value.data[0].villa_exhouseimg.length > 0 &&
      value.data[0].villa_exhouseimg[0] !== ""
        ? JSON.parse(value.data[0].villa_exhouseimg[0])
        : [];

    // console.log(eximageParse)
    var imgPlace =
      edit_data.villa_saleofficeimg[0] === ""
        ? []
        : config.requestUrl + edit_data.villa_saleofficeimg[0];
    var imgCon =
      edit_data.villa_currentconstructimg[0] === ""
        ? []
        : config.requestUrl + edit_data.villa_currentconstructimg[0];

    var imgPlan =
      edit_data.villa_projectplanimg[0] === ""
        ? []
        : config.requestUrl + edit_data.villa_projectplanimg[0];

    var imgBou =
      edit_data.villa_brochuremapimg[0] === ""
        ? []
        : config.requestUrl + edit_data.villa_brochuremapimg[0];

    this.setState({
      villa_saleofficeimg: edit_data.villa_saleofficeimg,
      villa_saleofficeimgState:
        edit_data.villa_saleofficeimg[0] === "" ? false : true,
      imgPlacePreview: imgPlace,
      imgPlace,
      villa_currentconstructimg: edit_data.villa_currentconstructimg,
      villa_currentconstructimgState:
        edit_data.villa_currentconstructimg[0] === "" ? false : true,
      imgConPreview: imgCon,
      imgCon,
      villa_brochuremapimg: edit_data.villa_brochuremapimg,
      villa_brochuremapimgState:
        edit_data.villa_brochuremapimg[0] === "" ? false : true,
      imgBouPreview: imgBou,
      imgBou,
      villa_projectplanimg: edit_data.villa_projectplanimg,
      villa_projectplanimgState:
        edit_data.villa_projectplanimg[0] === "" ? false : true,
      imgPlanPreview: imgPlan,
      imgPlan,

      villa_exhouseimg_single: eximageParse.house ? eximageParse.house : [],
      exImageSingle: eximageParse.house ? eximageParse.house : [],
      villa_exhouseimg_double: eximageParse.twinhouse
        ? eximageParse.twinhouse
        : [],
      exImageDouble: eximageParse.twinhouse ? eximageParse.twinhouse : [],
      villa_exhouseimg_townhouse: eximageParse.townhouse
        ? eximageParse.townhouse
        : [],
      exImageTownvilla: eximageParse.townhouse ? eximageParse.townhouse : [],
      villa_exhouseimg_commercial: eximageParse.commercialbuilding
        ? eximageParse.commercialbuilding
        : [],
      exImageCommercial: eximageParse.commercialbuilding
        ? eximageParse.commercialbuilding
        : [],
      villa_exhouseimg_land: eximageParse.land ? eximageParse.land : [],
      exImageLand: eximageParse.land ? eximageParse.land : [],

      fileSurvey: edit_data.villa_filesurvey,
      file_surveyState: edit_data.villa_filesurvey === "" ? false : true,
      fileSurveyPreview:
        edit_data.villa_filesurvey === "" ? defaultFile : fileSuccess,
      fileSurveyName: edit_data.villa_filesurvey,

      fileBorchure: edit_data.villa_filebrochure,
      file_borchureState: edit_data.villa_filebrochure === "" ? false : true,
      fileBorchurePreview:
        edit_data.villa_filebrochure === "" ? defaultFile : fileSuccess,
      fileBorchureName: edit_data.villa_filebrochure,

      fileQuote: edit_data.villa_filequotation,
      file_quoteState: edit_data.villa_filequotation === "" ? false : true,
      fileQuotePreview:
        edit_data.villa_filequotation === "" ? defaultFile : fileSuccess,
      fileQuoteName: edit_data.villa_filequotation,
    });
  };

  showNotification(place) {
    if (!this.state[place]) {
      var x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(
        function() {
          x[place] = false;
          this.setState(x);
        }.bind(this),
        1000
      );
    }
  }

  // handle trigger function
  // check add org trigger?
  validateRegister() {
    const {
      villa_saleofficeimg,
      villa_exhouseimg_single,
      villa_exhouseimg_double,
      villa_exhouseimg_townhouse,
      villa_exhouseimg_commercial,
      villa_exhouseimg_land,
      villa_currentconstructimg,
      villa_brochuremapimg,
      villa_projectplanimg,
      fileSurvey,
      fileBorchure,
      fileQuote,
    } = this.state;

    setTimeout(() => {
      let eximg_obj = {
        house: villa_exhouseimg_single,
        twinhouse: villa_exhouseimg_double,
        townhouse: villa_exhouseimg_townhouse,
        commercialbuilding: villa_exhouseimg_commercial,
        land: villa_exhouseimg_land,
      };

      var body = {
        villa_exhouseimg: [JSON.stringify(eximg_obj)],
        villa_saleofficeimg: [villa_saleofficeimg],
        villa_currentconstructimg: [villa_currentconstructimg],
        villa_brochuremapimg: [villa_brochuremapimg],
        villa_projectplanimg: [villa_projectplanimg],
        villa_filesurvey: fileSurvey === "" ? "N_A" : fileSurvey,
        villa_filebrochure: fileBorchure === "" ? "N_A" : fileBorchure,
        villa_filequotation: fileQuote === "" ? "N_A" : fileQuote,
      };

      this.props.villa_sec1_part4Object(body);
    }, 500);
  }

  // check all state
  validateState(state) {
    const {
      villa_saleofficeimgState,
      villa_currentconstructimgState,
      villa_projectplanimgState,
      villa_brochuremapimgState,
      file_surveyState,
      file_borchureState,
      file_quoteState,
    } = this.state;

    switch (state) {
      case "save":
        if (
          villa_saleofficeimgState === false ||
          villa_currentconstructimgState === false ||
          villa_projectplanimgState === false ||
          villa_brochuremapimgState === false ||
          file_surveyState === false ||
          file_borchureState === false ||
          file_quoteState === false
        ) {
          return false;
        } else {
          return true;
        }
      case "submit":
        if (
          villa_saleofficeimgState === true &&
          villa_currentconstructimgState === true &&
          villa_projectplanimgState === true &&
          villa_brochuremapimgState === true &&
          file_surveyState === true &&
          file_borchureState === true &&
          file_quoteState === true
        ) {
          return true;
        } else {
          this.setAllEmpty();
          return false;
        }
      default:
        break;
    }
  }

  setAllEmpty() {
    if (this.state.villa_saleofficeimg === "") {
      this.setState({ villa_saleofficeimgState: false });
    }
    if (this.state.villa_currentconstructimg === "") {
      this.setState({ villa_currentconstructimgState: false });
    }
    if (this.state.villa_projectplanimg === "") {
      this.setState({ villa_projectplanimgState: false });
    }
    if (this.state.villa_brochuremapimg === "") {
      this.setState({ villa_brochuremapimgState: false });
    }
    if (this.state.fileSurvey === "") {
      this.setState({ file_surveyState: false });
    }
    if (this.state.fileBorchure === "") {
      this.setState({ file_borchureState: false });
    }
    if (this.state.fileQuote === "") {
      this.setState({ file_quoteState: false });
    }
  }

  handleOwnerType(event) {
    this.setState({
      ownerType: event.target.value,
    });
  }

  fileInput = React.createRef();
  fileimgPlace = React.createRef();
  fileimgCon = React.createRef();
  fileimgBou = React.createRef();
  fileimgPlan = React.createRef();
  fileInputSurvey = React.createRef();
  fileInputBorchure = React.createRef();
  fileInputQuote = React.createRef();
  fileInputex_single = React.createRef();
  fileInputex_double = React.createRef();
  fileInputex_townhouse = React.createRef();
  fileInputex_commercial = React.createRef();
  fileInputex_land = React.createRef();

  handleExImageSeceltion = (event, name, state) => {
    event.preventDefault();

    let array = [];
    let base64array = [];
    let proId = this.props.project_id;

    if (proId != null) {
      var files = event.target.files;
      if (event.target.files.length === 0) {
      } else {
        if (files.length <= 5) {
          for (var x = 0; x < files.length; x++) {
            let file = files[x];
            let reader = new FileReader();
            reader.onload = function(event) {
              // Render thumbnail.
              let base64 = reader.result;
              array.push(base64);
              base64array.push(base64.split(",")[1]);
            };
            // Read in the image file as a data URL.
            reader.readAsDataURL(file);
          }

          setTimeout(() => {
            this.setState({
              [name]: array,
              [name + "Array"]: base64array,
              imgLoading: true,
            });

            var body = {
              projectid: proId,
              img: base64array,
              cat: state,
              form: "villa",
            };

            var request = new Request(config.API_addex_image, {
              method: "POST",
              headers: new Headers({ "Content-Type": "application/json" }),
              body: JSON.stringify(body),
            });

            fetch(request)
              .then((response) => response.json())
              .then((responseJson) => {
                // console.log(responseJson)
                this.setState({
                  [state]: responseJson.data,
                  imgLoading: false,
                });
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                // console.log(error);
              });
          }, 500);
        } else {
          this.showNotification("img10Noti");
        }
      }
    } else {
      this.setState({
        [name]: [],
        [name + "Array"]: [],
      });
      try {
        // console.log("fileInputex_"+name.substring(7).toLowerCase())
        this[
          "fileInputex_" + name.substring(7).toLowerCase()
        ].current.value = null;
      } catch {}
      this.showNotification("errorNoti");
    }
  };
  handleExImageRemove = (key, name, state) => {
    var array = [...this.state[name]]; // make a separate copy of the array
    var linkArray = [...this.state[state]];
    var index = key;
    if (index !== -1) {
      array.splice(index, 1);
      linkArray.splice(index, 1);
      this.setState({ [name]: array, [state]: linkArray });
    }
  };

  chk_imagePath(path) {
    if (path.charAt(0) === "/") {
      const mergePath = config.requestUrl + path;
      return mergePath;
    } else {
      return path;
    }
  }

  getImgPath = (base64, currentState, name) => {
    this.setState({
      [currentState + "Loading"]: true,
      [currentState + "State"]: false,
    });

    let body = {
      projectid: this.props.project_id,
      img: [base64],
      cat: currentState,
      form: "villa",
    };

    var request = new Request(config.API_add_image, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        if (responseJson.code === 200) {
          this.setState({
            [currentState + "Loading"]: false,
            [currentState + "State"]: true,
            [currentState]: responseJson.data[0],
          });
          this.props.imageuploading(false);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getFilePath = (files, name, nameState) => {
    this.setState({
      [nameState + "Loading"]: true,
      [nameState + "State"]: false,
    });
    let proId = this.props.project_id;

    var formData = new FormData();

    formData.append("id", proId);
    // console.log("");
    // console.log(formData);
    formData.append("attr", files);
    formData.append("form", "presurvey");
    for (var pair of formData.entries()) {
      // console.log(pair[0]);
      // console.log(pair[1]);
    }
    var request = new Request(config.API_submit_file, {
      method: "POST",
      body: formData,
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);

        this.setState({
          [name]: responseJson.data,
          [nameState + "Loading"]: false,
          [nameState + "State"]: true,
        });
        this.props.imageuploading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        // console.log(error);
      });
  };

  handleImageSection = (event, name, state) => {
    event.preventDefault();

    let proId = this.props.project_id;

    if (proId != null) {
      let reader = new FileReader();
      let file = event.target.files[0];

      if (event.target.files.length === 0 || file.size > 10485760) {
        if (event.target.files.length === 0) {
          this.showNotification("errorNoti");
        } else {
          this.showNotification("file10Noti");
        }
      } else {
        reader.onloadend = () => {
          let base64 = reader.result;
          this.setState({
            [name]: file,
            [name + "Preview"]: reader.result,
          });
          this.props.imageuploading(true);
          this.getImgPath(base64.split(",")[1], state, name);
        };
        reader.readAsDataURL(file);
      }
    } else {
      // console.log('ยังบ่มีเลข')
      this.setState({
        [name]: [],
        [name + "Preview"]: defaultImage,
      });
      this["file" + name].current.value = null;
      this.showNotification("errorNoti");
    }
  };

  handleFileSection = (event, name, state) => {
    event.preventDefault();

    let proId = this.props.project_id;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]/;

    if (proId != null) {
      let file = event.target.files[0];

      if (event.target.files.length === 0) {
      } else {
        if (format.test(file.name)) {
          this.showNotification("filespeNoti");
        } else {
          if (file.size < 10485760) {
            this.setState({
              [name]: file,
              [name + "Preview"]: fileSuccess,
              [name + "Name"]: file.name,
            });
            this.props.imageuploading(true);
            this.getFilePath(file, name, state);
          } else {
            this.showNotification("file10Noti");
          }
        }
      }
    } else {
      this.setState({
        [name]: "",
        [name + "Preview"]: defaultFile,
        [name + "Name"]: "",
      });
      try {
        this["fileInput" + name.substring(4)].current.value = null;
      } catch {}
      this.showNotification("errorNoti");
    }
  };

  handleClickSection = (refname) => {
    switch (refname) {
      case "imgPlace":
        this.fileimgPlace.current.click();
        break;
      case "imgCon":
        this.fileimgCon.current.click();
        break;
      case "imgBou":
        this.fileimgBou.current.click();
        break;
      case "imgPlan":
        this.fileimgPlan.current.click();
        break;
      case "fileSurvey":
        this.fileInputSurvey.current.click();
        break;
      case "fileBorchure":
        this.fileInputBorchure.current.click();
        break;
      case "fileQuote":
        this.fileInputQuote.current.click();
        break;
      case "exhouse_single":
        this.fileInputex_single.current.click();
        break;
      case "exhouse_double":
        this.fileInputex_double.current.click();
        break;
      case "exhouse_townhouse":
        this.fileInputex_townhouse.current.click();
        break;
      case "exhouse_commercial":
        this.fileInputex_commercial.current.click();
        break;
      case "exhouse_land":
        this.fileInputex_land.current.click();
        break;
      default:
        break;
    }
  };
  handleRemoveSection = (refname) => {
    switch (refname) {
      case "imgPlace":
        this.setState({
          imgPlace: [],
          imgPlacePreview: defaultImage,
          villa_saleofficeimg: "",
          villa_saleofficeimgState: false,
        });
        this.fileimgPlace.current.value = null;
        break;
      case "imgCon":
        this.setState({
          imgCon: [],
          imgConPreview: defaultImage,
          villa_currentconstructimg: "",
          villa_currentconstructimgState: false,
        });
        this.fileimgCon.current.value = null;
        break;
      case "imgBou":
        this.setState({
          imgBou: [],
          imgBouPreview: defaultImage,
          villa_brochuremapimg: "",
          villa_brochuremapimgState: false,
        });
        this.fileimgBou.current.value = null;
        break;
      case "imgPlan":
        this.setState({
          imgPlan: [],
          imgPlanPreview: defaultImage,
          villa_projectplanimg: "",
          villa_projectplanimgState: false,
        });
        this.fileimgPlan.current.value = null;
        break;
      case "fileSurvey":
        this.setState({
          fileSurvey: "",
          fileSurveyPreview: defaultFile,
          file_survey: "",
          fileSurveyName: "",
          file_surveyState: false,
        });
        this.fileInputSurvey.current.value = null;
        break;
      case "fileBorchure":
        this.setState({
          fileBorchure: "",
          fileBorchurePreview: defaultFile,
          file_borchure: "",
          fileBorchureName: "",
          file_borchureState: false,
        });
        this.fileInputBorchure.current.value = null;
        break;
      case "fileQuote":
        this.setState({
          fileQuote: "",
          fileQuotePreview: defaultFile,
          file_quote: "",
          fileQuoteName: "",
          file_quoteState: false,
        });
        this.fileInputQuote.current.value = null;
        break;
      default:
        break;
    }
  };
  resetAllState(state) {
    this.setState({
      villa_saleofficeimgState: "",
      villa_currentconstructimgState: "",
      villa_projectplanimgState: "",
      villa_brochuremapimgState: "",
      file_surveyState: "",
      file_borchureState: "",
      file_quoteState: "",
    });
  }
  // Render start here
  render() {
    const { classes } = this.props;
    const {
      imagePreviewUrl,
      imgPlace,
      imgPlacePreview,
      imgCon,
      imgConPreview,
      imgBou,
      imgBouPreview,
      imgPlan,
      imgPlanPreview,
      fileSurvey,
      fileSurveyPreview,
      fileSurveyName,
      fileBorchure,
      fileBorchurePreview,
      fileBorchureName,
      fileQuote,
      fileQuotePreview,
      fileQuoteName,
      exImageSingle,
      exImageDouble,
      exImageTownvilla,
      exImageCommercial,
      exImageLand,
      villa_exhouseimg_single,
      villa_exhouseimg_commercial,
      villa_exhouseimg_double,
      villa_exhouseimg_land,
      villa_exhouseimg_townhouse,
    } = this.state;

    const loadingCard = (
      <div className="card_loading" style={{ top: 0 }}>
        <BeatLoader
          sizeUnit={"px"}
          size={15}
          color={"#ffffff"}
          loading={true}
        />
      </div>
    );

    let exhouse_single = (
      <div className="fileinput text-center">
        <input
          type="file"
          multiple
          accept=".jpg,.jpeg,.png"
          onChange={(event) =>
            this.handleExImageSeceltion(
              event,
              "exImageSingle",
              "villa_exhouseimg_single"
            )
          }
          ref={this.fileInputex_single}
        />
        <div className={"resize_imageupload thumbnail"}>
          <img key={imagePreviewUrl} src={imagePreviewUrl} alt="..." />
        </div>
        <div>
          {exImageSingle && exImageSingle.length <= 0 ? (
            <Button
              size="sm"
              color="rose"
              onClick={() => this.handleClickSection("exhouse_single")}
            >
              Select image
            </Button>
          ) : (
            <span>
              <Button
                size="sm"
                color="rose"
                onClick={() =>
                  this.setState({
                    exImageSingle: [],
                    villa_exhouseimg_single: [],
                  })
                }
              >
                Clear
              </Button>
            </span>
          )}
        </div>
      </div>
    );
    let exhouse_single_arrayimg = villa_exhouseimg_single.map((image, key) => {
      let index = key;
      return (
        <GridItem key={key} xs={12} sm={12} md={3} lg={3}>
          <div className="fileinput text-center image_container">
            <input type="file" multiple accept=".jpg,.jpeg,.png" />
            <div
              style={{
                position: "relative",
                backgroundColor: "rgba(0,0,0,0)",
                zIndex: "1000",
                width: "100%",
                height: "100%",
                left: 0,
                right: 0,
                cursor: "pointer",
              }}
              className={"resize_imageupload thumbnail"}
            >
              <a
                className={"resize_imageupload thumbnail"}
                href={config.requestUrl + image}
                // download={config.requestUrl + image}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  key={key}
                  src={this.chk_imagePath(exImageSingle[key])}
                  alt="..."
                />
              </a>

              {this.state.imgLoading ? loadingCard : null}
            </div>
            <div>
              <Button
                className="close_button_hover"
                onClick={() =>
                  this.handleExImageRemove(
                    index,
                    "exImageSingle",
                    "villa_exhouseimg_single"
                  )
                }
                style={{
                  width: "20px",
                  height: "20px",
                }}
                simple
              >
                <Close
                  className={classes.icons}
                  style={{ color: "darkgray" }}
                />
              </Button>
            </div>
          </div>
        </GridItem>
      );
    });

    let exhouse_double = (
      <div className="fileinput text-center">
        <input
          type="file"
          multiple
          accept=".jpg,.jpeg,.png"
          onChange={(event) =>
            this.handleExImageSeceltion(
              event,
              "exImageDouble",
              "villa_exhouseimg_double"
            )
          }
          ref={this.fileInputex_double}
        />
        <div className={"resize_imageupload thumbnail"}>
          <img key={imagePreviewUrl} src={imagePreviewUrl} alt="..." />
        </div>
        <div>
          {exImageDouble.length <= 0 ? (
            <Button
              size="sm"
              color="rose"
              onClick={() => this.handleClickSection("exhouse_double")}
            >
              Select image
            </Button>
          ) : (
            <span>
              <Button
                size="sm"
                color="rose"
                onClick={() =>
                  this.setState({
                    exImageDouble: [],
                    villa_exhouseimg_double: [],
                  })
                }
              >
                Clear
              </Button>
            </span>
          )}
        </div>
      </div>
    );
    let exhouse_double_arrayimg = villa_exhouseimg_double.map((image, key) => {
      let index = key;
      return (
        <GridItem key={image} xs={12} sm={12} md={3} lg={3}>
          <div className="fileinput text-center image_container">
            <input type="file" multiple accept=".jpg,.jpeg,.png" />
            <div
              style={{
                position: "relative",
                backgroundColor: "rgba(0,0,0,0)",
                zIndex: "1000",
                width: "100%",
                height: "100%",
                left: 0,
                right: 0,
                cursor: "pointer",
              }}
              className={"resize_imageupload thumbnail"}
            >
              <a
                className={"resize_imageupload thumbnail"}
                href={config.requestUrl + image}
                // download={config.requestUrl + image}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  key={key}
                  src={this.chk_imagePath(exImageDouble[key])}
                  alt="..."
                />
              </a>
              {this.state.imgLoading ? loadingCard : null}
            </div>
            <div>
              <Button
                className="close_button_hover"
                onClick={() =>
                  this.handleExImageRemove(
                    index,
                    "exImageDouble",
                    "villa_exhouseimg_double"
                  )
                }
                style={{
                  width: "20px",
                  height: "20px",
                }}
                simple
              >
                <Close
                  className={classes.icons}
                  style={{ color: "darkgray" }}
                />
              </Button>
            </div>
          </div>
        </GridItem>
      );
    });

    let exhouse_townhouse = (
      <div className="fileinput text-center">
        <input
          type="file"
          multiple
          accept=".jpg,.jpeg,.png"
          onChange={(event) =>
            this.handleExImageSeceltion(
              event,
              "exImageTownvilla",
              "villa_exhouseimg_townhouse"
            )
          }
          ref={this.fileInputex_townhouse}
        />
        <div className={"resize_imageupload thumbnail"}>
          <img key={imagePreviewUrl} src={imagePreviewUrl} alt="..." />
        </div>
        <div>
          {exImageTownvilla.length <= 0 ? (
            <Button
              size="sm"
              color="rose"
              onClick={() => this.handleClickSection("exhouse_townhouse")}
            >
              Select image
            </Button>
          ) : (
            <span>
              <Button
                size="sm"
                color="rose"
                onClick={() =>
                  this.setState({
                    exImageTownvilla: [],
                    villa_exhouseimg_townhouse: [],
                  })
                }
              >
                Clear
              </Button>
            </span>
          )}
        </div>
      </div>
    );
    let exhouse_townhouse_arrayimg = villa_exhouseimg_townhouse.map(
      (image, key) => {
        let index = key;
        return (
          <GridItem key={image} xs={12} sm={12} md={3} lg={3}>
            <div className="fileinput text-center image_container">
              <input type="file" multiple accept=".jpg,.jpeg,.png" />
              <div
                style={{
                  position: "relative",
                  backgroundColor: "rgba(0,0,0,0)",
                  zIndex: "1000",
                  width: "100%",
                  height: "100%",
                  left: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                className={"resize_imageupload thumbnail"}
              >
                <a
                  className={"resize_imageupload thumbnail"}
                  href={config.requestUrl + image}
                  // download={config.requestUrl + image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    key={key}
                    src={this.chk_imagePath(exImageTownvilla[key])}
                    alt="..."
                  />
                </a>
                {this.state.imgLoading ? loadingCard : null}
              </div>
              <div>
                <Button
                  className="close_button_hover"
                  onClick={() =>
                    this.handleExImageRemove(
                      index,
                      "exImageTownvilla",
                      "villa_exhouseimg_townhouse"
                    )
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  simple
                >
                  <Close
                    className={classes.icons}
                    style={{ color: "darkgray" }}
                  />
                </Button>
              </div>
            </div>
          </GridItem>
        );
      }
    );

    let exhouse_commercial = (
      <div className="fileinput text-center">
        <input
          type="file"
          multiple
          accept=".jpg,.jpeg,.png"
          onChange={(event) =>
            this.handleExImageSeceltion(
              event,
              "exImageCommercial",
              "villa_exhouseimg_commercial"
            )
          }
          ref={this.fileInputex_commercial}
        />
        <div className={"resize_imageupload thumbnail"}>
          <img key={imagePreviewUrl} src={imagePreviewUrl} alt="..." />
        </div>
        <div>
          {exImageCommercial.length <= 0 ? (
            <Button
              size="sm"
              color="rose"
              onClick={() => this.handleClickSection("exhouse_commercial")}
            >
              Select image
            </Button>
          ) : (
            <span>
              <Button
                size="sm"
                color="rose"
                onClick={() =>
                  this.setState({
                    exImageCommercial: [],
                    villa_exhouseimg_commercial: [],
                  })
                }
              >
                Clear
              </Button>
            </span>
          )}
        </div>
      </div>
    );
    let exhouse_commercial_arrayimg = villa_exhouseimg_commercial.map(
      (image, key) => {
        let index = key;
        return (
          <GridItem key={image} xs={12} sm={12} md={3} lg={3}>
            <div className="fileinput text-center image_container">
              <input type="file" multiple accept=".jpg,.jpeg,.png" />
              <div
                style={{
                  position: "relative",
                  backgroundColor: "rgba(0,0,0,0)",
                  zIndex: "1000",
                  width: "100%",
                  height: "100%",
                  left: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                className={"resize_imageupload thumbnail"}
              >
                <a
                  className={"resize_imageupload thumbnail"}
                  href={config.requestUrl + image}
                  // download={config.requestUrl + image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    key={key}
                    src={this.chk_imagePath(exImageCommercial[key])}
                    alt="..."
                  />
                </a>
                {this.state.imgLoading ? loadingCard : null}
              </div>
              <div>
                <Button
                  className="close_button_hover"
                  onClick={() =>
                    this.handleExImageRemove(
                      index,
                      "exImageCommercial",
                      "villa_exhouseimg_commercial"
                    )
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  simple
                >
                  <Close
                    className={classes.icons}
                    style={{ color: "darkgray" }}
                  />
                </Button>
              </div>
            </div>
          </GridItem>
        );
      }
    );

    let exhouse_land = (
      <div className="fileinput text-center">
        <input
          type="file"
          multiple
          accept=".jpg,.jpeg,.png"
          onChange={(event) =>
            this.handleExImageSeceltion(
              event,
              "exImageLand",
              "villa_exhouseimg_land"
            )
          }
          ref={this.fileInputex_land}
        />
        <div className={"resize_imageupload thumbnail"}>
          <img key={imagePreviewUrl} src={imagePreviewUrl} alt="..." />
        </div>
        <div>
          {exImageLand.length <= 0 ? (
            <Button
              size="sm"
              color="rose"
              onClick={() => this.handleClickSection("exhouse_land")}
            >
              Select image
            </Button>
          ) : (
            <span>
              <Button
                size="sm"
                color="rose"
                onClick={() =>
                  this.setState({
                    exImageLand: [],
                    villa_exhouseimg_land: [],
                  })
                }
              >
                Clear
              </Button>
            </span>
          )}
        </div>
      </div>
    );
    let exhouse_land_arrayimg = villa_exhouseimg_land.map((image, key) => {
      let index = key;
      return (
        <GridItem key={image} xs={12} sm={12} md={3} lg={3}>
          <div className="fileinput text-center image_container">
            <input type="file" multiple accept=".jpg,.jpeg,.png" />
            <div
              style={{
                position: "relative",
                backgroundColor: "rgba(0,0,0,0)",
                zIndex: "1000",
                width: "100%",
                height: "100%",
                left: 0,
                right: 0,
                cursor: "pointer",
              }}
              className={"resize_imageupload thumbnail"}
            >
              <a
                className={"resize_imageupload thumbnail"}
                href={config.requestUrl + image}
                // download={config.requestUrl + image}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  key={key}
                  src={this.chk_imagePath(exImageLand[key])}
                  alt="..."
                />
              </a>
              {this.state.imgLoading ? loadingCard : null}
            </div>
            <div>
              <Button
                className="close_button_hover"
                onClick={() =>
                  this.handleExImageRemove(
                    index,
                    "exImageLand",
                    "villa_exhouseimg_land"
                  )
                }
                style={{
                  width: "20px",
                  height: "20px",
                }}
                simple
              >
                <Close
                  className={classes.icons}
                  style={{ color: "darkgray" }}
                />
              </Button>
            </div>
          </div>
        </GridItem>
      );
    });

    return (
      <div>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message="กรุณาเลือกตำแหน่งโครงการ ก่อนทำการเพิ่มรูปภาพ"
          open={this.state.errorNoti}
          closeNotification={() => this.setState({ errorNoti: false })}
          close
        />
        <Snackbar
          place="tc"
          color="warning"
          icon={AddAlert}
          message="ไม่สามารถเพิ่มรูปภาพเกิน 5 รูป"
          open={this.state.img10Noti}
          closeNotification={() => this.setState({ img10Noti: false })}
          close
        />
        <Snackbar
          place="tc"
          color="warning"
          icon={AddAlert}
          message="ไม่สามารถอัพโหลดไฟล์ขนาดเกิน 10 MB ได้"
          open={this.state.file10Noti}
          closeNotification={() => this.setState({ file10Noti: false })}
          close
        />
        <Snackbar
          place="tc"
          color="warning"
          icon={AddAlert}
          message="ไฟล์ไม่สามารถมีตัวอักษรพิเศษได้"
          open={this.state.filespeNoti}
          closeNotification={() => this.setState({ filespeNoti: false })}
          close
        />
        <h4>
          <b>
            รูปภาพที่ต้องแนบ (จำนวน 1 รูป ขนาดไม่เกิน 10 MB *.jpg .jpeg .png)
          </b>
        </h4>
        {/* Part 01 */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    width: "100%",
                    color:
                      this.state.villa_saleofficeimgState === false
                        ? "red"
                        : "black",
                  }}
                >
                  สำนักงานขาย
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(event) =>
                      this.handleImageSection(
                        event,
                        "imgPlace",
                        "villa_saleofficeimg"
                      )
                    }
                    ref={this.fileimgPlace}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {!(imgPlace.length <= 0) ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={
                          config.requestUrl + this.state.villa_saleofficeimg
                        }
                        // download={
                        //   config.requestUrl + this.state.villa_saleofficeimg
                        // }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img key={"imgPlace"} src={imgPlacePreview} alt="..." />
                      </a>
                    ) : (
                      <div className={"resize_imageupload thumbnail"}>
                        <img key={"imgPlace"} src={defaultImage} alt="..." />
                      </div>
                    )}

                    {this.state.villa_saleofficeimgLoading ? loadingCard : null}
                  </div>
                  <div>
                    {imgPlace.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("imgPlace")}
                      >
                        Select image
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClickSection("imgPlace")}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemoveSection("imgPlace")}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    width: "100%",
                    color:
                      this.state.villa_currentconstructimgState === false
                        ? "red"
                        : "black",
                  }}
                >
                  สภาพการก่อสร้าง
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(event) =>
                      this.handleImageSection(
                        event,
                        "imgCon",
                        "villa_currentconstructimg"
                      )
                    }
                    ref={this.fileimgCon}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {!(imgCon.length <= 0) ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={
                          config.requestUrl +
                          this.state.villa_currentconstructimg
                        }
                        // download={
                        //   config.requestUrl +
                        //   this.state.villa_currentconstructimg
                        // }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img key={"imgCon"} src={imgConPreview} alt="..." />
                      </a>
                    ) : (
                      <div className={"resize_imageupload thumbnail"}>
                        <img key={"imgCon"} src={defaultImage} alt="..." />
                      </div>
                    )}
                  </div>
                  <div>
                    {imgCon.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("imgCon")}
                      >
                        Select image
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClickSection("imgCon")}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemoveSection("imgCon")}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    width: "100%",
                    color:
                      this.state.villa_brochuremapimgState === false
                        ? "red"
                        : "black",
                  }}
                >
                  แผนที่ตามโบรชัวร์(ถ้ามี)
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(event) =>
                      this.handleImageSection(
                        event,
                        "imgBou",
                        "villa_brochuremapimg"
                      )
                    }
                    ref={this.fileimgBou}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {!(imgBou.length <= 0) ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={
                          config.requestUrl + this.state.villa_brochuremapimg
                        }
                        // download={
                        //   config.requestUrl + this.state.villa_brochuremapimg
                        // }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img key={"imgBou"} src={imgBouPreview} alt="..." />
                      </a>
                    ) : (
                      <div className={"resize_imageupload thumbnail"}>
                        <img key={"imgBou"} src={defaultImage} alt="..." />
                      </div>
                    )}
                  </div>
                  <div>
                    {imgBou.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("imgBou")}
                      >
                        Select image
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClickSection("imgBou")}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemoveSection("imgBou")}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    width: "100%",
                    color:
                      this.state.villa_projectplanimgState === false
                        ? "red"
                        : "black",
                  }}
                >
                  แผนผังโครงการ(ถ้ามี)
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(event) =>
                      this.handleImageSection(
                        event,
                        "imgPlan",
                        "villa_projectplanimg"
                      )
                    }
                    ref={this.fileimgPlan}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {!(imgPlan.length <= 0) ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={
                          config.requestUrl + this.state.villa_projectplanimg
                        }
                        // download={
                        //   config.requestUrl + this.state.villa_projectplanimg
                        // }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img key={"imgPlan"} src={imgPlanPreview} alt="..." />
                      </a>
                    ) : (
                      <div className={"resize_imageupload thumbnail"}>
                        <img key={"imgPlan"} src={defaultImage} alt="..." />
                      </div>
                    )}
                  </div>
                  <div>
                    {imgPlan.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("imgPlan")}
                      >
                        Select image
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClickSection("imgPlan")}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemoveSection("imgPlan")}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        {/* Part 02 */}
        <h4>
          <b>ไฟล์ที่ต้องแนบ (จำนวน 1 ไฟล์ ขนาดไม่เกิน 10 MB *.zip .rar .7z)</b>
        </h4>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    width: "100%",
                    color:
                      this.state.file_surveyState === false ? "red" : "black",
                  }}
                >
                  แบบสอบถาม
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept=".zip,.rar,.7z"
                    onChange={(event) =>
                      this.handleFileSection(event, "fileSurvey", "file_survey")
                    }
                    ref={this.fileInputSurvey}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {fileSurvey.length > 0 ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={config.requestUrl + fileSurvey}
                        download={config.requestUrl + fileSurvey}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          key={fileSurvey}
                          src={fileSurveyPreview}
                          alt="..."
                        />
                      </a>
                    ) : (
                      <div className={"resize_imageupload thumbnail"}>
                        <img
                          key={fileSurvey}
                          src={fileSurveyPreview}
                          alt="..."
                        />
                      </div>
                    )}
                    <p className="file_name">{fileSurveyName}</p>
                    {this.state.file_surveyLoading ? loadingCard : null}
                  </div>
                  <div>
                    {fileSurvey.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("fileSurvey")}
                      >
                        Select File
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClickSection("fileSurvey")}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemoveSection("fileSurvey")}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    width: "100%",
                    color:
                      this.state.file_borchureState === false ? "red" : "black",
                  }}
                >
                  โบรชัวร์
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept=".zip,.rar,.7z"
                    onChange={(event) =>
                      this.handleFileSection(
                        event,
                        "fileBorchure",
                        "file_borchure"
                      )
                    }
                    ref={this.fileInputBorchure}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {fileBorchure.length > 0 ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={config.requestUrl + fileBorchure}
                        target="_blank"
                        download={config.requestUrl + fileBorchure}
                        rel="noopener noreferrer"
                      >
                        <img
                          key={fileBorchure}
                          src={fileBorchurePreview}
                          alt="..."
                        />
                      </a>
                    ) : (
                      <div className={"resize_imageupload thumbnail"}>
                        <img
                          key={fileBorchure}
                          src={fileBorchurePreview}
                          alt="..."
                        />
                      </div>
                    )}
                    <p className="file_name">{fileBorchureName}</p>
                    {this.state.file_borchureLoading ? loadingCard : null}
                  </div>
                  <div>
                    {fileBorchure.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("fileBorchure")}
                      >
                        Select File
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() =>
                            this.handleClickSection("fileBorchure")
                          }
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() =>
                            this.handleRemoveSection("fileBorchure")
                          }
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={3} lg={3}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    width: "100%",
                    color:
                      this.state.file_quoteState === false ? "red" : "black",
                  }}
                >
                  ใบเสนอราคา
                </FormLabel>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept=".zip,.rar,.7z"
                    onChange={(event) =>
                      this.handleFileSection(event, "fileQuote", "file_quote")
                    }
                    ref={this.fileInputQuote}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {fileQuote.length > 0 ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={config.requestUrl + fileQuote}
                        target="_blank"
                        download={config.requestUrl + fileQuote}
                        rel="noopener noreferrer"
                      >
                        <img key={fileQuote} src={fileQuotePreview} alt="..." />
                      </a>
                    ) : (
                      <div className={"resize_imageupload thumbnail"}>
                        <img key={fileQuote} src={fileQuotePreview} alt="..." />
                      </div>
                    )}
                    <p className="file_name">{fileQuoteName}</p>
                    {this.state.file_quoteLoading ? loadingCard : null}
                  </div>
                  <div>
                    {fileQuote.length <= 0 ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClickSection("fileQuote")}
                      >
                        Select File
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClickSection("fileQuote")}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemoveSection("fileQuote")}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        {/* Part 03 */}
        <h5 style={{ marginTop: "40px", marginBottom: "0px" }}>
          <b>บ้านตัวอย่าง (เพิ่มได้สูงสุดประเภทละ 5 รูป *.jpg .jpeg .png)</b>
        </h5>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    fontSize: "16px",
                  }}
                >
                  บ้านเดี่ยว
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                {exhouse_single}
              </GridItem>
              {exhouse_single_arrayimg}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    fontSize: "16px",
                  }}
                >
                  บ้านแฝด
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                {exhouse_double}
              </GridItem>
              {exhouse_double_arrayimg}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    fontSize: "16px",
                  }}
                >
                  ทาวน์เฮาส์ หรือ ทาวน์โฮม
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                {exhouse_townhouse}
              </GridItem>
              {exhouse_townhouse_arrayimg}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    fontSize: "16px",
                  }}
                >
                  อาคารพาณิชย์
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                {exhouse_commercial}
              </GridItem>
              {exhouse_commercial_arrayimg}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    fontSize: "16px",
                  }}
                >
                  ที่ดินเปล่า
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                {exhouse_land}
              </GridItem>
              {exhouse_land_arrayimg}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ImageInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBoundary: (payload) => dispatch(setBoundary(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  notiToggle: (payload) => dispatch(notiToggle(payload)),
  villa_sec1_part4Object: (payload) =>
    dispatch(villa_sec1_part4Object(payload)),
  imageuploading: (bool) => dispatch(imageuploading(bool)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(ImageInfo));
